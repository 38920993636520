import { Guid } from '@samc/common';
import Methods from './Methods';
import { Role, User } from '../models';
import { ValidationErrors } from '../validators';

export default class RolesClient {
  private methods: Methods;

  constructor(methods: Methods) {
    this.methods = methods;
  }

  async listRoles() {
    const response = await this.methods.get('role');
    const json = await response.json();
    const roles = new Array<Role>();
    // eslint-disable-next-line no-restricted-syntax
    for (const model of json) {
      roles.push(Role.fromJson(model));
    }
    return roles;
  }

  async downloadRolesExport() {
    const type = 'application/vnd.ms-excel';
    await this.methods.download(
      await this.methods.get('role', [{ name: 'Accept', value: type }]),
      type,
      'Roles.xlsx',
    );
  }

  async getRole(id: Guid): Promise<[boolean, Role | null]> {
    const response = await this.methods.get(`role/${id.toString()}`);
    const success = await this.methods.handleErrors('Getting a role', response);
    if (!success) {
      return [false, null];
    }
    const json = await response.json();
    return [true, Role.fromJson(json)];
  }

  async listUsersForRole(id: Guid): Promise<[boolean, Array<User>]> {
    const response = await this.methods.get(`role/${id.toString()}/users`);
    const success = await this.methods.handleErrors(
      'Getting users for role',
      response,
    );
    const users = new Array<User>();

    if (success) {
      // eslint-disable-next-line no-restricted-syntax
      for (const model of await response.json()) {
        users.push(User.fromJson(model));
      }
    }
    return [success, users];
  }

  async createRole(
    role: Role,
    updateValidationErrors: (
      update: (errors: ValidationErrors) => void,
    ) => void,
  ): Promise<[boolean, Guid]> {
    const response = await this.methods.post('role', role.toCreateRequest());
    const success = await this.methods.handleErrors(
      'Creating new role',
      response,
      updateValidationErrors,
    );
    let id = Guid.createEmpty();
    if (success) {
      const idString = await response.text();
      id = Guid.parse(idString.replace(/['"]+/g, ''));
    }
    return [success, id];
  }

  async editRole(
    role: Role,
    updateValidationErrors: (
      update: (errors: ValidationErrors) => void,
    ) => void,
  ): Promise<boolean> {
    const id = role.id.toString();
    const response = await this.methods.put(`role/${id}`, role.toEditRequest());
    return this.methods.handleErrors(
      `Editing role with id ${id}`,
      response,
      updateValidationErrors,
    );
  }

  async updateRoleEntitlements(
    roleId: Guid,
    addedEntitlements: Guid[],
    removedEntitlements: Guid[],
  ) {
    const results = await Promise.all([
      this.addRoleEntitlements(roleId, addedEntitlements),
      this.removeRoleEntitlements(roleId, removedEntitlements),
    ]);
    return results.every(r => r);
  }

  async addRoleEntitlements(roleId: Guid, entitlementIds: Guid[]) {
    if (entitlementIds.length) {
      const response = await this.methods.patch(
        `role/${roleId.toString()}/entitlements`,
        {
          remove: false,
          entitlementIds: entitlementIds.map(r => r.toString()),
        },
      );
      return this.methods.handleErrors('Adding roles for user', response);
    }
    return true;
  }

  async removeRoleEntitlements(roleId: Guid, entitlementIds: Guid[]) {
    if (entitlementIds.length) {
      const response = await this.methods.patch(
        `role/${roleId.toString()}/entitlements`,
        {
          remove: true,
          entitlementIds: entitlementIds.map(r => r.toString()),
        },
      );
      return this.methods.handleErrors('Removing roles for user', response);
    }
    return true;
  }

  async deleteRole(roleId: Guid) {
    const response = await this.methods.delete(`role/${roleId.toString()}`);
    return this.methods.handleErrors('Deleting role', response);
  }
}
