import * as React from 'react';
import { OrderContextData } from './types';
import { reducer } from './reducer';
import initialState from './initialState';
import useApraisalActions from './appraisal/useAppraisalActions';
import useOrderActions from './useActions';
import useIndexingActions from './indexing/useIndexingActions';
import useBPOActions from './bpo/useBPOActions';
import useSecurentActions from './securent/useSecurentActions';
import useAUSActions from './aus/useAUSActions';

const OrderContext = React.createContext<OrderContextData>(
  {} as OrderContextData,
);
OrderContext.displayName = 'OrderContext';

interface OrderProviderProps {
  children: React.ReactNode;
}

export function OrderProvider(props: OrderProviderProps) {
  const [order, dispatch] = React.useReducer(reducer, initialState);

  const orderActions = useOrderActions({ dispatch });
  const appraisalActions = useApraisalActions({ dispatch });
  const indexingActions = useIndexingActions({ dispatch });
  const bpoActions = useBPOActions({ dispatch });
  const securentActions = useSecurentActions({ dispatch });
  const ausActions = useAUSActions({ dispatch });

  const value = React.useMemo(
    () =>
      ({
        order,
        orderActions,
        appraisalActions,
        indexingActions,
        bpoActions,
        securentActions,
        ausActions,
      }) as OrderContextData,
    [
      appraisalActions,
      ausActions,
      bpoActions,
      indexingActions,
      order,
      orderActions,
      securentActions,
    ],
  );

  return <OrderContext.Provider value={value} {...props} />;
}

export function useOrder(): OrderContextData {
  const context = React.useContext(OrderContext);

  if (Object.keys(context).length === 0) {
    throw new Error('useOrder must be used within the OrderProvider');
  }
  return context;
}
