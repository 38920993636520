/* eslint-disable react/function-component-definition */
import React, { useCallback } from 'react';
import { Role } from '../../../models';
import { ValidationErrors } from '../../../validators';
import Field from '../Field';

type RoleFieldsProps = {
  role?: Role;
  dirtyFields: string[];
  validationErrors: ValidationErrors;
  isReadOnly?: boolean;
  wasSaveAttempted: boolean;
  update: (propertyName: string, action: (role: Role) => void) => void;
};

const RoleFields: React.FC<RoleFieldsProps> = ({
  role,
  dirtyFields,
  validationErrors,
  isReadOnly,
  wasSaveAttempted,
  update,
}) => {
  const ref = useCallback((node: HTMLInputElement) => {
    if (node) {
      node.focus();
      node.select();
    }
  }, []);

  if (!role) {
    return null;
  }

  return (
    <div className="pb-2">
      <Field
        label="Name"
        name="displayName"
        dirtyFields={dirtyFields}
        isRequired
        model={role}
        isReadOnly={isReadOnly}
        validationErrors={validationErrors}
        wasSaveAttempted={wasSaveAttempted}
      >
        <input
          ref={ref}
          type="text"
          value={role.displayName}
          onChange={e =>
            update('displayName', r => {
              r.displayName = e.target.value;
            })
          }
        />
      </Field>
      <Field
        label="Description"
        name="description"
        dirtyFields={dirtyFields}
        isRequired
        model={role}
        validationErrors={validationErrors}
        isReadOnly={isReadOnly}
        wasSaveAttempted={wasSaveAttempted}
      >
        <input
          type="text"
          value={role.description}
          onChange={e =>
            update('description', r => {
              r.description = e.target.value;
            })
          }
        />
      </Field>
    </div>
  );
};

export default RoleFields;
