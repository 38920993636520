import React from 'react';

import { SecurentActionTypes, SecurentFile, UseSecurentActions } from './types';

interface Props {
  dispatch: (action: SecurentActionTypes) => void;
}

export default function useApraisalActions({
  dispatch,
}: Props): UseSecurentActions {
  const onChangeFiles = React.useCallback(
    (files: SecurentFile[], stagingId: string) => {
      dispatch({
        type: 'SET_SECURENT_FILES',
        payload: { files, stagingId },
      });
    },
    [dispatch],
  );

  const onRemoveFile = React.useCallback(
    (fileStagingId: string) => {
      dispatch({
        type: 'REMOVE_SECURENT_FILES',
        payload: { fileStagingId },
      });
    },
    [dispatch],
  );

  return {
    onChangeFiles,
    onRemoveFile,
  };
}
