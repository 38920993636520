import { AxiosError } from 'axios';
import React from 'react';
import { useMutation } from '../hooks/useMutation';
import api from '../services/api';

export async function removeAUSLoanFile({ stagingId }: { stagingId: string }) {
  await api.delete('AUSOrder/loanFileStaging', {
    params: { stagingId },
  });
}

export function useRemoveAUSLoanFile() {
  return useMutation<void, AxiosError<ErrorData>, { stagingId: string }>(
    React.useCallback(({ stagingId }) => removeAUSLoanFile({ stagingId }), []),
  );
}
