import { AxiosError } from 'axios';
import React from 'react';
import { useMutation } from '../hooks/useMutation';
import { BPOLoanData, BPOLoanValidationData } from '../context/order/bpo/types';
import {
  calculateBPOOrderResponse,
  GetBPOOrderResponse,
} from '../queries/useBPOOrder';
import api, { BPO_ENDPOINT_PATH } from '../services/api';
import { queryClient } from '../services/queryClient';

export type SetBPOLoanParams = {
  orderId: string;
  loanData: BPOLoanData;
};

async function setBPOLoan(formData: SetBPOLoanParams) {
  const response = await api.patch<GetBPOOrderResponse>(
    `/${BPO_ENDPOINT_PATH}/${formData.orderId}`,
    {
      orderId: formData.orderId,
      data: [
        {
          ...formData.loanData,
          addresses: formData.loanData.addresses.map(address => {
            if (address.bpoOrderStagingAddressId === 'edited') {
              address.bpoOrderStagingAddressId = undefined;
            }
            return address;
          }),
          errors: formData.loanData.errors?.map(error => {
            if (error.type !== 'Warning') {
              error.isDeleted = true;
            }
            return error;
          }),
        },
      ],
    },
  );
  queryClient.invalidateQueries(['bpoOrder', formData.orderId]);
  return calculateBPOOrderResponse(response.data);
}

export function useSetBPOLoan() {
  return useMutation<
    BPOLoanValidationData,
    AxiosError<ErrorData>,
    SetBPOLoanParams
  >(React.useCallback(params => setBPOLoan(params), []));
}
