/* eslint-disable react/function-component-definition */
import React, { useEffect, useState } from 'react';
import { Role, Entitlement, User } from '../../../models';
import { useClient, useUserArray } from '../../../contexts';
import VerticalLists from '../../dragAndDrop/VerticalLists';
import Patience from '../../Patience';
import Tabs from '../../Tabs';
import { getAddedModels, getRemovedModels } from '../../../helpers/arrayDiffs';
import { columnDef } from '../../../helpers/columnDef';
import Grid from '../../grid/Grid';
import { FillRemainingContainer } from '../..';
import { RoleEditingFlags } from './RoleEditingFlags';

type Props = {
  originalState: Role;
  currentState: Role;
  roleEditingFlags: RoleEditingFlags;
  startingTab?: string;
  updateRole: (action: (r: Role) => void) => void;
  setAddedUsers: (list: User[]) => void;
  setRemovedUsers: (list: User[]) => void;
};

const RoleEntitlementsAndUsers: React.FC<Props> = ({
  originalState,
  currentState,
  roleEditingFlags,
  updateRole,
  setAddedUsers,
  setRemovedUsers,
  startingTab = 'Entitlements',
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [entitlements, setEntitlements] = useState(new Array<Entitlement>());
  const [originalAssignedUsers, setOriginalAssignedUsers] = useState(
    new Array<User>(),
  );
  const [assignedUsers, setAssignedUsers] = useState(new Array<User>());
  const users = useUserArray();
  const client = useClient();
  const [availableUsers, setAvailableUsers] = useState(new Array<User>());
  const filteredUsers = users
    ? User.RemoveSystemAccount(users)
    : new Array<User>();

  useEffect(() => {
    if (users) {
      setAvailableUsers(
        filteredUsers.filter(
          u => !assignedUsers.some(au => au.id.equals(u.id)),
        ),
      );
      // Find users that no longer exist, then reset assignedUsers to filter out those users.
      const deletedUsers = getRemovedModels(filteredUsers, assignedUsers);
      setAssignedUsers(getAddedModels(assignedUsers, deletedUsers));
      // Filter deleted users out of the original set, then correct addedUsers and updatedUsers to strip them out
      const original = getAddedModels(originalAssignedUsers, deletedUsers);
      const added = getAddedModels(assignedUsers, original);
      const removed = getRemovedModels(assignedUsers, original);
      setAddedUsers(added);
      setRemovedUsers(removed);
      setOriginalAssignedUsers(original);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  useEffect(() => {
    let cancelled = false;
    setIsLoaded(false);
    const promises = new Array<Promise<void>>();
    promises.push(
      client.entitlements.listEntitlements().then(newEntitlements => {
        if (!cancelled) {
          setEntitlements(
            newEntitlements.filter(
              e => !currentState.entitlements.some(re => re.id.equals(e.id)),
            ),
          );
        }
      }),
    );
    if (!originalState.id.isEmpty()) {
      promises.push(
        client.roles.listUsersForRole(originalState.id).then(res => {
          const [success, myUsers] = res;
          if (success && !cancelled) {
            const assigned = User.RemoveSystemAccount(myUsers);
            setAssignedUsers(assigned);
            setOriginalAssignedUsers(assigned);
            setAvailableUsers(getRemovedModels(assigned, filteredUsers));
          }
        }),
      );
    } else {
      setAvailableUsers(filteredUsers);
      setOriginalAssignedUsers(new Array<User>());
    }
    Promise.all(promises).then(() => {
      if (!cancelled) {
        setIsLoaded(true);
      }
    });
    return () => {
      cancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originalState]);

  const addUsers = (list: User[]) => {
    const assigned = assignedUsers.concat(list);
    setAssignedUsers(assigned);
    setAvailableUsers(getRemovedModels(list, availableUsers));
    setAddedUsers(getAddedModels(assigned, originalAssignedUsers));
  };

  const removeUsers = (list: User[]) => {
    const assigned = getAddedModels(assignedUsers, list);
    setAssignedUsers(assigned);
    setAvailableUsers(availableUsers.concat(list));
    setRemovedUsers(getRemovedModels(assigned, originalAssignedUsers));
  };

  const addEntitlements = (list: Entitlement[]) => {
    updateRole(r => {
      r.entitlements.push(...list);
      r.entitlements.sort((a, b) => a.order - b.order);
    });
    const available = getRemovedModels(list, entitlements);
    available.sort((a, b) => a.order - b.order);
    setEntitlements(available);
  };

  const removeEntitlements = (list: Entitlement[]) => {
    entitlements.push(...list);
    entitlements.sort((a, b) => a.order - b.order);
    setEntitlements(entitlements);
    updateRole(r => {
      r.entitlements = getAddedModels(r.entitlements, list);
      r.entitlements.sort((a, b) => a.order - b.order);
    });
  };

  const displayEntitlement = (model: Entitlement) => {
    return model.displayName;
  };

  const tabNames = new Array<string>();
  const tabContents = new Map<string, React.ReactNode>();
  if (
    roleEditingFlags.canViewEntitlements &&
    roleEditingFlags.canAssignEntitlements
  ) {
    tabNames.push('Entitlements');
    tabContents.set(
      'Entitlements',
      <VerticalLists
        assignedModels={currentState.entitlements}
        key={Entitlement.name}
        display={displayEntitlement}
        availableModels={entitlements}
        groupProperty="group"
        typeName={Entitlement.name}
        addModels={addEntitlements}
        removeModels={removeEntitlements}
        querySelectorForParent="div.editor"
      />,
    );
  } else if (roleEditingFlags.canViewEntitlements) {
    tabNames.push('Entitlements');
    const columnDefs = [
      columnDef('Name', 'displayName'),
      columnDef('Group', 'group'),
      columnDef('Description', 'description'),
    ];
    tabContents.set(
      'Entitlements',
      <FillRemainingContainer querySelectorForParent="div.editor">
        <Grid
          columnDefs={columnDefs}
          rowData={currentState.entitlements}
          paging
        />
      </FillRemainingContainer>,
    );
  }

  if (roleEditingFlags.canViewUsers && roleEditingFlags.canAssignUsers) {
    tabNames.push('Users');
    tabContents.set(
      'Users',
      <VerticalLists
        assignedModels={assignedUsers}
        key={User.name}
        availableModels={availableUsers}
        typeName={User.name}
        addModels={addUsers}
        display={(model: User) =>
          `${model.firstName} ${model.lastName} (Username: ${model.username})`
        }
        removeModels={removeUsers}
        querySelectorForParent="div.editor"
      />,
    );
  } else if (roleEditingFlags.canViewUsers) {
    const columnDefs = [
      columnDef('Username', 'username'),
      columnDef('Email', 'email'),
      columnDef('Last Name', 'lastName'),
      columnDef('First Name', 'firstName'),
      columnDef('Company', 'company'),
    ];
    tabContents.set(
      'Users',
      <FillRemainingContainer querySelectorForParent="div.editor">
        <Grid columnDefs={columnDefs} rowData={assignedUsers} paging />
      </FillRemainingContainer>,
    );
  }
  if (!tabNames.length) {
    return null;
  }

  return (
    <Patience showPatience={!isLoaded}>
      <Tabs
        tabNames={['Entitlements', 'Users']}
        tabContent={tabContents}
        startingTab={startingTab}
      />
    </Patience>
  );
};

export default RoleEntitlementsAndUsers;
