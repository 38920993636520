export class ValidationErrors {
  private propertyNames: string[];

  private hasServerErrors = false;

  private hasLocalErrors = false;

  private localErrors: Map<string, string[]>;

  private serverErrors: Map<string, string[]>;

  constructor(propertyNames: string[]) {
    this.propertyNames = propertyNames.map(p => p.toLowerCase());
    this.resetLocal();
    this.resetServer();
    this.localErrors = new Map<string, string[]>();
    this.serverErrors = new Map<string, string[]>();
  }

  get hasAnyErrors() {
    return this.hasLocalErrors || this.hasServerErrors;
  }

  resetLocal() {
    this.hasLocalErrors = false;
    this.localErrors = new Map<string, string[]>();
    // eslint-disable-next-line no-restricted-syntax
    for (const name of this.propertyNames) {
      this.localErrors.set(name, []);
    }
  }

  resetServer() {
    this.hasServerErrors = false;
    this.serverErrors = new Map<string, string[]>();
    // eslint-disable-next-line no-restricted-syntax
    for (const name of this.propertyNames) {
      this.serverErrors.set(name, []);
    }
  }

  clearServerErrorForProperty(name: string) {
    const lowered = name.toLowerCase();
    this.serverErrors.set(lowered, []);
    let found = false;
    this.serverErrors.forEach(v => {
      if (v && v.length > 0) {
        found = true;
      }
    });
    this.hasServerErrors = found;
  }

  addLocalError(name: string, error: string) {
    this.hasLocalErrors = true;
    const list = ValidationErrors.getErrorList(this.localErrors, name);
    list.push(error);
  }

  addServerErrors(name: string, errors: string[]) {
    this.hasServerErrors = true;
    const list = ValidationErrors.getErrorList(this.serverErrors, name);
    list.push(...errors);
  }

  private static getErrorList(
    errorCollection: Map<string, string[]>,
    name: string,
  ) {
    let errorList = errorCollection.get(name.toLowerCase());
    if (!errorList) {
      // eslint-disable-next-line no-console
      console.warn(
        `Property '${name}' was not initialized in the ${ValidationErrors.name} constructor.`,
      );
      errorList = [];
      errorCollection.set(name, errorList);
    }
    return errorList;
  }

  hasErrors(name: string) {
    return this.getAllErrorsForProperty(name).length > 0;
  }

  getErrors(name: string) {
    return this.getAllErrorsForProperty(name);
  }

  getAllErrors() {
    return this.propertyNames
      .map(pn => this.getAllErrorsForProperty(pn))
      .filter(errs => errs.length);
  }

  private getAllErrorsForProperty(name: string) {
    const lowered = name.toLowerCase();
    const errorList = this.localErrors.get(lowered);
    const serverErrorList = this.serverErrors.get(lowered);

    if (!errorList && !serverErrorList) {
      // eslint-disable-next-line no-console
      console.warn(
        `Property '${name}' was not initialized in the ${ValidationErrors.name} constructor.`,
      );
      return [];
    }

    const result = [];

    if (errorList) {
      result.push(...errorList);
    }

    if (serverErrorList) {
      result.push(...serverErrorList);
    }

    return result;
  }
}
