import { AxiosError } from 'axios';
import React from 'react';
import { useMutation } from '../hooks/useMutation';
import api, { BPO_ENDPOINT_PATH } from '../services/api';

type UpdateBPOOrderParams = {
  orderId: string;
  orderIdentifier?: string;
};

type UpdateBPOOrderResponse = {
  orderNumber: number;
};

async function updateBPOOrder({
  orderId,
  orderIdentifier,
}: UpdateBPOOrderParams) {
  const escapedId = window.encodeURIComponent(orderId);
  const escapedIdentifier = window.encodeURIComponent(orderIdentifier || '');
  const response = await api.post<UpdateBPOOrderResponse>(
    `/${BPO_ENDPOINT_PATH}/${escapedId}/submit?identifier=${escapedIdentifier}`,
  );
  return response.data;
}

export function useSubmitBPOOrder() {
  return useMutation<
    UpdateBPOOrderResponse,
    AxiosError<ErrorData>,
    UpdateBPOOrderParams
  >(React.useCallback(params => updateBPOOrder(params), []));
}
