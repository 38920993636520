import * as React from 'react';
import { Stack, IStackStyles, IStackTokens } from '@fluentui/react';
import { FileRejection } from 'react-dropzone';
import { useOrder } from '../../../context/order';
import { Dropzone, Instructions, Loading, StepContainer } from '../../Common';
import { useSetAUSUploadLoan } from '../../../mutations/useSetAUSUploadLoan';
import { useRemoveAUSLoanFile } from '../../../mutations/useRemoveAUSLoanFile';
import { BorrowerList, Box, DarkRow, Row } from './styles';

function AUSUploadLoanFile() {
  const {
    order,
    ausActions: { onChangeAUSLoan, onDeleteAUSLoanDocument },
  } = useOrder();

  const { isLoading, mutate: mutateSet } = useSetAUSUploadLoan();
  const { mutate: mutateRemove } = useRemoveAUSLoanFile();

  const stackStylesDropZone: Partial<IStackStyles> = {
    root: { width: 483 },
  };

  const stackStylesDropZoneUploaded: Partial<IStackStyles> = {
    root: { width: 633, marginLeft: 141 },
  };

  const stackStylesResults: Partial<IStackStyles> = {
    root: { width: 483 },
  };

  const stackToken: IStackTokens = {
    childrenGap: 10,
  };

  const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    if (fileRejections.length === 0) {
      mutateSet(
        { stagingId: order.stagingId, files: acceptedFiles },
        {
          onSuccess: loanFile => {
            onChangeAUSLoan(loanFile);
          },
        },
      );
    }
  };

  const onRemoveDocument = () => {
    if (order.ausLoan.loanStagingId) {
      mutateRemove(
        { stagingId: order.ausLoan.loanStagingId },
        {
          onSuccess: () => {
            onDeleteAUSLoanDocument();
          },
        },
      );
    }
  };

  const hasAnyFileUploaded = React.useMemo(() => {
    return !!order.ausLoan.loanStagingId;
  }, [order.ausLoan.loanStagingId]);

  const documentId = order.ausLoan.documentId;
  const documentName = order.ausLoan.documentName;

  const address = `${order.ausLoan.subjectPropertyInfo?.street}, ${order.ausLoan.subjectPropertyInfo?.city}, ${order.ausLoan.subjectPropertyInfo?.state} ${order.ausLoan.subjectPropertyInfo?.zip}`;

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const loanAmount =
    order.ausLoan.loanAmount !== undefined
      ? formatter.format(order.ausLoan.loanAmount)
      : '';

  return (
    <StepContainer align="center">
      {isLoading && <Loading message="Processing XML File..." />}

      <Stack
        styles={
          hasAnyFileUploaded ? stackStylesDropZoneUploaded : stackStylesDropZone
        }
        tokens={stackToken}
      >
        {!hasAnyFileUploaded && (
          <Instructions>
            First, <strong>upload the ULAD (MISMO 3.4) XML File.</strong>
          </Instructions>
        )}
        {hasAnyFileUploaded && (
          <Instructions>
            File <strong>Uploaded</strong>
          </Instructions>
        )}

        <Dropzone
          onDrop={onDrop}
          documentId={documentId}
          documentName={documentName}
          extension="XML"
          onDelete={onRemoveDocument}
          multiple={false}
          type="wide"
          fileType="ULAD (MISMO 3.4) XML"
          maxFiles={1}
        />
      </Stack>

      {!!order.ausLoan.loanStagingId && (
        <Stack styles={stackStylesResults}>
          <Instructions>
            Please <strong>review your details extracted from XML file.</strong>
          </Instructions>
          <Box>
            <Row>
              <strong>ULAD (MISMO 3.4)</strong>
              <span>{order.ausLoan.documentName}</span>
            </Row>
            <Row>
              <strong>Loan ID</strong>
              <span>{order.ausLoan.clientLoanId}</span>
            </Row>
            <DarkRow>
              <strong>Number of Borrowers</strong>
              <span>
                {order.ausLoan.borrowerInfos &&
                  order.ausLoan.borrowerInfos.length}
              </span>
            </DarkRow>
            <Row>
              <strong>Borrower Name</strong>
              <BorrowerList>
                {order.ausLoan.borrowerInfos &&
                  order.ausLoan.borrowerInfos.map(borrower => {
                    return (
                      <span key={borrower.borrowerName}>
                        {borrower.borrowerName}
                      </span>
                    );
                  })}
              </BorrowerList>
            </Row>
            <DarkRow>
              <strong>Loan Amount</strong>
              <span>{loanAmount}</span>
            </DarkRow>
            <Row>
              <strong>Address</strong>
              <span>{address}</span>
            </Row>
          </Box>
        </Stack>
      )}
    </StepContainer>
  );
}

export default AUSUploadLoanFile;
