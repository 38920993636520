/* eslint-disable react/function-component-definition */
import React from 'react';
import { WithChildren } from '../types';

export enum AlertType {
  Boring,
  NotBoring,
  Problem,
  BigProblem,
}

type AlertSize = 3 | 4 | 5 | 6;

type AlertProps = Partial<WithChildren> & {
  hidden?: boolean;
  type: AlertType;
  size?: AlertSize;
};

const Alert: React.FC<AlertProps> = ({
  hidden = false,
  type,
  size = 6,
  children,
}) => {
  if (hidden) {
    return null;
  }

  let className = 'p-2 mt-xs mb-xs border rounded text-2';
  let icon = '';
  switch (type) {
    case AlertType.Boring:
      className = `${className} border-mono-8 bg-mono-5 text-mono-12`;
      break;
    case AlertType.NotBoring:
      className = `${className} border-blue-7 bg-blue-2 text-blue-7`;
      break;
    case AlertType.Problem:
      className = `${className} border-orange-2 bg-orange-1 text-orange-4`;
      icon = 'mdi mdi-alert-outline';
      break;
    case AlertType.BigProblem:
      className = `${className} border-red-2 bg-red-1 text-red-3`;
      icon = 'mdi mdi-alert-outline';
      break;
    default:
      break;
  }
  if (icon) {
    return (
      <div className={`${className} grid grid-cols-12`}>
        <div className="hcon">
          <div className={`hc text-${size}`}>
            <span className={icon} />
          </div>
        </div>
        <div className="col-span-11">{children}</div>
      </div>
    );
  }
  return <div className={className}>{children}</div>;
};

export default Alert;
