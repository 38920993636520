import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { RiDeleteBin7Line } from 'react-icons/ri';
import ButtonDrivenPopout from '@samc/react-ui-core/lib/atoms/ButtonDrivenPopout/ButtonDrivenPopout';
import { MdEdit } from 'react-icons/md';
import { DirectionalHint } from '@fluentui/react';
import { BPOLoanData } from '../../../context/order/bpo/types';
import {
  DeleteContainer,
  ErrorMessage,
  ExclamationIconContainer,
} from './styles';
import CellStyleParams from '../../../types/CellStyleParams';
import { BPOLoanError } from '../../../context/order/types';
import { PopupContainer } from '../BPOReviewSubmit/styles';
import { useOrder } from '../../../context/order';
import UpdateLoanModal from '../BPOReviewSubmit/UpdateLoanModal';
import { extractAddresses } from '../../../context/order/bpo/util';

export interface BPOLoanGridValidationData {
  row: number;
  clientLoanId: string;
  street: string;
  unitNumber: string;
  city: string;
  state: string;
  zip: string;
  accessDetails: string;
  errors: BPOLoanError[];
}

export interface FrameworkComp {
  [p: string]: unknown;
}

interface ErrorType {
  [key: string]: {
    color: string;
    borderColor: string;
    backgroundColor: string;
  };
}

export const errorType: ErrorType = {
  Error: {
    color: '#BD0E08',
    borderColor: '#BD0E08',
    backgroundColor: '#f5e2e0',
  },
  Address: {
    color: '#BD0E08',
    borderColor: '#BD0E08',
    backgroundColor: '#f5e2e0',
  },
  Warning: {
    color: '#e6930a',
    borderColor: '#e6930a',
    backgroundColor: '#ffe1b0',
  },
  Duplicated: {
    color: '#646464',
    borderColor: '#646464',
    backgroundColor: '#dcdcdc',
  },
};

export const cellStyle = (params: CellStyleParams): React.CSSProperties => {
  const data = params.data as BPOLoanData;
  const column = params.column.getColId();

  if (data.errors && data.errors.length > 0) {
    if (column === 'errors') {
      return { color: errorType[data.errors[0].type].color, fontWeight: 600 };
    }

    const duplicatedRowIndex = data.errors.findIndex(
      error => error.type === 'Duplicated',
    );

    if (duplicatedRowIndex > -1) {
      return {
        backgroundColor: errorType.Duplicated.backgroundColor,
        borderColor: errorType.Duplicated.borderColor,
      };
    }

    const columnIndex = data.errors.findIndex(error => error.column === column);
    return data.errors[columnIndex]
      ? {
          backgroundColor:
            errorType[data.errors[columnIndex].type].backgroundColor,
          borderColor: errorType[data.errors[columnIndex].type].borderColor,
        }
      : {};
  }
  return {};
};

export function ErrorRenderer({ data }: ICellRendererParams): JSX.Element {
  return (
    <div style={{ minHeight: 28, paddingTop: 7, paddingBottom: 6 }}>
      {((data.errors || []) as BPOLoanError[]).reduce<JSX.Element[]>(
        (ret, error, index) => {
          if (error.type !== 'Address') {
            ret.push(
              <ErrorMessage key={index} variant={error.type}>
                {error.message}
              </ErrorMessage>,
            );
          }
          return ret;
        },
        [],
      )}
    </div>
  );
}

export interface DeleteBtRendererProps extends ICellRendererParams {
  onClick: (rowIndex: number) => void;
}

export function DeleteBtCellRenderer({
  onClick,
  value,
}: DeleteBtRendererProps) {
  return (
    <DeleteContainer onClick={() => onClick(value)}>
      <RiDeleteBin7Line color="#bd0e08" size={16} />
      <p>Delete Loan</p>
    </DeleteContainer>
  );
}

function ExclamationIcon({ id }: { id?: string }) {
  return <ExclamationIconContainer id={id}>!</ExclamationIconContainer>;
}

export function PopUpRenderer({
  data,
  value,
  node,
  api,
  colDef,
}: ICellRendererParams) {
  const [isOpen, setIsOpen] = React.useState(false);
  const isMounted = React.useRef(false);

  React.useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const {
    order,
    bpoActions: { onChangeSingleBPOValidationData },
  } = useOrder();

  const setData = (loanData: BPOLoanData) => {
    let loan = order.bpoLoanValidation.data.find(
      possibleMatch => possibleMatch.row === loanData.row,
    );
    if (loan === undefined) return;

    loan = {
      ...loan,
      ...loanData,
      hasChange: true,
    };

    onChangeSingleBPOValidationData(loan);

    const updatedLoan = order.bpoLoanValidation.data.find(
      row => row.bpoOrderStagingId === data.bpoOrderStagingId,
    );
    node.setData(updatedLoan);

    api.refreshCells({ force: true, rowNodes: [node] });
  };

  const errors: JSX.Element[] = React.useMemo(() => {
    const messages: JSX.Element[] = [];
    data.errors?.forEach((error: BPOLoanError, index: number) => {
      if (colDef) {
        if (
          colDef.field === 'addresses' &&
          ['street', 'city', 'state', 'zip', 'unitNumber'].includes(
            error.column || '',
          )
        ) {
          messages.push(<div key={index}>{error.message}</div>);
        } else if (error.column === colDef.field) {
          messages.push(<div key={index}>{error.message}</div>);
        }
      }
    });
    return messages;
  }, [colDef, data.errors]);

  return (
    <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
      <PopupContainer onClick={() => setIsOpen(true)}>
        {value}
        <MdEdit size={16} />
      </PopupContainer>
      {errors.length > 0 && (
        <ButtonDrivenPopout
          button={<ExclamationIcon />}
          directionalHint={DirectionalHint.topCenter}
        >
          <ErrorMessage>{errors}</ErrorMessage>
        </ButtonDrivenPopout>
      )}
      <UpdateLoanModal
        isOpen={isOpen}
        rowData={data}
        onSubmit={setData}
        onDismiss={() => setIsOpen(false)}
        isLoading={false}
      />
    </div>
  );
}

export function AddressPopUpRenderer({
  data,
  node,
  api,
  ...args
}: ICellRendererParams) {
  const addresses = extractAddresses(data);
  const selected = addresses.selected || addresses.edited || addresses.original;
  const address = [
    selected?.street,
    selected?.unitNumber,
    selected?.city,
    selected?.state,
    selected?.zip,
  ]
    .filter(part => part !== undefined && part !== '')
    .join(', ');
  return PopUpRenderer({
    data,
    node,
    api,
    ...args,
    value: address,
  });
}
