import React from 'react';
import {
  Container,
  Left,
  Right,
  TopLeft,
  BottomLeft,
  Button,
  Center,
} from './styles';

interface HeadingProps {
  topLeft?: React.ReactChild | string;
  bottomLeft: React.ReactElement | string;
  actionTopLeft?: () => void;
  children?: React.ReactNode | string;
  bottomRight?: React.ReactElement | null;
}

function Heading({
  children,
  topLeft,
  bottomLeft,
  actionTopLeft,
  bottomRight,
}: HeadingProps) {
  return (
    <Container>
      <Left>
        <TopLeft>
          <Button onClick={actionTopLeft}>{topLeft}</Button>
        </TopLeft>
        <BottomLeft>{bottomLeft}</BottomLeft>
      </Left>
      <Center>{children && children}</Center>
      <Right>{bottomRight}</Right>
    </Container>
  );
}

export default Heading;
