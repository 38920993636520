import styled, { css } from 'styled-components';

interface OptionProps {
  checked: boolean;
  notChecked: boolean;
}

export const Option = styled.div<OptionProps>`
  background-image: linear-gradient(0deg, #fcfcfc 0%, #ffffff 100%);
  background-position: center center;
  background-size: cover;
  font-family: ${props => props.theme.fontFamily.main};
  font-size: 14px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.colors.primary};

  input {
    display: none;
  }

  & + button {
    border-left: 1px solid #d1d1d1;
  }

  ${props =>
    props.checked &&
    css`
      background: linear-gradient(0deg, #2ca9ff 0%, #2eafff 100%);
      border-color: #207cc7;
      font-weight: 700;
      color: white;
    `}

  ${(props: OptionProps) =>
    props.notChecked &&
    css`
      color: #214d88;
      background-color: linear-gradient(0deg, #fcfcfc 0%, #ffffff 100%);
      border-color: #d1d1d1;
    `}

  &:focus {
    border-color: #2eaeff;
    border-width: 1px;
  }
`;

export const Container = styled.div``;

type LabelProps = {
  required: boolean;
};

export const Label = styled.p<LabelProps>`
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  padding: 5px 0;
  cursor: default;

  ${props =>
    props.required &&
    css`
      &::after {
        content: '*';
        color: ${props.theme.colors.red};
        margin-left: 5px;
      }
    `}
`;
interface OptionsProps {
  hasError: boolean;
}

export const Options = styled.div<OptionsProps>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #000;
  border-radius: 2px;
  cursor: pointer;
  height: 32px;
  border-color: #d1d1d1;
  background: #fff;
  overflow: auto;

  ${props =>
    props.hasError &&
    css`
      border-color: rgb(164, 38, 44);

      ${Option}+ div {
        border-color: #a4262c;
      }
    `}
`;

export const ErrorMessage = styled.div`
  color: #a4262c;
  font-size: 12px;
  font-weight: 400;
  font-family: 'Segoe UI';
`;
