import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px;
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 478px;
  gap: 20px;
`;

export const Title = styled.p``;

export const Box = styled.div`
  margin-top: 19px !important;
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  min-height: 29px;
  border: solid 1px #d1d1d1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: -1px;
  background-color: #fff;
  font-size: 14px;
  padding: 5px 9px;

  > strong {
    flex: 0 0 auto;
  }
  > span {
    text-align: right;
  }
`;

export const DarkRow = styled(Row)`
  background-color: #f0f0f0;
`;

export const BorrowerList = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  flex: 0 0 auto;
  margin: -5px -9px -5px 0;

  > span {
    border: solid 1px #d1d1d1;
    border-top: none;
    border-right: none;
    margin-bottom: -1px;
    min-height: 32px;
    padding: 4px;
    text-align: right;
  }
`;

export const Overview = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 14px;
  border-radius: 3px;
  border: 1px solid #d2d2d2;
  background-color: #ffffff;
  background-size: cover;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
`;

export const Description = styled.div``;

export const Total = styled.div`
  span {
    margin-left: 10px;
    font-size: ${props => props.theme.fontSize.xl2};
  }
`;

export const OrderIdentifier = styled.div``;
export const OrderSubmitted = styled.div`
  display: flex;
  align-items: center;

  svg {
    color: #36b300;
  }

  div {
    margin-left: 10px;

    p {
      font-size: 16px;
      color: #000000;
    }
  }
`;

export const Info = styled.p`
  font-size: 18px;
`;

export const XmlContainerLink = styled.p`
  font-size: ${props => props.theme.fontSize.base};

  button {
    font-size: ${props => props.theme.fontSize.base};
    color: ${props => props.theme.colors.primary};
    cursor: pointer;
    font-weight: bold;
  }
`;

export const XMLButton = styled.button``;

export const DeleteButton = styled.button`
  padding: 0px;
`;

export const DocumentContainer = styled.div`
  display: flex;
`;

export const DocumentInfo = styled.div`
  padding-left: 30px;
  border: solid 1px #ccc;
`;
