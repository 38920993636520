import React from 'react';
import { Container } from './styles';

type BoxProps = {
  children: React.ReactNode;
};

function Box({ children }: BoxProps) {
  return <Container>{children}</Container>;
}

export default Box;
