import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px;
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 478px;
  gap: 20px;
`;

export const Title = styled.p``;

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  min-height: 36px;
  background-size: cover;
  font-size: 14px;

  &:nth-child(even) {
    background-color: #f6f6f6;
  }

  > span > div {
    text-align: right;
  }
`;

export const Overview = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 14px;
  border-radius: 3px;
  border: 1px solid #d2d2d2;
  background-color: #ffffff;
  background-size: cover;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
`;

export const Description = styled.div``;

export const Total = styled.div`
  span {
    margin-left: 10px;
    font-size: ${props => props.theme.fontSize.xl2};
  }
`;

export const OrderIdentifier = styled.div``;
export const OrderSubmitted = styled.div`
  display: flex;
  align-items: center;

  svg {
    color: #36b300;
  }

  div {
    margin-left: 10px;

    p {
      font-size: 16px;
      color: #000000;
    }
  }
`;

export const Info = styled.p`
  font-size: 18px;
`;

export const XmlContainerLink = styled.p`
  font-size: ${props => props.theme.fontSize.base};

  button {
    font-size: ${props => props.theme.fontSize.base};
    color: ${props => props.theme.colors.primary};
    cursor: pointer;
    font-weight: bold;
  }
`;

export const XMLButton = styled.button``;
