import { ICurrentUser, Guid } from '@samc/common';
import User from './User';

export default class CurrentUser implements ICurrentUser {
  /** The full name of the user, most likely their first and last name combined. For token-based users, will be the username instead. */
  fullName: string;

  firstName: string;

  lastName: string;

  username: string;

  company: string;

  companyClarityId: number | null;

  email: string;

  id: Guid;

  systemId: string;

  exists: boolean;

  isDisabled: boolean;

  isLoaded: boolean;

  termsOfUseAcceptance: boolean;

  termsOfUseAcceptanceDate?: Date;

  entitlements: string[];

  entitlementsByReferenceId: Guid[];

  /** fullName and name are the same - the definition in samc/common is name, but I decided to provide better clarity here in the Auth package. */
  get name(): string {
    return this.fullName;
  }

  /**
   * Returns a new CurrentUser if the user data differs from this CurrentUser
   * @param user
   * @returns
   */
  update(user: User): CurrentUser {
    const newUser = new CurrentUser();
    newUser.populate(user);

    if (this.fullName !== newUser.fullName) {
      return newUser;
    }
    if (this.firstName !== newUser.firstName) {
      return newUser;
    }
    if (this.lastName !== newUser.lastName) {
      return newUser;
    }
    if (this.username !== newUser.username) {
      return newUser;
    }
    if (this.company !== newUser.company) {
      return newUser;
    }
    if (this.email !== newUser.email) {
      return newUser;
    }
    if (this.id.equals(newUser.id) !== true) {
      return newUser;
    }
    if (this.systemId !== newUser.systemId) {
      return newUser;
    }
    if (this.isDisabled !== newUser.isDisabled) {
      return newUser;
    }
    if (this.termsOfUseAcceptance !== newUser.termsOfUseAcceptance) {
      return newUser;
    }
    if (
      this.termsOfUseAcceptanceDate?.getTime() !==
      newUser.termsOfUseAcceptanceDate?.getTime()
    ) {
      return newUser;
    }
    if (this.entitlements.length !== newUser.entitlements.length) {
      return newUser;
    }
    if (
      this.entitlementsByReferenceId.length !==
      newUser.entitlementsByReferenceId.length
    ) {
      return newUser;
    }
    if (this.companyClarityId !== newUser.companyClarityId) {
      return newUser;
    }
    return this;
  }

  populate(user: User | null) {
    this.isLoaded = true;
    // Null user means they don't have an account in the system. We bail because they're going to get an error and default values are fine.
    if (user === null) {
      this.exists = false;
      return;
    }
    this.fullName = user.name;
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.username = user.username;
    this.company = user.company?.name ?? '---';
    this.email = user.email;
    this.id = user.id;
    this.systemId = user.systemId;
    this.isDisabled = user.isDisabled;
    this.termsOfUseAcceptance = user.termsOfUseAcceptance;
    this.termsOfUseAcceptanceDate = user.termsOfUseAcceptanceDate;
    // woah: https://stackoverflow.com/a/9229821
    this.entitlements = [
      ...new Set(user.roles.flatMap(r => r.entitlements.map(e => e.name))),
    ];
    this.entitlements.concat(user.entitlements.map(e => e.name));
    this.entitlementsByReferenceId = user.roles.flatMap(r =>
      r.entitlements
        .filter(e => e.referenceId !== undefined && e.referenceId !== null)
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .map(e => e.referenceId!),
    );
    this.entitlementsByReferenceId.concat(
      user.entitlements
        .filter(e => e.referenceId !== undefined && e.referenceId !== null)
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .map(e => e.referenceId!),
    );
    this.companyClarityId = user.company?.clarityId || null;
  }

  hasEntitlement(entitlement: string): boolean {
    return this.entitlements.some(e => e === entitlement);
  }

  hasEntitlementWithReferenceId(entitlementReferenceId: Guid): boolean {
    return this.entitlementsByReferenceId.some(
      e => e === entitlementReferenceId,
    );
  }

  constructor() {
    this.fullName = '';
    this.firstName = '';
    this.lastName = '';
    this.company = '';
    this.email = '';
    this.entitlements = [];
    this.exists = true;
    this.isDisabled = false;
    this.isLoaded = false;
    this.id = Guid.createEmpty();
    this.systemId = '';
    this.companyClarityId = null;
    this.termsOfUseAcceptance = false;
    this.username = '';
    this.entitlementsByReferenceId = [];
  }
}
