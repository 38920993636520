import * as React from 'react';
import { Workbench as WorkbenchUI } from '@samc/react-ui-core';
import { InnerWorkbenchProps } from '@samc/react-ui-core/lib/organisms/Workbench/InnerWorkbench';

type WorkbenchProps = {
  children: InnerWorkbenchProps['children'];
  headerContent: React.ReactChild;
  /**
   * Indicates that the external button is being hovered and
   * that the workbench should open until the mouse enters and exits
   * or the hovermode ends.
   */
  hoverMode?: boolean;
  collapsed?: boolean;
  setCollapsed?: (collapsed: boolean) => void;
  /**
   * The width of the workbench specified in px values
   * @default 280
   */
  width?: number;
  footer?: JSX.Element;
};

function Workbench(props: WorkbenchProps) {
  return <WorkbenchUI {...props} />;
}

export default Workbench;
