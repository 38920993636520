import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
`;

export const Button = styled.button`
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  height: 30px;
  padding: 0px 25px;
  border: 1px solid #163f75;
  border-radius: 2px;
  background-color: ${props => props.theme.colors.primary};
  background-size: cover;
  font-family: ${props => props.theme.fontFamily.heading};
  font-size: ${props => props.theme.fontSize.base};
  color: #ffffff;
  text-decoration: none solid rgb(255, 255, 255);
  text-align: center;
  transition: ${props => props.theme.animations.button};

  &:hover {
    background-color: ${props => props.theme.colors.darkBlue};
  }

  &:disabled {
    border: 1px solid #a6a6a6;
    background-color: #dbdbdb;
    color: #a6a6a6;
    text-decoration: none solid rgb(166, 166, 166);
  }
`;
