import { IDropdownStyles } from '@fluentui/react';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
`;

export const GridWrapper = styled.div`
  flex: 1;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  gap: 10px;
`;

export const Required = styled.div`
  margin-top: 10px;
  font-size: 14px;
  color: #bd0e08;
  font-weight: 600;
  text-align: center;
`;

export const Editable = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  select,
  input {
    font-size: 14px;
    background: transparent;
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
  }

  select::-ms-expand {
    display: none;
  }
`;

export const gridDropDownStyles: Partial<IDropdownStyles> = {
  root: {
    width: '100%',
    height: '28px',
  },
  title: {
    borderColor: '#dbdbdb',
    borderWidth: 0,
    backgroundColor: 'transparent',
    padding: 0,
    fontFamily: 'Source Sans Pro, sans-serif',
    fontSize: 14,
  },
  callout: {
    maxHeight: '450px',
    fontFamily: 'Source Sans Pro, sans-serif',
    fontSize: 14,
  },
};
