import React from 'react';
import { Container, Content, ContentRow, Label, Title, Value } from './styles';

type NotesTooltipProps = {
  clientLoanId: string;
  address: string;
  bpoType: string;
  customerReferenceId1?: string;
  customerReferenceId2?: string;
  customerReferenceId3?: string;
};

function NotesTooltip({
  clientLoanId,
  customerReferenceId1,
  customerReferenceId2,
  customerReferenceId3,
  address,
  bpoType,
}: NotesTooltipProps) {
  return (
    <Container>
      <Title>Original Loans Details</Title>
      <Content>
        <ContentRow>
          <div>
            <Label>Loan ID</Label>
            <Value>{clientLoanId}</Value>
          </div>
          <div>
            <Label>Customer Reference ID1</Label>
            <Value>{customerReferenceId1}</Value>
          </div>
          <div>
            <Label>Customer Reference ID2</Label>
            <Value>{customerReferenceId2}</Value>
          </div>
          <div>
            <Label>Customer Reference ID3</Label>
            <Value>{customerReferenceId3}</Value>
          </div>
        </ContentRow>
        <ContentRow>
          <div>
            <Label>BPO Type</Label>
            <Value>{bpoType}</Value>
          </div>
          <div>
            <Label>Address</Label>
            <Value>{address}</Value>
          </div>
        </ContentRow>
      </Content>
    </Container>
  );
}

export default NotesTooltip;
