import { AxiosError } from 'axios';
import React from 'react';
import { useMutation } from '../hooks/useMutation';
import api from '../services/api';

interface loansDisputeResolutionParams {
  loanId: string;
  clarityLoanId: number;
  clientLoanId: string;
  disputeId: number;
  disputeResponse: string;
}

export interface createLoanDisputeResolutionParams {
  orderId: string;
  loansDisputeResolution: loansDisputeResolutionParams[];
}

async function createLoanDisputeResolution(
  params: createLoanDisputeResolutionParams,
) {
  const response = await api.post(
    'BPOOrder/SubmitLoansDisputeResolution',
    params,
  );
  return response.data;
}

export function useCreateLoanDisputeResolution() {
  return useMutation<
    void,
    AxiosError<ErrorData>,
    createLoanDisputeResolutionParams
  >(React.useCallback(params => createLoanDisputeResolution(params), []));
}
