/* eslint-disable react/function-component-definition */
import React, { useState } from 'react';
import './Tabs.css';

type TabsProps = {
  tabNames: string[];
  startingTab: string;
  tabContent: Map<string, React.ReactNode>;
};

const Tabs: React.FC<TabsProps> = ({ tabNames, startingTab, tabContent }) => {
  const [selectedTab, setSelectedTab] = useState(startingTab);
  const content = tabContent.get(selectedTab);
  return (
    <div className="auth-tabs">
      <div className="auth-tabHeaders">
        {tabNames.map(t => (
          <button
            type="button"
            key={t}
            className={`auth-tabHeader${selectedTab === t ? ' active' : ''}`}
            tabIndex={selectedTab === t ? -1 : 0}
            onClick={() => setSelectedTab(t)}
          >
            {t}
          </button>
        ))}
      </div>
      <div className="auth-tabContent">{content}</div>
    </div>
  );
};

export default Tabs;
