import { IOrder, ActionTypes } from './types';
import initialState, { initialBPOManualLoan } from './initialState';
import { ILoan } from './appraisal/types';
import { BPOLoanData, IManualLoan } from './bpo/types';
import { AUSBorrowerInfo, AUSCreditInfo } from './aus/types';
import { extractAddresses } from './bpo/util';

const matchBorrowers = (
  borrowerInfos?: AUSBorrowerInfo[],
  creditInfo?: AUSCreditInfo[],
) => {
  if (borrowerInfos) {
    borrowerInfos.forEach(borrower => {
      borrower.hasMatch = false;
    });
  }

  if (creditInfo) {
    creditInfo.forEach(info => {
      info.hasMatch = false;

      if (borrowerInfos) {
        borrowerInfos.some((borrower: AUSBorrowerInfo) => {
          if (borrower.ssn === info.ssn && borrower.ssn !== '') {
            info.hasMatch = true;
            borrower.hasMatch = true;
            return true;
          }
          return false;
        });
      }
    });
  }
};

export const reducer = (state: IOrder, action: ActionTypes): IOrder => {
  switch (action.type) {
    case 'ADD_PRODUCT':
      return {
        ...state,
        product: action.payload.product,
      };
    case 'REMOVE_PRODUCT':
      return {
        ...state,
        product: initialState.product,
      };
    case 'SET_DIVISION':
      return {
        ...state,
        division: action.payload.division,
      };
    case 'SET_LOAN_ID': {
      const appraisalLoan = [...state.appraisalLoan];

      appraisalLoan[0] = {
        ...appraisalLoan[0],
        clientLoanId: action.payload.loanId,
      };
      return {
        ...state,
        appraisalLoan,
      };
    }
    case 'SET_ORDER_ID':
      return {
        ...state,
        orderId: action.payload.orderId,
      };
    case 'SET_ORDER_NUMBER':
      return {
        ...state,
        orderNumber: action.payload.orderNumber,
      };
    case 'SET_ORDER_PRICE':
      return {
        ...state,
        product: {
          ...state.product,
          price: action.payload.price,
          cvpPrice: action.payload.price,
          vpPrice: action.payload.price,
        },
      };
    case 'SET_LOAN_DETAILS': {
      const { orderId, loan } = action.payload;

      const appraisalLoan = [...state.appraisalLoan];
      appraisalLoan[0].borrowerLastName = loan.borrowerLastName;
      appraisalLoan[0].address = loan.address;
      appraisalLoan[0].city = loan.city;
      appraisalLoan[0].state = loan.state;
      appraisalLoan[0].zip = loan.zip;
      appraisalLoan[0].unitNumber = loan.unitNumber;
      appraisalLoan[0].documents = [...loan.documents];

      return {
        ...state,
        orderId,
        ...appraisalLoan,
      };
    }
    case 'SET_LOAN_STAGING_DETAILS': {
      const { stagingId, loan } = action.payload;
      const appraisalLoan = [...state.appraisalLoan];
      appraisalLoan[0].borrowerLastName = loan.borrowerLastName;
      appraisalLoan[0].address = loan.address;
      appraisalLoan[0].city = loan.city;
      appraisalLoan[0].state = loan.state;
      appraisalLoan[0].zip = loan.zip;
      appraisalLoan[0].unitNumber = loan.unitNumber;
      appraisalLoan[0].documents = [...loan.documents];
      return {
        ...state,
        stagingId,
        ...appraisalLoan,
      };
    }
    case 'UPDATE_ORDER_IDENTIFIER': {
      return {
        ...state,
        orderIdentifier: action.payload.orderIdentifier,
      };
    }
    case 'REMOVE_DOCUMENT': {
      const updatedState = { ...state };
      const updatedDocuments = state.appraisalLoan[0].documents.filter(
        document => document.documentId !== action.payload.documentId,
      );
      updatedState.stagingId = '';
      updatedState.appraisalLoan[0].borrowerLastName = '';
      updatedState.appraisalLoan[0].address = '';
      updatedState.appraisalLoan[0].city = '';
      updatedState.appraisalLoan[0].state = '';
      updatedState.appraisalLoan[0].state = '';
      updatedState.appraisalLoan[0].zip = '';
      updatedState.appraisalLoan[0].unitNumber = '';
      updatedState.appraisalLoan[0].documents = updatedDocuments;

      return {
        ...updatedState,
      };
    }
    case 'CLEAR_INDEXING_DOCUMENT': {
      return {
        ...state,
        indexingLoan: {
          ...initialState.indexingLoan,
        },
      };
    }
    case 'CLEAR_INDEXING_DOC_PREVIEW_DATA': {
      return {
        ...state,
        indexingLoan: {
          ...state.indexingLoan,
          metaData: initialState.indexingLoan.metaData,
          columnName: initialState.indexingLoan.columnName,
        },
      };
    }
    case 'SET_INDEXING_DOC_DATA': {
      return {
        ...state,
        indexingLoan: {
          ...state.indexingLoan,
          metaData: action.payload.metaData,
          fileSource: action.payload.fileSource,
        },
      };
    }
    case 'SET_INDEXING_CLIENT_LOAN_ID': {
      return {
        ...state,
        indexingLoan: {
          ...state.indexingLoan,
          columnName: action.payload.columnName,
        },
      };
    }
    case 'SET_BPO_LOAN_MODE': {
      return {
        ...state,
        bpoLoan: {
          ...initialState.bpoLoan,
          bpoLoanMode: action.payload.bpoLoanMode,
        },
      };
    }
    case 'CLEAR_BPO_DOCUMENT': {
      return {
        ...state,
        bpoLoan: {
          ...initialState.bpoLoan,
        },
      };
    }
    case 'CLEAR_BPO_DOC_PREVIEW_DATA': {
      return {
        ...state,
        bpoLoan: {
          ...state.bpoLoan,
          metaData: initialState.bpoLoan.metaData,
        },
      };
    }
    case 'SET_BPO_DOC_DATA': {
      return {
        ...state,
        bpoLoan: {
          ...state.bpoLoan,
          metaData: action.payload.metaData || null,
          fileSource: action.payload.fileSource,
        },
      };
    }
    case 'SET_BPO_LOAN_ERROR': {
      if (
        state.bpoLoan.isCompletedSuccessfully !==
        action.payload.isCompletedSuccessfully
      ) {
        const newState = { ...state };
        newState.bpoLoan.isCompletedSuccessfully =
          action.payload.isCompletedSuccessfully;
        return newState;
      }
      return state;
    }
    case 'ADD_MANUAL_BPO_LOAN_ROW': {
      const updatedBPOLoans = state.bpoLoan.manualLoans;
      updatedBPOLoans.push(initialBPOManualLoan);
      return {
        ...state,
        bpoLoan: {
          ...state.bpoLoan,
          manualLoans: updatedBPOLoans,
        },
      };
    }
    case 'REMOVE_MANUAL_BPO_LOAN_ROW': {
      const updatedBPOLoans = state.bpoLoan.manualLoans;

      updatedBPOLoans.filter((_, index) => index !== action.payload.index);
      return {
        ...state,
        bpoLoan: {
          ...state.bpoLoan,
          manualLoans: updatedBPOLoans,
        },
      };
    }
    case 'SET_MANUAL_BPO_LOAN_DATA': {
      return {
        ...state,
        bpoLoan: {
          ...state.bpoLoan,
          manualLoans: action.payload.manualLoans,
        },
      };
    }
    case 'SET_BPO_LOAN_VALIDATION_DATA': {
      const newState = { ...state };

      const errorLoans: number[] = action.payload.resetErrorsCorrections
        ? []
        : state.bpoLoanValidation.errorLoans || [];
      action.payload.bpoLoanValidationData.errorLoans?.forEach(row => {
        if (!errorLoans.includes(row)) {
          errorLoans.push(row);
        }
      });

      const correctionLoans: number[] = action.payload.resetErrorsCorrections
        ? []
        : state.bpoLoanValidation.correctionLoans || [];
      action.payload.bpoLoanValidationData.correctionLoans?.forEach(row => {
        if (!correctionLoans.includes(row)) {
          correctionLoans.push(row);
        }
      });

      newState.bpoLoanValidation = {
        ...newState.bpoLoanValidation,
        ...action.payload.bpoLoanValidationData,
        errorLoans,
        correctionLoans,
      };

      return newState;
    }
    case 'SET_SHOW_SAVE_PROMPT': {
      const newState = { ...state };
      newState.bpoLoanValidation.showSavePrompt = action.payload.value;
      return newState;
    }
    case 'SET_SINGLE_BPO_LOAN_VALIDATION_DATA': {
      const newState = { ...state };
      const loan = action.payload.loan;
      newState.bpoLoanValidation.data.some((row, index) => {
        if (row.bpoOrderStagingId === loan.bpoOrderStagingId) {
          newState.bpoLoanValidation.data[index] = loan;
          newState.bpoLoanValidation.data = ([] as BPOLoanData[]).concat(
            newState.bpoLoanValidation.data,
          );
          return true;
        }
        return false;
      });

      if (loan.hasChange) {
        newState.bpoLoanValidation.showSavePrompt = true;
      }

      return newState;
    }
    case 'EDIT_BPO_LOAN_ADDRESS': {
      const newData = state.bpoLoanValidation.data.map(loan => {
        if (loan.row === action.payload.row) {
          const newLoan = { ...loan, hasChange: true };
          const addressLookup = extractAddresses(newLoan);
          if (addressLookup.edited) {
            Object.assign(addressLookup.edited, action.payload.editedAddress, {
              isSelected: true,
              isValidated: false,
            });
            newLoan.addresses.forEach(address => {
              if (address !== addressLookup.edited) {
                address.isSelected = false;
              }
            });
          } else {
            newLoan.addresses.forEach(address => {
              address.isSelected = false;
            });
            newLoan.addresses.push({
              bpoOrderStagingAddressId: 'edited',
              type: 'Edited',
              isValidated: false,
              isSelected: true,
              ...action.payload.editedAddress,
            });
          }
          return newLoan;
        }
        return loan;
      });
      return {
        ...state,
        bpoLoanValidation: {
          ...state.bpoLoanValidation,
          data: newData,
        },
      };
    }
    case 'PATCH_BPO_LOAN_VALIDATION_DATA': {
      const updatedBpoLoanValidation = { ...state.bpoLoanValidation };
      updatedBpoLoanValidation.data.some(loan => {
        if (loan.row === action.payload.record.row && loan.errors) {
          return true;
        }
        return false;
      });

      const data = updatedBpoLoanValidation.data.map(loan => {
        if (loan.row === action.payload.record.row) {
          Object.assign(loan, {
            row: action.payload.record.row,
            clientLoanId: action.payload.record.clientLoanId,
            bpoProduct: action.payload.record.bpoProduct,
            accessDetails: action.payload.record.accessDetails,
            orderItemId: action.payload.record.orderItemId,
            addresses: [...action.payload.record.addresses],
            errors: [...(action.payload.record.errors || [])],
          });
        }
        return loan;
      });

      return {
        ...state,
        bpoLoanValidation: {
          ...updatedBpoLoanValidation,
          data,
          changeData: true,
        },
      };
    }
    case 'REMOVE_ROW_LOAN_VALIDATION_DATA': {
      const updatedBpoLoanValidation = { ...state.bpoLoanValidation };

      let removedIndex = -1;
      const removedLoan = updatedBpoLoanValidation.data.find((loan, index) => {
        if (loan.row === action.payload.rowIndex) {
          removedIndex = index;
          return true;
        }
        return false;
      });

      if (removedLoan === undefined) {
        return { ...state };
      }

      const duplicateLoans: number[] = [];
      if (removedLoan.errors) {
        removedLoan.errors.forEach(err => {
          const matches = /^Property address is a duplicate of row (\d+)$/.exec(
            err.message,
          );
          if (matches) {
            duplicateLoans.push(parseInt(matches[1], 10));
          }
        });
      }

      const data = updatedBpoLoanValidation.data.map(loan => {
        let mapped = loan;
        if (duplicateLoans.includes(loan.row) && mapped.errors) {
          mapped = { ...loan };
          mapped.errors = mapped.errors?.filter(
            error =>
              error.message !==
              `Property address is a duplicate of row ${action.payload.rowIndex}`,
          );
        }
        return mapped;
      });

      data.splice(removedIndex, 1);

      return {
        ...state,
        bpoLoanValidation: { ...updatedBpoLoanValidation, data },
      };
    }
    case 'REMOVE_DUPLICATES_LOAN_VALIDATION_DATA': {
      const updatedBpoLoanValidation = { ...state.bpoLoanValidation };

      const data: BPOLoanData[] = updatedBpoLoanValidation.data.map(loan => {
        const mapped = { ...loan };
        if (mapped.errors) {
          mapped.errors = mapped.errors.filter(
            error => error.type !== 'Duplicated',
          );
          if (mapped.errors.length === 0) delete mapped.errors;
        }
        return mapped;
      });

      return {
        ...state,
        bpoLoanValidation: { ...updatedBpoLoanValidation, data },
      };
    }
    case 'REMOVE_SELECTED_LOAN_VALIDATION_DATA': {
      const updatedBpoLoanValidation = { ...state.bpoLoanValidation };

      const removedDuplicates: { [key: number]: string[] } = {};

      const data = updatedBpoLoanValidation.data.map(loan => {
        if (action.payload.rows.includes(loan.row) && loan.errors) {
          loan.errors.forEach(err => {
            const matches =
              /^(Property address is a duplicate of row )(\d+)$/.exec(
                err.message,
              );
            if (matches) {
              const dupRow = parseInt(matches[2], 10);
              if (removedDuplicates[dupRow] === undefined)
                removedDuplicates[dupRow] = [];
              removedDuplicates[dupRow].push(matches[1] + loan.row.toString());
            }
          });
        }
        return loan;
      });

      for (let i = 0; i < data.length; i++) {
        if (action.payload.rows.includes(data[i].row)) {
          data.splice(i, 1);
          i--;
        } else if (removedDuplicates[data[i].row]) {
          const dupData = data[i];
          if (dupData.errors) {
            for (let j = 0; j < dupData.errors.length; j++) {
              if (
                removedDuplicates[dupData.row].includes(
                  dupData.errors[j].message,
                )
              ) {
                dupData.errors.splice(j, 1);
                j--;
              }
            }
            if (dupData.errors.length === 0) {
              delete dupData.errors;
            }
          }
        }
      }

      return {
        ...state,
        bpoLoanValidation: { ...updatedBpoLoanValidation, data },
      };
    }
    case 'SET_SELECTED_ADDRESS': {
      const updatedBpoLoanValidation = { ...state.bpoLoanValidation };
      updatedBpoLoanValidation.data = updatedBpoLoanValidation.data.map(
        loan => {
          let updated = loan;
          if (updated.row === action.payload.row) {
            updated = { ...loan };
            updated.addresses = loan.addresses.map(address => {
              if (
                address.bpoOrderStagingAddressId ===
                action.payload.bpoOrderStagingAddressId
              ) {
                return { ...address, isSelected: true };
              }
              if (address.isSelected) {
                return { ...address, isSelected: false };
              }
              return address;
            });
          }
          return updated;
        },
      );

      return {
        ...state,
        bpoLoanValidation: {
          ...updatedBpoLoanValidation,
          changeData: true,
        },
      };
    }
    case 'UPDATE_BPO_LOAN_DATA': {
      const updatedBpoLoanValidation = { ...state.bpoLoanValidation };
      const updatedIndex = updatedBpoLoanValidation.data.findIndex(
        loan => loan.orderItemId === action.payload.updatedLoan.orderItemId,
      );

      if (updatedIndex === -1) {
        return {
          ...state,
          bpoLoanValidation: {
            ...updatedBpoLoanValidation,
            data: [
              ...updatedBpoLoanValidation.data,
              action.payload.updatedLoan,
            ],
          },
        };
      }

      updatedBpoLoanValidation.data[updatedIndex] = action.payload.updatedLoan;

      return {
        ...state,
        bpoLoanValidation: {
          ...updatedBpoLoanValidation,
        },
      };
    }
    case 'REMOVE_SECURENT_FILES': {
      const updatedSecurentFiles = [
        ...state.securentFiles.filter(
          file => file.fileStagingId !== action.payload.fileStagingId,
        ),
      ];

      return {
        ...state,
        securentFiles: updatedSecurentFiles,
      };
    }
    case 'SET_SECURENT_FILES': {
      const updatedSecurentFiles = [
        ...state.securentFiles,
        ...action.payload.files,
      ];

      return {
        ...state,
        securentFiles: updatedSecurentFiles,
      };
    }
    case 'SET_AUS_LOAN': {
      let borrowerInfos: AUSBorrowerInfo[] | undefined;
      let creditInfo;
      let loan;

      if (action.payload.loan) {
        ({ borrowerInfos, creditInfo, ...loan } = action.payload.loan);
        if (action.payload.loan.creditInfo === undefined) {
          creditInfo = state.ausLoan.creditInfo;
        }
      } else {
        ({ borrowerInfos, creditInfo, ...loan } = state.ausLoan);
      }

      if (action.payload.creditInfo) {
        creditInfo = action.payload.creditInfo;
      }

      matchBorrowers(borrowerInfos, creditInfo);

      const updatedAusLoan = {
        ...state.ausLoan,
        ...loan,
        borrowerInfos,
        creditInfo,
      };

      return {
        ...state,
        ausLoan: updatedAusLoan,
      };
    }
    case 'REMOVE_AUS_LOAN_DOCUMENT': {
      const updatedAusLoan = {
        ...state.ausLoan,
        addressType: undefined,
        borrowerInfos: undefined,
        city: undefined,
        clientLoanId: undefined,
        documentId: undefined,
        documentName: undefined,
        loanAmount: undefined,
        loanStagingId: undefined,
        state: undefined,
        street: undefined,
        unitNumber: undefined,
        zip: undefined,
      };

      matchBorrowers(updatedAusLoan.borrowerInfos, state.ausLoan.creditInfo);

      return {
        ...state,
        ausLoan: updatedAusLoan,
      };
    }
    case 'REMOVE_AUS_CREDIT_DOCUMENT': {
      const updatedCreditInfo = state.ausLoan.creditInfo?.filter(
        creditInfo => creditInfo.creditStagingId !== action.payload.stagingId,
      );

      matchBorrowers(state.ausLoan.borrowerInfos, updatedCreditInfo);

      return {
        ...state,
        ausLoan: {
          ...state.ausLoan,
          creditInfo: updatedCreditInfo,
        },
      };
    }
    case 'INIT_ORDER': {
      return JSON.parse(JSON.stringify(initialState));
    }
    case 'LOAD_ORDER': {
      const {
        loans,
        orderId,
        orderNumber,
        stagingId,
        orderStatus,
        orderIdentifier,
        division,
        product,
      } = action.payload.unsubmittedOrder;

      let appraisalLoan: ILoan[] = [];

      if (product && product.productName === 'Appraisal Review') {
        appraisalLoan = loans;
      }

      return {
        ...state,
        orderId,
        orderNumber,
        stagingId,
        orderStatus,
        product,
        division,
        orderIdentifier,
        appraisalLoan,
      };
    }
    case 'LOAD_BPO_ORDER': {
      const order = action.payload.order;
      return {
        ...state,
        orderId: order.orderId,
        orderIdentifier: order.identifier,
        bpoLoan: {
          ...state.bpoLoan,
          isCompletedSuccessfully: order.isCompletedSuccessfully,
          bpoLoanMode: order.uploadSource === 'Excel' ? 'upload' : 'manual',
          manualLoans:
            order.uploadSource === 'manual'
              ? order.data
                  .map(loan => {
                    const addressLookup = extractAddresses(loan);
                    return {
                      ...loan,
                      customerReferenceId1: loan.customerReferenceId1 || '',
                      customerReferenceId2: loan.customerReferenceId2 || '',
                      customerReferenceId3: loan.customerReferenceId3 || '',
                      street: addressLookup.original?.street || '',
                      unitNumber: addressLookup.original?.unitNumber || '',
                      city: addressLookup.original?.city || '',
                      state: addressLookup.original?.state || '',
                      zip: addressLookup.original?.zip || '',
                    } as IManualLoan;
                  })
                  .concat(
                    order.data.length === 10 ? [] : [initialBPOManualLoan],
                  )
              : [],
        },
        bpoLoanValidation: {
          ...state.bpoLoanValidation,
          data: order.data,
          summary: order.summary,
          productInfo: order.productInfo,
          hasError: order.hasError,
          hasUnselected: order.hasUnselected,
          hasUnselectedNonSingleSuggestion:
            order.hasUnselectedNonSingleSuggestion,
          hasUnvalidated: order.hasUnvalidated,
          hasWarning: order.hasWarning,
          hasUnacknowledgedWarning: order.hasUnacknowledgedWarning,
          errorLoans: order.errorLoans,
          correctionLoans: order.correctionLoans,
          uploadFileName: order.uploadFileName,
          uploadSource: order.uploadSource,
        },
        product: {
          ...state.product,
          productId: order.productInfo?.productId,
          productName: order.productInfo?.productName,
          vendorId: order.productInfo?.vendorId,
          price: order.productInfo?.price,
        },
      };
    }
    default:
      return state;
  }
};
