/* eslint-disable react/function-component-definition */
import React, { useEffect, useContext, useState } from 'react';
import { useClient } from './ClientContext';
import { ServiceConfig } from '../models/ServiceConfig';
import { WithChildren } from '../types';

const ServiceConfigContext = React.createContext<
  ServiceConfig | null | undefined
>(undefined);

const ServiceConfigProvider: React.FC<Partial<WithChildren>> = ({
  children,
}) => {
  const client = useClient();
  const [config, SetConfig] = useState<ServiceConfig | null>(null);

  useEffect(() => {
    const isCanceled = false;
    if (client.isReady) {
      client.config.getConfig().then(newConfig => {
        if (!isCanceled) {
          SetConfig(newConfig);
        }
      });
    }
  }, [client]);

  return (
    <ServiceConfigContext.Provider value={config}>
      {children}
    </ServiceConfigContext.Provider>
  );
};

function useServiceConfig() {
  const context = useContext(ServiceConfigContext);
  if (context === undefined) {
    throw new Error(
      'useServiceConfig must be used within a CurrentUserProvider!',
    );
  }
  return context;
}

export { ServiceConfigProvider, useServiceConfig };
