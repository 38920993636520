import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10;
  width: 100%;

  p {
    font-size: 16px;
    text-align: center;
    margin-bottom: 10px;
  }
`;
