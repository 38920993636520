import { SelectionEvent, SelectionType } from '../events/SelectionEvent';
import Base from '../models/Base';

/** Processing a selection changed event in a way that takes this slightly complex code out of our Droppable. The important detail is the return here:
 * if the function returns an array of T, then that's the new selection items. If it returns NULL, then don't do anything.
 * It will also return the index to set for the last item selected, and the index for the last focused item for keyboard navigation.
 * It always returns these values and so updating state with them every time is safe.
 */
export function getSelectedModels<T extends Base<T>>(
  models: Array<T>,
  visibleModels: Array<T>,
  selectedModels: Array<T>,
  eventParameters: SelectionEvent,
  lastSelectionIndex: number,
): [T[] | null, number, number] {
  const modelIndex = models.findIndex(m => m.id.equals(eventParameters.id));

  // If model is not in this list, then that means we're either resetting our selections (user clicked in a different list),
  // or we don't have any selections anyways, so we return null, telling the consumer to do nothing.
  if (modelIndex === -1) {
    if (selectedModels.length) {
      return [new Array<T>(), -1, 0];
    }
    return [null, -1, 0];
  }

  const model = models[modelIndex];
  let newSelectedArray: Array<T>;

  if (eventParameters.selectionType === SelectionType.Normal) {
    // With no modifier, the item selected is now going to be our only item in the selected array.

    // If the selection list is already that, user clicked the item they already have selected so don't do anything.
    if (
      selectedModels.length === 1 &&
      selectedModels[0].id === eventParameters.id
    ) {
      return [null, modelIndex, 0];
    }
    return [new Array<T>(model), modelIndex, modelIndex];
  }

  if (eventParameters.selectionType === SelectionType.Single) {
    // With the CTRL modifier, the only thing that needs to happen is to flip the selected status of the item in question.
    // If it's in the list, remove it, vice versa.
    if (!selectedModels.length) {
      return [new Array<T>(model), modelIndex, modelIndex];
    }

    newSelectedArray = [...selectedModels];
    const selectionIndex = selectedModels.findIndex(m =>
      m.id.equals(eventParameters.id),
    );

    if (selectionIndex === -1) {
      newSelectedArray.push(model);
      return [newSelectedArray, modelIndex, modelIndex];
    }

    newSelectedArray.splice(selectionIndex, 1);
    return [newSelectedArray, modelIndex, modelIndex];
  }

  // if (eventParameters.selectionType === SelectionType.Range) {
  // Early bail because nothing was selected yet, so we'll treat this as a "Normal".
  if (!selectedModels.length) {
    return [new Array<T>(model), modelIndex, modelIndex];
  }

  // The weird note here is that lastSelectionIndex is not updated with the latest shift-clicked value.
  // Click around the files list in VS Code and you'll see why.

  const lastSelectedModel = models[lastSelectionIndex];
  const lastSelectedVisibleIndex = visibleModels.indexOf(lastSelectedModel);
  const modelVisibleIndex = visibleModels.indexOf(model);
  const [start, stop] =
    modelVisibleIndex < lastSelectedVisibleIndex
      ? [modelVisibleIndex, lastSelectedVisibleIndex]
      : [lastSelectedVisibleIndex, modelVisibleIndex];

  newSelectedArray = visibleModels.slice(start, stop + 1);
  return [newSelectedArray, lastSelectionIndex, modelIndex]; // Last focus moves to the newest selected item every time
  // }
}
