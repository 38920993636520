import * as React from 'react';
import { Container } from './styles';

function PrivacyLink() {
  return (
    <Container href="https://www.situsamc.com/privacy-policy" target="blank">
      Privacy Policy
    </Container>
  );
}
export default PrivacyLink;
