import { Company } from '../models';
import Methods from './Methods';

export default class CompanyClient {
  private methods: Methods;

  constructor(methods: Methods) {
    this.methods = methods;
  }

  async listCompanies() {
    const response = await this.methods.get(`company`);
    const json = await response.json();
    const companies = new Array<Company>();
    // eslint-disable-next-line no-restricted-syntax
    for (const company of json) {
      companies.push(Company.fromJson(company));
    }
    return companies;
  }
}
