import { Company } from '../models';
import { ValidationErrors } from './ValidationErrors';

export function ValidateCompany(
  validationErrors: ValidationErrors,
  company: Company | null,
  usesClarityCompanies: boolean,
) {
  if (!company || (company.id.isEmpty() && company.name.trim() === '')) {
    validationErrors.addLocalError('company', 'A company must be selected.');
  } else if (company.id.isEmpty() && usesClarityCompanies) {
    // Really shouldn't hit this validation (the combobox restricts creating new items when usesClarityCompanies is active).
    // This is here just in case.
    validationErrors.addLocalError(
      'company',
      'You must use an existing company.',
    );
  }
  return validationErrors;
}
