import React, { useEffect, useState } from 'react';
import { DefaultPublisher } from '@samc/common';
import { User } from '../models';
import { useClient } from './ClientContext';
import { useCurrentUser } from './CurrentUserContext';
import Entitlements from '../Entitlements';
import { UserSavedEvent } from '../events/UserSavedEvent';
import { WithChildren } from '../types';

type Users = {
  map: Map<string, User>;
  mapByUserName: Map<string, User>;
  array: Array<User>;
};

const UserContext = React.createContext<Users | null | undefined>(undefined);

// eslint-disable-next-line react/function-component-definition
const UsersProvider: React.FC<Partial<WithChildren>> = ({ children }) => {
  const client = useClient();
  const currentUser = useCurrentUser();
  const [users, setUsers] = useState<Users | null>(null);
  const reloadUsers = () => {
    if (currentUser.hasEntitlement(Entitlements.Users.List)) {
      client.users.listUsers().then(u => {
        const map = new Map<string, User>();
        const mapByUserName = new Map<string, User>();
        // eslint-disable-next-line no-restricted-syntax
        for (const user of u) {
          map.set(user.id.toString(), user);
          mapByUserName.set(user.username.toLowerCase(), user);
        }
        setUsers({ map, array: u, mapByUserName });
      });
    }
  };
  useEffect(() => {
    if (client.isReady && currentUser.isLoaded && !currentUser.isDisabled) {
      reloadUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, currentUser]);

  useEffect(() => {
    const subscriptionId = DefaultPublisher.subscribe<UserSavedEvent>(
      UserSavedEvent.eventName,
      reloadUsers,
    );
    return () =>
      DefaultPublisher.unsubscribe(UserSavedEvent.eventName, subscriptionId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, currentUser]);

  return <UserContext.Provider value={users}>{children}</UserContext.Provider>;
};

function useUserMap() {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUserMap must be used within a UsersProvider!');
  }
  return context?.map;
}

function useUserMapByUserName() {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUserMap must be used within a UsersProvider!');
  }
  return context?.mapByUserName;
}

function useUserArray() {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUserArray must be used within a UsersProvider!');
  }
  return context?.array;
}

export { UsersProvider, useUserMap, useUserArray, useUserMapByUserName };
