import styled from 'styled-components';

export const ModalTitle = styled.h3`
  text-align: center;
`;

export const Grid = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
  flex: 1 1 auto;
`;
