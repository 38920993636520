import styled from 'styled-components';

type ImgStates = 'default' | 'hover' | 'active';

export type OrderImageStates = Record<ImgStates, string>;
interface Props {
  images: OrderImageStates;
}

export const Container = styled.div<Props>`
  display: flex;
  width: 234px;
  height: 134px;
  cursor: pointer;
  background: url(${props => props.images.default});

  &:hover {
    background: url(${props => props.images.hover});
  }

  &:active {
    background: url(${props => props.images.active});
  }
`;
