import { default as EditorActionRender } from './EditorActionRenderer';
import { default as DateRender } from './DateRenderer';
import { default as UserRender } from './UserRenderer';
import { default as CheckboxRender } from './CheckboxRenderer';
import { default as CheckRender } from './CheckRenderer';
import { default as UserStatusRender } from './UserStatusRenderer';
import { default as ModelNameRender } from './ModelNameRenderer';

export enum Renderers {
  EditorActionRenderer = 'editorActionRenderer',
  DateRenderer = 'dateRenderer',
  UserRenderer = 'userRenderer',
  CheckRenderer = 'checkRenderer',
  CheckboxRenderer = 'checkboxRenderer',
  UserStatusRenderer = 'userStatusRenderer',
  ModelNameRenderer = 'modelNameRenderer',
  Default = '',
}
export {
  EditorActionRender as EditorActionRenderer,
  DateRender as DateRenderer,
  UserRender as UserRenderer,
  CheckRender as CheckRenderer,
  CheckboxRender as CheckboxRenderer,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function addRenderers(frameworkComponents: any) {
  frameworkComponents[Renderers.EditorActionRenderer.toString()] =
    EditorActionRender;
  frameworkComponents[Renderers.DateRenderer.toString()] = DateRender;
  frameworkComponents[Renderers.UserRenderer.toString()] = UserRender;
  frameworkComponents[Renderers.CheckRenderer.toString()] = CheckRender;
  frameworkComponents[Renderers.CheckboxRenderer.toString()] = CheckboxRender;
  frameworkComponents[Renderers.UserStatusRenderer.toString()] =
    UserStatusRender;
  frameworkComponents[Renderers.ModelNameRenderer.toString()] = ModelNameRender;
}
