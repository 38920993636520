import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  border: 1px solid #c8c8c8;
  border-radius: 3px;
  background-color: #ffffff;
  background-size: cover;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
`;
