import * as React from 'react';
import { reducer } from './reducer';
import initialState from './initialState';
import { StepperContextData } from './types';
import useActions from './useActions';

const StepperContext = React.createContext<StepperContextData>(
  {} as StepperContextData,
);
StepperContext.displayName = 'StepperContext';

interface StepperProviderProps {
  children: React.ReactNode;
}

export function StepperProvider(props: StepperProviderProps) {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const {
    onClearStep,
    onFinalizeStep,
    onInitSteps,
    onNavigateToStep,
    onNextStep,
    onPreviousStep,
    onResetSteps,
    onReviewStep,
    onRefreshData,
  } = useActions({ dispatch });

  const value = React.useMemo(
    () => ({
      state,
      dispatch,
      onClearStep,
      onFinalizeStep,
      onInitSteps,
      onNavigateToStep,
      onNextStep,
      onPreviousStep,
      onResetSteps,
      onReviewStep,
      onRefreshData,
    }),
    [
      onClearStep,
      onFinalizeStep,
      onInitSteps,
      onNavigateToStep,
      onNextStep,
      onPreviousStep,
      onResetSteps,
      onReviewStep,
      onRefreshData,
      state,
    ],
  );
  return <StepperContext.Provider value={value} {...props} />;
}

export function useStepper(): StepperContextData {
  const context = React.useContext(StepperContext);

  if (Object.keys(context).length === 0) {
    throw new Error('useStepper must be used within the StepperProvider');
  }
  return context;
}
