import React from 'react';
import { useOrder } from '../../../context/order';
import { Loading, StepContainer } from '../../Common';

const UploadBPOLoans = React.lazy(() => import('./UploadBPOLoans'));
const ManualBPOLoan = React.lazy(() => import('./ManualBPOLoans'));

function BPOLoanDetails() {
  const { order } = useOrder();

  return (
    <StepContainer>
      <React.Suspense fallback={<Loading />}>
        {order.bpoLoan.bpoLoanMode === 'upload' ? (
          <UploadBPOLoans />
        ) : (
          <ManualBPOLoan />
        )}
      </React.Suspense>
    </StepContainer>
  );
}

export default BPOLoanDetails;
