import * as React from 'react';
import { ControlBar as ControlBarUI } from '@samc/react-ui-grid';
import { SearchButton, SearchField } from './styles';

interface ControlBarProps {
  onExport(): void;
  onClickDelete?: () => void;
  suppressDeleteButton?: boolean;
  loanSearchText?: string;
  setLoanSearchText?: (text: string) => void;
}

function ControlBar({
  onExport,
  suppressDeleteButton = true,
  onClickDelete,
  loanSearchText,
  setLoanSearchText,
}: ControlBarProps) {
  const loanSearchRef = React.useRef<HTMLInputElement>();
  const loaded = React.useRef(false);

  React.useEffect(() => {
    if (loaded.current === false && loanSearchRef.current && loanSearchText) {
      loaded.current = true;
      loanSearchRef.current.value = loanSearchText;
    }
  });

  return (
    <ControlBarUI
      onClickExcelExport={onExport}
      suppressDeleteButton={suppressDeleteButton}
      onClickDelete={onClickDelete}
    >
      {setLoanSearchText && (
        <>
          <SearchField
            placeholder="Loan ID"
            ref={(ref: HTMLInputElement) => {
              if (ref !== null) {
                loanSearchRef.current = ref;
              }
            }}
          />
          <SearchButton
            text="Search"
            onClick={() => {
              if (setLoanSearchText && loanSearchRef.current) {
                setLoanSearchText(loanSearchRef.current.value);
              }
            }}
          />
        </>
      )}
    </ControlBarUI>
  );
}

export default ControlBar;
