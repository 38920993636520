import React from 'react';
import { useStepper } from '../context';
import { Container } from './styles';

export interface StepsProps {
  initialStep?: number | string;
  children: JSX.Element[];
}

function Steps({ initialStep, children }: StepsProps) {
  const isResuming = React.useRef(false);

  const { onInitSteps } = useStepper();

  React.useEffect(() => {
    const stepsChildren = children;
    if (stepsChildren.length > 0) {
      const stepsNode = stepsChildren
        ? stepsChildren.map((stepNode: JSX.Element) => ({
            ...stepNode.props,
          }))
        : [];
      if (stepsNode.length > 0) {
        if (initialStep) {
          if (!isResuming.current) {
            isResuming.current = true;
            onInitSteps(stepsNode, initialStep);
          }
        } else if (!isResuming.current) {
          onInitSteps(stepsNode);
        }
      }
    }
  }, [children, initialStep, onInitSteps]);

  return <Container>{children}</Container>;
}

export default Steps;
