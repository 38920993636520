import { AxiosError } from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import api from '../services/api';

export type GetBPOPanelOrderResponse = {
  orderId: string;
  stagingId: string;
  orderStatus: string;
  orderNumber: number;
  companyDivision: number;
  productInfo: {
    vendorId: string;
    productId: string;
    productName: string;
    price: number;
  };
  identifier: string;
  createdBy: string;
  createdOn: string;
  submittedBy: string;
  submittedOn: string;
  loans: {
    orderItemId: string;
    loanId: string;
    loanNumber: number;
    clientLoanId: string;
    clarityLoanNumber: number;
    loanStatus: string;
    borrowerLastName: string;
    accessDetails: string;
    address: string;
    unitNumber: string;
    city: string;
    state: string;
    zip: string;
    valuation: number;
    lastDownloadedExcelData: string;
    lastDownloadedPdfDate: string;
    productInfo: {
      vendorId: string;
      productId: string;
      productName: string;
      price: number;
    };
    providerRequestType: string;
    documents: {
      documentId: string;
      documentName: string;
      typeId: string;
    }[];
    disputeActionType: string;
    customerReferenceId1: string;
    customerReferenceId2: string;
    customerReferenceId3: string;
  }[];
};

export async function getBPOPanelOrder(
  orderId: string,
): Promise<GetBPOPanelOrderResponse> {
  const response = await api.get<GetBPOPanelOrderResponse>(`Order/${orderId}`);
  return response.data;
}

export function useBPOPanelOrder({
  orderId,
}: {
  orderId: string;
}): UseQueryResult<GetBPOPanelOrderResponse, AxiosError<ErrorData>> {
  return useQuery(['bpoPanelOrder', orderId], () => getBPOPanelOrder(orderId), {
    enabled: !!orderId,
  });
}
