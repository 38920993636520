import { AxiosError } from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { Division } from '../context/order/types';
import api from '../services/api';

type Divisions = {
  divisions: Division[];
};

export const getDivisions = async (): Promise<Division[]> => {
  const response = await api.get<Divisions>('/Division');
  return response.data.divisions;
};

export const useDivisions = (): UseQueryResult<
  Division[],
  AxiosError<ErrorData>
> =>
  useQuery('divisions', getDivisions, {
    staleTime: 0,
    cacheTime: 0,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
