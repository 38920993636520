import axios from 'axios';

const authApiUrl = window.config.authApiUrl;

const api = axios.create({
  baseURL: authApiUrl,
  timeout: 1000 * 10 * 6, // 60 seconds,
});

export default api;
