import { useQuery } from 'react-query';
import { ILoan } from '../context/order/appraisal/types';
import { BPOLoanData } from '../context/order/bpo/types';
import { extractAddresses } from '../context/order/bpo/util';
import { ISubProduct } from '../context/order/types';
import api, { BPO_ENDPOINT_PATH } from '../services/api';

export type GetBPOOrderResponse = {
  identifier: string;
  orderNumber?: number;
  createdBy?: string;
  companyDivision: number;
  productInfo: {
    vendorId: string;
    productId: string;
    productName: string;
    price: number;
    subProducts: ISubProduct[];
  };
  isCompletedSuccessfully: boolean;
  orderId: string;
  summary: {
    loansCant: number;
    orderTotal: number;
    interiorPrice: number;
    exteriorPrice: number;
    exteriorCant: number;
    interiorCant: number;
  };
  data: BPOLoanData[];

  loans?: ILoan[];
  uploadFileName?: string;
  uploadSource?: string;
  /*
  products?: [
    {
      productId: string;
      productName: string;
      price: number;
      currentPrice: number;
      didPriceChange: boolean;
      isAvailable: boolean;
    },
  ];
  */

  // Front-end calculated values
  hasError?: boolean;
  hasWarning?: boolean;
  hasUnacknowledgedWarning?: boolean;
  hasUnselected?: boolean;
  hasUnselectedNonSingleSuggestion?: boolean;
  hasUnvalidated?: boolean;
  errorLoans?: number[];
  correctionLoans?: number[];
};

export const calculateBPOOrderResponse = (
  data: GetBPOOrderResponse,
): GetBPOOrderResponse => {
  let hasError = false;
  let hasWarning = false;
  let hasUnacknowledgedWarning = false;
  let hasUnselected = false;
  let hasUnselectedNonSingleSuggestion = false;
  let hasUnvalidated = false;
  const errorLoans: number[] = [];
  const correctionLoans: number[] = [];

  data.data.forEach((loan: BPOLoanData) => {
    if (loan.errors) {
      loan.errors.forEach(err => {
        if (err.type === 'Warning') {
          hasWarning = true;

          if (err.errorAcknowledged !== true) {
            hasUnacknowledgedWarning = true;
          }

          if (errorLoans.includes(loan.row) === false) {
            errorLoans.push(loan.row);
          }
        } else if (err.type !== 'Address') {
          hasError = true;

          if (errorLoans.includes(loan.row) === false) {
            errorLoans.push(loan.row);
          }
        }
      });
    }

    if (
      !!loan.addresses &&
      (!hasUnselected || !hasUnselectedNonSingleSuggestion || !hasUnvalidated)
    ) {
      let suggestionCount = 0;
      const hasSelection = loan.addresses.some(address => {
        if (address.type === 'Suggested') suggestionCount++;

        if (address.isSelected) {
          if (!address.isValidated) {
            hasUnvalidated = true;
          }
          return true;
        }
        return false;
      });

      if (!hasSelection) {
        hasUnselected = true;
        if (suggestionCount !== 1) {
          hasUnselectedNonSingleSuggestion = true;
        }
      }
    }

    const addressLookup = extractAddresses(loan);

    if (
      addressLookup.suggested.length > 0 ||
      !addressLookup.original ||
      addressLookup.original.isValidated !== true
    ) {
      correctionLoans.push(loan.row);
    }
  });

  return {
    ...data,
    hasError,
    hasWarning,
    hasUnacknowledgedWarning,
    hasUnselected,
    hasUnselectedNonSingleSuggestion,
    hasUnvalidated,
    errorLoans,
    correctionLoans,
  };
};

export async function getBPOOrder(orderId: string) {
  const response = await api.get<GetBPOOrderResponse>(
    `/${BPO_ENDPOINT_PATH}/${orderId}`,
  );
  return calculateBPOOrderResponse(response.data);
}

export function useBPOOrder({
  orderId,
  productName,
}: {
  orderId: string;
  productName: string;
}) {
  return useQuery(['bpoOrder', orderId], () => getBPOOrder(orderId), {
    staleTime: 0,
    enabled: !!orderId && productName === 'Broker Price Opinion',
    refetchOnWindowFocus: false,
  });
}
