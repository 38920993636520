import { Button } from '@samc/react-ui-core';
import styled from 'styled-components';
import SearchIcon from '../../../assets/icon-search.svg';

export const Container = styled.div`
  display: flex;
  padding: 5px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  border: 1px solid #d1d1d1;
  background-color: #ffffff;
  background-size: cover;

  border-top-width: 1px;
  border-bottom-width: 1px;
  border-style: solid;
  border-color: #d2d2d2;
  background-position: center center;
  background-size: cover;
`;

export const SearchField = styled.input`
  border: solid 1px #dbdbdb;
  border-radius: 4px;
  padding: 5px 8px 5px 6px;
  margin: 3px;
  margin-right: 8px;
  width: 250px;

  &:empty {
    background-image: url(${SearchIcon});
    background-position: 3px 5px;
    background-repeat: no-repeat;
    padding-left: 26px;
  }
`;

export const SearchButton = styled(Button)`
  margin: 6px;
`;
