import { createGlobalStyle } from 'styled-components';
import './index.esm.css';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  html {
    font-family: Roboto;
    line-height: 1.5;
  }

  html .Toastify {
    --toastify-z-index: 1000000000;
  }

  body {
    background: ${props => props.theme.colors.background};
    color: ${props => props.theme.colors.dark};
    -webkit-font-smoothing: antialiased;
  }

  body, input, button {
    font-family: ${props => props.theme.fontFamily.main};
    font-size: ${props => props.theme.fontSize.lg};
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: ${props => props.theme.fontFamily.heading};
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 700;
  }

  h2 {
    font-size: ${props => props.theme.fontSize.xl2};
  }

  h3 {
    font-size: ${props => props.theme.fontSize.xl};
  }

  button {
    cursor: pointer;
    border: none;
    background-color: transparent;

    &:active {
      outline: none;
      border: none;
      }

    &:focus {
      outline: 0;
    }
  }





  /* SAMC Components overrides */

  .ag-theme-balham {
    font-family: ${props => props.theme.fontFamily.main};
  }

  .text-blue-7 {
    font-size: 14px;
  }

  .ag-theme-balham {
    font-size: 14px;
  }

  .workbench-body {
    width: 100%;
  }

  .situs-workbench .workbench-header {
    margin-top: 20px;
  }

  .workbench-router-container {
    padding-left: 0;
    padding-right: 0;
  };

  #order-grid .highlight-row,
  .ag-row.highlight-row,
  .ag-row.highlight-row {
    background: #e7fad4 !important;
  }

  .clickable-row {
    cursor: pointer;
  }

  #auth div.field {
    padding-top: unset;
  }

  #auth div.field input {
    border-width: 0;
  }

  .ag-theme-centricity {
    font-size: 14px;

    .ag-header-cell-text {
      color: ${props => props.theme.colors.dark};
      font-weight: 700;
    }
  }

  .ms-Button-icon {
    color: ${props => props.theme.colors.primary} !important;
  }

  .data-import[data-v-1e775311] {
    margin: -20px 0 -15px;
  }

/* Fluent-Ui Components overrides */

  #statewrapper::after, #vendorswrapper::after {
    pointer-events: none;
    content: "";
    position: absolute;
    inset: 0px;
    border: 1px solid #dbdbdb;
    border-radius: 2px;
  }

  .ms-Label {
    font-family: ${props => props.theme.fontFamily.main};
    font-size: ${props => props.theme.fontSize.base};
    font-weight: 600;
    color: ${props => props.theme.colors.dark};
  }

  .css-118::after, .css-143::after, .title-154, .title-190 {
    border-color: #dbdbdb !important

  }

  .ms-TextField-fieldGroup {
    border: 1px solid #dbdbdb;
    border-radius: 2px;
    background-color: #ffffff;
    background-size: cover;
  }

  .ms-TextField-field {
    font-family: ${props => props.theme.fontFamily.main};
    font-size: ${props => props.theme.fontSize.base};
    color: ${props => props.theme.colors.dark};
  }
`;
