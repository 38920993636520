import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const Heading = styled.p`
  font-family: ${props => props.theme.fontFamily.heading};
  font-size: ${props => props.theme.fontSize.xl2};
  color: ${props => props.theme.colors.dark};
`;

export const Cards = styled.div`
  display: flex;
  gap: 10px;
`;
