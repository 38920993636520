/* eslint-disable no-restricted-globals */
import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import PrettyCenteredBox from '../components/PrettyCenteredBox';
import { useAuthenticationConfig } from '../contexts/AuthenticationConfigContext';

function LoggedOut() {
  const { oktaAuth } = useOktaAuth();
  const log = (window as any).$log || console; // use centricity client logging if available.
  log.info(`loading loggedout page`, 'Component: Auth');

  const { appName } = useAuthenticationConfig();

  const login = async () => {
    if (!(await oktaAuth.isAuthenticated())) {
      log.debug('redirection to okta login', 'Component: Auth');

      // Start the browser based oidc flow, then parse tokens from the redirect callback url
      await oktaAuth.signInWithRedirect();
    }
  };

  return (
    <PrettyCenteredBox>
      <p className="mt-xl font-bold text-2">
        You are now logged out of {appName}.
      </p>
      <p className="text-2">
        To log back in, please{' '}
        <a href="#" className="text-blue-5 underline" onClick={login}>
          click here to log in
        </a>
        .
      </p>
    </PrettyCenteredBox>
  );
}

export default LoggedOut;
