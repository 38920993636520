import styled from 'styled-components';

export interface ContainerProps {
  height?: string;
  width?: string;
}

export const Container = styled.div<ContainerProps>`
  z-index: 100;
  position: fixed;
  width: ${props => (props.width ? props.width : '100%')};
  height: ${props => (props.width ? props.width : '100%')};

  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  background: rgba(0, 0, 0, 0.5);
`;

export const Message = styled.div`
  font-size: ${props => props.theme.fontSize.xl};
  font-weight: 700;
  text-align: center;
  color: #ffffff;
`;
