import { AxiosError } from 'axios';
import React from 'react';
import { useMutation } from '../hooks/useMutation';
import api from '../services/api';

type Request = {
  clientLoanId: string;
};

type Response = {
  orderIds: string[];
};

export const getOrderTrackingLoanSearch = async (
  request: Request,
): Promise<Response> => {
  const response = await api.get<Response>(
    `/Order/ordertrackingloansearch?clientLoanId=${encodeURIComponent(
      request.clientLoanId,
    )}`,
  );
  return response.data;
};

export const useOrderTrackingLoanSearch = () => {
  return useMutation<Response, AxiosError<ErrorData>, Request>(
    React.useCallback(request => getOrderTrackingLoanSearch(request), []),
  );
};
