import { ICellRendererParams } from 'ag-grid-enterprise';
import { Dropdown, IDropdownOption } from '@fluentui/react';
import { GridFieldConfiguration } from '@samc/react-ui-grid';
import React from 'react';
import { useDisputeReasons } from '../../../../../queries/useDisputeReasons';
import CellStyleParams from '../../../../../types/CellStyleParams';
import { FormatCombobox, formatComboBox } from '../../../../../utils/helpers';
import { gridDropDownStyles, Editable } from './styles';

export const cellStyle = (params: CellStyleParams): React.CSSProperties => {
  if (!params.value) {
    return { borderColor: '#a52c28', background: '#f5e2e0' };
  }
  return { borderColor: '#dbdbdb', background: '#fafafa' };
};

export function EditableRenderer({
  value,
  setValue,
}: ICellRendererParams & GridFieldConfiguration) {
  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleBlur = () => {
    if (!!setValue && inputRef.current && value !== inputRef.current.value) {
      setValue(inputRef.current.value);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      !!setValue &&
      e.key === 'Enter' &&
      inputRef.current &&
      value !== inputRef.current.value
    ) {
      setValue(inputRef.current.value);
    }
  };

  return (
    <Editable>
      <input
        ref={inputRef}
        defaultValue={value}
        type="text"
        onBlur={handleBlur}
        onKeyUp={handleKeyPress}
        placeholder="Enter a comment"
      />
    </Editable>
  );
}

export function SelectableRenderer({
  value,
  setValue,
}: ICellRendererParams & GridFieldConfiguration) {
  const { data } = useDisputeReasons();

  const comboboxDataParams: FormatCombobox = {
    options: data || [],
    key: 'reason',
    text: 'reason',
  };
  const options = formatComboBox(comboboxDataParams);

  const handleChange = (
    _: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption | undefined,
  ) => {
    if (option && !!setValue) {
      setValue(option.text);
    }
  };

  return (
    <Editable>
      <Dropdown
        placeholder="Select a reason"
        options={options}
        selectedKey={value}
        onChange={handleChange}
        styles={gridDropDownStyles}
      />
    </Editable>
  );
}
