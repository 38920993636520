import { IButtonStyles } from '@fluentui/react';
import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: -20px;
`;

export const ContentMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100px;
`;

export const Countdown = styled.div`
  font-size: 24px;
  font-weight: 600;
`;

export const DialogFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 5px 0px -10px 0px;
`;

export const dialogButtonStyles: Partial<IButtonStyles> = {
  root: {
    width: '49%',
  },
};
