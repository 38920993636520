import React from 'react';
import { ActionTypes } from '../types';
import { UseIndexingActions } from './types';
import {
  IFileSource,
  IRawMetaData,
} from '../../../components/Common/ImportComponents/interfaces';

interface Props {
  dispatch: (action: ActionTypes) => void;
}
export default function useIndexingActions({
  dispatch,
}: Props): UseIndexingActions {
  const onChangeIndexingDocument = React.useCallback(() => {
    dispatch({
      type: 'CLEAR_INDEXING_DOCUMENT',
    });
  }, [dispatch]);

  const onChangeIndexingPreviewData = React.useCallback(() => {
    dispatch({
      type: 'CLEAR_INDEXING_DOC_PREVIEW_DATA',
    });
  }, [dispatch]);

  const onChangeIndexingData = React.useCallback(
    (fileSource: IFileSource, metaData: IRawMetaData) => {
      dispatch({
        type: 'SET_INDEXING_DOC_DATA',
        payload: { fileSource, metaData },
      });
    },
    [dispatch],
  );

  const onChangeIndexingColumnName = React.useCallback(
    (columnName: string) => {
      dispatch({
        type: 'SET_INDEXING_CLIENT_LOAN_ID',
        payload: { columnName },
      });
    },
    [dispatch],
  );

  return {
    onChangeIndexingDocument,
    onChangeIndexingPreviewData,
    onChangeIndexingData,
    onChangeIndexingColumnName,
  };
}
