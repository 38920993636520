import React from 'react';
import { Security } from '@okta/okta-react';
import OktaAuth, { AuthState, AuthStateEventHandler } from '@okta/okta-auth-js';
import { Guid, setCurrentUser } from '@samc/common';
import { useAuthenticationConfig } from './contexts';
import { getLoginRedirectPath } from './helpers/loginRedirectPathHelpers';
import LastAccessedPathTracking from './LastAccessedPathTracking';
import { LoginRedirectMethod } from './models';
import { TokenContext } from './contexts/TokenContext';
import api from '../../services/api';
import authApi from '../../services/authApi';

interface OktaManagerProps {
  /**
   * The Okta auth instance
   */
  oktaAuth: OktaAuth;
  /**
   * The component's children
   */
  children: React.ReactNode;
  /**
   * A navigation callback for when login/post-login redirects are executed
   * @param href the URL to navigate to
   * @param strategy the type of redirect
   * @param replace whether to append or replace history
   */
  navigate: (
    href: string,
    strategy: LoginRedirectMethod,
    replace?: boolean,
  ) => void;
}

interface GetLatestCookieParams {
  token: string;
  cookieUrl: string;
}

/**
 * A component that manages redirections for login/logout as Okta state changes and registers the Okta
 * {@link Security} component. Also populates the {@link TokenContext} and calls {@link setCurrentUser}.
 *
 * @note Does not do anything to refresh the token periodically (see MouseMovementTokenRefresh/InteractionTokenRefresh), but does track token changes.
 */
export function OktaManager(props: OktaManagerProps) {
  const { navigate, children, oktaAuth } = props;
  const log = (window as any).$log || console; // use centricity client logging if available.
  const [bearerToken, setBearerToken] = React.useState(
    oktaAuth.getAccessToken(),
  );

  api.defaults.headers.authorization = `Bearer ${bearerToken}`;
  authApi.defaults.headers.authorization = `Bearer ${bearerToken}`;

  const authStateLoading = React.useRef(true);
  const latestCookieFetch = React.useRef<{
    token: string;
    cookieUrl: string;
    promise: Promise<void>;
  }>();

  const {
    loginRedirectMethod,
    useOktaUser,
    cookieUrl,
    loginRedirectStrategy,
    loginRedirectPath,
  } = useAuthenticationConfig();

  // When subscribeToAuth is true, change events are responded to
  const [subscribeToAuth, setSubscribeToAuth] = React.useState(false);

  const [isLogInComplete, setIsLoginComplete] = React.useState(false);
  const [needsRedirect, setNeedsRedirect] = React.useState(
    oktaAuth.isLoginRedirect(),
  );

  // when recordLocationHistory is true, last visited URL is committed to storage
  const [recordLocationHistory, setRecordLocationHistory] =
    React.useState(false);

  /**
   * Fetches the latest cookie for the given params, caches latest result for duplicate calls
   */
  const getLatestCookie = React.useCallback(
    (params: GetLatestCookieParams): Promise<void> => {
      const { token, cookieUrl: myCookieUrl } = params;

      const cookieFetch = latestCookieFetch.current;
      if (
        cookieFetch &&
        cookieFetch.cookieUrl === myCookieUrl &&
        cookieFetch.token === token
      )
        return cookieFetch.promise;

      const promise = fetch(myCookieUrl, {
        headers: new Headers({ Authorization: `Bearer ${token}` }),
      }).then(() => log.debug('Updated auth cookie', 'Component: Auth'));

      latestCookieFetch.current = { promise, token, cookieUrl: myCookieUrl };
      return promise.catch(() => {
        latestCookieFetch.current = undefined;
      });
    },
    [],
  );

  /**
   * A callback to run after okta reports a login
   */
  const afterLogin = React.useCallback(
    async (authState: AuthState, redirect: boolean) => {
      const redirectTo = getLoginRedirectPath(loginRedirectPath, {
        loginRedirectStrategy,
      });
      let shouldRedirect = redirect;

      const token = authState.accessToken?.accessToken;
      if (cookieUrl && token) {
        if (loginRedirectMethod !== 'HardRedirect' && shouldRedirect) {
          shouldRedirect = false;
          log.warn(
            'Cannot soft redirect after cookie update',
            'Component: Auth',
          );
        }

        await getLatestCookie({ token, cookieUrl }).catch(err => {
          log.error(err, 'Component: Auth');
        });
      }

      if (
        useOktaUser &&
        (!shouldRedirect || loginRedirectMethod !== 'HardRedirect')
      ) {
        // update global user, don't bother on hard redirects since page will remount
        await oktaAuth.getUser().then(
          userClaims => {
            setCurrentUser({
              id: Guid.createEmpty(),
              name: userClaims.name ?? '',
              email: userClaims.email ?? '',
              company: '',
              entitlements: new Array<string>(),
              entitlementsByReferenceId: new Array<Guid>(),
              hasEntitlement: (entitlement: string) => true,
              hasEntitlementWithReferenceId: (entitlementReferenceId: Guid) =>
                true,
              isDisabled: false,
              isLoaded: true,
            });
          },
          err => {
            log.error(
              'Cannot update global current user',
              err,
              'Component: Auth',
            );
          },
        );
      }

      if (shouldRedirect)
        navigate(redirectTo as string, loginRedirectMethod, true);

      setIsLoginComplete(true);
    },
    [
      loginRedirectPath,
      loginRedirectStrategy,
      cookieUrl,
      loginRedirectMethod,
      useOktaUser,
    ],
  );

  /**
   * Initiates a login by navigating to the login page
   */
  const onLoginRequested = React.useCallback(() => {
    navigate('/login', 'HardRedirect', true);
  }, []);

  // add state listeners (login handler, updates cookie/token)
  React.useLayoutEffect(() => {
    if (!subscribeToAuth) return undefined;

    const onAuthStateChanged: AuthStateEventHandler = authState => {
      const previousAuthState =
        oktaAuth.authStateManager.getPreviousAuthState();
      const { isAuthenticated, accessToken } = authState;
      const { isAuthenticated: wasAuthenticated } = previousAuthState ?? {};

      // if we hard redirect here, it'll cause an infinite loop
      if (isAuthenticated && !wasAuthenticated) afterLogin(authState, false);
      // logout is automatically routed by OktaAuth

      // update cookie and bearer token for token provider
      if (accessToken) {
        if (cookieUrl)
          getLatestCookie({ token: accessToken.accessToken, cookieUrl });
        setBearerToken(accessToken.accessToken);
      }
    };

    oktaAuth.authStateManager.subscribe(onAuthStateChanged);
    return () => oktaAuth.authStateManager.unsubscribe(onAuthStateChanged);
  }, [oktaAuth, subscribeToAuth, cookieUrl, afterLogin]);

  // startup handler, handles redirects, begins url tracking.
  React.useLayoutEffect(() => {
    const exec = async () => {
      if (needsRedirect) {
        // handle loading tokens from URL
        await oktaAuth.token.parseFromUrl().then(
          ({ tokens }) => oktaAuth.tokenManager.setTokens(tokens),
          err => log.error(err, 'Component: Auth'),
        );
      }

      await oktaAuth.authStateManager.updateAuthState().then(
        async authState => {
          await afterLogin(authState, needsRedirect);
          authStateLoading.current = false;
          setNeedsRedirect(false);
        },
        err => log.error(err, 'Component: Auth'),
      );

      setSubscribeToAuth(true);
      setRecordLocationHistory(true);
    };

    exec();
  }, []);

  return (
    <TokenContext.Provider value={bearerToken}>
      <Security
        oktaAuth={oktaAuth}
        onAuthRequired={() => {
          const authState = oktaAuth.authStateManager.getAuthState();

          if (authStateLoading.current) {
            const handler: AuthStateEventHandler = myAuthState => {
              if (!myAuthState.isAuthenticated) onLoginRequested();

              oktaAuth.authStateManager.unsubscribe(handler);
            };

            oktaAuth.authStateManager.subscribe(handler);
          } else if (!authState || !authState.isAuthenticated) {
            onLoginRequested();
          }
        }}
        restoreOriginalUri={() => {
          // pass, this is unused due to special return logic
        }}
      >
        <LastAccessedPathTracking suspendTracking={!recordLocationHistory}>
          {/** Only render children if we know that the page isn't interim */}
          {!needsRedirect && isLogInComplete && children}
        </LastAccessedPathTracking>
      </Security>
    </TokenContext.Provider>
  );
}
