import styled from 'styled-components';

interface ContentProps {
  padding?: string;
}

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

export const Content = styled.div<ContentProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: ${props => (props.padding ? props.padding : '20px 0 0 0')};

  .tab-header {
    font-size: 14px;
    height: 37px;
  }

  > div:first-child > div:first-child {
    padding: ${props => (props.padding ? '0px' : '0 10px')};
  }

  iframe {
    flex: 1;
    height: 100%;
  }
`;

export const GridWrapper = styled.div`
  flex: 1;
`;

export const Button = styled.button`
  height: 100%;
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.primary};
  font-size: 12px;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
`;
