import { ICellRendererParams } from 'ag-grid-enterprise';
import React from 'react';
import {
  FaCheckCircle,
  FaClock,
  FaExclamationCircle,
  FaEye,
} from 'react-icons/fa';
import { FiDownload, FiUpload } from 'react-icons/fi';
import { TiCancel } from 'react-icons/ti';
import { MdEdit } from 'react-icons/md';
import { IoReloadSharp } from 'react-icons/io5';
import { useCurrentUser } from '../../../features/authorization';
import { DownloadResultsParams } from '.';
import {
  ILoan,
  OrderTrackingLoan,
} from '../../../context/order/appraisal/types';
import { ContainerIcons, Editable, LinkButton } from './styles';
import { replaceWhiteSpaceWithSpace } from '../../../utils/helpers';

interface LoanStatusContentAction {
  label: string;
  color?: string;
  handleClick?: () => void;
  icon?: React.ReactElement;
  entitlement?: boolean;
  switchValue?: string;
}

interface LoanStatusContent {
  [key: string]: {
    color: string;
    icon: React.ReactElement;
    message: string;
    switchField?: string;
    actions: LoanStatusContentAction[];
  };
}
interface StatusCellRenderer {
  [key: string]: {
    color: string;
    fontWeight?: string;
    fontColor?: string;
    icon: React.ReactElement;
    message: string;
  };
}

interface BtnCellRendererParams extends ICellRendererParams {
  onOpenRequestDialog: (loan: ILoan) => void;
  openClarityBPOOrder: (loan: ILoan) => void;
  openAddressReviewNeeded: (loan: ILoan) => void;
  handleDownloadResults: ({
    loan,
    reportType,
  }: DownloadResultsParams) => Promise<void>;
}

export function CheckboxCellRenderer({ colDef, data }: ICellRendererParams) {
  if (data.loanStatus !== 'Completed') return null;
  return (
    <input
      type="checkbox"
      className="select-row-checkbox"
      checked={
        colDef &&
        colDef.cellRendererParams &&
        colDef.cellRendererParams.selectedLoans &&
        colDef.cellRendererParams.selectedLoans.some(
          (loan: OrderTrackingLoan) => loan.orderItemId === data.orderItemId,
        )
      }
      onChange={event => {
        if (
          colDef &&
          colDef.cellRendererParams &&
          colDef.cellRendererParams.onSelectionChanged
        ) {
          colDef.cellRendererParams.onSelectionChanged(
            data,
            event.target.checked,
          );
        }
      }}
    />
  );
}

export function StatusCellRederer({ value }: ICellRendererParams) {
  const cleanValue = replaceWhiteSpaceWithSpace(value);
  const loanStatusContentLabel: StatusCellRenderer = {
    Completed: {
      color: '#36b300',
      fontWeight: 'bold',
      icon: <FaCheckCircle size={14} />,
      message: 'Loan Complete',
    },
    Error: {
      color: '#BD0E08',
      fontWeight: 'bold',
      icon: <FaExclamationCircle size={14} />,
      message: 'Error:',
    },
    Pending: {
      color: '#e6930a',
      icon: <FaExclamationCircle size={14} />,
      message: 'Action Required:',
    },
    'In Progress': {
      color: '#7a7f87',
      fontColor: '#000000',
      icon: <FaClock size={14} />,
      message: 'In Progress',
    },
    'In Progress: Update requested': {
      color: '#7a7f87',
      fontColor: '#000000',
      icon: <FaClock size={14} />,
      message: 'In Progress: Update requested',
    },
    'Action Required': {
      color: '#e6930a',
      fontWeight: 'bold',
      icon: <FaExclamationCircle size={14} />,
      message: 'Action Required',
    },
    Canceled: {
      color: '#ff8589',
      fontWeight: 'bold',
      icon: (
        <TiCancel
          size={20}
          color="#000000"
          style={{ marginRight: -3, marginLeft: -3 }}
        />
      ),
      message: 'Canceled',
    },
  };

  if (!loanStatusContentLabel[cleanValue]) {
    if (cleanValue !== '' && typeof cleanValue === 'string') {
      return <div>{cleanValue}</div>;
    }

    return <div>Loan status is undefined</div>;
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        color: loanStatusContentLabel[cleanValue].color,
      }}
    >
      {loanStatusContentLabel[cleanValue].icon}
      <p
        style={{
          marginLeft: 10,
          color: loanStatusContentLabel[cleanValue].fontColor
            ? loanStatusContentLabel[cleanValue].fontColor
            : loanStatusContentLabel[cleanValue].color,
          fontWeight: loanStatusContentLabel[cleanValue].fontWeight
            ? loanStatusContentLabel[cleanValue].fontWeight
            : 'normal',
        }}
      >
        {`${loanStatusContentLabel[cleanValue].message} `}
      </p>
    </div>
  );
}

export function BtnCellRenderer({
  data,
  value,
  onOpenRequestDialog,
  handleDownloadResults,
  openClarityBPOOrder,
  openAddressReviewNeeded,
}: BtnCellRendererParams) {
  const handleOpenRequestUpdate = () => {
    onOpenRequestDialog(data);
  };
  const currentUser = useCurrentUser();
  const cleanValue = replaceWhiteSpaceWithSpace(value);
  const loanStatusContent: LoanStatusContent = {
    Completed: {
      color: '#36b300',
      icon: <FaCheckCircle size={14} />,
      message: 'Loan Complete',
      actions: [
        {
          label: 'Results',
          color: '#133D91',
          icon: <FiDownload size={15} />,
          handleClick: () => handleDownloadResults({ loan: data }),
          entitlement: currentUser.hasEntitlement(
            'Order Tracking - BPO Panel - Results',
          ),
        },
        {
          label: 'Update',
          color: '#133D91',
          icon: <IoReloadSharp size={14} />,
          handleClick: handleOpenRequestUpdate,
          entitlement: currentUser.hasEntitlement(
            'Order Tracking - BPO Panel - Update',
          ),
        },
      ],
    },
    Error: {
      color: '#BD0E08',
      icon: <FaExclamationCircle size={14} />,
      message: 'Error:',
      actions: [
        {
          label: 'Unable to process order',
          entitlement: true,
          color: '#BD0E08',
        },
      ],
    },
    Pending: {
      color: '#e6930a',
      icon: <FaExclamationCircle size={14} />,
      message: 'Action Required:',
      actions: [
        {
          label: 'Order Details',
          color: '#133D91',
          icon: <FaEye size={14} />,
          handleClick: () => openClarityBPOOrder(data),
          entitlement: currentUser.hasEntitlement(
            'Order Tracking - BPO Panel - Action Required',
          ),
        },
      ],
    },
    'In Progress': {
      color: '#7a7f87',
      icon: <FaClock size={14} />,
      message: 'In Progress',
      actions: [{ label: '', color: '#7a7f87' }],
    },
    'In Progress: Agent Accepted': {
      color: '#7a7f87',
      icon: <FaClock size={14} />,
      message: 'In Progress: Agent Accepted',
      actions: [{ label: '', color: '#7a7f87' }],
    },
    'In Progress: Pending Agent Acceptance': {
      color: '#7a7f87',
      icon: <FaClock size={14} />,
      message: 'In Progress: Pending Agent Acceptance',
      actions: [{ label: '', color: '#7a7f87' }],
    },
    'In Progress: Quality Check': {
      color: '#7a7f87',
      icon: <FaClock size={14} />,
      message: 'In Progress: Quality Check',
      actions: [{ label: '', color: '#7a7f87' }],
    },
    'In Progress: Update requested': {
      color: '#7a7f87',
      icon: <FaClock size={14} />,
      message: 'In Progress: Update requested',
      actions: [{ label: '', color: '#7a7f87' }],
    },
    'Action Required': {
      color: '#e6930a',
      icon: <FaExclamationCircle size={14} />,
      message: 'Action Required:',
      switchField: 'disputeActionType',
      actions: [
        {
          label: data.disputeActionType
            ? data.disputeActionType
            : 'Resolve Invalid Address',
          icon: <MdEdit size={15} />,
          color: '#133D91',
          handleClick: () => openAddressReviewNeeded(data),
          entitlement: currentUser.hasEntitlement(
            'Order Tracking - BPO Panel - Action Required',
          ),
          switchValue: 'Resolve Invalid Address',
        },
        {
          label: data.disputeActionType
            ? data.disputeActionType
            : 'Provide Additional Information',
          icon: <FiUpload size={15} />,
          color: '#133D91',
          handleClick: () => openClarityBPOOrder(data),
          entitlement: currentUser.hasEntitlement(
            'Order Tracking - BPO Panel - Action Required',
          ),
          switchValue: 'default',
        },
      ],
    },
    Canceled: {
      color: '#999999',
      icon: (
        <TiCancel
          size={20}
          color="#000000"
          style={{ marginRight: -3, marginLeft: -3 }}
        />
      ),
      message: 'Canceled',
      actions: [{ label: '', color: '#ff8589' }],
    },
  };

  if (!loanStatusContent[cleanValue]) {
    const cellText = [];

    if (cleanValue !== '' && typeof cleanValue === 'string') {
      cellText.push(cleanValue);
    }

    if (data.disputeActionType && typeof data.disputeActionType === 'string') {
      cellText.push(data.disputeActionType);
    }

    return <div>{cellText.join(' - ')}</div>;
  }

  const loanStatus = loanStatusContent[cleanValue];

  if (loanStatus.switchField !== undefined) {
    let foundMatch = false;

    return (
      <ContainerIcons>
        {loanStatus.actions.map((action, index) => {
          let actionMatch = false;
          if (action.entitlement) {
            if (
              action.switchValue !== undefined &&
              loanStatus.switchField !== undefined &&
              data[loanStatus.switchField] !== undefined &&
              data[loanStatus.switchField] === action.switchValue
            ) {
              actionMatch = true;
              foundMatch = true;
            }

            if (
              index === loanStatus.actions.length - 1 &&
              foundMatch === false &&
              actionMatch === false
            ) {
              actionMatch = true;
              foundMatch = true;
            }

            if (actionMatch) {
              return (
                <LinkButton
                  key={index}
                  color={action.color}
                  onKeyUp={action.handleClick}
                  onClick={action.handleClick}
                  role="button"
                  tabIndex={0}
                >
                  <span>{action.icon}</span>
                  {action.label}
                </LinkButton>
              );
            }
            return null;
          }

          return null;
        })}
      </ContainerIcons>
    );
  }

  return (
    <ContainerIcons>
      {loanStatus.actions.map((action, index) => {
        if (action.entitlement) {
          return (
            <LinkButton
              key={index}
              color={action.color ? action.color : loanStatus.color}
              onKeyUp={action.handleClick}
              onClick={action.handleClick}
              role="button"
              tabIndex={0}
            >
              <span>{action.icon}</span>
              {action.label}
            </LinkButton>
          );
        }
        return null;
      })}
    </ContainerIcons>
  );
}

export function EditableRenderer({ value, setValue }: ICellRendererParams) {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const handleBlur = () => {
    if (inputRef.current && value !== inputRef.current.value && setValue) {
      setValue(inputRef.current.value);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      e.key === 'Enter' &&
      inputRef.current &&
      value !== inputRef.current.value &&
      setValue
    ) {
      setValue(inputRef.current.value);
    }
  };

  return (
    <Editable>
      <input
        ref={inputRef}
        defaultValue={value}
        type="text"
        placeholder="Click to add identifier"
        onBlur={handleBlur}
        onKeyUp={handleKeyPress}
      />
      <MdEdit size={16} />
    </Editable>
  );
}
