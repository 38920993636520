import styled, { css } from 'styled-components';

export type DropzoneType = 'wide' | 'large' | 'compact' | 'simple';

type DropzoneProps = {
  type?: DropzoneType;
  isDragActive?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`;

export const Content = styled.div<DropzoneProps>`
  min-width: 210px;
  width: fit-content;
  height: 60px;
  padding: 14px;
  border: 1px dashed #b1b3b4;
  border-radius: 4px;
  background-color: #ffffff;
  background-size: cover;
  gap: 10px;
  outline: 0;

  display: flex;
  align-items: center;
  cursor: pointer;

  & > div {
    flex: 1;
    font-size: ${props => props.theme.fontSize.base};

    p {
      font-size: ${props => props.theme.fontSize.base};
      font-weight: 600;
      color: #000000;
      text-align: center;

      span {
        color: #133d91;
        text-decoration: underline solid rgb(19, 61, 145);
        text-align: center;
        font-weight: 700;
      }
    }
  }

  img {
    width: 20px;
    height: 20px;
  }

  ${props =>
    props.type === 'wide' &&
    css`
      width: 850px;
      height: 70px;
      padding: 0px 15px;
      border: 1px dashed #b1b3b4;

      & > div {
        font-size: ${props.theme.fontSize.xl};
      }

      img {
        width: 30px;
        height: 30px;
      }
    `}

  ${props =>
    props.type === 'simple' &&
    css`
      background-color: transparent;
      border: none;
      border-radius: 0;
      padding: 4px 10px;
      height: 30px;

      ${props.isDragActive &&
      css`
        box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3);
      `}

      & > div {
        color: ${props.theme.colors.darkBlue};
      }

      img {
        width: 14px;
        height: 14px;
      }
    `}

  ${props =>
    props.isDragActive &&
    css`
      border-width: 3px;
    `}
`;
