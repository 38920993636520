import { IState } from './types';

const initialState: IState = {
  steps: [],
  currentStep: 0,
  isSubmitted: false,
  isRevalidating: false,
};

export default initialState;
