/* eslint-disable react/function-component-definition */
import React, { CSSProperties } from 'react';
import {
  ColDef,
  ColGroupDef,
  FirstDataRenderedEvent,
  MenuItemDef,
} from 'ag-grid-community';
import { DefaultPublisher } from '@samc/common';
import Grid from '../components/grid/Grid';
import User from '../models/User';
import Patience from '../components/Patience';
import { useUserArray } from '../contexts/UsersContext';
import { dateFilterParams } from '../helpers/columnDef';
import { Renderers } from '../components/grid/GridRenderers';
import { EditUserEvent } from '../events/EditUserEvent';
import { useClient } from '../contexts';
import { AdditionalUserSettings } from '../models/AdditionalUserSettings';

const minWidth = 177;

const defaultColumnDefs: (ColDef | ColGroupDef)[] = [
  {
    headerName: 'Disabled',
    field: '',
    cellRenderer: Renderers.UserStatusRenderer,
    type: 'rightAligned',
    sortable: false,
    resizable: false,
    width: 200,
  },
  {
    headerName: 'Username',
    field: 'username',
    cellRenderer: Renderers.EditorActionRenderer,
    cellClass: 'cursor-pointer',
    floatingFilter: true,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Email',
    field: 'email',
    floatingFilter: true,
    filter: 'agTextColumnFilter',
    filterParams: { applyMiniFilterWhileTyping: true },
  },
  {
    headerName: 'Last Name',
    field: 'lastName',
    floatingFilter: true,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'First Name',
    field: 'firstName',
    floatingFilter: true,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Company',
    field: 'company.name',
    floatingFilter: true,
    filter: 'agTextColumnFilter',
    floatingFilterComponentParams: { suppressFloatingFilterButton: false },
  },
  {
    headerName: 'Created',
    minWidth,
    field: 'created',
    cellRenderer: Renderers.DateRenderer,
    filter: 'agDateColumnFilter',
    floatingFilter: true,
    type: 'leftAligned',
    filterParams: dateFilterParams,
    floatingFilterComponentParams: { suppressFloatingFilterButton: false },
  },
  {
    headerName: 'Last Login',
    minWidth,
    field: 'lastLogin',
    cellRenderer: Renderers.DateRenderer,
    filter: 'agDateColumnFilter',
    floatingFilter: true,
    type: 'leftAligned',
    filterParams: dateFilterParams,
    floatingFilterComponentParams: { suppressFloatingFilterButton: false },
  },
  {
    headerName: 'Updated',
    minWidth,
    field: 'updated',
    cellRenderer: Renderers.DateRenderer,
    filter: 'agDateColumnFilter',
    floatingFilter: true,
    type: 'leftAligned',
    filterParams: dateFilterParams,
    floatingFilterComponentParams: { suppressFloatingFilterButton: false },
  },
  {
    headerName: 'Updated By',
    field: 'updatedByUser',
    cellRenderer: Renderers.UserRenderer,
    floatingFilter: true,
    filter: 'agTextColumnFilter',
    floatingFilterComponentParams: { suppressFloatingFilterButton: false },
  },
  {
    headerName: 'System Account',
    field: 'isSystemAccount',
    cellRenderer: Renderers.CheckRenderer,
    floatingFilter: false,
    filter: 'agTextColumnFilter',
    filterParams: { applyMiniFilterWhileTyping: true },
  },
];

type UsersProps = {
  gridContainerStyling?: CSSProperties;
  userDataFieldSettings?: AdditionalUserSettings;
};

const Users: React.FC<UsersProps> = ({
  gridContainerStyling,
  userDataFieldSettings = new AdditionalUserSettings(),
}) => {
  const columnDefs = React.useMemo(
    () =>
      defaultColumnDefs.map((x: ColDef) => {
        if (x.field === 'username' || x.field === 'updatedByUser') {
          x.cellRendererParams = { userDataFieldSettings };
        }
        return x;
      }),
    [userDataFieldSettings],
  );

  const client = useClient();

  const users = useUserArray();
  const filtered = React.useMemo(
    () => (users ? User.RemoveSystemAccount(users) : new Array<User>()),
    [users],
  );

  const createUser = React.useCallback(() => {
    DefaultPublisher.publish(
      new EditUserEvent(new User(), false, userDataFieldSettings),
    );
  }, [userDataFieldSettings]);

  const onFirstDataRendered = React.useCallback(
    (event: FirstDataRenderedEvent) => {
      event.api.autoSizeAllColumns();
    },
    [],
  );

  const buttons = React.useMemo(
    () => [
      {
        key: 'createUser',
        children: (
          <span className="font-proxima text-1">
            <i className="mdi mdi-plus-circle-outline text-4" /> USER
          </span>
        ),
        action: createUser,
        entitlement: 'Create User',
      },
    ],
    [createUser],
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const contextMenu = React.useCallback(
    (data?: any) => {
      const result = new Array<MenuItemDef>();
      if (data instanceof User) {
        result.push({
          name: 'Clone as a new user',
          action: () => {
            DefaultPublisher.publish(
              new EditUserEvent(data, true, userDataFieldSettings),
            );
          },
        });
      }
      return result;
    },
    [userDataFieldSettings],
  );

  return (
    <Patience showPatience={!users}>
      <Grid
        columnDefs={columnDefs}
        rowData={filtered}
        buttonProps={buttons}
        onFirstDataRendered={onFirstDataRendered}
        paging
        excelExportAction={() => client.users.downloadUsersExport()}
        canUseFullScreen
        containerStyling={gridContainerStyling}
        getContextMenuItems={contextMenu}
      />
    </Patience>
  );
};

export default Users;
