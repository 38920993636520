import styled, { css } from 'styled-components';

export type Variant =
  | 'All'
  | 'Complete'
  | 'Error'
  | 'Action Required'
  | 'In Progress'
  | 'Canceled';

interface Variations {
  [key: string]: {
    background: string;
    color: string;
    borderColor: string;
  };
}

export const variations: Variations = {
  All: {
    background: '#133D91',
    color: '#133D91',
    borderColor: '#001b56',
  },
  Complete: {
    background: '#36b300',
    color: '#36b300',
    borderColor: '#048100',
  },
  Error: {
    background: '#BD0E08',
    color: '#BD0E08',
    borderColor: '#730000',
  },
  'Action Required': {
    background: '#e6930a',
    color: '#e6930a',
    borderColor: '#b46100',
  },
  'In Progress': {
    background: '#7a7f87',
    color: '#7a7f87',
    borderColor: '#484d55',
  },
  Canceled: {
    background: '#ff8589',
    color: '#ff8589',
    borderColor: '#cf5b5f',
  },
};

interface StyledProps {
  variant?: Variant;
  selected: boolean;
}

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
export const TabMenuWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const TabMenu = styled.div`
  display: flex;
  min-height: 40px;
  background-image: linear-gradient(0deg, #fcfcfc 0%, #ffffff 100%);
  background-position: center center;
  background-size: cover;
  font-size: 16px;
  text-decoration: none solid rgb(33, 77, 136);
`;

export const TabLabelItem = styled.div<StyledProps>`
  border: 1px solid #d1d1d1;
  cursor: pointer;
  padding: 7px 20px;
  background: none;
  color: ${props => props.theme.colors.primary};
  border-right-width: 0;

  transition: ${props => props.theme.animations.button};

  &:first-child {
    border-top-left-radius: 4px;
  }
  &:last-child {
    border-top-right-radius: 4px;
    border-right-width: 1px;
  }

  span {
    font-size: ${props => props.theme.fontSize.base};
    margin-left: 10px;
    background-color: ${props =>
      props.variant && variations[props.variant]
        ? variations[props.variant].background
        : props.theme.colors.lightBlue};
    color: #fff;
    padding: 2px 6px;
    border-radius: 10px;
  }

  &:hover {
    background-image: linear-gradient(0deg, #ededed 0%, #f0f0f0 100%);
    color: ${props => props.theme.colors.primary};
    span {
      background-color: ${props =>
        props.variant && variations[props.variant]
          ? variations[props.variant].background
          : props.theme.colors.lightBlue};
      color: #fff;
    }
  }

  ${props =>
    props.selected &&
    css`
      pointer-events: none;
      font-weight: 600;
      background-color: ${props.variant
        ? variations[props.variant].background
        : props.theme.colors.lightBlue};
      color: #ffffff;
      text-decoration: none solid rgb(255, 255, 255);
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.02);
      border-color: ${props.variant
        ? variations[props.variant].borderColor
        : '#1C6CAD'};

      span {
        background-color: #fff;
        color: ${props.variant && variations[props.variant]
          ? variations[props.variant].background
          : props.theme.colors.lightBlue};
      }

      & + div {
        border-left-color: ${props.variant
          ? variations[props.variant].borderColor
          : '#1C6CAD'};
      }
    `}
`;

export const TabView = styled.div`
  display: flex;
  flex: 1;
`;

export const TabViewItem = styled.div`
  flex: 1;
`;

export const SearchBar = styled.div``;
