/* eslint-disable react/function-component-definition */
import React from 'react';
import './Error.css';
import { useAuthorizationConfig } from '../contexts/AuthorizationConfigContext';
import logo from '../images/situsamc.png';

type ErrorProps = {
  title: string;
  description?: string;
};

const Error: React.FC<ErrorProps> = ({ title, description }) => {
  const authConfig = useAuthorizationConfig();
  const descriptionElement = description ? (
    <p className="text-blue-2">{description}</p>
  ) : null;

  return (
    <div id="bigProblem">
      <div className="main bg-blue-7 text-mono-1">
        <img src={logo} width="200" alt="Logo" />
        <h3 className="font-noto">{title}</h3>
        {descriptionElement}
        <hr />
        <div className="links text-2">
          <a href={authConfig.helpLink}>Help</a>|
          <a href={`mailto:${authConfig.contactEmail}`}>Contact Us</a>
        </div>
      </div>
    </div>
  );
};

export default Error;
