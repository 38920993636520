import { Guid, IEvent } from '@samc/common';

export class DragStartedEvent implements IEvent {
  public name: string = DragStartedEvent.eventName;

  static eventName: 'DragStartedEvent' = 'DragStartedEvent' as const;

  id: Guid;

  constructor(id: Guid) {
    this.id = id;
  }
}
