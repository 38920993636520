import * as React from 'react';
import { LoanDispute } from '..';
import { OrderTrackingLoan } from '../../../../context/order/appraisal/types';
import { useDisputeReasons } from '../../../../queries/useDisputeReasons';
import Modal from '../../../Common/Modal';
import MultipleRequestsDisputeGrid from './MulitpleRequestsDisputeGrid';
import SingleRequestDisputeForm from './SingleRequestDisputeForm';
import { MultipleRequestsDisputeHeader } from './styles';

export interface RequestUpdateModalProps {
  isRequestModalOpen: boolean;
  handleCloseRequestUpdate: () => void;
  isLoading: boolean;
  handleRequestUpdate: (disputeLoans: LoanDispute[]) => Promise<void> | void;
  selectedLoans: OrderTrackingLoan[];
}

function RequestUpdateModal({
  isRequestModalOpen,
  handleCloseRequestUpdate,
  selectedLoans,
  handleRequestUpdate,
  isLoading,
}: RequestUpdateModalProps) {
  const disputeReasonQuery = useDisputeReasons();

  if (disputeReasonQuery.error) {
    return <p>Error</p>;
  }

  if (disputeReasonQuery.isLoading && !disputeReasonQuery.data) {
    return null;
  }
  return (
    <Modal
      isOpen={isRequestModalOpen}
      onDismiss={handleCloseRequestUpdate}
      width={selectedLoans.length > 1 ? 760 : 340}
      height={selectedLoans.length > 1 ? 748 : 370}
    >
      {selectedLoans.length > 1 ? (
        <>
          <MultipleRequestsDisputeHeader>
            <h3>Request Update</h3>
            <p>
              Requesting updates will{' '}
              <strong>re-submit {selectedLoans.length} loans</strong> for
              updated broker price opinions.
            </p>
          </MultipleRequestsDisputeHeader>
          <MultipleRequestsDisputeGrid
            handleRequestUpdate={handleRequestUpdate}
            selectedLoans={selectedLoans}
            isLoading={isLoading}
            handleCloseRequestUpdate={handleCloseRequestUpdate}
            isRequestModalOpen={isRequestModalOpen}
          />
        </>
      ) : (
        <>
          <h3>Request Update</h3>
          <SingleRequestDisputeForm
            selectedLoans={selectedLoans}
            handleRequestUpdate={handleRequestUpdate}
            isLoading={isLoading}
            handleCloseRequestUpdate={handleCloseRequestUpdate}
            isRequestModalOpen={isRequestModalOpen}
          />
        </>
      )}
    </Modal>
  );
}

export default RequestUpdateModal;
