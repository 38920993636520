// DON'T CHANGE ANYTHING WITHOUT READING THE WIKI! https://dev.azure.com/TPMS-AMC/TPMS-Centricity/_wiki/wikis/TPMSDataImport.wiki/83/Entitlement-Names

// Caveat: not all entitlements have been added here; only the ones directly relevant to the UI. If you need
// to add an existing entitlement to this list, you naturally don't have to change the other locations.
const Entitlements = {
  Users: {
    Get: 'Get User',
    List: 'List Users',
    Create: 'Create Users',
    Update: 'Update Users',
    Delete: 'Delete Users',
    Disable: 'Enable or Disable Users',
    AssignRoles: 'Assign Roles to Users',
    AssignEntitlements: 'Assign Entitlements to Users',
  },
  Roles: {
    Get: 'Get Role',
    List: 'List Roles',
    Create: 'Create Roles',
    Update: 'Update Roles',
    Delete: 'Delete Roles',
    AssignEntitlements: 'Assign Entitlements to Roles',
  },
  Entitlements: {
    List: 'List Entitlements',
  },
};

export default Entitlements;
