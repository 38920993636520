import * as React from 'react';
import { useId } from '@fluentui/react-hooks';
import {
  getTheme,
  mergeStyleSets,
  FontWeights,
  Modal as ModalUI,
  IModalProps,
} from '@fluentui/react';

import { FiX } from 'react-icons/fi';
import { CloseModalButton, ModalContent } from './styles';

const theme = getTheme();

export interface ModalProps extends Partial<IModalProps> {
  ref?: React.Ref<HTMLDivElement>;
  isOpen: boolean;
  onDismiss?: () => void;
  width?: number | string;
  height?: number | string;
  suppressCloseButton?: boolean;
  children: React.ReactNode;
}

function Modal({
  ref,
  isOpen,
  onDismiss,
  suppressCloseButton = false,
  width = 200,
  height = 200,
  children,
}: ModalProps) {
  const contentStyles = mergeStyleSets({
    container: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'stretch',
      width,
      height,
    },
    header: [
      {
        flex: '1 1 auto',
        borderTop: `4px solid ${theme.palette.themePrimary}`,
        color: theme.palette.neutralPrimary,
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '12px 12px 14px 24px',
      },
    ],
    body: {
      flex: '4 4 auto',
      padding: '0 24px 24px 24px',
      overflowY: 'hidden',
      selectors: {
        p: { margin: '14px 0' },
        'p:first-child': { marginTop: 0 },
        'p:last-child': { marginBottom: 0 },
      },
    },
  });

  const titleId = useId('title');

  return (
    <div>
      <ModalUI
        ref={ref}
        titleAriaId={titleId}
        isOpen={isOpen}
        onDismiss={onDismiss}
        isBlocking
        containerClassName={contentStyles.container}
      >
        <ModalContent>
          {!suppressCloseButton && (
            <CloseModalButton onClick={onDismiss}>
              <FiX size={20} />
            </CloseModalButton>
          )}

          {children}
        </ModalContent>
      </ModalUI>
    </div>
  );
}

export default Modal;
