import React from 'react';
import { ComboBox, IComboBox, IComboBoxOption } from '@fluentui/react';
import { useOrder } from '../../../context/order';
import '@samc/icons/css/materialdesignicons.css';
import 'ag-grid-enterprise/styles/ag-grid.css';
import 'ag-grid-enterprise/styles/ag-theme-balham.css';
import { Container, Label, ColumnIdWrapper } from './styles';
import { equalArrays } from '../../../utils/helpers';
import {
  ISourcePickerInput,
  ISourceStepUpdatePreview,
} from '../../Common/ImportComponents/interfaces';
import SourcePicker from '../../Common/ImportComponents/SourcePicker';

function SourceFile() {
  const {
    order,
    indexingActions: {
      onChangeIndexingDocument,
      onChangeIndexingPreviewData,
      onChangeIndexingData,
      onChangeIndexingColumnName,
    },
  } = useOrder();

  const onSelectClientLoanId = React.useCallback(
    (event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
      if (option && option.key) {
        onChangeIndexingColumnName(String(option.key));
      }
    },
    [onChangeIndexingColumnName],
  );

  const headersComboBoxData: IComboBoxOption[] = React.useMemo(() => {
    return order.indexingLoan.metaData
      ? order.indexingLoan.metaData.columnMeta.map(header => ({
          key: header.headerName,
          text: header.headerName,
        }))
      : [];
  }, [order.indexingLoan.metaData]);

  const hasData =
    order.indexingLoan.metaData &&
    order.indexingLoan.metaData.columnMeta.length > 0;
  const hasColumnName = !!order.indexingLoan.columnName;

  const comboboxStyle = {
    container: { maxWidth: 200 },
    callout: { minWidth: 200 },
  };

  const onFileChange = () => {
    onChangeIndexingDocument();
  };

  const onUpdatePreview = (evt: ISourceStepUpdatePreview) => {
    const { headers } = evt;
    if (order.indexingLoan.metaData) {
      const columns = order.indexingLoan.metaData.columnMeta.map(
        column => column.headerName,
      );

      if (!equalArrays(headers, columns)) {
        onChangeIndexingPreviewData();
      }
    }
  };

  const onFinalize = (input: ISourcePickerInput) => {
    const { fileSource, metaData } = input;
    onChangeIndexingData(fileSource, metaData);
  };

  return (
    <Container>
      <SourcePicker
        fileChange={onFileChange}
        updatePreview={onUpdatePreview}
        finalize={onFinalize}
        initialMetaData={order.indexingLoan.metaData}
        initialFileSource={order.indexingLoan.fileSource}
        client="citi"
        user="Web Component"
        excludeIncompleteImports
        excludeCompletedImports
        hideDbf
        documentUrl={
          window.config.gatewayApiUrlImportConf || window.config.gatewayApiUrl
        }
        verificationToken="<verification token>"
        dataImportUrl={`${
          window.config.gatewayApiUrlImportConf || window.config.gatewayApiUrl
        }/dataimportapi`}
        configurationUrl={`${
          window.config.gatewayApiUrlImportConf || window.config.gatewayApiUrl
        }/configapi`}
      />
      <ColumnIdWrapper>
        {!hasColumnName && (
          <Label>
            Next, <strong>select your Loan IDs column</strong> in the dropdown
            below.
          </Label>
        )}
        <ComboBox
          label="Loan IDs Column"
          id="clientLoandId"
          placeholder="Select Loan IDs column"
          selectedKey={order.indexingLoan.columnName}
          options={headersComboBoxData}
          onChange={onSelectClientLoanId}
          styles={comboboxStyle}
          disabled={!hasData}
        />
      </ColumnIdWrapper>
    </Container>
  );
}

export default SourceFile;
