export function doStringsMatch(
  a?: string,
  b?: string,
  trim?: boolean,
  caseSensitive?: boolean,
) {
  if (a === null || a === undefined || b === null || b === undefined) {
    return false;
  }

  let compareA = caseSensitive ? a : a.toUpperCase();
  let compareB = caseSensitive ? b : b.toUpperCase();
  if (trim) {
    compareA = compareA.trim();
    compareB = compareB.trim();
  }

  return compareA === compareB;
}
