import { useState } from 'react';
import { DefaultPublisher } from '@samc/common';
import { Button, PrettyCenteredBox, Checkbox } from '../components';
import { useAuthConfig, useClient, useCurrentUser } from '../contexts';
import { TermsOfUseAcceptedEvent } from '../events';
import { WithChildren } from '../types';
import { TermsOfUseContent } from './TermsOfUseContent';
// import './TermsOfUse.css';

function TermsOfUse({ children }: WithChildren) {
  const config = useAuthConfig();
  const [isSaving, setIsSaving] = useState(false);
  const [hasScolledToEnd, setHasScrolledToEnd] = useState(false);
  const [hasAccepted, setHasAccepted] = useState(false);
  const currentUser = useCurrentUser();

  const client = useClient();
  if (config.requireTermsOfUseAcceptance && !currentUser.termsOfUseAcceptance) {
    const accept = async () => {
      setIsSaving(true);
      client.users.acceptTerms().then(success => {
        if (success) {
          DefaultPublisher.publish(new TermsOfUseAcceptedEvent());
        }
      });
    };
    return (
      <PrettyCenteredBox className="select-none" widthClassName="w-4/5">
        <h1 className="text-7">Terms of Use</h1>
        <TermsOfUseContent setHasScrolledToEnd={setHasScrolledToEnd} />
        <div className="flex justify-start">
          <Checkbox
            readonly={!hasScolledToEnd}
            value={hasAccepted}
            setValue={setHasAccepted}
            label="I Agree"
            classNames="text-4 justify-self-start"
          />
        </div>
        <div>
          <Button
            className="p-1 rounded mt-xs bg-blue-6 text-mono-1 min-w-1/4"
            disabledClassName="p-1 rounded mt-xs bg-mono-7 text-mono-1 min-w-1/4"
            isDisabled={isSaving || !hasAccepted}
            onClick={accept}
          >
            Continue
          </Button>
          <a className="ml-md" href="https://www.situsamc.com">
            <Button
              className="p-1 rounded mt-xs bg-blue-6 text-mono-1 min-w-1/4"
              onClick={null}
            >
              Cancel
            </Button>
          </a>
        </div>
      </PrettyCenteredBox>
    );
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}

export default TermsOfUse;
