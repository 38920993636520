import { Guid, IEvent } from '@samc/common';

export class GroupSelectionEvent implements IEvent {
  public name: string = GroupSelectionEvent.eventName;

  static eventName: 'GroupSelectionEvent' = 'GroupSelectionEvent' as const;

  ids: Guid[];

  constructor(ids: Guid[]) {
    this.ids = ids;
  }
}
