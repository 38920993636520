import styled, { css } from 'styled-components';

export interface SeparatorProps {
  fullwidth?: boolean;
}

export const Container = styled.hr<SeparatorProps>`
  color: #c0c3c5;
  stroke-width: 1;

  ${props =>
    props.fullwidth &&
    css`
      margin-left: -30px;
      margin-right: -30px;
    `}
`;
