import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { GridFieldConfiguration } from '@samc/react-ui-grid';
import { FiAlertCircle } from 'react-icons/fi';
import { DirectionalHint } from '@fluentui/react';
import ButtonDrivenPopout from '@samc/react-ui-core/lib/atoms/ButtonDrivenPopout/ButtonDrivenPopout';
import { MdEdit } from 'react-icons/md';
import {
  Editable,
  ErrorMessage,
  ProductTitle,
  MainContainer,
  HeaderContainer,
  PopupContainer,
  NotesContainer,
} from './styles';
import CellStyleParams from '../../../types/CellStyleParams';
import UpdateLoanModal from './UpdateLoanModal';
import { BPOLoanData } from '../../../context/order/bpo/types';
import { ConfirmModal, RoundButton } from '../../Common';
import NotesTooltip from './NotesTooltip';
import {
  extractAddresses,
  formatAddress,
} from '../../../context/order/bpo/util';
import { useOrder } from '../../../context/order';
import { BPOLoanError } from '../../../context/order/types';
import { ExclamationIconContainer } from '../BPOError/styles';

export interface BPOLoanGridValidationData {
  row: number;
  clientLoanId: string;
  street: string;
  unitNumber: string;
  city: string;
  state: string;
  zip: string;
  bpoProduct: {
    id: string;
    name: string;
  };
  accessDetails: string;
  customerReferenceId1: string;
  customerReferenceId2: string;
  customerReferenceId3: string;
}

export interface BPOProductGridAvailableData extends BPOLoanData {
  isAvailable: boolean;
  providerRequestType: string;
}

export interface FrameworkComp {
  [p: string]: unknown;
}

interface ErrorType {
  [key: string]: {
    color: string;
    borderColor: string;
    backgroundColor: string;
  };
}

export const errorType: ErrorType = {
  Bpo: {
    color: '#BD0E08',
    borderColor: '#BD0E08',
    backgroundColor: '#f5e2e0',
  },
};

export const cellStyle = (params: CellStyleParams): React.CSSProperties => {
  const data = params.data as BPOProductGridAvailableData;
  if (!data.isAvailable) {
    return {
      backgroundColor: errorType.Bpo.backgroundColor,
      borderColor: errorType.Bpo.borderColor,
    };
  }
  return {};
};
export interface BPOPanelUnAvailableProductProps {
  productName?: string;
  quantityLoan: number;
}

export function BPOPanelUnAvailableProduct({
  productName,
  quantityLoan,
}: BPOPanelUnAvailableProductProps) {
  return (
    <>
      <br />
      <MainContainer>
        <FiAlertCircle size={40} />
        <HeaderContainer>
          <ProductTitle>
            <strong>{productName} product is unavailable</strong>
          </ProductTitle>
          <p>
            You have{' '}
            <strong>
              {quantityLoan} {productName} {quantityLoan > 1 ? 'loans' : 'loan'}{' '}
              .
            </strong>{' '}
            You may proceed, but these loans will not be submitted.
          </p>
        </HeaderContainer>
      </MainContainer>
    </>
  );
}

export function BPOTypeRenderer({
  value,
  ...params
}: ICellRendererParams & GridFieldConfiguration) {
  const data = params.data as BPOProductGridAvailableData;
  const message = `BPO - ${data.providerRequestType} is unavailable`;

  if (data.isAvailable) {
    return value;
  }
  return (
    <Editable>
      <p>{value}</p>
      {!data.isAvailable && (
        <ButtonDrivenPopout
          button={<FiAlertCircle size={20} />}
          directionalHint={DirectionalHint.topCenter}
        >
          <ErrorMessage>{message}</ErrorMessage>
        </ButtonDrivenPopout>
      )}
    </Editable>
  );
}

function ExclamationIcon({ id }: { id?: string }) {
  return <ExclamationIconContainer id={id}>!</ExclamationIconContainer>;
}

export function PopUpRenderer({
  data,
  value,
  node,
  api,
  colDef,
}: ICellRendererParams) {
  const [isOpen, setIsOpen] = React.useState(false);
  const isMounted = React.useRef(false);

  React.useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const {
    order,
    bpoActions: { onChangeSingleBPOValidationData },
  } = useOrder();

  const [duplicateTitle, setDuplicateTitle] = React.useState<string>('');
  const [duplicateMessage, setDuplicateMessage] = React.useState<string>('');
  const [duplicateLoanData, setDuplicateLoanData] =
    React.useState<BPOLoanData | null>(null);

  const setData = (loanData: BPOLoanData) => {
    let loan = order.bpoLoanValidation.data.find(
      possibleMatch => possibleMatch.row === loanData.row,
    );
    if (loan === undefined) return;

    if (
      loan.clientLoanId !== loanData.clientLoanId &&
      duplicateLoanData === null
    ) {
      const oldDuplicates: BPOLoanData[] = [];
      const newDuplicates: BPOLoanData[] = [];
      order.bpoLoanValidation.data.forEach(possibleDup => {
        if (possibleDup.row !== loanData.row && loan) {
          if (possibleDup.clientLoanId === loan.clientLoanId) {
            oldDuplicates.push(possibleDup);
          } else if (possibleDup.clientLoanId === loanData.clientLoanId) {
            newDuplicates.push(possibleDup);
          }
        }
      });

      if (oldDuplicates.length === 0 && newDuplicates.length > 0) {
        setDuplicateLoanData(loanData);
        setDuplicateTitle('Multiple Property Loan');
        setDuplicateMessage(
          'As a result of the loan ID update, there are multiple property addresses that have the same loan ID. This property will be added to the existing multiple property loan.',
        );
        setIsOpen(false);
        return;
      }
      if (oldDuplicates.length > 0 && newDuplicates.length === 0) {
        setDuplicateLoanData(loanData);
        setDuplicateTitle('Single Property Loan');
        setDuplicateMessage(
          'This loan is part of a multiple property loan. Changing the loan ID will create a single property loan.',
        );
        setIsOpen(false);
        return;
      }
    }

    loan = {
      ...loan,
      ...loanData,
      hasChange: true,
    };

    onChangeSingleBPOValidationData(loan);

    const updatedLoan = order.bpoLoanValidation.data.find(
      row => row.bpoOrderStagingId === data.bpoOrderStagingId,
    );
    node.setData(updatedLoan);

    api.refreshCells({ force: true, rowNodes: [node] });
  };

  const errors: JSX.Element[] = React.useMemo(() => {
    const messages: JSX.Element[] = [];
    data.errors?.forEach((error: BPOLoanError) => {
      if (colDef && error.type !== 'Warning') {
        if (
          colDef.field === 'addresses' &&
          ['street', 'city', 'state', 'zip', 'unitNumber'].includes(
            error.column || '',
          )
        ) {
          messages.push(<div>{error.message}</div>);
        } else if (error.column === colDef.field) {
          messages.push(<div>{error.message}</div>);
        }
      }
    });
    return messages;
  }, [colDef, data.errors]);

  return (
    <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
      <PopupContainer onClick={() => setIsOpen(true)}>
        {value}
        <MdEdit size={16} />
      </PopupContainer>
      {errors.length > 0 && (
        <ButtonDrivenPopout
          button={<ExclamationIcon />}
          directionalHint={DirectionalHint.topCenter}
        >
          <ErrorMessage>{errors}</ErrorMessage>
        </ButtonDrivenPopout>
      )}
      <UpdateLoanModal
        isOpen={isOpen}
        rowData={data}
        onSubmit={setData}
        onDismiss={() => setIsOpen(false)}
        isLoading={false}
      />
      <ConfirmModal
        isModalOpen={duplicateMessage !== ''}
        title={duplicateTitle}
        details={duplicateMessage}
        cancelText="Cancel"
        handleCancel={() => {
          setDuplicateLoanData(null);
          setDuplicateTitle('');
          setDuplicateMessage('');
        }}
        confirmText="Confirm"
        confirmVariant="tertiary"
        handleConfirm={() => {
          if (duplicateLoanData) {
            setData(duplicateLoanData);
          }
          setDuplicateLoanData(null);
          setDuplicateTitle('');
          setDuplicateMessage('');
        }}
      />
    </div>
  );
}

export function AddressPopUpRenderer({
  data,
  node,
  api,
  ...args
}: ICellRendererParams) {
  const addresses = extractAddresses(data);
  const selected = addresses.selected || addresses.edited || addresses.original;
  const address = [
    selected?.street,
    selected?.unitNumber,
    selected?.city,
    selected?.state,
    selected?.zip,
  ]
    .filter(part => part !== undefined && part !== '')
    .join(', ');
  return PopUpRenderer({
    data,
    node,
    api,
    ...args,
    value: address,
  });
}

export function NotesRenderer({ data }: ICellRendererParams) {
  const loan = data as BPOLoanData;

  const { address, addressChanged } = React.useMemo(() => {
    const addressLookup = extractAddresses(loan);
    if (addressLookup.selected === addressLookup.original) {
      return { address: addressLookup.original, addressChanged: false };
    }

    if (
      addressLookup.selected === undefined ||
      addressLookup.original === undefined
    ) {
      return {
        address: addressLookup.original,
        addressChanged: false,
      };
    }

    return {
      address: addressLookup.original,
      addressChanged:
        addressLookup.selected.street !== addressLookup.original.street ||
        addressLookup.selected.unitNumber !==
          addressLookup.original.unitNumber ||
        addressLookup.selected.city !== addressLookup.original.city ||
        addressLookup.selected.state !== addressLookup.original.state ||
        addressLookup.selected.zip !== addressLookup.original.zip,
    };
  }, [loan]);

  const originalDetails = loan.originalDetails
    ? JSON.parse(loan.originalDetails)
    : {
        clientLoanId: '???',
        bpoProduct: {
          Name: '???',
        },
        accessDetails: '???',
        customerReferenceId1: '???',
        customerReferenceId2: '???',
        customerReferenceId3: '???',
      };

  const bpoTypeId = originalDetails.bpoProduct?.Id;
  const bpoType = originalDetails.bpoProduct?.Name;

  if (
    (originalDetails.clientLoanId === loan.clientLoanId &&
      bpoTypeId === loan.bpoProduct.id &&
      addressChanged === false) ||
    address === undefined
  ) {
    return '';
  }

  const fullAddress = formatAddress(address);

  return (
    <NotesContainer>
      Details Updated
      <ButtonDrivenPopout
        button={<RoundButton>?</RoundButton>}
        directionalHint={DirectionalHint.topCenter}
      >
        <NotesTooltip
          bpoType={bpoType}
          clientLoanId={originalDetails.clientLoanId}
          customerReferenceId1={originalDetails.customerReferenceId1}
          customerReferenceId2={originalDetails.customerReferenceId2}
          customerReferenceId3={originalDetails.customerReferenceId3}
          address={fullAddress}
        />
      </ButtonDrivenPopout>
    </NotesContainer>
  );
}
