/* eslint-disable react/function-component-definition */
import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { DefaultPublisher, Guid } from '@samc/common';
import {
  useUserMap,
  useUserMapByUserName,
} from '../../../contexts/UsersContext';
import { EditUserEvent } from '../../../events/EditUserEvent';
import { AdditionalUserSettings, User } from '../../../models';

interface IUserRendererParams extends ICellRendererParams {
  userDataFieldSettings: AdditionalUserSettings;
}

const UserRenderer: React.FC<IUserRendererParams> = ({
  value,
  userDataFieldSettings,
}) => {
  const users = useUserMap();
  const usersByUserName = useUserMapByUserName();
  if (!users) {
    // Users is empty (probably currently loading), so nothing to show.
    return null;
  }
  if (!usersByUserName) {
    // Users is empty (probably currently loading), so nothing to show.
    return null;
  }

  if (value instanceof Guid) {
    const id = value as Guid;
    const user = users.get(id.toString());
    if (user) {
      return user.username === User.SystemAccount ? (
        <span className="text-mono-10">SYSTEM</span>
      ) : (
        <span
          className="cursor-pointer text-blue-6"
          onClick={() =>
            DefaultPublisher.publish(
              new EditUserEvent(user, false, userDataFieldSettings),
            )
          }
        >
          {user.username}
        </span>
      );
    }
    // Probably means a token user aka API made the update. Showing SYSTEM as we would with the internal account.
    // We will probably show token users in the future, so consider this a TODO.
    return <span className="text-mono-10">SYSTEM</span>;
  }

  const user = usersByUserName.get(value.toLowerCase());
  if (user) {
    return user.username === User.SystemAccount ? (
      <span className="text-mono-10">SYSTEM</span>
    ) : (
      <span
        className="cursor-pointer text-blue-6"
        onClick={() =>
          DefaultPublisher.publish(
            new EditUserEvent(user, false, userDataFieldSettings),
          )
        }
      >
        {user.username}
      </span>
    );
  }
  return <span className="text-mono-10">SYSTEM</span>;
};

export default UserRenderer;
