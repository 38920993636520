/* eslint-disable react/function-component-definition */
import React from 'react';
import GridButton, { GridButtonProps } from './GridButton';
import { Button } from '..';
import ToolTip from '../ToolTip';

type ButtonBarProps = {
  clearFiltersTooltip: string;
  clearFilterFunc?: () => void;
  excelFunc?: () => void;
  fullScreenFunc?: () => void;
  isFullScreen?: boolean;
  buttonProps?: Array<GridButtonProps & { key?: string | number }>;
  excelTooltip: string;
  fullScreenTooltip: string;
};

const ButtonBar: React.FC<ButtonBarProps> = ({
  clearFiltersTooltip,
  clearFilterFunc,
  excelFunc,
  isFullScreen,
  fullScreenFunc,
  buttonProps,
  excelTooltip,
  fullScreenTooltip,
}) => {
  const className =
    'w-full bg-mono-1 border border-mono-5 flex justify-end p-2 content-center text-blue-7';
  // Empty button bar, return fragment
  if (
    !clearFilterFunc &&
    !excelFunc &&
    !fullScreenFunc &&
    (!buttonProps || buttonProps.length === 0)
  ) {
    return null;
  }

  const buttons =
    buttonProps && buttonProps.length > 0
      ? buttonProps.map(({ key, ...myProps }, i) => (
          <GridButton key={key || i} {...myProps} />
        ))
      : null;

  // Button bar that only contains buttons defined by the grid consumer
  if (!clearFilterFunc && !excelFunc && !fullScreenFunc) {
    return <div className={className}>{buttons}</div>;
  }

  const commonButtons = [];

  if (clearFilterFunc) {
    commonButtons.push(
      <ToolTip
        key="clearFilters"
        title={clearFiltersTooltip}
        orientation="bottom"
      >
        <Button key="clearFilter" onClick={clearFilterFunc}>
          <i className="mdi mdi-filter-remove-outline text-4 mr-xs" />
        </Button>
      </ToolTip>,
    );
  }

  if (excelFunc) {
    commonButtons.push(
      <ToolTip key="excel" title={excelTooltip} orientation="bottom">
        <Button key="excel" onClick={excelFunc}>
          <i className="mdi mdi-microsoft-excel text-4 mr-xs" />
        </Button>
      </ToolTip>,
    );
  }

  if (fullScreenFunc) {
    commonButtons.push(
      <ToolTip
        key="fullScreen"
        title={fullScreenTooltip}
        orientation="bottom"
        style={{ marginRight: '15px', marginLeft: '5px' }}
      >
        <Button key="fullScreen" onClick={fullScreenFunc}>
          <i
            className={
              isFullScreen
                ? 'mdi mdi-arrow-collapse-all text-4'
                : 'mdi mdi-arrow-expand-all text-4'
            }
          />
        </Button>
      </ToolTip>,
    );
  }

  return (
    <div className={className}>
      {buttons}
      <span className="border-l border-mono-4 ml-sm mr-sm" />
      {commonButtons}
    </div>
  );
};

export default ButtonBar;
