import { AuthenticationConfig } from '../features/authentication-okta';
import AuthorizationConfig from '../features/authorization/models/AuthorizationConfig';

export const oktaConfig: AuthenticationConfig = {
  platformName: 'Certainty',
  appName: 'Certainty',
  baseUrl: window.config.oktaBaseUrl,
  clientId: window.config.oktaClientId,
  issuer: window.config.oktaIssuerUrl,
  loginRedirectMethod: 'HardRedirect',
  oktaUrl: window.config.oktaUrl,
  contactEmail: '#',
  helpLink: '#',
  loginRedirectStrategy: 'ToLastVisitedUrl',
  tokenRefreshMethod: 'None',
  useOktaUser: false,
  showLogoutWarning: false,
  cookieUrl: `${window.config.authApiUrl}/api/v2/jwt/cookie`,
};

export const authConfig: AuthorizationConfig = {
  platformName: 'Certainty',
  appName: 'Certainty',
  apiUrl: window.config.authApiUrl,
  agGridLicense:
    'Using_this_{AG_Grid}_Enterprise_key_{AG-056580}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{SitusAMC_Holdings_Corporation}_is_granted_a_{Multiple_Applications}_Developer_License_for_{45}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_{38}_Production_Environments___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{6_April_2025}____[v3]_[01]_MTc0Mzg5NDAwMDAwMA==8020a272b78223a6d82258727e04c265',
  helpLink: '#',
  contactEmail: '#',
  authenticationScheme: 'Okta',
  setContainerId: false,
  showErrorForUserWithNoAccess: false,
  requireTermsOfUseAcceptance: true,
};
