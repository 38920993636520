import React from 'react';

interface MutateOptions<R, E> {
  onSuccess?: (data: R) => void;
  onError?: (error: E) => void;
}

export const useMutation = <R, E, P>(mutateFn: (params: P) => Promise<R>) => {
  const [status, setStatus] = React.useState<
    'idle' | 'loading' | 'error' | 'success'
  >('idle');
  const [error, setError] = React.useState<E | null>(null);

  return React.useMemo(() => {
    return {
      isLoading: status === 'loading',
      status,
      error,
      mutate: async (params: P, options?: MutateOptions<R, E>) => {
        const { onSuccess, onError } = options || {};

        setStatus('loading');
        try {
          const data = await mutateFn(params);
          setStatus('success');
          if (onSuccess) {
            onSuccess(data);
          }
          return data;
        } catch (e) {
          setError(e as E);
          setStatus('error');
          if (onError) {
            onError(e as E);
          }
          throw e;
        }
      },
    };
  }, [error, mutateFn, status]);
};
