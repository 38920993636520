import {
  GridOptions,
  RowClassRules,
  GridReadyEvent,
  SelectionChangedEvent,
} from 'ag-grid-community';
import { BaseGrid, ChangeDataArgs, BaseGridRef } from '@samc/react-ui-grid';
import React from 'react';
import ExtendedGridFieldConfiguration from '../../../types/ExtendedGridFieldConfiguration';

export interface GridProps {
  ref?: React.Ref<BaseGridRef<Record<string, unknown>>>;
  data?: Record<string, unknown>[];
  onChangeData?: (args: ChangeDataArgs) => void;
  onGridReady?: (api: GridReadyEvent) => void;
  frameworkComponents?:
    | {
        [p: string]: unknown;
      }
    | undefined;

  fields: ExtendedGridFieldConfiguration[];
  idField?: string;
  rowsPerPage?: number;
  rowSelection?: 'multiple' | 'none' | 'single';
  autoHeight?: boolean;
  gridOptions?: GridOptions;
  onSelectionChanged?: (event: SelectionChangedEvent) => void;
  rowClassRules?: RowClassRules;
  sizeColumnsToFit?: boolean;
  suppressPaginationPanel?: boolean;
  suppressSelectionCheckbox?: boolean;
  suppressColumnFilters?: boolean;
  animateRows?: boolean;
  suppressRowSelector?: boolean;
}

function Grid({
  onGridReady,
  ref,
  data,
  fields,
  onChangeData,
  idField,
  frameworkComponents,
  sizeColumnsToFit = true,
  rowsPerPage = 100000,
  rowSelection,
  autoHeight,
  gridOptions,
  onSelectionChanged,
  rowClassRules,
  animateRows,
  suppressPaginationPanel = true,
  suppressSelectionCheckbox,
  suppressRowSelector,
  suppressColumnFilters,
}: GridProps) {
  return (
    <BaseGrid
      ref={ref}
      onBodyGridReady={onGridReady}
      data={data}
      fields={fields}
      onChangeData={onChangeData}
      gridOptions={gridOptions}
      rowsPerPage={rowsPerPage}
      rowSelection={rowSelection}
      idField={idField}
      onSelectionChanged={onSelectionChanged}
      frameworkComponents={frameworkComponents}
      rowClassRules={rowClassRules}
      animateRows={animateRows}
      suppressRowSelector={suppressRowSelector}
      sizeColumnsToFit={sizeColumnsToFit}
      suppressPaginationPanel={suppressPaginationPanel}
      checkboxSelection={!suppressSelectionCheckbox}
      suppressColumnFilters={suppressColumnFilters}
      autoHeight={autoHeight}
    />
  );
}

export default Grid;
