import { RefObject } from 'react';

export function tabLocker(evt: KeyboardEvent, ref: RefObject<HTMLElement>) {
  // https://stackoverflow.com/a/60031728
  if (evt.key === 'Tab') {
    if (ref.current) {
      // https://zellwk.com/blog/keyboard-focusable-elements/
      const focusable = ref.current.querySelectorAll<HTMLElement>(
        'input, button, [tabindex]:not([tabindex="-1"])',
      );
      if (focusable.length) {
        const first = focusable[0];
        const last = focusable[focusable.length - 1];
        const shift = evt.shiftKey;
        if (
          !(evt.target instanceof HTMLElement) ||
          !ref.current.contains(evt.target)
        ) {
          first.focus();
          evt.preventDefault();
          return;
        }
        if (shift) {
          if (evt.target === first) {
            // shift-tab pressed on first input in dialog
            last.focus();
            evt.preventDefault();
          }
        } else if (evt.target === last) {
          // tab pressed on last input in dialog
          first.focus();
          evt.preventDefault();
        }
      }
    }
  }
}
