import styled, { css, keyframes } from 'styled-components';

export type Variant = 'primary' | 'secondary' | 'tertiary';

type Version = 'main' | 'alternate';

type State = 'default' | 'hover' | 'active';

type Info = {
  color?: string;
  border?: string;
  background?: string;
  boxShadow?: string;
};

type StateInfo = Record<State, Info>;

type VariantVersion = Record<Version, StateInfo>;

interface RequiredMainVersion extends Partial<VariantVersion> {
  main: StateInfo;
}

const variants: Record<Variant, RequiredMainVersion> = {
  primary: {
    main: {
      default: {
        background: '#2ca9ff',
        border: '1px solid #207cc7',
        color: '#ffffff',
        boxShadow:
          'box-shadow: 0px 1px 2px rgba(0,0,0,0.15), inset 0px -1px 0px rgba(47,180,255,1)',
      },
      hover: {
        background: '#50b7ff',
        boxShadow:
          'box-shadow: 0px 1px 2px rgba(0,0,0,0.15), inset 0px -1px 0px rgba(47,180,255,1)',
      },
      active: {
        background: '#299cef',
        border: '1px solid #0c68b3',
        boxShadow: 'box-shadow: 0px 1px 2px rgba(0,0,0,0.15)',
      },
    },
  },
  secondary: {
    main: {
      default: {
        background: 'rgb(21, 68, 163)',
        border: '1px solid #0c285e;',
        color: '#ffffff',
        boxShadow:
          'box-shadow: 0px 1px 2px rgba(0,0,0,0.15), inset 0px -1px 0px rgba(21,68,163,1)',
      },
      hover: {
        background: 'rgb(24, 81, 192)',
        boxShadow:
          'box-shadow: 0px 1px 2px rgba(0,0,0,0.15), inset 0px -1px 0px rgba(24,81,192,1)',
      },
      active: {
        background: '#0c285e',
        border: '1px solid #000a40;',
        boxShadow: 'box-shadow: 0px 1px 2px rgba(0,0,0,0.15)',
      },
    },
    alternate: {
      default: {
        background: '#ffffff',
        border: '1px solid #cccccc;',
        color: '#133d91',
        boxShadow: 'box-shadow: 0px 1px 2px rgba(0,0,0,0.05)',
      },
      hover: {
        background: '#ebebeb',
        boxShadow: 'box-shadow: 0px 1px 2px rgba(0,0,0,0.05)',
      },
      active: {
        background: '#d3d3d3',
        border: '1px solid #c7c7c7;',
        boxShadow: 'box-shadow: 0px 1px 2px rgba(0,0,0,0.05)',
      },
    },
  },
  tertiary: {
    main: {
      default: {
        background: 'rgb(212, 16, 9)',
        border: '1px solid #8a0a06;',
        color: '#ffffff',
        boxShadow:
          'box-shadow: 0px 1px 2px rgba(0,0,0,0.15), inset 0px -1px 0px rgba(212,16,9,1)',
      },
      hover: {
        background: '#d80f09',
        boxShadow:
          'box-shadow: 0px 1px 2px rgba(0,0,0,0.15), inset 0px -1px 0px rgba(241,17,10,1)',
      },
      active: {
        background: '#a90c07',
        border: '1px solid #760000',
        boxShadow: 'box-shadow: 0px 1px 2px rgba(0,0,0,0.15)',
      },
    },
    alternate: {
      default: {
        background: '#ffffff',
        border: '1px solid #cccccc;',
        color: '#bd0e08',
        boxShadow: 'box-shadow: 0px 1px 2px rgba(0,0,0,0.05);',
      },
      hover: {
        background: '#ebebeb',
        boxShadow: 'box-shadow: 0px 1px 2px rgba(0,0,0,0.05)',
      },
      active: {
        background: '#d3d3d3',
        border: '1px solid #c7c7c7;',
        boxShadow: 'box-shadow: 0px 1px 2px rgba(0,0,0,0.05)',
      },
    },
  },
};

interface StyleProps {
  isLoading?: boolean;
  width?: string;
  height?: string;
  variant: Variant;
  alternate: boolean;
  navButton?: 'previous' | 'next';
}

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Animation = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.5);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */

  & div {
    box-sizing: content-box;
  }

  div {
    box-sizing: border-box !important;
  }

  & > div {
    position: absolute;
    width: 34px;
    height: 34px;
    top: 8px;
    left: 8px;
    border-radius: 50%;
    border: 4px solid #000;
    border-color: #ffffff transparent #ffffff transparent;
    animation: ldio-lvijpe7xwqp 1s linear infinite;
  }
  & > div:nth-child(2) {
    border-color: transparent;
  }
  & > div:nth-child(2) div {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: rotate(45deg);
  }
  & > div:nth-child(2) div:before,
  & > div:nth-child(2) div:after {
    content: '';
    display: block;
    position: absolute;
    width: 4px;
    height: 4px;
    top: -4px;
    left: 11px;
    background: #ffffff; /* color here should be only #000000 (light background) or #FFFFFF (dark background) depending on usage */
    border-radius: 50%;
    box-shadow: 0 30px 0 0 #ffffff;
  }
  & > div:nth-child(2) div:after {
    left: -4px;
    top: 11px;
    box-shadow: 30px 0 0 0 #ffffff;
  }
`;

export const Container = styled.button<StyleProps>`
  min-width: ${props => (props.width ? props.width : '200px')};
  width: ${props => (props.width ? props.width : 'fit-content')};
  display: flex;
  align-items: center;
  height: ${props => (props.height ? props.height : '32px')};
  justify-content: center;
  padding: 0px 10px;
  border: ${props => {
    return props.alternate && variants[props.variant].alternate
      ? variants[props.variant].alternate?.default.border
      : variants[props.variant].main.default.border;
  }};
  border-radius: 4px;
  background: ${props =>
    props.alternate && variants[props.variant].alternate
      ? variants[props.variant].alternate?.default.background
      : variants[props.variant].main.default.background};
  background-position: center center;
  background-size: cover;
  font-family: ${props => props.theme.fontFamily.heading};
  font-size: 15px;
  font-weight: 600;
  color: ${props =>
    props.alternate && variants[props.variant].alternate
      ? variants[props.variant].alternate?.default.color
      : variants[props.variant].main.default.color};
  text-decoration: none solid rgb(255, 255, 255);
  text-align: center;
  position: relative;
  box-shadow: ${props =>
    props.alternate && variants[props.variant].alternate
      ? variants[props.variant].alternate?.default.boxShadow
      : variants[props.variant].main.default.boxShadow};
  transition: ${props => props.theme.animations.button};
  outline: none;

  &:focus {
    outline: 0;
  }

  &:hover {
    background: ${props =>
      props.alternate && variants[props.variant].alternate
        ? variants[props.variant].alternate?.hover.background
        : variants[props.variant].main.hover.background};
    box-shadow: ${props =>
      props.alternate && variants[props.variant].alternate
        ? variants[props.variant].alternate?.hover.background
        : variants[props.variant].main.hover.boxShadow};
  }

  &:disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  &:active {
    box-shadow: ${props =>
      props.alternate && variants[props.variant].alternate
        ? variants[props.variant].alternate?.active.boxShadow
        : variants[props.variant].main.active.boxShadow};
    border: ${props =>
      props.alternate && variants[props.variant].alternate
        ? variants[props.variant].alternate?.active.border
        : variants[props.variant].main.active.border};
    background: ${props =>
      props.alternate && variants[props.variant].alternate
        ? variants[props.variant].alternate?.active.background
        : variants[props.variant].main.active.background};
  }

  ${props =>
    props.navButton === 'previous' &&
    css`
      svg {
        position: absolute;
        margin-left: -175px;
      }
    `}

  ${props =>
    props.navButton === 'next' &&
    css`
      svg {
        position: absolute;
        margin-left: 175px;
      }
    `}

  ${props =>
    props.isLoading &&
    css`
      pointer-events: none;
      cursor: wait;
    `}
`;

export const Spinner = styled.div`
  margin-left: 5px;
  width: 25px;
  height: 25px;
  display: inline-block;
  overflow: hidden;
  background: none;

  animation: ${rotate} 2s linear infinite;
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Children = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
  }
`;
