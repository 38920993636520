import { IComboBox, IComboBoxOption, ComboBox } from '@fluentui/react';
import * as React from 'react';
import { Instructions } from '../../../Common';
import { Container } from './styles';

type Division = {
  id: number;
  name: string;
};

interface DivisionsProps {
  isLoading: boolean;
  divisions: Division[];
  selectedDivision?: number;
  onSelectDivision: (division: number) => void;
}

function Divisions({
  isLoading,
  divisions,
  onSelectDivision,
  selectedDivision,
}: DivisionsProps) {
  const onSelect = React.useCallback(
    (event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
      if (option) {
        const division = {
          id: Number(option.key),
          name: option.text,
        };
        onSelectDivision(division.id);
      }
    },
    [onSelectDivision],
  );

  if (isLoading || divisions.length === 0) {
    return null;
  }

  if (divisions.length === 1) {
    return (
      <Container>
        <Instructions>
          This order will be submitted under{' '}
          <strong>{divisions[0].name}</strong>.
        </Instructions>
      </Container>
    );
  }

  const comboboxStyle = {
    container: { maxWidth: 196 },
    callout: { minWidth: 196 },
  };

  const divisionsComboBoxData: IComboBoxOption[] = divisions.map(
    ({ id: key, name: text }) => ({
      key,
      text,
    }),
  );

  return (
    <Container>
      <Instructions>
        Next, <strong>select a division</strong> to submit the order under.
      </Instructions>
      <ComboBox
        id="divisions"
        selectedKey={selectedDivision || undefined}
        options={divisionsComboBoxData}
        allowFreeform
        onChange={onSelect}
        styles={comboboxStyle}
      />
    </Container>
  );
}

export default Divisions;
