import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  flex-direction: column;
  padding: 10px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 575px;
  flex-wrap: wrap;
`;

export const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 5px;

  > :nth-child(1) {
    width: 100px;
    flex: 0 0 auto;
  }
`;

export const Title = styled.div`
  font-size: ${props => props.theme.fontSize.lg};
  color: ${props => props.theme.colors.dark};
  font-weight: 700;
`;

export const Label = styled.p`
  font-size: ${props => props.theme.fontSize.base};
  color: ${props => props.theme.colors.dark};
  font-weight: 700;
`;

export const Value = styled.p`
  font-size: ${props => props.theme.fontSize.base};
  color: ${props => props.theme.colors.dark};
`;
