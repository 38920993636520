/* eslint-disable react/function-component-definition */
import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { User } from '../../../models';
import { DateControl } from './DateRenderer';
import './UserStatusRenderer.css';

const Active = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="7.5" cy="7.5" r="7" strokeWidth="1" />
    </svg>
  );
};

const Disabled = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="7.5" cy="7.5" r="6.5" fill="transparent" strokeWidth="1.5" />
      <line x1="1" y1="7.5" x2="14" y2="7.5" strokeWidth="1.5" />
    </svg>
  );
};

const UserStatusRenderer: React.FC<ICellRendererParams> = params => {
  const { data } = params;
  if (data instanceof User) {
    return data.isDisabled ? (
      <span className="status disabled">
        <Disabled />
        <DateControl date={data.disabledDate} />
      </span>
    ) : (
      <span className="status active">
        <Active />
      </span>
    );
  }
  return null;
};

export default UserStatusRenderer;
