import React from 'react';
import './Error.css';
import { useAuthenticationConfig } from '../contexts/AuthenticationConfigContext';
import logo from '../images/situsamc.png';

type ErrorProps = {
  title: string;
  description?: string;
};

function Error({ title, description }: ErrorProps) {
  const authConfig = useAuthenticationConfig();
  const descriptionElement = description ? (
    <p className="text-blue-2">{description}</p>
  ) : undefined;

  return (
    <div id="bigProblem">
      <div className="main bg-blue-5 text-mono-1">
        <img src={logo} width="200" alt="" />
        <h3 className="font-noto">{title}</h3>
        {descriptionElement}
        <hr />
        <div className="links text-2">
          <a href={authConfig.helpLink}>Help</a>|
          <a href={`mailto:${authConfig.contactEmail}`}>Contact Us</a>
        </div>
      </div>
    </div>
  );
}

export default Error;
