import styled, { css } from 'styled-components';

interface IProps {
  isAvailable?: boolean;
  isChecked?: boolean;
}

export const Container = styled.div<IProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  justify-content: space-between;
  width: 315px;
  min-height: 104px;
  border-radius: 3px;
  background-color: #ffffff;
  background-size: cover;
  box-shadow:
    0px 0px 0px 1px rgba(0, 0, 0, 0.2),
    0px 1px 3px rgba(0, 0, 0, 0.1);
  padding: 10px;
  cursor: pointer;
  transition: ${props => props.theme.animations.button};

  &:hover {
    background-color: #f7f9fa;
  }

  ${props =>
    props.isChecked &&
    css`
      background-color: #299dfa;
      background-size: cover;
      box-shadow:
        0px 0px 0px 1px #1c6cad,
        0px 1px 3px #1c6cad;

      &:hover {
        background-color: #299dfa;
      }
    `}
`;

export const ProductDetails = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const ProductIcon = styled.div<IProps>`
  margin-right: 10px;
  margin-top: 5px;
`;

export const ProductName = styled.div<IProps>`
  font-family: ${props => props.theme.fontFamily.heading};
  font-size: ${props => props.theme.fontSize.lg};
  font-weight: 600;
  color: ${props =>
    props.isAvailable ? props.theme.colors.primary : '#707070'};

  transition: color 0.3s ease;

  ${props =>
    props.isChecked &&
    css`
      color: #fff;
    `}
`;

export const ProductPriceVendor = styled.div<IProps>`
  font-family: ${props => props.theme.fontFamily.main};
  font-size: ${props => props.theme.fontSize.base};
  color: #000000;
  transition: color 0.3s ease;

  ${props =>
    props.isChecked &&
    css`
      color: #fff;
    `}
`;

export const ProductDescription = styled.div<IProps>`
  font-family: ${props => props.theme.fontFamily.main};
  font-size: ${props => props.theme.fontSize.base};
  color: #000000;
  transition: color 0.3s ease;

  ${props =>
    props.isChecked &&
    css`
      color: #fff;
    `}
`;
