import * as React from 'react';
import { FieldProps, useField } from 'formik';
import { Option, Options } from './styles';

interface SwitcherOption {
  key: string;
  text: string;
}

interface SwitcherProps extends FieldProps {
  options: SwitcherOption[];
  error: boolean;
  onChange?: () => void;
  onFocus?: () => void;
}

function Switcher({ field, options, error, onChange, onFocus }: SwitcherProps) {
  const [, meta, helpers] = useField(field.name);

  const { value } = meta;
  const { setValue } = helpers;

  function handleClick(option: string) {
    setValue(option);
    if (onChange) onChange();
  }

  return (
    <Options hasError={error}>
      {options.map(
        option =>
          option.key !== '' && (
            <Option
              key={option.key}
              onClick={() => handleClick(option.key)}
              onFocusCapture={onFocus}
              checked={value === option.key}
              notChecked={value !== option.key && value !== ''}
            >
              {option.text}
            </Option>
          ),
      )}
    </Options>
  );
}

export default Switcher;
