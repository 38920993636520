import * as React from 'react';
import { ToastContainerProps, Slide, toast } from 'react-toastify';
import {
  ToastMessageContainer,
  toastError,
  toastInfo,
  toastSuccess,
  toastWarning,
} from '@samc/react-ui-core';
import { IToastContextData } from './types';

const defaultToastContainerProps: ToastContainerProps = {
  position: 'top-center',
  draggable: true,
  pauseOnHover: true,
  pauseOnFocusLoss: true,
  autoClose: 10000,
  transition: Slide,
};

const ToastContext = React.createContext<IToastContextData>(
  {} as IToastContextData,
);

interface IToastProviderProps {
  children: React.ReactNode;
}

function ToastProvider({ children }: IToastProviderProps) {
  const value = React.useMemo((): IToastContextData => {
    return {
      toastError,
      toastInfo,
      toastSuccess,
      toastWarning,
    };
  }, []);

  return (
    <ToastContext.Provider value={value}>
      {children}
      <ToastMessageContainer {...defaultToastContainerProps} />
    </ToastContext.Provider>
  );
}

function useToast(): IToastContextData {
  const context = React.useContext(ToastContext);
  React.useEffect(() => {
    const handleClick = (evt: MouseEvent) => {
      const toastElement = document.getElementById('ReactUILibraryToasts');
      let targetElement = evt.target as HTMLElement;
      do {
        if (targetElement === toastElement) {
          return;
        }
        targetElement = targetElement.parentNode as HTMLElement;
      } while (targetElement);
      toast.dismiss();
    };
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, []);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }

  return context;
}

export { ToastProvider, useToast };
