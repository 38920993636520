import styled from 'styled-components';

export const LayoutContainer = styled.div`
  flex: 1;
  display: flex;
  width: 100%;
  height: 100vh;
`;

export const LeftPane = styled.aside`
  width: 200px;
  color: ${props => props.theme.colors.background};

  > div > div:first-child > div:last-child {
    z-index: 100;
  }
`;

export const HeaderContainer = styled.div`
  padding: 20px;
`;

export const FooterContainer = styled.div`
  margin-top: -20px;
  padding: 0px 20px 20px;
  display: flex;
  align-items: center;

  & :not(:first-child):before {
    content: '|';
    margin: 0px 5px;
  }
`;

export const RightPane = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
`;

export const Content = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export const ButtonLink = styled.button`
  border: none;
  background-color: transparent;
  color: inherit;
  font-size: inherit;
`;

export const CompanyName = styled.div`
  margin-left: 5px;
  margin-right: auto;
`;
