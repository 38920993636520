import React from 'react';
import {
  Container,
  TabMenuWrapper,
  TabMenu,
  TabLabelItem,
  TabView,
  TabViewItem,
  Variant,
} from './styles';

interface TabProps {
  label: string;
  index: number;
  count?: number;
  variant?: Variant;
  children: React.ReactNode;
}

export function Tab({ children, ...rest }: TabProps) {
  return <TabViewItem {...rest}>{children}</TabViewItem>;
}

interface TabLabelProps {
  label: string;
  index: number;
  count?: number;
  variant?: Variant;
  selectedTab: number;
  setSelectedTab: (index: number) => void;
}

function TabTitle({
  label,
  count,
  variant,
  selectedTab,
  setSelectedTab,
  index,
}: TabLabelProps) {
  const onClick = React.useCallback(() => {
    setSelectedTab(index);
  }, [setSelectedTab, index]);

  return (
    <TabLabelItem
      onClick={onClick}
      selected={index === selectedTab}
      variant={variant}
    >
      {label}
      {count !== undefined && <span>{count}</span>}
    </TabLabelItem>
  );
}

interface TabsProps {
  children: JSX.Element[];
  componentRender?: React.ReactElement;
  selectedTab?: number;
  onSelectTab: (index: number) => void;
}

export function Tabs({
  children,
  componentRender,
  selectedTab = 0,
  onSelectTab,
}: TabsProps) {
  return (
    <Container>
      <TabMenuWrapper>
        <TabMenu>
          {children.map(item => (
            <TabTitle
              key={item.props.index}
              label={item.props.label}
              count={item.props.count}
              index={item.props.index}
              variant={item.props.variant}
              setSelectedTab={onSelectTab}
              selectedTab={selectedTab}
            />
          ))}
        </TabMenu>
        {componentRender && componentRender}
      </TabMenuWrapper>
      <TabView>{children[selectedTab]}</TabView>
    </Container>
  );
}
