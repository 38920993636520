import * as React from 'react';
import { Container, Animation } from './styles';

interface SpinnerProps extends React.HTMLAttributes<HTMLDivElement> {
  isLoading?: boolean;
}

function Spinner(props: SpinnerProps) {
  return (
    <Container {...props}>
      <Animation>
        <div />
        <div>
          <div />
        </div>
      </Animation>
    </Container>
  );
}

export default Spinner;
