import React from 'react';
import { GridReadyEvent, GridApi } from 'ag-grid-community';

interface NoApis {
  gridApi?: undefined;
}

interface WithApis<TData> {
  gridApi: GridApi<TData>;
}

type Apis<TData> = NoApis | WithApis<TData>;

type ReturnType<TData> =
  | [undefined, (event: GridReadyEvent<TData>) => void, undefined]
  | [GridApi<TData>, (event: GridReadyEvent<TData>) => void];

/**
 * Saves the grid api into a stately variable. Should only be wired to one grid.
 * @param onGridReady the onGridReady callback to pass through to
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useAgGridApi = <TData = any>(
  onGridReady?: (event: GridReadyEvent<TData>) => void,
): ReturnType<TData> => {
  const [{ gridApi }, setApis] = React.useState<Apis<TData>>({});

  const handleGridReady = React.useCallback(
    (event: GridReadyEvent<TData>): void => {
      if (onGridReady) {
        onGridReady(event);
      }

      setApis({ gridApi: event.api });
    },
    [onGridReady],
  );

  return [gridApi, handleGridReady] as ReturnType<TData>;
};

export default useAgGridApi;
