import * as React from 'react';
import { useStepper } from '../context';
import { StepperData } from '../context/types';
import { Container, Number, StepTitle } from './styles';

export interface StepProps extends Partial<StepperData> {
  index: number;
}

function Step({ index, title }: StepProps) {
  const { onNavigateToStep, state } = useStepper();

  const number = React.useMemo(() => {
    let num = index + 1;
    for (let i = index - 1; i >= 0; i--) {
      if (state.steps[i] && state.steps[i].isVisible === false) {
        num--;
      }
    }
    return num;
  }, [index, state.steps]);

  if (!state.steps[index] || state.steps[index].isVisible === false) {
    return null;
  }

  const { variant } = state.steps[index];

  return (
    <Container
      onClick={() => onNavigateToStep(index)}
      variant={variant}
      isSubmitted={state.isSubmitted}
    >
      <Number variant={variant}>{number}</Number>
      <StepTitle variant={variant}>
        {state.isSubmitted && state.steps.length === number
          ? 'Submitted'
          : title}
      </StepTitle>
    </Container>
  );
}

export default Step;
