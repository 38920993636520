import * as React from 'react';
import { useStepper } from '../context';
import { Container } from './styles';

export interface ContentProps {
  index: number;
  children: React.ReactNode;
}

export function Content({ index, children }: ContentProps) {
  const { state } = useStepper();
  return state.currentStep === index ? <Container>{children}</Container> : null;
}
