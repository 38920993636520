import {
  BPOLoanAddress,
  BPOLoanData,
  EditedBPOLoanAddress,
  OriginalBPOLoanAddress,
  SuggestedBPOLoanAddress,
} from './types';

interface extractAddressesResult {
  original?: OriginalBPOLoanAddress;
  edited?: EditedBPOLoanAddress;
  selected?: BPOLoanAddress;
  suggested: SuggestedBPOLoanAddress[];
}

export function extractAddresses(data: BPOLoanData): extractAddressesResult {
  const result: extractAddressesResult = {
    suggested: [],
  };

  data.addresses.forEach(address => {
    if (address.isSelected) {
      result.selected = address;
    }

    switch (address.type) {
      case 'Suggested':
        result.suggested.push(address);
        break;
      case 'Original':
        result.original = address;
        break;
      case 'Edited':
        result.edited = address;
        break;
      default:
        break;
    }
  });

  return result;
}

export const formatAddress = (address: BPOLoanAddress): string => {
  const parts = [
    address?.street,
    address?.unitNumber,
    address?.city,
    address?.state,
    address?.zip,
  ].filter(part => part !== undefined && part !== '');

  return parts.join(', ');
};
