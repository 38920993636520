import React from 'react';
import { FileRejection, FileError, useDropzone } from 'react-dropzone';
import { ToastMessage } from '@samc/react-ui-core';
import { RiDeleteBin7Line } from 'react-icons/ri';
import uploadIcon from '../../../assets/icon-upload.svg';
import uploadedIcon from '../../../assets/checkbox-marked-circle-outline.svg';
import { useToast } from '../../../context/toaster';
import { IExtension } from '../../../context/order/appraisal/types';
import { Container, Content, DropzoneType } from './styles';
import { Button } from '..';

const accept = {
  XML: 'text/xml',
  PDF: 'application/pdf',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};

const codeTitle: { [key: string]: string } = {
  'file-invalid-type': 'Error - File type is not supported.',
  'max-character-limit': 'Maximum Character Limit',
};

interface DropzoneProps {
  onDrop: (acceptedFiles: File[], fileRejections: FileRejection[]) => void;
  label?: string;
  documentId?: string;
  documentName?: string;
  extension: IExtension;
  multiple: boolean;
  onDelete?: (documentId: string) => void;
  type?: DropzoneType;
  fileType?: string;
  maxFiles?: number;
  fileValidator?: <T extends File>(file: T) => FileError | FileError[] | null;
}

function Dropzone({
  onDrop,
  documentId,
  documentName,
  extension,
  multiple,
  onDelete,
  type,
  fileType,
  maxFiles,
  fileValidator,
}: DropzoneProps) {
  const validator = (file: File) => {
    if (fileValidator) {
      return fileValidator(file);
    }
    return null;
  };
  const { getRootProps, getInputProps, fileRejections, isDragActive } =
    useDropzone({
      onDrop,
      accept: accept[extension],
      multiple,
      maxFiles,
      validator,
    });
  const { toastError } = useToast();

  React.useEffect(() => {
    if (fileRejections.length > 0) {
      const codeMessage: { [key: string]: string } = {
        'file-invalid-type': `This field only accepts "${extension}" extension. Please try again`,
      };

      fileRejections.map((fileRejection: FileRejection) => {
        let title = 'Error';
        let message = '';

        if (fileRejection.errors.length === 1) {
          title = codeTitle[fileRejection.errors[0].code]
            ? codeTitle[fileRejection.errors[0].code]
            : 'Error';
          message = codeMessage[fileRejection.errors[0].code]
            ? codeMessage[fileRejection.errors[0].code]
            : fileRejection.errors[0].message;
        } else {
          title = 'Multiple File Errors';
          const messageArr = fileRejection.errors.map((error: FileError) => {
            const errTitle = codeTitle[error.code]
              ? codeTitle[error.code]
              : 'Error';
            const errMessage = codeMessage[error.code]
              ? codeMessage[error.code]
              : error.message;

            return `${errTitle}: ${errMessage}`;
          });
          message = messageArr.join(' | ');
        }

        return toastError(<ToastMessage title={title} message={message} />);
      });
    }
  }, [extension, fileRejections, toastError]);

  const uploadTextElement = () => {
    if (type === 'simple') return <span>Choose or Drop File</span>;
    return (
      <p>
        Drag and drop here to upload <strong>{fileType}</strong>
        <br />
        or <span>Select File{multiple && 's'}</span>
      </p>
    );
  };

  return (
    <Container>
      <Content
        type={type}
        {...getRootProps({
          isDragActive,
        })}
      >
        <input {...getInputProps()} />

        <img src={documentId ? uploadedIcon : uploadIcon} alt="Upload" />
        <div>{documentId ? documentName : uploadTextElement()}</div>
      </Content>
      {documentId && onDelete ? (
        <Button
          variant="tertiary"
          alternate
          onClick={() => onDelete(documentId)}
          width="120px"
          height="32px"
        >
          <RiDeleteBin7Line color="#bd0e08" size={16} />
          <p>Delete File</p>
        </Button>
      ) : null}
    </Container>
  );
}

export default Dropzone;
