import * as React from 'react';
import { Callout as CalloutUI } from '@fluentui/react';
import { styles } from './styles';

interface DropdownProps {
  isCalloutVisible: boolean;
  labelId: string;
  descriptionId: string;
  buttonId: string;
  toggleIsCalloutVisible: () => void;
}

interface CalloutProps extends DropdownProps {
  children: React.ReactNode;
}

export function Callout({
  children,
  isCalloutVisible,
  labelId,
  descriptionId,
  buttonId,
  toggleIsCalloutVisible,
}: CalloutProps) {
  return isCalloutVisible ? (
    <CalloutUI
      className={styles.callout}
      ariaLabelledBy={labelId}
      ariaDescribedBy={descriptionId}
      beakWidth={5}
      gapSpace={0}
      target={`#${buttonId}`}
      setInitialFocus
      onDismiss={toggleIsCalloutVisible}
    >
      {children}
    </CalloutUI>
  ) : null;
}

export default Callout;
