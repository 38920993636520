import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  & + div {
    border-left: 1px solid #d2d2d2;
  }
  padding: 10px;

  &:last-child {
    flex: 1;
  }
`;

export const Label = styled.label`
  font-family: ${props => props.theme.fontFamily.main};
  font-size: 14px;
  font-weight: 600;
  color: ${props => props.theme.colors.dark};
`;

export const Value = styled.div`
  font-family: ${props => props.theme.fontFamily.main};
  font-size: 14px;
  color: ${props => props.theme.colors.dark};
`;
