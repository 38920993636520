/* eslint-disable react/function-component-definition */
import React from 'react';
import { WithChildren } from '../types';

type PatienceProps = Partial<WithChildren> & {
  showPatience: boolean;
};

const Patience: React.FC<PatienceProps> = ({ showPatience, children }) => {
  if (showPatience) {
    return (
      <div className="hcon">
        <div className="hc">Loading...</div>
        <div className="opacity-0">{children}</div>
      </div>
    );
  }
  return children as React.ReactElement;
};

export default Patience;
