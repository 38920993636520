import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px;
`;

export const FormWrapper = styled.form`
  margin: 15px 0;
`;

export const GridWrapper = styled.div`
  flex: 1;
`;

export const ColumnIdWrapper = styled.div`
  margin-top: 15px;
`;

export const Label = styled.div`
  font-family: ${props => props.theme.fontFamily.heading};
  font-size: ${props => props.theme.fontSize.lg};
  color: ${props => props.theme.colors.dark};
  margin-bottom: 5px;
`;
