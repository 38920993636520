import * as React from 'react';
import { FieldHelperProps, FieldProps, useField } from 'formik';
import {
  ComboBox,
  ICalloutProps,
  IComboBox,
  IComboBoxOption,
  IComboBoxStyles,
} from '@fluentui/react';
import { getComboBoxStyles } from './styles';

interface TextFieldProps extends FieldProps {
  id?: string;
  label?: string;
  index: number;
  options: IComboBoxOption[];
  handleFocus?: (row: number) => void;
  handleKeyPress?: (row: number) => void;
  handleBlur?: () => void;
  error?: boolean;
  required?: boolean;
  selectedKey?: string | number | number[] | string[] | null;
  styles?: Partial<IComboBoxStyles>;
  calloutProps?: ICalloutProps;
  onChange?: (_: React.FormEvent<IComboBox>, option?: IComboBoxOption) => void;
  onSetValue?: (
    helpers: FieldHelperProps<unknown>,
    option?: IComboBoxOption,
  ) => void;
}
const calloutProps: ICalloutProps = {
  calloutMaxHeight: 300,
};

function FormikComboBox({
  id,
  label,
  index,
  handleFocus,
  handleKeyPress,
  handleBlur,
  required,
  options,
  error,
  styles,
  calloutProps: myCalloutProps,
  selectedKey,
  onChange,
  onSetValue,
  ...fieldProps
}: TextFieldProps) {
  const [, , helpers] = useField(fieldProps.field.name);
  const { setValue } = helpers;

  return (
    <ComboBox
      id={id}
      label={label}
      options={options}
      required={required}
      allowFreeform
      autoComplete="on"
      calloutProps={{ ...calloutProps, ...myCalloutProps }}
      onFocus={() => handleFocus && handleFocus(index)}
      styles={{ ...getComboBoxStyles(error || false), ...styles }}
      onBlur={handleBlur}
      selectedKey={selectedKey}
      onChange={(_: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
        if (handleKeyPress) handleKeyPress(index);

        if (onSetValue) {
          onSetValue(helpers, option);
        } else if (option && option.key) {
          setValue(String(option.key));
        }

        if (onChange) {
          onChange(_, option);
        }
      }}
      {...fieldProps}
    />
  );
}

export default FormikComboBox;
