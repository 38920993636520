import * as React from 'react';
import { toastError, ToastMessage } from '@samc/react-ui-core';
import { QueryCache, QueryClient } from 'react-query';
import { AxiosError } from 'axios';
import { formatErrorMessage } from '../utils/helpers';

async function onError(err: unknown) {
  const message = await formatErrorMessage(err as AxiosError<ErrorData>);

  toastError(<ToastMessage title="Error" message={message} />);
}

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError,
  }),
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60,
      onError,
    },
    mutations: {
      onError,
    },
  },
});
