import { useQuery } from 'react-query';
import api from '../services/api';

interface GetStatesResponse {
  code: string;
  name: string;
}

async function getStates() {
  const response = await api.get<GetStatesResponse[]>('/States');
  return response.data;
}

export function useStates() {
  return useQuery('states', getStates, {
    staleTime: 0,
    // cacheTime: 0,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
}
