/* eslint-disable react/function-component-definition */
import React from 'react';
import { format, compareDesc } from 'date-fns';

type StatusProps = {
  label: string;
  value?: string | Date;
  className: string;
  hasDateClassName?: string;
  showTime?: boolean;
};

const Status: React.FC<StatusProps> = ({
  label,
  value,
  className,
  hasDateClassName = '',
  showTime = false,
}) => {
  let displayString = '---';
  let classes = className;
  if (value instanceof Date) {
    const date = value as Date;
    if (compareDesc(date, new Date(0)) !== 0) {
      displayString = format(
        date,
        showTime ? 'yyyy-MM-dd hh:mm a' : 'yyyy-MM-dd',
      );
      classes = `${classes} ${hasDateClassName}`;
    }
  } else if (value) {
    displayString = value;
  }

  return (
    <div className={classes}>
      <div>{label.toUpperCase()}</div>
      <div>{displayString}</div>
    </div>
  );
};

export default Status;
