import * as React from 'react';
import { Stack, IStackStyles, IStackTokens, TextField } from '@fluentui/react';
import { FileRejection } from 'react-dropzone';
import { useOrder } from '../../../context/order';
import { Dropzone, Instructions, Loading, StepContainer } from '../../Common';
import { useSetAppraisalUpload } from '../../../mutations/useSetAppraisalUpload';
import { useRemoveAppraisalUpload } from '../../../mutations/useRemoveAppraisalUpload';

function AppraisalLoanDetails() {
  const {
    order,
    appraisalActions: { onChangeLoanId, onChangeLoanStaging, onDeleteDocument },
  } = useOrder();

  const { isLoading, mutate: mutateSet } = useSetAppraisalUpload();
  const { mutate: mutateRemove } = useRemoveAppraisalUpload();

  const stackStyles: Partial<IStackStyles> = {
    root: { width: 450 },
  };

  const stackStylesDropZone: Partial<IStackStyles> = {
    root: { width: 700 },
  };

  const stackToken: IStackTokens = {
    childrenGap: 10,
  };

  const handleChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string | undefined,
  ) => {
    event.preventDefault();

    if (value !== undefined) {
      onChangeLoanId(value);
    }
  };

  function onDrop(acceptedFiles: File[], fileRejections: FileRejection[]) {
    if (fileRejections.length === 0) {
      mutateSet(
        { stagingId: order.stagingId, files: acceptedFiles },
        {
          onSuccess: data => {
            const { stagingId, ...loan } = data;

            onChangeLoanStaging(stagingId, loan);
          },
        },
      );
    }
  }

  function onRemoveDocument(documentId: string) {
    mutateRemove(
      { stagingId: order.stagingId, documentId },
      {
        onSuccess: () => onDeleteDocument(documentId),
      },
    );
  }

  const hasAnyFileUploaded = React.useMemo(() => {
    return (
      order.appraisalLoan[0].documents.length > 0 && order.stagingId !== ''
    );
  }, [order.appraisalLoan, order.stagingId]);

  const documentId = order.appraisalLoan[0].documents[0]?.documentId;
  const documentName = order.appraisalLoan[0].documents[0]?.documentName;
  return (
    <StepContainer>
      {isLoading && <Loading message="Processing XML File..." />}

      <Stack styles={stackStyles} tokens={stackToken}>
        <Instructions>
          Please enter your <strong>Loan ID</strong>
        </Instructions>
        <TextField
          id="clientLoanId"
          value={order.appraisalLoan[0].clientLoanId}
          onChange={handleChange}
        />
      </Stack>
      <Stack styles={stackStylesDropZone} tokens={stackToken}>
        {!hasAnyFileUploaded && (
          <Instructions>
            Next, <strong>upload the Appraisal XML</strong> document
          </Instructions>
        )}

        <Dropzone
          onDrop={onDrop}
          documentId={documentId}
          documentName={documentName}
          extension="XML"
          onDelete={onRemoveDocument}
          multiple={false}
          type="wide"
          fileType="Appraisal XML"
          maxFiles={1}
        />
      </Stack>
    </StepContainer>
  );
}

export default AppraisalLoanDetails;
