import * as React from 'react';
import { Field, FieldProps, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { ComboBox, IComboBox, IComboBoxOption } from '@fluentui/react';
import { TextField } from '../../../Common/Form';
import { RequestUpdateModalProps } from '.';
import { Button } from '../../../Common';
import { useDisputeReasons } from '../../../../queries/useDisputeReasons';
import { FormatCombobox, formatComboBox } from '../../../../utils/helpers';

const RequestUpdateSchema = Yup.object().shape({
  requestReason: Yup.string().required('Required'),
  requestComment: Yup.string().min(5, 'Too Short!').required('Required'),
});

function SingleRequestDisputeForm({
  handleCloseRequestUpdate,
  isLoading,
  handleRequestUpdate,
  selectedLoans,
}: RequestUpdateModalProps) {
  const { data } = useDisputeReasons();

  const comboboxDataParams: FormatCombobox = {
    options: data || [],
    key: 'reason',
    text: 'reason',
  };

  const options = formatComboBox(comboboxDataParams);

  return (
    <Formik
      validationSchema={RequestUpdateSchema}
      initialValues={{
        requestReason: '',
        requestComment: '',
      }}
      onSubmit={async values => {
        await handleRequestUpdate([
          {
            ...selectedLoans[0],
            disputeComment: values.requestComment,
            disputeReason: values.requestReason,
          },
        ]);
      }}
    >
      {({ isValid, dirty, handleReset, handleBlur, errors, setFieldValue }) => (
        <Form
          translate="no"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            flex: 1,
          }}
        >
          <p style={{ marginBottom: 10 }}>
            Requesting an update will{' '}
            <strong>
              re-submit the loan{' '}
              {selectedLoans.length > 0 && selectedLoans[0].clientLoanId}
            </strong>{' '}
            for an updated broker price opinion.
          </p>
          <Field name="requestReason">
            {(fieldProps: FieldProps) => (
              <ComboBox
                {...fieldProps}
                placeholder="Select a reason"
                label="Request Reason"
                options={options}
                onChange={(
                  _: React.FormEvent<IComboBox>,
                  option?: IComboBoxOption,
                ) => {
                  if (option && option.key) {
                    setFieldValue('requestReason', String(option.key), true);
                  }
                }}
                onBlur={handleBlur}
                errorMessage={errors.requestReason}
                calloutProps={{ calloutMinWidth: 300 }}
                required
              />
            )}
          </Field>

          <Field name="requestComment">
            {(fieldProps: FieldProps) => (
              <TextField
                styles={{ root: { height: 86 } }}
                label="Comment"
                placeholder="Enter a comment"
                required
                {...fieldProps}
              />
            )}
          </Field>

          <div
            style={{
              marginTop: 20,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 10,
            }}
          >
            <Button
              type="reset"
              width="48.5%"
              height="30px"
              variant="secondary"
              alternate
              onClick={() => {
                handleReset();
                handleCloseRequestUpdate();
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              width="48.5%"
              height="30px"
              variant="secondary"
              isLoading={isLoading}
              disabled={!(isValid && dirty)}
            >
              Request Update
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default SingleRequestDisputeForm;
