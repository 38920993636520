import React from 'react';

import ContentLoader from 'react-content-loader';
import { useOrder } from '../../../../../context/order';
import {
  Container,
  ProductDetails,
  ProductIcon,
  ProductName,
  ProductPriceVendor,
  ProductDescription,
} from './styles';
import { IProduct } from '../../../../../context/order/types';
import appraisalIcon from '../../../../../assets/icon-product-appraisal.svg';
import appraisalIconSelected from '../../../../../assets/icon-product-appraisal-selected.svg';
import indexingIcon from '../../../../../assets/icon-product-indexing.svg';
import indexingIconSelected from '../../../../../assets/icon-product-indexing-selected.svg';
import ausIcon from '../../../../../assets/icon-product-aus.svg';
import ausIconSelected from '../../../../../assets/icon-product-aus-selected.svg';
import bpoIcon from '../../../../../assets/icon-product-bpo.svg';
import bpoIconSelected from '../../../../../assets/icon-product-bpo-selected.svg';
import securentIcon from '../../../../../assets/Icon_Securent_Default.png';
import securentIconSelected from '../../../../../assets/Icon_Securent_selected.png';
import securentName from '../../../../../assets/Name_Securent_Default.png';
import securentNameSelected from '../../../../../assets/Name_Securent_Selected.png';

import { formatCurrency, formatElementId } from '../../../../../utils/helpers';
import { useStepper } from '../../../../Common';

type ProductIcons = {
  default: string;
  selected: string;
};

interface Icons {
  [name: string]: ProductIcons;
}

const icons: Icons = {
  'Appraisal Review': {
    default: appraisalIcon,
    selected: appraisalIconSelected,
  },
  'Acuity Indexing': {
    default: indexingIcon,
    selected: indexingIconSelected,
  },
  Securent: {
    default: securentIcon,
    selected: securentIconSelected,
  },
  AUS: {
    default: ausIcon,
    selected: ausIconSelected,
  },
  'Broker Price Opinion': {
    default: bpoIcon,
    selected: bpoIconSelected,
  },
};

interface ProductProps {
  product: IProduct;
}

function Product({ product }: ProductProps) {
  const {
    order,
    orderActions: { onCheckProduct, onInitOrder },
  } = useOrder();
  const { onResetSteps } = useStepper();

  const [checked, setChecked] = React.useState(false);

  React.useEffect(() => {
    setChecked(order.product.productId === product.productId);
  }, [product, order.product]);

  const onSelectProduct = () => {
    onInitOrder();
    onResetSteps();
    onCheckProduct(product, checked);
  };

  const productElementId = formatElementId(product.productName, 'product');

  return (
    <Container
      isChecked={checked}
      onClick={onSelectProduct}
      id={productElementId}
    >
      <ProductDetails>
        {product.productName && icons[product.productName] ? (
          <ProductIcon>
            <img
              src={
                checked
                  ? icons[product.productName].selected
                  : icons[product.productName].default
              }
              alt="product icon"
              width="30px"
              height="30px"
            />
          </ProductIcon>
        ) : null}
        <div>
          <ProductName isAvailable={product.available} isChecked={checked}>
            {product.productName === 'Securent' ? (
              <img
                src={checked ? securentNameSelected : securentName}
                alt="product name"
                width="95px"
              />
            ) : (
              product.productName
            )}
          </ProductName>
          <ProductPriceVendor isChecked={checked}>
            {product.subProducts.map(sub => (
              <p key={sub.productId}>
                {sub.productName && `${sub.productName}:`}{' '}
                {!!sub.price && `${formatCurrency(sub.price)} per loan`}
              </p>
            ))}
          </ProductPriceVendor>
        </div>
      </ProductDetails>
      <ProductDescription isChecked={checked}>
        {product.description}
      </ProductDescription>
    </Container>
  );
}

export default Product;

export function ProductLoader() {
  return (
    <ContentLoader
      speed={2}
      width={214}
      height={104}
      viewBox="0 0 246 104"
      backgroundColor="#e6e6e6"
      foregroundColor="#fff"
    >
      <rect x="0" y="0" rx="2" ry="2" width="246" height="104" />
    </ContentLoader>
  );
}
