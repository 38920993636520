// https://stackoverflow.com/a/38327540
export default function groupBy<T, TKey>(
  list: Array<T>,
  keyFunc: (key: T) => TKey,
) {
  const map = new Map<TKey, Array<T>>();
  list.forEach(item => {
    const key = keyFunc(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}
