import produce from 'immer';
import { IState, ActionTypes, IStep } from './types';

export const reducer = (state: IState, action: ActionTypes): IState => {
  return produce(state, draft => {
    switch (action.type) {
      case 'INIT_STEPPER': {
        draft.steps = [...action.payload.steps];

        if (action.payload.initialStep) {
          if (typeof action.payload.initialStep === 'string') {
            draft.steps.some((step, index) => {
              if (step.title === action.payload.initialStep) {
                draft.currentStep = index;
                return true;
              }
              return false;
            });
          } else {
            draft.currentStep = action.payload.initialStep;
          }
        }

        if (draft.currentStep >= draft.steps.length) {
          draft.currentStep = draft.steps.length - 1;
        }

        draft.steps[draft.currentStep].variant = 'current';

        draft.steps.forEach((step, index) => {
          if (index < draft.currentStep) {
            step.variant = 'validated';
          }
        });
        return draft;
      }
      case 'NEXT_STEP': {
        draft.currentStep = state.currentStep + 1;
        while (
          draft.steps[draft.currentStep] &&
          draft.steps[draft.currentStep].isVisible === false
        ) {
          draft.currentStep++;
        }
        draft.steps[state.currentStep].variant = 'validated';
        draft.steps[draft.currentStep].variant = 'current';
        return draft;
      }
      case 'PREVIOUS_STEP': {
        draft.currentStep = state.currentStep - 1;
        while (
          draft.steps[draft.currentStep] &&
          draft.steps[draft.currentStep].isVisible === false
        ) {
          draft.currentStep--;
        }
        draft.steps[state.currentStep].variant = 'validated';
        draft.steps[draft.currentStep].variant = 'current';
        return draft;
      }
      case 'CLEAR_STEP': {
        draft.currentStep = state.currentStep - 1;
        draft.steps[state.currentStep - 1].variant = 'current';
        draft.steps[state.currentStep].variant = undefined;
        return draft;
      }
      case 'NAVIGATE_TO': {
        draft.currentStep = action.payload.index;
        draft.steps[state.currentStep].variant = 'validated';
        draft.steps[action.payload.index].variant = 'current';
        return draft;
      }
      case 'JUMP_TO': {
        draft.currentStep = action.payload.stepNumber;
        for (let i = 0; i < action.payload.stepNumber; i++) {
          draft.steps[i].variant = 'validated';
        }
        draft.steps[action.payload.stepNumber].variant = 'current';
        return draft;
      }
      case 'SET_SUBMITTED': {
        draft.isSubmitted = true;
        return draft;
      }
      case 'RESET_STEPPER': {
        for (let i = 0; i < draft.steps.length; i++) {
          draft.steps[i].variant = undefined;
        }
        draft.currentStep = 0;
        draft.steps[0].variant = 'current';
        draft.isSubmitted = false;
        return draft;
      }
      case 'SET_REFRESH_DATA': {
        if (action.payload.stepperData) {
          const stepLookup: { [key: string]: IStep } = {};
          const currentStep = draft.steps[draft.currentStep]
            ? draft.steps[draft.currentStep].title
            : '';
          draft.steps.forEach(step => {
            stepLookup[step.title] = step;
          });

          let foundCurrent = false;
          draft.steps = [];
          action.payload.stepperData?.data.stepperData.forEach(
            (step, index) => {
              const newStep = {
                ...stepLookup[step.title],
                ...step,
              };

              if (newStep.variant === undefined && !foundCurrent) {
                newStep.variant = 'validated';
              }

              if (step.title === currentStep) {
                draft.currentStep = index;
                foundCurrent = true;
              }

              draft.steps.push(newStep);
            },
          );
        }
        draft.isRevalidating = !state.isRevalidating;
        return draft;
      }
      default:
        return state;
    }
  });
};
