import * as React from 'react';
import RadioItem from './RadioItem';

interface RadioProps {
  onChange: (value: string | number) => void;
  selected: string | number;
  name: string;
  suppressCheckIcon?: boolean;
  options: { key: string; text: string | number }[];
}

export default function Radio({ options, ...props }: RadioProps) {
  return (
    <>
      {options.map(({ key, text }) => (
        <RadioItem key={text} text={text} value={key} {...props} />
      ))}
    </>
  );
}
