import * as React from 'react';
import {
  ComboBox,
  ICalloutProps,
  IComboBox,
  IComboBoxOption,
} from '@fluentui/react';
import { ToastMessage } from '@samc/react-ui-core';
import { Heading } from '../../components/Common';
import Box from '../../components/AccountSettings/Box';
import Row from '../../components/AccountSettings/Row';
import { Container, Content } from './styles';
import api from '../../services/api';
import { useToast } from '../../context/toaster';

interface UserSettings {
  id: string;
  name: string;
  type: string;
  value: string;
  description: string;
}

function AccountSettings() {
  const { toastError, toastSuccess } = useToast();
  const [userSettings, setUserSettings] = React.useState<UserSettings[]>([]);

  React.useEffect(() => {
    const fetch = async () => {
      const response = await api.get('/UserSettings');
      setUserSettings(response.data.settings);
    };
    fetch();
  }, []);

  const handleUpdate = React.useCallback(
    async (key: string, value: string | number) => {
      const updatedSettings = [...userSettings];
      const index = updatedSettings.findIndex(item => item.name === key);

      updatedSettings[index].value = String(value);
      setUserSettings(updatedSettings);

      const formData = {
        items: [
          {
            name: key,
            value: updatedSettings[index].value,
          },
        ],
      };
      try {
        await api.patch('/UserSettings', formData);

        toastSuccess(<ToastMessage title="Changes saved!" />);
      } catch (err) {
        toastError(
          <ToastMessage
            title="Error!"
            message="Sorry, but something bad happend. Please try again later."
          />,
        );
      }
    },
    [toastError, toastSuccess, userSettings],
  );

  const onSelectExpiringDays = React.useCallback(
    async (event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
      event.preventDefault();

      if (option && option.key) {
        await handleUpdate('unsubordersautoexpiredays', option.key);
      }
    },
    [handleUpdate],
  );

  const unsubordersautoexpiredays = React.useMemo(() => {
    const setting = userSettings.find(
      item => item.name === 'unsubordersautoexpiredays',
    );
    return setting ? setting.value : null;
  }, [userSettings]);

  const expiringDaysComboBoxData: IComboBoxOption[] = [
    {
      key: '180',
      text: '180',
    },
    {
      key: '90',
      text: '90',
    },
  ];

  const calloutProps: ICalloutProps = {
    calloutMaxHeight: 300,
  };

  if (!userSettings || userSettings.length === 0) {
    return <div />;
  }

  return (
    <Container>
      <Heading bottomLeft="Account Settings" />
      <Content>
        <h2>Unsubmitted Order Preferences</h2>
        <Box>
          <Row>
            <strong>Automatically delete unsubmitted orders after </strong>
            <ComboBox
              id="expires"
              options={expiringDaysComboBoxData}
              allowFreeform
              autoComplete="on"
              onChange={onSelectExpiringDays}
              selectedKey={unsubordersautoexpiredays}
              styles={{
                container: {
                  marginTop: '0px !important',
                  marginLeft: 5,
                  marginRight: 5,
                  width: 100,
                },
              }}
              calloutProps={calloutProps}
              required
            />
            <strong>days</strong>
          </Row>
        </Box>
      </Content>
    </Container>
  );
}

export default AccountSettings;
