import React from 'react';
import SitusStyledAppbar from '@samc/react-ui-core/lib/atoms/Appbar/SitusStyledAppbar/SitusStyledAppbar';
import SitusStyledListItem from '@samc/react-ui-core/lib/atoms/ListItem/SitusStyledListItem/SitusStyledListItem';
import { Link, useHistory } from 'react-router-dom';
import { useCurrentUser } from '../../../features/authorization';
import { ButtonLink, CompanyName } from './styles';
import { useUserInfo } from '../../../queries/useUserInfo';

type UserDropdownItemsParams = {
  onSwitchClient: () => void;
};

function UserDropdownItems({ onSwitchClient }: UserDropdownItemsParams) {
  const currentUser = useCurrentUser();
  const history = useHistory();

  return (
    <>
      <SitusStyledListItem
        onClick={() => {
          history.push('/account-settings');
        }}
        left={
          <Link to="/account-settings" tabIndex={0}>
            Account Settings
          </Link>
        }
      />
      {currentUser.hasEntitlement('Act On Behalf Of Company') && (
        <SitusStyledListItem
          onClick={onSwitchClient}
          left={<ButtonLink onClick={onSwitchClient}>Switch Client</ButtonLink>}
        />
      )}
      <SitusStyledListItem
        onClick={() => {
          history.push('/logout');
        }}
        left={
          <Link to="/logout" tabIndex={0}>
            Logout
          </Link>
        }
      />
    </>
  );
}

type AppbarProps = {
  userName: string;
  workbenchCollapsed: boolean;
  setWorkbenchCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  setHovermode: (hoverMode: boolean) => void;
  onSwitchClient: () => void;
};

function Appbar({
  userName,
  workbenchCollapsed,
  setWorkbenchCollapsed,
  setHovermode,
  onSwitchClient,
}: AppbarProps) {
  const userInfo = useUserInfo();

  return (
    <div>
      <SitusStyledAppbar
        userName={userName}
        hideWorkbenchButton={!workbenchCollapsed}
        onClickWorkbenchButton={() =>
          setWorkbenchCollapsed(prevState => !prevState)
        }
        userDropdownContents={
          <UserDropdownItems onSwitchClient={onSwitchClient} />
        }
        onHoverWorkbenchButton={() => setHovermode(true)}
        additionalControls={
          <CompanyName>
            {userInfo.data?.emulatedCompany
              ? userInfo.data.emulatedCompany.name
              : userInfo.data?.company.name}
          </CompanyName>
        }
      />
    </div>
  );
}

export default Appbar;
