import * as React from 'react';
import { useParams } from 'react-router-dom';
import api from '../services/api';
import { useOrder } from '../context/order';
import { IProduct, IUnsubmittedOrder } from '../context/order/types';
import SelectProduct from '../components/NewOrder/SelectProduct';
import AppraisalLoanDetails from '../components/NewOrder/AppraisalLoanDetails';
import IndexingLoanDetails from '../components/NewOrder/IndexingLoanDetails';
import AppraisalReviewSubmit from '../components/NewOrder/AppraisalReviewSubmit';
import { StepperData } from '../components/Common/Stepper/context/types';
import { useSetAppraisalOrder } from '../mutations/useSetAppraisalOrder';
import { useSetBPOLoans } from '../mutations/useSetBPOLoans';
import { useSetBPOOrder } from '../mutations/useSetBPOOrder';
import {
  GetAppraisalOrderResponse,
  useAppraisalOrder,
} from '../queries/useAppraisalOrder';
import { queryClient } from '../services/queryClient';
import { GetBPOOrderResponse, useBPOOrder } from '../queries/useBPOOrder';
import { useSubmitBPOOrder } from '../mutations/useSubmitBPOOrder';
import { useSubmitAppraisalOrder } from '../mutations/useSubmitAppraisalOrder';
import SecurentUploadLoan from '../components/NewOrder/SecurentUploadLoan';
import AUSUploadLoanFile from '../components/NewOrder/AUSUploadLoanFile';
import AUSUploadCreditFiles from '../components/NewOrder/AUSUploadCreditFiles';
import AUSReviewSubmit from '../components/NewOrder/AUSReviewSubmit';
import { useDivisions } from '../queries/useDivisions';
import { useValidateBpoOrder } from './useValidateBpoOrder';
import { useBpoSteps } from './useBpoSteps';

interface ProductsStepperData {
  [key: string]: StepperData[];
}

interface Data {
  stepperData: StepperData[];
  initialStep?: number | string;
}

interface ValidateBPOOrderProps {
  acknowledgeWarnings?: boolean;
  onNextStep: (response: GetBPOOrderResponse) => void;
}

export interface UseStepperData {
  data: Data;
  isLoading: boolean;
  isSaving: boolean;
  savingMessage: string;
  onValidateBPOOrder: (options: ValidateBPOOrderProps) => void;
}

function useStepperData(): UseStepperData {
  const isMounted = React.useRef(false);
  const { id, productOrder } = useParams<Record<string, string | undefined>>();
  const { order, orderActions } = useOrder();
  const { onSaveOrderId, onLoadOrder, onLoadBPOOrder } = orderActions;
  const setAppraisalOrderMutation = useSetAppraisalOrder();
  const submitAppraisalOrderMutation = useSubmitAppraisalOrder();
  const setBPOLoansMutation = useSetBPOLoans();
  const setBPOOrderMutation = useSetBPOOrder();
  const submitBPOOrderMutation = useSubmitBPOOrder();
  const divisionsQuery = useDivisions();

  const appraisalOrder = useAppraisalOrder({
    orderId: id || '',
    productName: productOrder || '',
  });

  const bpoOrder = useBPOOrder({
    orderId: id || '',
    productName: productOrder || '',
  });

  const isLoading = React.useMemo(() => {
    return (
      appraisalOrder.isLoading || bpoOrder.isLoading
      // setBPOOrderMutation.isLoading
      // setBPOLoansMutation.isLoading ||
      // setAppraisalOrderMutation.isLoading ||
      // setBPOLoanMutation.isLoading ||
      // submitBPOOrderMutation.isLoading
    );
  }, [appraisalOrder.isLoading, bpoOrder.isLoading]);

  const onSetAppraisalOrder = React.useCallback(
    (onNextStep?: () => void) => {
      const formData = {
        orderId: order.orderId,
        companyDivision: order.division ? order.division : undefined,
        stagingId: order.stagingId,
        vendorId: order.product.vendorId,
        productId: order.product.productId,
        clientLoanId: order.appraisalLoan[0].clientLoanId,
        productPrice: {
          productId: order.product.productId,
          productName: order.product.productName,
          price: order.product.cvpPrice,
        },
      };

      setAppraisalOrderMutation.mutate(formData, {
        onSuccess: ({ orderId }) => {
          onSaveOrderId(orderId);

          if (onNextStep) {
            onNextStep();
          }

          queryClient.invalidateQueries(['appraisalOrder', order.orderId]);
          queryClient.invalidateQueries(['orders', 'UnsubmittedOrders']);
        },
      });
    },
    [
      onSaveOrderId,
      order.appraisalLoan,
      order.division,
      order.orderId,
      order.product.cvpPrice,
      order.product.productId,
      order.product.productName,
      order.product.vendorId,
      order.stagingId,
      setAppraisalOrderMutation,
    ],
  );

  const onSubmitAppraisalOrder = React.useCallback(
    onFinalizeStep => {
      submitAppraisalOrderMutation.mutate(
        { orderId: order.orderId, orderIdentifier: order.orderIdentifier },
        {
          onSuccess: data => {
            queryClient.invalidateQueries('orders');
            orderActions.onChangeOrderNumber(data.orderNumber);

            onFinalizeStep();
          },
        },
      );
    },
    [
      order.orderId,
      order.orderIdentifier,
      orderActions,
      submitAppraisalOrderMutation,
    ],
  );

  const onSubmitIndexingLoan = React.useCallback(
    async onNextStep => {
      if (order.indexingLoan.metaData) {
        const formData = {
          ProductId: order.product && order.product.productId,
          VendorId: order.product && order.product.vendorId,
          DocumentId: order.indexingLoan.metaData.id,
          ColumnName: order.indexingLoan.columnName,
          Validation: false,
          companyDivision: order.division ? order.division : undefined,
        };

        if (!order.orderId) {
          const response = await api.post('/IndexingOrder', formData);
          const { orderId } = response.data;
          onSaveOrderId(orderId);
        } else {
          Object.assign(formData, {
            orderId: order.orderId,
          });
          await api.patch('/IndexingOrder', formData);
        }
        onNextStep();
      }
    },
    [
      onSaveOrderId,
      order.division,
      order.indexingLoan.columnName,
      order.indexingLoan.metaData,
      order.orderId,
      order.product,
    ],
  );

  const myOrderIdentifier = React.useRef(order.orderIdentifier);

  React.useEffect(() => {
    myOrderIdentifier.current = order.orderIdentifier;
  }, [order.orderIdentifier]);

  const { onValidateBpoOrder } = useValidateBpoOrder();

  const isLoanValid = React.useMemo(
    () =>
      order.appraisalLoan.length === 1 &&
      order.appraisalLoan[0].clientLoanId !== '' &&
      order.appraisalLoan[0].documents.length === 1,
    [order.appraisalLoan],
  );

  const isIndexingLoadValid = React.useMemo(
    () =>
      !!order.indexingLoan.metaData &&
      !!order.indexingLoan.metaData.id &&
      !!order.indexingLoan.columnName,
    [order.indexingLoan.columnName, order.indexingLoan.metaData],
  );

  const selectProductStep = React.useMemo(
    () => ({
      title: 'Select Product',
      content: <SelectProduct />,
      isValid:
        !!order.product &&
        !!order.product.productId &&
        ((divisionsQuery.data && divisionsQuery.data.length === 0) ||
          !!order.division),
    }),
    [divisionsQuery.data, order.division, order.product],
  );

  const appraisalReviewSteps = React.useMemo(
    () => [
      selectProductStep,
      {
        title: 'Loan Details',
        content: <AppraisalLoanDetails />,
        isValid: isLoanValid,
        onSubmit: onSetAppraisalOrder,
        loadingMessage: 'Getting Everything Ready...',
      },
      {
        title: 'Review & Submit',
        contentTitle: 'Your order has been submitted',
        content: <AppraisalReviewSubmit />,
        isValid: true,
        onSubmit: onSubmitAppraisalOrder,
        nextButtonLabel: 'Submit',
      },
    ],
    [
      isLoanValid,
      onSetAppraisalOrder,
      onSubmitAppraisalOrder,
      selectProductStep,
    ],
  );

  const acuityIndexingSteps = React.useMemo(
    () => [
      selectProductStep,
      {
        title: 'Loan Details',
        content: <IndexingLoanDetails />,
        isValid: isIndexingLoadValid,
        onSubmit: onSubmitIndexingLoan,
      },
      {
        title: 'Upload Files',
        contentTitle:
          'Please upload documents to support the loans added in step 2.',
        content: <h1>Upload Files</h1>,
        isValid: true,
      },
      {
        title: 'Match Results',
        contentTitle: 'Review your order and upload supporting documents',
        content: <h1>Match Results</h1>,
        isValid: true,
      },
      {
        title: 'Review & Submit',
        content: <h2>Review</h2>,
        isValid: true,
      },
    ],
    [isIndexingLoadValid, onSubmitIndexingLoan, selectProductStep],
  );

  const { steps: bpoSteps, isLoading: isBpoStepsLoading } = useBpoSteps({
    selectProductStep,
    setBPOLoansMutation,
    submitBPOOrderMutation,
  });

  const securentSteps = React.useMemo(
    () => [
      selectProductStep,
      {
        title: 'Upload Loan Files',
        content: <SecurentUploadLoan />,
        isValid: order.securentFiles.length > 0,
      },
      {
        title: 'Review & Submit',
        content: <p>Review & Submit</p>,
        isValid: true,
        nextButtonLabel: 'Submit',
      },
    ],
    [order.securentFiles.length, selectProductStep],
  );

  const ausSteps = React.useMemo(
    () => [
      selectProductStep,
      {
        title: 'Upload Loan File',
        content: <AUSUploadLoanFile />,
        isValid: !!order.ausLoan && !!order.ausLoan.loanStagingId,
      },
      {
        title: 'Upload Credit Files',
        content: <AUSUploadCreditFiles />,
        isValid:
          !!order.ausLoan &&
          !!order.ausLoan.creditInfo &&
          !!order.ausLoan.creditInfo &&
          !order.ausLoan.creditInfo.some(creditInfo => !creditInfo.hasMatch) &&
          !!order.ausLoan.borrowerInfos &&
          !order.ausLoan.borrowerInfos.some(
            borrowerInfo => !borrowerInfo.hasMatch,
          ),
      },
      {
        title: 'Review & Submit',
        content: <AUSReviewSubmit />,
        isValid: true,
        nextButtonLabel: 'Submit',
      },
    ],
    [order.ausLoan, selectProductStep],
  );

  const productsStepperData: ProductsStepperData = React.useMemo(
    () => ({
      'Appraisal Review': appraisalReviewSteps,
      'Acuity Indexing': acuityIndexingSteps,
      'Broker Price Opinion': bpoSteps,
      Securent: securentSteps,
      AUS: ausSteps,
    }),
    [
      acuityIndexingSteps,
      appraisalReviewSteps,
      ausSteps,
      bpoSteps,
      securentSteps,
    ],
  );

  const { isSaving, savingMessage } = React.useMemo(() => {
    let message = 'Saving';
    if (setBPOLoansMutation.isLoading) {
      message = 'Checking for Errors';
    } else if (submitBPOOrderMutation.isLoading) {
      message = 'Submitting';
    }

    return {
      isSaving:
        setBPOOrderMutation.isLoading ||
        setBPOLoansMutation.isLoading ||
        submitBPOOrderMutation.isLoading ||
        isBpoStepsLoading,
      savingMessage: message,
    };
  }, [
    submitBPOOrderMutation.isLoading,
    setBPOLoansMutation.isLoading,
    setBPOOrderMutation.isLoading,
    isBpoStepsLoading,
  ]);

  const stepperData: StepperData[] = React.useMemo(() => {
    return (
      (order.product && productsStepperData[order.product.productName]) ||
      productsStepperData['Broker Price Opinion']
    );
  }, [order.product, productsStepperData]);

  const loadOrder = React.useCallback(async () => {
    if (appraisalOrder.data) {
      const {
        orderId,
        orderNumber,
        orderStatus,
        stagingId,
        identifier: orderIdentifier,
        companyDivision: division,
        productInfo: { productId, productName, vendorId, price },
        loans,
      } = appraisalOrder.data as GetAppraisalOrderResponse;

      const product: IProduct = {
        productId,
        productName,
        vendorId,
        vendorName: 'Situs AMC',
        available: true,
        cvpPrice: price,
        price,
        description: '',
        clarityCompanyId: 0,
        clarityProductId: '',
        clarityVendorId: 0,
        companyId: '',
        companyName: '',
        pricePer: '',
        vpPrice: price,
        subProducts: [],
      };

      const resumedOrder: IUnsubmittedOrder = {
        stagingId,
        orderId,
        orderNumber,
        orderStatus,
        orderIdentifier,
        loans,
        product,
        division,
      };

      onLoadOrder(resumedOrder);
    }
  }, [appraisalOrder.data, onLoadOrder]);

  const loadBPOOrder = React.useCallback(() => {
    if (!bpoOrder.isLoading) {
      if (bpoOrder.data) {
        onLoadBPOOrder(bpoOrder.data);
      }
    }
  }, [bpoOrder.data, bpoOrder.isLoading, onLoadBPOOrder]);

  React.useEffect(() => {
    if (id && id !== order.orderId) {
      if (productOrder === 'Appraisal Review') {
        loadOrder();
      } else {
        loadBPOOrder();
      }
    }
  }, [id, productOrder, loadOrder, loadBPOOrder, order.orderId]);

  const initialStep = React.useMemo(() => {
    if (productOrder === 'Appraisal Review') {
      if (appraisalOrder.data) {
        return 2;
      }
    }

    if (productOrder === 'Broker Price Opinion') {
      if (!bpoOrder.isLoading && bpoOrder.data) {
        const hasErrors =
          bpoOrder.data.errorLoans && bpoOrder.data.errorLoans.length > 0;
        const hasCorrections =
          bpoOrder.data.correctionLoans &&
          bpoOrder.data.correctionLoans.length > 0;

        if (bpoOrder.data.data.length === 0) {
          return 'Loan Details';
        }

        if (
          hasErrors &&
          (bpoOrder.data.hasError || bpoOrder.data.hasUnacknowledgedWarning)
        ) {
          return 'Errors';
        }

        if (hasCorrections) {
          if (bpoOrder.data.hasUnselected) {
            return 'Address Corrections';
          }
        }

        return 'Review & Submit';
      }
      return 'Loan Details';
    }
    return 0;
  }, [appraisalOrder.data, bpoOrder.data, bpoOrder.isLoading, productOrder]);

  React.useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
    }

    return () => {
      isMounted.current = false;
    };
  }, []);

  return React.useMemo(
    () => ({
      data: { initialStep, stepperData },
      onValidateBPOOrder: onValidateBpoOrder,
      isLoading,
      isSaving,
      savingMessage,
    }),
    [
      initialStep,
      isLoading,
      isSaving,
      onValidateBpoOrder,
      savingMessage,
      stepperData,
    ],
  );
}

export default useStepperData;
