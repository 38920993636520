import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 60px;
  border-bottom-width: 1px;
  border-style: solid;
  border-color: #d2d2d2;
  background-color: #ffffff;
  background-size: cover;
`;
