import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  ConfirmationDialogOptions,
  StyledNavItemI,
  StyledNavItemIProps,
  confirm,
} from '@samc/react-ui-core';

import { useCurrentUser } from '../../../features/authorization';
import logoCertaintyNav from '../../../assets/logo-certainty-nav.svg';
import {
  Content,
  LayoutContainer,
  HeaderContainer,
  FooterContainer,
  LeftPane,
  RightPane,
} from './styles';
import { AppVersion, PrivacyLink } from '..';
import Workbench from './Workbench';
import Appbar from './Appbar';
import SwitchClientModal from '../SwitchClientModal';
import EditIcon from '../../../assets/icon-edit.svg';
import { useOrder } from '../../../context/order';
import { useValidateBpoOrder } from '../../../hooks/useValidateBpoOrder';

export interface ExtendedWorkbenchItem extends StyledNavItemIProps {
  id: string;
  url: string;
  iconSVG?: string;
}

const workbenchItems: ExtendedWorkbenchItem[] = [
  {
    id: 'new-order',
    content: 'New Order',
    url: '/new-order',
    iconName: 'ShoppingCart',
  },
  {
    id: 'unsubmitted-orders',
    content: 'Unsubmitted Orders',
    iconSVG: EditIcon,
    url: '/unsubmitted-orders',
  },
  {
    id: 'order-tracking',
    content: 'Order Tracking',
    iconName: 'Communications',
    url: '/order-tracking',
  },
  {
    id: 'admin',
    content: 'Admin',
    iconName: 'Settings',
    url: '/admin',
  },
];

function WorkbenchHeader() {
  return (
    <HeaderContainer>
      <img src={logoCertaintyNav} alt="Certainty Logo" />
    </HeaderContainer>
  );
}

function WorkbenchFooter() {
  return (
    <FooterContainer>
      <PrivacyLink />
      <AppVersion />
    </FooterContainer>
  );
}

interface LayoutProps {
  children: React.ReactNode;
}

function Layout({ children }: LayoutProps) {
  const [selectedId, setSelectedId] = React.useState<string | undefined>();
  const [workbenchCollapsed, setWorkbenchCollapsed] = React.useState(false);
  const [hovermode, setHovermode] = React.useState(false);

  const location = useLocation();
  const currentUser = useCurrentUser();
  const { name, isLoaded } = currentUser;
  const [firstName, lastName] = name.split(' ');

  React.useEffect(() => {
    if (!selectedId || selectedId !== location.pathname) {
      const selectedItem = workbenchItems.find(item => {
        return item.url === location.pathname;
      });

      if (selectedItem) {
        setSelectedId(selectedItem.id);
      }
    }
  }, [location, selectedId]);

  const [isSwitchClientOpen, setIsSwitchClientOpen] = React.useState(false);

  const onSwitchClient = React.useCallback(() => {
    setIsSwitchClientOpen(true);
  }, []);

  const { order } = useOrder();
  const { onValidateBpoOrder } = useValidateBpoOrder();
  const history = useHistory();

  const confirmNavigate = React.useCallback(
    (item: ExtendedWorkbenchItem) => {
      const navigate = () => {
        setSelectedId(item.id);
        history.push(item.url);
      };

      const confirmIt = async () => {
        const dialogOptions: ConfirmationDialogOptions = {
          title: 'Unsaved data',
          detail: 'You have unsaved changes in the current order.',
          confirmText: 'Save Changes',
          cancelText: 'Cancel',
        };

        const response = await confirm(dialogOptions);
        if (response) {
          onValidateBpoOrder({
            acknowledgeWarnings: false,
            onNextStep: () => {
              navigate();
            },
          });
        }
      };

      if (
        order.bpoLoanValidation &&
        order.bpoLoanValidation.showSavePrompt !== false &&
        order.bpoLoanValidation.data.some(loan => loan.hasChange)
      ) {
        confirmIt();
      } else {
        navigate();
      }
    },
    [history, onValidateBpoOrder, order.bpoLoanValidation],
  );

  const navItems = React.useMemo(() => {
    return workbenchItems
      .filter(
        item =>
          (item.content && currentUser.hasEntitlement(item.content)) ||
          (item.content === 'Admin' &&
            currentUser.hasEntitlement('List Users')),
      )
      .map(item => (
        <StyledNavItemI
          key={item.id}
          selected={selectedId === item.id}
          onClick={() => {
            confirmNavigate(item);
          }}
          iconName={
            item.iconSVG ? (
              <img src={item.iconSVG} alt={item.content} />
            ) : (
              item.iconName
            )
          }
          content={item.content}
        />
      ));
  }, [confirmNavigate, currentUser, selectedId]);

  if (!isLoaded) {
    return null;
  }

  return (
    <LayoutContainer>
      {!workbenchCollapsed && (
        <LeftPane>
          <Workbench
            setCollapsed={setWorkbenchCollapsed}
            hoverMode={hovermode}
            headerContent={<WorkbenchHeader />}
            collapsed={workbenchCollapsed}
            footer={<WorkbenchFooter />}
            width={200}
          >
            {navItems}
          </Workbench>
        </LeftPane>
      )}

      <RightPane>
        <Appbar
          setHovermode={setHovermode}
          workbenchCollapsed={workbenchCollapsed}
          setWorkbenchCollapsed={setWorkbenchCollapsed}
          userName={`${firstName} ${lastName}`}
          onSwitchClient={onSwitchClient}
        />

        <Content>{children}</Content>
      </RightPane>

      {currentUser.hasEntitlement('Act On Behalf Of Company') && (
        <SwitchClientModal
          isOpen={isSwitchClientOpen}
          onDismiss={() => {
            setIsSwitchClientOpen(false);
          }}
        />
      )}
    </LayoutContainer>
  );
}
export default Layout;
