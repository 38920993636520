import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 30px;
  gap: 20px;

  svg {
    color: #bd0e08;
  }
`;

export const Title = styled.h3``;

export const Text = styled.p`
  text-align: center;
  width: 300px;
`;
