import { AxiosError } from 'axios';
import React from 'react';
import { useMutation } from '../hooks/useMutation';
import api from '../services/authApi';

export async function switchClient(id: string | null) {
  await api.post('/api/v3/EmulatedCompany', {
    id,
  });
}

export function useSwitchClient() {
  return useMutation<void, AxiosError<ErrorData>, { id: string | null }>(
    React.useCallback(({ id }) => switchClient(id), []),
  );
}
