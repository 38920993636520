/* eslint-disable react/function-component-definition */
import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { format, compareDesc } from 'date-fns';

type DateProps = {
  date: Date;
  showDashes?: boolean;
};

export const DateControl: React.FC<DateProps> = ({ date, showDashes }) => {
  if (compareDesc(date, new Date(0)) === 0) {
    return showDashes ? <span>---</span> : null;
  }

  return <span>{format(date, 'yyyy-MM-dd HH:mm:ss aaa')}</span>;
};

const DateRenderer: React.FC<ICellRendererParams> = ({
  data,
  colDef,
  getValue,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let value: any = '';
  if (colDef?.field) {
    value = data[colDef.field];
  } else if (getValue !== undefined) {
    value = getValue();
  }
  if (!(typeof value.getMonth === 'function')) {
    // eslint-disable-next-line no-console
    console.error('DateRenderer used for a non-date.');
    return <span>---</span>;
  }
  const date = value as Date;
  return <DateControl date={date} showDashes />;
};

export default DateRenderer;
