import { AxiosError } from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import api from '../services/api';
import { formatDate } from '../utils/helpers';

type Request = 'TrackingOrders' | 'UnsubmittedOrders';

type OrderTrackingResponse = {
  orderId: string;
  orderNumber: 0;
  orderStatus: string;
  productId: string;
  productName: string;
  orderIdentifier: string;
  details: string;
  division: {
    id: number;
    name: string;
  };
  status: string;
  createdBy: string;
  starterDate: string;
  submittedDate: string;
  expiryDate: string;
};

type Orders = {
  orders: OrderTrackingResponse[];
};

export type OrderTrackingData = OrderTrackingResponse & {
  divisionName: string;
};

export const getOrders = async (
  request: Request,
): Promise<OrderTrackingData[]> => {
  const response = await api.get<Orders>('/Order/ordertracking?', {
    params: { request },
  });
  const formattedData: OrderTrackingData[] = response.data.orders.map(data => ({
    ...data,
    divisionName: data.division?.name,
    submittedDate: formatDate(data.submittedDate),
    starterDate: formatDate(data.starterDate),
    expiryDate: formatDate(data.expiryDate),
  }));
  return formattedData;
};

export const useOrders = (
  request: Request,
): UseQueryResult<OrderTrackingData[], AxiosError<ErrorData>> =>
  useQuery(['orders', request], () => getOrders(request));
