/* eslint-disable react/no-unescaped-entities */
import { useRef } from 'react';

type TermsOfUseContentProps = {
  setHasScrolledToEnd: (value: boolean) => void;
};

// CSS Properties
const blueColor: React.CSSProperties = { color: 'rgb(41 157 250)' };
const grayColor: React.CSSProperties = { color: 'rgb(112 112 112)' };
const pPadding: React.CSSProperties = { paddingTop: '5px' };
const indent: React.CSSProperties = { ...pPadding, paddingLeft: '42px' };
const fontStyle: React.CSSProperties = {
  fontSize: '12.25px',
  lineHeight: '1.25',
};
const subIndent: React.CSSProperties = {
  ...pPadding,
  paddingLeft: '82px',
};
const bold: React.CSSProperties = { fontWeight: 'bold' };
const italic: React.CSSProperties = { fontStyle: 'italic' };

function NumberedLabel({
  order,
  label,
  indented,
}: {
  order: string;
  label: string;
  indented?: boolean;
}) {
  return (
    <h3 style={{ fontWeight: indented ? 'normal' : 'bold', paddingTop: '5px' }}>
      <span style={{ paddingLeft: indented ? '32px' : '0px', ...grayColor }}>
        {order}
      </span>
      <span
        style={{ paddingLeft: '32px', ...(indented ? grayColor : blueColor) }}
      >
        {label}
      </span>
    </h3>
  );
}

export function TermsOfUseContent({
  setHasScrolledToEnd,
}: TermsOfUseContentProps) {
  const ref = useRef<HTMLDivElement>(null);

  const onScroll = () => {
    const deltaScalingDisplay = 2; // when system has display scaling feature scrollTop may return decimals, so there will be no exact equality to scrollHeight
    if (ref.current) {
      const { scrollTop, scrollHeight, clientHeight } = ref.current;
      if (scrollTop + clientHeight >= scrollHeight - deltaScalingDisplay) {
        setHasScrolledToEnd(true);
      }
    }
  };

  return (
    <div
      ref={ref}
      style={{
        overflowY: 'auto',
        textAlign: 'left',
        borderColor: 'rgb(44 51 59)',
        borderWidth: '1px',
        userSelect: 'text',
        padding: '7px',
        ...fontStyle,
      }}
      onScroll={onScroll}
    >
      <h1 style={{ ...bold, ...fontStyle }}>SitusAMC TERMS OF USE</h1>
      <h2 style={{ ...italic, ...fontStyle }}>
        Updated and Eﬀective Date: August 2024
      </h2>

      <h2 style={{ paddingTop: '15px', ...fontStyle }}>
        These Terms of Use ("<span style={bold}>Terms of Use</span>" or "
        <span style={bold}>Terms</span>") govern your ("
        <span style={bold}>you</span>" or "<span style={bold}>your</span>") use
        on behalf of yourself as an individual consumer or your employer (each,
        a "<span style={bold}>Firm</span>"), of the web sites, including any
        related documentation or services as hosted, operated, maintained and
        made available to you (collectively, "<span style={bold}>Solution</span>
        ") by SitusAMC Holdings Corporation, a Delaware corporation, either
        directly or through its subsidiaries (collectively, "
        <span style={bold}>SitusAMC</span>", "<span style={bold}>we</span>", "
        <span style={bold}>us</span>" or "<span style={bold}>our</span>"). You
        and SitusAMC may be individually referred to herein as a "
        <span style={bold}>Party</span>" and together as the "
        <span style={bold}>Parties</span>" to this Terms of Use. If you use our
        Solution on behalf of a Firm, all references to "you" throughout these
        Terms will include the Firm.
      </h2>
      <h2
        style={{
          paddingTop: '15px',
          fontWeight: 'bold',
          ...blueColor,
          ...fontStyle,
        }}
      >
        IMPORTANT - READ THIS TERMS OF USE CAREFULLY INCLUDING THE MANDATORY
        ARBITRATION PROVISION IN SECTION 10, WHICH REQUIRES THAT DISPUTES BE
        RESOLVED BY FINAL AND BINDING ARBITRATION ON AN INDIVIDUAL BASIS, AND
        NOT ON A CLASS-WIDE OR CONSOLIDATED BASIS. IF YOU DO NOT AGREE TO BE
        SUBJECT TO ARBITRATION, YOU MAY OPT OUT OF THE ARBITRATION PROVISION BY
        FOLLOWING THE INSTRUCTIONS PROVIDED IN SECTION 10.
      </h2>
      <h2
        style={{
          paddingTop: '15px',
          ...bold,
          ...blueColor,
          ...fontStyle,
        }}
      >
        BY ACCESSING OR USING THE SOLUTION, YOU: (A) REPRESENT THAT YOU ARE DULY
        AUTHORIZED TO ACCESS AND USE THE SOLUTION ON BEHALF OF YOURSELF OR YOUR
        FIRM; AND (B) HAVE READ, UNDERSTOOD AND HEREBY ACCEPT THIS TERMS OF USE
        AND AGREE THAT YOU ARE LEGALLY BOUND BY THEM, BOTH IN YOUR INDIVIDUAL
        CAPACITY AND ON BEHALF OF YOUR FIRM. IF YOU DO NOT AGREE TO THESE TERMS
        OF USE, DO NOT ACCESS OR USE THE SOLUTION.
      </h2>
      <h2 style={{ paddingTop: '15px', ...fontStyle }}>
        For information about how we collect, use, share and otherwise process
        information about you, please see Our Privacy Policy. Click{' '}
        <a href="https://www.situsamc.com/privacy-policy">here</a> for Privacy
        Policy.
      </h2>

      <NumberedLabel order="1." label="Accessing the Solution." />
      <div style={indent}>
        Our Solution is provided to you only for your internal business use and
        not for the benefit or use of any third party. To access the Solution,
        we will provide you with a username and password or you may need
        register for an account (collectively, the "
        <span style={bold}>Credentials</span>") that you must use to access your
        account and use the Solution in accordance with the terms herein. You
        are responsible for all use of the Solution by and through such
        Credentials and you authorize us to accept any use of the Solution
        through your Credentials as use by you or someone authorized to act for
        you. You agree to treat the Credentials as confidential and shall not
        disclose it to any other person or entity, use your Credentials for any
        unauthorized purpose, or use the Credentials of any other person. You
        agree to notify us immediately of any unauthorized access to, use, or
        loss of the Credentials, or any other potential breach of security or
        access protocols involving the Solution. To the extent applicable, you
        shall exit from your account at the end of each session to ensure the
        Solution is securely accessed and used. You agree that your Firm is
        responsible for any violation of this policy by its employees or agents.
        You may only use our Solution in jurisdictions authorized by SitusAMC.
        Use of our Solution is currently authorized only in the United States.
      </div>

      <NumberedLabel order="2." label="Customer Privacy." />
      <NumberedLabel
        order="a."
        label="Customer Information Obligations."
        indented
      />
      <div style={subIndent}>
        Your use of the Solution may allow you access to highly confidential
        information, including loan data, NPPI (as defined below) and other
        information relating to your customers (collectively, "
        <span style={bold}>Customer Information</span>") as well as access to
        and/or receive non-public personal information, as defined in Title V of
        the Gramm-Leach-Bliley Act of 1999 and its promulgating regulation,
        Regulation P ("
        <span style={bold}>NPPI</span>" and, together with Regulation P, the "
        <span style={bold}>GLBA</span>," respectively). You agree to:
        <ul
          style={{
            listStyle: 'initial',
            paddingLeft: '42px',
            paddingTop: '10px',
            paddingBottom: '5px',
          }}
        >
          <li>
            comply with the privacy requirements of the GLBA and of all other
            foreign, federal, state or local law or regulation, including all
            applicable privacy or data protection laws (including, but not
            limited to, the California Consumer Protection Act ("
            <span style={bold}>CCPA</span>"), the General Data Protection
            Regulation ("<span style={bold}>GDPR</span>") and the Taxpayer First
            Act of 2019) and all export control laws and regulations
            (collectively, "<span style={bold}>Applicable Laws</span>"), as
            applicable;
          </li>
          <li>
            disclose or use Customer Information only in strict compliance with
            all federal, state, local, and international laws and regulations;
          </li>
          <li>
            take all commercially reasonable steps necessary to safeguard and
            prevent the unauthorized disclosure of Customer Information to any
            third party;
          </li>
          <li>
            not disclose or make available Customer Information to any third
            party (including company employees who have no need for such
            information) for any reason whatsoever, other than for the Approved
            Purpose or as required by law;
          </li>
          <li>
            not use Customer Information for any kind of marketing or
            solicitation of any kind (including but not limited to sending
            unsolicited email, facsimile transmissions, or spam, even if the
            person to whom you intend to send unsolicited email or spam has
            communicated with you in the past); and
          </li>
          <li>
            notify us promptly (in any event within 24 hours after the event) if
            you become aware of: (a) any copying, disclosure, alteration,
            destruction, or use of Customer Information that is inconsistent
            with this Terms of Use; or (b) any security breach to your
            information systems or operations, or any other material risk, that
            could result in disclosure of the Customer Information.
          </li>
        </ul>
      </div>

      <NumberedLabel order="3." label="Third-Party Data Providers." />
      <div style={subIndent}>
        We may receive data from third-party data providers and the Solution may
        otherwise interoperate with and be dependent on third-party data
        providers and their services ("
        <span style={bold}>Third-Party Materials</span>"). We are not
        responsible or liable for such Third-Party Materials, including if such
        third-party data providers encounter a data breach, cyberattack, systems
        security breach or other digital infiltration. We may further provide
        information about or links to third-party products, services,
        activities, or events, or we may allow third parties to make their
        content and information available on or through the Solution
        (collectively, "<span style={bold}>Third-Party Content</span>"). We
        provide Third-Party Content as a service to those interested in such
        content. Your dealings or correspondence with third parties and your use
        of or interaction with any Third-Party Content are solely between you
        and the third party. We have no obligation to monitor Third-Party
        Materials or Third-Party Content, and we may block or disable access to
        any Third-Party Materials or Third-Party Content (in whole or part)
        through our Solution at any time. Your access to and use of such
        Third-Party Content or Third-Party Materials may be subject to
        additional terms, conditions, and policies applicable to such
        Third-Party Content (including terms of service or privacy policies of
        the providers of such Third-Party Materials). You are responsible for
        obtaining and maintaining any computer hardware, equipment, network
        services and connectivity, telecommunications services, and other
        products and services necessary to access and use the Solution.
      </div>

      <NumberedLabel order="4." label="User Data; Safeguarding; Feedback." />
      <NumberedLabel order="a." label="User Data License." indented />
      <div style={subIndent}>
        You hereby grant to SitusAMC a non-exclusive, non-transferable perpetual
        right and license to: (a) access, use, download and store the User Data
        for our internal use, review and analysis and (b) disclose such data to
        relevant third parties, in each case of (a) and (b), in our ordinary
        course of business (the "<span style={bold}>Approved Purpose</span>"). "
        <span style={bold}>User Data</span>" means any and all information
        provided, inputted, ordered from and returned via API, XML, SFTP, or any
        other delivery method, or uploaded or submitted to the Solution by you,
        on your behalf, or by your agents or designees. Except for the license
        you grant above, as between you and SitusAMC, you retain all rights in
        and to your User Data, excluding any portion of the Solution included in
        your User Data.
      </div>

      <NumberedLabel
        order="b."
        label="User Data License Restrictions."
        indented
      />
      <div style={subIndent}>
        You acknowledge and agree that we shall have no obligation to monitor
        the User Data and that we shall have no liability to you or any third
        party related to your User Data. As between the parties, you are solely
        responsible ensuring the security of User Data and maintaining all
        necessary licenses, consents, and permissions necessary for SitusAMC to
        use such User Data in accordance with these Terms, and you may not
        upload any User Data for which you do not have all the rights necessary
        to grant the license described in these Terms. You represent, warrant,
        and covenant that the User Data: (a) does not violate any Applicable
        Laws; (b) does not infringe, misappropriate, or otherwise violate any
        intellectual property right, privacy right, right of publicity, or any
        other right of any person or entity; (c) does not contain any material
        which is unlawful, hateful, obscene, libelous, threatening, defamatory,
        or otherwise objectionable, in our sole discretion; (d) does not contain
        any scripts, agents, code, or programs, the purpose or effect of which
        is to permit unauthorized access to, or to destroy, disrupt, disable,
        distort, or otherwise harm or impede in any manner, any computer,
        services, software, firmware, hardware, system or network, or any
        application or function of any of the foregoing or the integrity, use,
        or operation of any data processed thereby, including any virus, bug,
        Trojan horse, worm, backdoor, or other malicious computer code and any
        time bomb or drop-dead device ("
        <span style={bold}>Harmful Code</span>"); (e) is and shall remain
        accurate and correct at all times; (f) is owned or controlled by you,
        and you are authorized or have a right to provide, input, order, or
        upload it and that if you do not possess the necessary rights to this
        User Data, that you will obtain the necessary rights before you submit
        this User Data to us; (g) does not impersonate or misrepresent your
        affiliation with any person or entity; (h) does not contain any
        unsolicited promotions, political campaigning, advertising, or
        solicitations; (i) does not contain any private or personal information
        of a third party without such third party's consent; and (g) complies
        with these Terms of Use and any other terms or conditions set forth
        herein (collectively (a) - (g), the "
        <span style={bold}>Prohibited Acts</span>"). If you or SitusAMC become
        aware that any User Data constitutes or may constitute a Prohibited Act,
        such Party shall notify the other of, and work together with the other
        Party promptly and in good faith to remedy, any such User Data issues,
        in accordance with this Terms of Use. You agree that you shall be solely
        responsible for all rights and use associated with and shall ensure the
        security of any personally identifiable information contained in, the
        User Data. You acknowledge that the User Data will contain personally
        identifiable data that is subject to special laws and regulations
        regarding the collection, access and use of such User Data.
      </div>

      <NumberedLabel order="c." label="Feedback." indented />
      <div style={subIndent}>
        You may voluntarily post, submit or otherwise communicate to us any
        questions, comments, suggestions, ideas, original or creative materials
        or other information about SitusAMC or our Platform (collectively, "
        <span style={bold}>Feedback</span>"). You understand that we may use
        such Feedback for any purpose, commercial or otherwise, without
        acknowledgment or compensation to you, including to develop, copy,
        publish, or improve the Feedback in SitusAMC's sole discretion. You
        understand that SitusAMC may treat Feedback as nonconfidential.
      </div>

      <NumberedLabel order="d." label="Safeguarding of Information." indented />
      <div style={subIndent}>
        You represent and warrant that you (or your Firm, as applicable) have a
        program of administrative, technical, and physical safeguards in place
        designed to (a) ensure the security and confidentiality of User Data and
        Customer Information, (b) protect against unauthorized access to or use
        of such User Data and Customer Information, (c) protect against threats
        or hazards to the security or integrity of such User Data and Customer
        Information; and (d) screen for viruses and other malicious code.
      </div>

      <NumberedLabel order="5." label="Our Monitoring and Enforcement." />
      <div style={indent}>
        We are under no obligation to restrict or monitor the User Data in any
        way. You understand and acknowledge that we do not regularly monitor the
        accuracy, reliability, or quality of User Data. Any opinions, advice,
        statements, services, offers, or other information or User Data
        expressed or made available by third-parties, including other users, are
        those of the respective third parties and not of us. We do not endorse
        and are not responsible for the accuracy, reliability, or quality of any
        opinion, advice, information, or statement within the User Data. You
        agree that you must evaluate, and bear all risks associated with, the
        User Data, including any reliance on the accuracy, completeness, or
        usefulness of such User Data. Under no circumstances will we be liable
        in any way for any User Data, including, but not limited to, for any
        errors or omissions in any User Data, or for any loss or damage of any
        kind incurred as a result of the use of any User Data posted, emailed,
        or otherwise transmitted via the Solution.
      </div>
      <div style={indent}>
        Notwithstanding the foregoing, we may:
        <ul
          style={{
            listStyle: 'initial',
            paddingLeft: '42px',
            paddingTop: '10px',
            paddingBottom: '5px',
          }}
        >
          <li>
            remove or refuse to use any User Data in our sole and reasonable
            discretion;
          </li>
          <li>
            take any action with respect to any User Data that we deem necessary
            or appropriate in its sole and reasonable discretion, including, but
            not limited to, if we believe that such User Data violates this
            Terms of Use, infringes any right of any person or entity, threatens
            the personal safety of users of the Solution or the public, or could
            create liability for SitusAMC;
          </li>
          <li>
            take appropriate legal action, including without limitation,
            referral to law enforcement, for any illegal or unauthorized use of
            the Solution; and
          </li>
          <li>
            terminate or suspend your access to all or part of the Solution at
            any time, with or without notice, for any or no reason, including
            without limitation, for any violation of this Terms of Use.
          </li>
        </ul>
      </div>
      <div style={indent}>
        Without limiting the foregoing, we have the right to disclose User Data
        and any other information collected through the Solution to comply with
        any court order, law, or legal process, including to respond to any
        government, regulatory or law enforcement request.{' '}
        <span style={bold}>
          YOU WAIVE AND HOLD HARMLESS SITUSAMC AND OUR AFFILIATES, LICENSORS,
          LICENSEES, AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY
          ACTION TAKEN BY ANY OF THE FOREGOING PARTIES DURING, OR TAKEN AS A
          CONSEQUENCE OF, SUCH A DISCLOSURE, INCLUDING, BUT NOT LIMITED TO,
          INVESTIGATIONS BY SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.
          SITUSAMC ASSUMES NO LIABILITY FOR ANY ACTION OR INACTION REGARDING
          TRANSMISSIONS, COMMUNICATIONS, OR CONTENT PROVIDED BY ANY USER OF THE
          SOLUTION OR ANY THIRD PARTY.
        </span>
      </div>

      <NumberedLabel order="6." label="Solution License." />
      <NumberedLabel
        order="a."
        label="SitusAMC License Grant; Responsibilities."
        indented
      />
      <div style={subIndent}>
        Subject to the terms and conditions herein, SitusAMC hereby grants you a
        limited, revocable, terminable, non-sublicensable, non-exclusive, and
        non-transferable right and license to access and use the Solution for
        your internal use. You assume the sole responsibility and liability as
        to your use of the Solution, including, but not limited to, verifying
        that the Solution is usable and meets your requirements, and ensuring
        the accuracy of any User Data you provide in the course of accessing or
        using the Solution. You shall comply with all Applicable Laws and these
        Terms of Use in connection with your access to and use of the Solution.
        We may revoke your license at any time in our sole discretion. Upon such
        revocation, you must promptly destroy all content downloaded or
        otherwise obtained through the Solution, as well as copies of such
        materials, whether made in accordance with these Terms of Use or
        otherwise.
      </div>

      <NumberedLabel
        order="b."
        label="Restrictions and Prohibited Conduct."
        indented
      />
      <div style={subIndent}>
        You shall not, either directly or indirectly: (a) transfer, distribute,
        sell, lease, license, display, assign, disclose, permit time-sharing of
        (such as by sharing your Credentials with another individual),
        commercially exploit, or otherwise make any aspect or portion of the
        Solution available to a third party; (b) reproduce, copy, monitor
        (manually or by utilizing any robot, spider or other automatic device),
        translate, download, modify, adapt, decompile, disassemble, create
        derivative works of, or reverse engineer (except as allowed under
        Applicable Laws) all or any part of the Solution (including the object
        code version of or otherwise attempt to secure the source code of all or
        any part of the Solution) or access the Solution to build a similar or
        competitive product or service, except strictly as and to the extent
        expressly authorized by these Terms of Use and Applicable Laws; (c)
        obfuscate, remove or alter any of the logos, trademarks, Internet links,
        patent or copyright notices, confidentiality or proprietary legends or
        other notices or markings that are on or in the Solution; (d) take any
        action that imposes an unreasonable or disproportionately large load on
        the infrastructure that hosts the Solution; (e) use any device,
        software, or in any other way interfere with the proper working,
        functionality, or quality of the Solution, including, but not limited
        to, by introducing any viruses, Trojan horses, or other malware; (f)
        attempt to gain unauthorized access to, interfere with, damage or
        disrupt any parts of the Solution or its infrastructure; (g) modify,
        alter any downloaded documents downloaded from the Solution, nor make
        any representation or warranties related to said documents; (h) modify
        the Solution or remove any proprietary rights, notices, or markings
        therefrom; (i) except as permitted by Applicable Laws, reverse engineer
        any aspect of our Solution or do anything that might discover or reveal
        source code, or bypass or circumvent measures employed to prevent or
        limit access to any part of our Solution; (j) use any data mining,
        robots, or similar data gathering or extraction methods designed to
        scrape or extract data from our Solution except in accordance with
        instructions contained in our robot.txt file and only to compile for
        search results; (k) impersonate or post on behalf of any person or
        entity or otherwise misrepresent your affiliation with a person or
        entity; (l) use or attempt to use another user's account or Credentials
        without authorization from that user and from SitusAMC; (m) engage in
        any harassing, threatening, intimidating, predatory, or stalking
        conduct; or (n) access or use the Solution in violation of Applicable
        Laws or these Terms of Use or for any illegal or unauthorized purpose,
        or engage in, encourage or promote any activity that in any way that is
        inconsistent with the terms herein, or for any other purpose other than
        the Approved Purpose.
      </div>
      <div style={subIndent}>
        If your Solution includes access to a data room and/or the capability to
        share information in or through the Solution, the following additional
        terms shall apply: (1) content, including User Data, available via the
        Solution may be protected by others' intellectual property rights, so
        please do not copy, upload, download, or share content unless you have
        the express right to do so; (2) SitusAMC may review your conduct and
        User Data for compliance with these Terms; (3) SitusAMC is not
        responsible for the content or User Data posted and shared via the
        Solution; and (4) you may use the Solution only in compliance with
        Applicable Laws and these Terms.
      </div>

      <NumberedLabel order="c." label="Reservation of Rights." indented />
      <div style={subIndent}>
        You understand and agree that you do not acquire any ownership interest
        in the Solution under these Terms of Use, or any other rights to the
        Solution other than to use the Solution for the Approved Purpose,
        subject to all terms, conditions, and restrictions set forth herein.
        SitusAMC reserves and shall retain all right, title, and interest in and
        to the Solution and all intellectual property rights arising out of or
        relating to the Solution, subject to the licenses expressly granted
        herein. Additionally, we reserve the right to terminate your access to
        or use of the Solution immediately and take any other legal action if
        you, or anyone using your account, violates Applicable Laws or any of
        the terms herein. We may pursue any legal and/or technical remedies to
        prevent the violation of this Section 6 and to enforce these Terms of
        Use. SitusAMC and our logos, product or service names, slogans, and the
        look and feel of the Solution are trademarks of SitusAMC and may not be
        copied, imitated or used, in whole or in part, without our prior written
        permission. All other trademarks, registered trademarks, product names,
        and company names or logos mentioned on or in connection with the
        Solution are the property of their respective owners. Reference to any
        products, services, processes, or other information by trade name,
        trademark, manufacturer, supplier, or otherwise does not constitute or
        imply endorsement, sponsorship, or recommendation by us.
      </div>

      <NumberedLabel
        order="d."
        label="Repeat Infringer Policy; Copyright Complaints"
        indented
      />
      <div style={subIndent}>
        <ul
          style={{
            listStyle: 'lower-alpha',
            paddingLeft: '42px',
            paddingBottom: '5px',
          }}
        >
          <li>
            Our Policy. In accordance with the Digital Millennium Copyright Act
            ("<span style={bold}>DMCA</span>") and other Applicable Laws, we
            have adopted a policy of terminating, in appropriate circumstances,
            the accounts of users who repeatedly infringe the intellectual
            property rights of others (our "
            <span style={bold}>DMCA Policy</span>").
          </li>
          <li style={pPadding}>
            <div>
              Reporting Claims of Copyright Infringement. If you believe that
              any content on our Solution infringe any copyright that you own or
              control, you may notify our designated agent (your notification, a
              "<span style={bold}>DMCA Notice</span>") as follows:
            </div>
            <div
              style={{
                ...subIndent,
                paddingTop: '10px',
              }}
            >
              <div style={pPadding}>Designated Agent: General Counsel</div>
              <div style={pPadding}>
                Address: 12 East 49th Street, 34th Floor
              </div>
              <div style={pPadding}>New York, NY 10017</div>
              <div style={pPadding}>Telephone Number: 212-294-1300</div>
              <div style={pPadding}>Email Address: legal@situsamc.com</div>
            </div>
            <div
              style={{
                paddingTop: '15px',
              }}
            >
              Please see Section 512(c)(3) of the DMCA for the requirements of a
              proper notification. If you fail to comply with all of the
              requirements of Section 512(c)(3) of the DMCA, your notice may not
              be effective. If you knowingly materially misrepresent that any
              activity or material on our Solution is infringing, you may be
              liable to SitusAMC for certain costs and damages.
            </div>
          </li>
        </ul>
      </div>

      <NumberedLabel
        order="7."
        label="Disclaimer and Limitation of Liability."
      />
      <div style={{ ...indent, ...bold }}>
        TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAWS AND EXCEPT AS
        OTHERWISE EXPRESSLY PROVIDED HEREIN, YOUR USE OF THE SOLUTION AND ANY
        OTHER MATERIALS OR INFORMATION OBTAINED THROUGH THE SOLUTION IS AT YOUR
        OWN RISK. THE SOLUTION AND ANY MATERIALS OR INFORMATION OBTAINED THROUGH
        THE SOLUTION (INCLUDING THIRD-PARTY CONTENT, THIRD-PARTY MATERIALS, USER
        DATA, AND CUSTOMER INFORMATION) ARE PROVIDED ON AN "AS IS" AND "AS
        AVAILABLE" BASIS, WITHOUT ANY REPRESENTATIONS, GUARANTEES, ASSURANCES,
        OR WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, STATUTORY, OR
        OTHERWISE, INCLUDING, BUT NOT LIMITED TO, ANY WARRANTIES OF
        MERCHANTABILITY, NON- INFRINGEMENT OF THIRD-PARTY RIGHTS, TITLE, FITNESS
        FOR PARTICULAR PURPOSE, COURSE OF DEALING, OR USAGE OF TRADE. THE
        FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR
        LIMITED UNDER APPLICABLE LAWS. NEITHER SITUSAMC NOR ANY PERSON
        ASSOCIATED WITH SITUSAMC MAKES ANY WARRANTY OR REPRESENTATION: (I) WITH
        RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY,
        OR AVAILABILITY OF THE SOLUTION OR ANY MATERIALS OR INFORMATION OBTAINED
        THROUGH THE SOLUTION (INCLUDING THIRD-PARTY CONTENT, THIRD-PARTY
        MATERIALS, AND USER DATA, AND CUSTOMER INFORMATION); OR (II) THAT THE
        SOLUTION OR ANY MATERIALS OR INFORMATION OBTAINED THROUGH THE SOLUTION
        (INCLUDING THIRD-PARTY CONTENT, THIRD-PARTY MATERIALS, USER DATA, AND
        CUSTOMER INFORMATION) WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS. WE
        DO NOT UNDERTAKE TO CORRECT OR NOTIFY YOU OF ANY ERRORS OR OMISSIONS IN
        THE INFORMATION DISPLAYED ON OR THROUGH THE SOLUTION OF WHICH IT MAY
        BECOME AWARE AT ANY TIME OR TO NOTIFY YOU OF ANY CHANGES IN ANY
        INFORMATION OR METHODOLOGIES INCORPORATED IN SUCH INFORMATION.
      </div>
      <div style={{ ...indent, ...bold }}>
        SITUSAMC FURTHER DISCLAIMS ALL RESPONSIBILITY, AND YOU HEREBY RELEASE
        SITUSAMC AND THEIR AFFILIATES AND EACH OF THEIR DIRECTORS, OFFICERS,
        EMPLOYEES, AGENTS, ADVISORS, AND REPRESENTATIVES, FROM ANY LOSS, INJURY,
        CLAIM, LIABILITY, OR DAMAGE OF ANY KIND RESULTING FROM, ARISING OUT OF,
        OR IN ANY WAY RELATED TO (A) ANY THIRD-PARTY MATERIALS OR THIRD-PARTY
        CONTENT THEREIN DIRECTLY OR INDIRECTLY ACCESSED THROUGH LINKS PROVIDED
        IN OR IN CONNECTION WITH THE SOLUTION, INCLUDING BUT NOT LIMITED TO ANY
        ERRORS IN OR OMISSIONS THEREFROM; (B) YOUR USE OF, OR INABILITY TO USE,
        THE SOLUTION OR THE INFORMATION ACCESSIBLE THEREFROM OR ANY DECISION
        MADE USING THE SOLUTION; (C) UNAUTHORIZED ACCESS TO THE SOLUTION OR
        INFORMATION PROVIDED THEREIN; OR (D) YOUR USE OF ANY HARDWARE, EQUIPMENT
        OR SOFTWARE IN CONNECTION WITH THE SOLUTION OR THE INFORMATION
        ACCESSIBLE THEREFROM. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN,
        OBTAINED BY YOU FROM SITUSAMC OR THROUGH OR FROM THE SOLUTION SHALL
        CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THESE TERMS.
      </div>
      <div style={{ ...indent, ...bold }}>
        TO THE EXTENT PERMITTED UNDER APPLICABLE LAWS, YOU EXPRESSLY AND
        IRREVOCABLY WAIVE ANY IMPLIED WARRANTIES OR SIMILAR PROTECTIONS UNDER
        STATUTE THAT MIGHT BE CLAIMED TO APPLY TO ANY PART OF THE SOLUTION.
      </div>
      <div style={{ ...indent, ...bold }}>
        TO THE EXTENT PERMITTED UNDER APPLICABLE LAWS, SITUSAMC SHALL NOT BE
        LIABLE FOR ANY DAMAGES, WHETHER DIRECT, INDIRECT, INCIDENTAL,
        CONSEQUENTIAL, EXEMPLARY, SPECIAL OR ANY OTHER FORM (INCLUDING WITHOUT
        LIMITATION, LOST PROFITS OR LOSS OF GOODWILL), REGARDLESS OF THE FORM OF
        ACTION OR THE THEORY OF LIABILITY (WHETHER BASED IN CONTRACT, TORT,
        NEGLIGENCE, STRICT LIABILITY, WARRANTY, OR OTHERWISE), AND EVEN IF
        SITUSAMC HAS KNOWLEDGE OF THE POSSIBILITY OF SUCH DAMAGES, RESULTING
        FROM ANY DATA BREACH, CYBERATTACK, SYSTEMS SECURITY BREACH OR DIGITAL
        INFILTRATION OF OR AGAINST SITUSAMC, ITS SUBCONTRACTORS OR INDEPENDENT
        CONTRACTORS.
      </div>
      <div style={{ ...indent, ...bold }}>
        NOTWITHSTANDING ANY OTHER PROVISIONS OF THESE TERMS OF USE AND WITHOUT
        LIMITING THE FOREGOING, AND EXCEPT FOR SITUSAMC'S WILLFUL MISCONDUCT,
        THE MAXIMUM AGGREGATE LIABILITY OF SITUSAMC AND OUR AFFILIATES UNDER THE
        TERMS OF USE WILL NOT EXCEED U.S. $500. IN STATES WHERE SUCH LIMITATIONS
        ON LIABILITY ARE NOT PERMITTED, SITUSAMC'S AND OUR AFFILIATES' LIABILITY
        SHALL BE LIMITED TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAWS.
      </div>
      <div style={{ ...indent, ...bold }}>
        SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR
        THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL
        DAMAGES. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS OF SECTION 7 MAY NOT
        APPLY TO YOU.
      </div>

      <NumberedLabel order="8." label="Indemnification." />
      <div style={indent}>
        To the fullest extent permitted by Applicable Laws, you agree to
        indemnify, defend, and hold SitusAMC and our affiliates and respective
        directors, officers, employees, agents, advisors and representatives
        (the "<span style={bold}>SitusAMC Parties</span>
        ") harmless from and against any and all third party claims, demands and
        assessments, judgments, liabilities, losses, costs, damages and expenses
        including, without limitation, interest, penalties, reasonable
        attorneys' fees, expenses, and disbursements incurred in connection with
        any action, suit or proceeding or incurred in enforcing any right of
        indemnification against you, that arise out of or result from: (a) your
        breach of these Terms of Use or of our Privacy Policy or the inaccuracy
        of any of your covenants, agreements, representations, or warranties
        under these Terms of Use or in the documents they incorporate by
        reference (including, but not limited to, our{' '}
        <a href="https://www.situsamc.com/privacy-policy">Privacy Policy</a>
        ), (b) any user contribution, trademarks, other source identifiers, User
        Data, or Feedback you enter into the Solution, (c) your violation,
        misappropriation, or infringement of any Applicable Laws or intellectual
        property, privacy, or other rights of a third party; (d) your access to
        or use of, or your inability to access or use, any part of the Solution
        or the User Data therein or your conduct in connection with the
        Solution; (e) your violation of any Applicable Laws; or (f) the actions
        of any persons, authorized or unauthorized, who gained access to the
        Solution through your Credentials.
      </div>

      <NumberedLabel order="9." label="Release." />
      <div style={indent}>
        To the fullest extent permitted by Applicable Laws, you release{' '}
        <span style={bold}>SitusAMC</span> from responsibility, liability,
        claims, demands and/or damages (actual and consequential) of every kind
        and nature, known and unknown (including claims of negligence), arising
        out of or related to disputes between users and the acts or omissions of
        third parties. If you are a consumer who resides in California, you
        hereby waive your rights under California Civil Code § 1542, which
        provides: "A general release does not extend to claims which the
        creditor does not know or suspect to exist in his or her favor at the
        time of executing the release, which if known by him or her must have
        materially affected his or her settlement with the debtor."
      </div>

      <NumberedLabel
        order="10."
        label="Dispute Resolution; Binding Arbitration."
      />
      <div style={{ ...indent, ...bold }}>
        PLEASE READ THE FOLLOWING SECTION CAREFULLY BECAUSE IT REQUIRES YOU TO
        ARBITRATE CERTAIN DISPUTES AND CLAIMS WITH SITUSAMC AND LIMITS THE
        MANNER IN WHICH YOU CAN SEEK RELIEF FROM US, UNLESS YOU OPT OUT OF
        ARBITRATION BY FOLLOWING THE INSTRUCTIONS SET FORTH BELOW. NO CLASS OR
        REPRESENTATIVE ACTIONS OR ARBITRATIONS ARE ALLOWED UNDER THIS
        ARBITRATION PROVISION. IN ADDITION, ARBITRATION PRECLUDES YOU FROM SUING
        IN COURT OR HAVING A JURY TRIAL.
      </div>

      <NumberedLabel order="a." label="No Representative Actions." indented />
      <div style={subIndent}>
        You and SitusAMC agree that any dispute arising out of or related to
        these Terms of Use or our Solution is personal to you and SitusAMC and
        that any dispute will be resolved solely through individual action, and
        will not be brought as a class arbitration, class action, or any other
        type of representative proceeding.
      </div>

      <NumberedLabel order="b." label="Arbitration of Disputes." indented />
      <div style={subIndent}>
        Except for small claims disputes in which you or SitusAMC seeks to bring
        an individual action in small claims court located in the county of your
        billing address or disputes in which you or SitusAMC seeks injunctive or
        other equitable relief for the alleged infringement or misappropriation
        of intellectual property,{' '}
        <span style={bold}>
          YOU AND SITUSAMC WAIVE YOUR RIGHTS TO A JURY TRIAL AND TO HAVE ANY
          OTHER DISPUTE ARISING OUT OF OR RELATED TO THESE TERMS OF USE OR OUR
          SOLUTION, INCLUDING CLAIMS RELATED TO PRIVACY AND DATA SECURITY,
          (COLLECTIVELY, "DISPUTES") RESOLVED IN COURT
        </span>
        . Instead, for any Dispute that you have against SitusAMC you agree to
        first contact SitusAMC and attempt to resolve the claim informally by
        sending a written notice of your claim ("
        <span style={bold}>Notice</span>") to SitusAMC by email at{' '}
        <a href="mailto:termsofuse@situsamc.com">termsofuse@situsamc.com</a> or
        by certified mail addressed to SitusAMC, 150 East 52nd Street, Suite
        4002, New York, NY 10022, Attn: General Counsel. The Notice must (a)
        include your name, residence address, email address, and telephone
        number; (b) describe the nature and basis of the Dispute; and (c) set
        forth the specific relief sought. Our notice to you will be similar in
        form to that described above. If you and SitusAMC cannot reach an
        agreement to resolve the Dispute within thirty (30) days after such
        Notice is received, then either party may submit the Dispute to binding
        arbitration administered by JAMS or, under the limited circumstances set
        forth above, in court. All Disputes submitted to JAMS will be resolved
        through confidential, binding arbitration before one arbitrator.
        Arbitration proceedings will be held in New York, New York unless you
        are a consumer, in which case you may elect to hold the arbitration in
        your county of residence. For purposes of this Section 10, a "consumer"
        means a person using the Solution for personal, family or household
        purposes. You and SitusAMC agree that Disputes will be held in
        accordance with the JAMS Streamlined Arbitration Rules and Procedures ("
        <span style={bold}>JAMS Rules</span>"). The most recent version of the
        JAMS Rules are available on the JAMS website and are hereby incorporated
        by reference. You either acknowledge and agree that you have read and
        understand the JAMS Rules or waive your opportunity to read the JAMS
        Rules and waive any claim that the JAMS Rules are unfair or should not
        apply for any reason.
      </div>
      <div style={subIndent}>
        You and SitusAMC agree that these Terms of Use affect interstate
        commerce and that the enforceability of this Section 10 will be
        substantively and procedurally governed by the Federal Arbitration Act,
        9 U.S.C. § 1, et seq. (the "<span style={bold}>FAA</span>"), to the
        maximum extent permitted by Applicable Laws. As limited by the FAA,
        these Terms of Use and the JAMS Rules, the arbitrator will have
        exclusive authority to make all procedural and substantive decisions
        regarding any Dispute and to grant any remedy that would otherwise be
        available in court, including the power to determine the question of
        arbitrability. The arbitrator may conduct only an individual arbitration
        and may not consolidate more than one individual's claims, preside over
        any type of class or representative proceeding or preside over any
        proceeding involving more than one individual.
      </div>
      <div style={subIndent}>
        The arbitration will allow for the discovery or exchange of
        non-privileged information relevant to the Dispute. The arbitrator,
        SitusAMC, and you will maintain the confidentiality of any arbitration
        proceedings, judgments, and awards, including information gathered,
        prepared, and presented for purposes of the arbitration or related to
        the Dispute(s) therein. The arbitrator will have the authority to make
        appropriate rulings to safeguard confidentiality, unless the law
        provides to the contrary. The duty of confidentiality does not apply to
        the extent that disclosure is necessary to prepare for or conduct the
        arbitration hearing on the merits, in connection with a court
        application for a preliminary remedy or in connection with a judicial
        challenge to an arbitration award or its enforcement, or to the extent
        that disclosure is otherwise required by law or judicial decision.
      </div>
      <div style={subIndent}>
        You and SitusAMC agree that for any arbitration you initiate, you will
        pay the filing fee (up to a maximum of $250 if you are a consumer), and
        SitusAMC will pay the remaining JAMS fees and costs. For any arbitration
        initiated by SitusAMC, SitusAMC will pay all JAMS fees and costs. You
        and SitusAMC agree that the state or federal courts of the State of New
        York and the United States sitting in New York, New York have exclusive
        jurisdiction over any appeals and the enforcement of an arbitration
        award.
      </div>
      <div style={{ ...subIndent, ...bold }}>
        ANY DISPUTE MUST BE FILED WITHIN ONE YEAR AFTER THE RELEVANT CLAIM
        AROSE; OTHERWISE, THE DISPUTE IS PERMANENTLY BARRED, WHICH MEANS THAT
        YOU AND SITUSAMC WILL NOT HAVE THE RIGHT TO ASSERT THE CLAIM.
      </div>
      <div style={subIndent}>
        <span style={bold}>
          YOU HAVE THE RIGHT TO OPT OUT OF BINDING ARBITRATION WITHIN 30 DAYS OF
          THE DATE YOU FIRST ACCEPTED THE TERMS OF THIS SECTION 10 BY EMAILING{' '}
          <a href="mailto:termsofuse@situsamc.com">TERMSOFUSE@SITUSAMC.COM</a>.
        </span>
        &nbsp; In order to be effective, the opt-out notice must include your
        full name and address and clearly indicate your intent to opt out of
        binding arbitration. By opting out of binding arbitration, you are
        agreeing to resolve Disputes in accordance with Section 11.2 (Governing
        Law; Jurisdiction and Venue).
      </div>
      <div style={subIndent}>
        If any portion of this Section 10 is found to be unenforceable or
        unlawful for any reason, (a) the unenforceable or unlawful provision
        shall be severed from these Terms of Use; (b) severance of the
        unenforceable or unlawful provision shall have no impact whatsoever on
        the remainder of this Section 10 or the parties' ability to compel
        arbitration of any remaining claims on an individual basis pursuant to
        this Section 10; and (c) to the extent that any claims must therefore
        proceed on a class, collective, consolidated, or representative basis,
        such claims must be litigated in a civil court of competent jurisdiction
        and not in arbitration, and the parties agree that litigation of those
        claims shall be stayed pending the outcome of any individual claims in
        arbitration. Further, if any part of this Section 10 is found to
        prohibit an individual claim seeking public injunctive relief, that
        provision will have no effect to the extent such relief is allowed to be
        sought out of arbitration, and the remainder of this Section 10 will be
        enforceable.
      </div>

      <NumberedLabel order="11." label="Miscellaneous." />
      <NumberedLabel order="a." label="Force Majeure." indented />
      <div style={subIndent}>
        No Party shall be liable for any failure or delay in the performance of
        its obligations, due to fire, flood, earthquake, elements of nature or
        acts of God, acts of war, terrorism, riots, civil disorder, rebellions,
        nor other similar cause beyond the reasonable control of such Party.
      </div>

      <NumberedLabel
        order="b."
        label="Governing Law; Jurisdiction and Venue."
        indented
      />
      <div style={subIndent}>
        This Terms of Use shall be governed by and construed in accordance with
        the laws of the State of New York, without reference to the principles
        of conflicts of law that would apply the substantive laws of another
        jurisdiction. Each Party hereby submits to the exclusive jurisdiction
        and venue of the state and federal courts located in New York, New York,
        over any dispute arising out of or relating to this Terms of Use and
        waives the right to object to such venue or make a claim of{' '}
        <span style={italic}>forum non conveniens</span>. Notwithstanding
        anything herein to the contrary, each Party shall be entitled to seek
        injunctive or equitable relief whenever the circumstances permit such
        Party to seek such equitable relief in a court of competent
        jurisdiction. You must bring any cause of action arising out of, or in
        connection with, this Terms of Use within one year of when the alleged
        breach occurred.
      </div>

      <NumberedLabel order="c." label="Relationship of the Parties." indented />
      <div style={subIndent}>
        The Parties shall at all times be independent contractors with respect
        to each other in carrying out this Terms of Use, and nothing herein
        renders them partners, joint venturers, agents, or employer and
        employee.
      </div>

      <NumberedLabel
        order="d."
        label="No Third-Party Beneficiaries."
        indented
      />
      <div style={subIndent}>
        The Parties do not confer any rights or remedies upon any person or
        entity other than the Parties to this Terms of Use and their respective
        successors and permitted assigns. Use of the Solution to view data or
        results which may reside within the Solution shall not, in any way,
        create or otherwise confer any rights or title or ownership interest in
        such data is it relates to the user; instead data and results which may
        reside within the Solution are and remain the property of any Party or
        third-party entity that provided such data to the Solution, or whose
        ownership or usage is contemplated under a separate third-party
        agreement.
      </div>

      <NumberedLabel order="e." label="Modifications; Waiver." indented />
      <div style={subIndent}>
        This Terms of Use may be modified by SitusAMC at any time in its sole
        discretion. We will use commercially reasonable efforts to notify you
        electronically or otherwise of any material modification that affects
        you, and the date set forth above on the copy of this Terms of Use will
        display the new revision date. You are responsible for being familiar
        with, and by continuing to use the Solution you agree to be bound by
        this Terms of Use, as it may be revised from time to time. The Parties
        expressly disclaim the right to claim the enforceability of any oral
        modifications to this Terms of Use or any amendments based on course of
        dealing, waiver, reliance, estoppel or other similar legal theory. No
        delay or omission by either Party to exercise any right occurring upon
        any non-compliance or default of the other Party regarding any of the
        terms herein shall impair any such right or be construed to be a waiver
        thereof.
      </div>

      <NumberedLabel order="f." label="Termination." indented />
      <div style={subIndent}>
        You agree that SitusAMC may, at any time and at our sole discretion,
        with or without cause or any notice to you, terminate these Terms of
        Use, your access to the Solution, and/or your Credentials, or suspend or
        block your access to the Solution. Any suspected fraudulent, abusive, or
        illegal activity that may be grounds for termination of your use of
        service may be referred to appropriate law enforcement authorities. If
        your access to the Solution is terminated, you may no longer have access
        to the copies of material or other User Data available through the
        Solution. SitusAMC may also in our sole discretion and at any time
        discontinue providing the Solution, or any part thereof, with or without
        notice. You agree that any termination of your access to the Solution
        under any provision of these Terms may be affected without prior notice
        and acknowledge and agree that SitusAMC may immediately deactivate or
        delete any account you may have and all related information and files in
        that account and/or bar any further access to such files or the
        Solution. Further, you agree that SitusAMC shall not be liable to you or
        any third-party for any termination of your access to the Solution. For
        the avoidance of doubt, our license rights to User Data will survive any
        termination of these Terms of Use and will not be limited, restricted,
        reduced or otherwise negatively affected by any termination or other
        action described in this paragraph.
      </div>

      <NumberedLabel order="g." label="Survival." indented />
      <div style={subIndent}>
        Any provision herein that contemplates performance or observance
        subsequent to any expiration or termination of this Terms of Use, or
        which is otherwise necessary to interpret the respective rights and
        obligations of the Parties hereunder, shall survive any expiration or
        termination of this Terms of Use and continue in full force and effect.
      </div>

      <NumberedLabel order="h." label="Headings." indented />
      <div style={subIndent}>
        The headings of the various sections herein are for convenience of
        reference only and shall not affect the construction or interpretation
        of this Terms of Use.
      </div>

      <NumberedLabel
        order="i."
        label="Incorporation of Disclaimers."
        indented
      />
      <div style={subIndent}>
        This Terms of Use incorporates all disclaimers and notices appearing on
        any portion of the Solution. Such disclaimers and notices may be
        augmented, modified or removed and/or additional disclaimers or notices
        added by SitusAMC without further notice to you.
      </div>

      <NumberedLabel order="j." label="Severability." indented />
      <div style={subIndent}>
        If any provision of this Terms of Use is determined by a court of
        competent jurisdiction to be invalid or unenforceable under applicable
        law, such provision shall be amended and interpreted to accomplish the
        objectives of such provision to the greatest extent possible under
        applicable law, and the remaining provisions of this Terms of Use shall
        continue in full force and effect.
      </div>

      <NumberedLabel order="12." label="Assignment." />
      <div style={indent}>
        You may not transfer or assign this Terms of Use.
      </div>

      <NumberedLabel order="13." label="Entire Agreement." />
      <div style={indent}>
        This Terms of Use supersede all prior versions of this document,
        constitutes the sole and entire agreement among you, SitusAMC and also
        supersede all prior and contemporaneous understandings, agreements,
        representations, and warranties, both written and oral, regarding the
        Solution, unless you or your Firm has entered into a signed written
        agreement with us.
      </div>

      <NumberedLabel order="14." label="Questions or Comments." />
      <div style={indent}>
        If you have any questions or comments about this Terms of Use, please
        email{' '}
        <a href="mailto:termsofuse@situsamc.com">termsofuse@situsamc.com</a>.
      </div>
    </div>
  );
}

export default TermsOfUseContent;
