import React from 'react';
import { setTruncatedValue } from '../../../../utils/helpers';
import { Container, Label, Value as StyledValue } from './styles';

export interface PanelItemProps {
  children: React.ReactNode;
}

function PanelItem({ children }: PanelItemProps) {
  return <Container>{children}</Container>;
}

export interface PanelItemValueProps {
  children: React.ReactNode;
  maxLength?: number;
}

function Value({ children, maxLength }: PanelItemValueProps) {
  let value;
  if (typeof children === 'string' && maxLength) {
    value = setTruncatedValue(children, maxLength);
  } else {
    value = children;
  }

  return <StyledValue>{value}</StyledValue>;
}

PanelItem.Label = Label;
PanelItem.Value = Value;

export default PanelItem;
