import React from 'react';

const TokenContext = React.createContext<string | null | undefined>(undefined);

function useToken() {
  const context = React.useContext(TokenContext);
  if (context === undefined) {
    throw new Error('useToken must be used within a TokenProvider!');
  }
  return context;
}

export { TokenContext, useToken };
