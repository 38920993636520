/* eslint-disable no-underscore-dangle */
import * as Comlink from 'comlink';
import { AccessToken } from '@okta/okta-auth-js';
import { v4 as uuidv4 } from 'uuid';
import { AuthenticationConfig } from './models';

export class AuthenticationWatcher {
  /**
   * The shared worker that allows use to communicate between windows without an issue
   */
  private readonly _worker: SharedWorker;

  private _authenticationContext: any;

  private _uuid: string;

  private _logoutWarningCallback?: ReturnType<
    typeof Comlink.proxy<(visibility: boolean) => void>
  >; // (visibility: boolean) => void;

  private _renewTokensCallback?: () => void;

  private _removeTokensCallback?: () => void;

  private _sessionKeepAliveCallback?: () => void;

  public constructor() {
    this._worker = new SharedWorker(
      new URL('authentication_worker.js', import.meta.url),
      { name: 'auth-authentication-okta', type: 'module' },
    );
    this._authenticationContext = Comlink.wrap(this._worker.port);
    this._uuid = uuidv4();
  }

  public init(config: AuthenticationConfig) {
    this._authenticationContext.init(config);
  }

  public setShowLogoutWarning(show: boolean) {
    this._authenticationContext.setShowLogoutWarning(show);
  }

  public setToken(accessToken: AccessToken | undefined | null) {
    this._authenticationContext.setToken(accessToken);
  }

  public setLastInteractionTime(lastInteractionTime: number) {
    this._authenticationContext.setLastInteractionTime(lastInteractionTime);
  }

  public onLogoutWarning(
    setLogoutWarningVisibility: (visibility: boolean) => void,
  ) {
    this._logoutWarningCallback = Comlink.proxy(setLogoutWarningVisibility);
    this._authenticationContext.addLogoutWarningListener(
      this._uuid,
      this._logoutWarningCallback,
    );
  }

  public async offLogoutWarning() {
    if (!this._logoutWarningCallback) return;

    await this._authenticationContext.removeLogoutWarningListener(this._uuid);
    // @ts-expect-error - Comlink release proxy is not typed
    await this._logoutWarningCallback[Comlink.releaseProxy]();
  }

  public onRenewTokens(renewTokens: () => void) {
    this._renewTokensCallback = Comlink.proxy(renewTokens);
    this._authenticationContext.addRenewTokensListener(
      this._uuid,
      this._renewTokensCallback,
    );
  }

  public async offRenewTokens() {
    if (!this._renewTokensCallback) return;

    await this._authenticationContext.removeRenewTokensListener(this._uuid);
    // @ts-expect-error - Comlink release proxy is not typed
    await this._renewTokensCallback[Comlink.releaseProxy]();
  }

  public onRemoveTokens(removeTokens: () => void) {
    this._removeTokensCallback = Comlink.proxy(removeTokens);
    this._authenticationContext.addRemoveTokensListener(
      this._uuid,
      this._removeTokensCallback,
    );
  }

  public async offRemoveTokens() {
    if (!this._removeTokensCallback) return;

    await this._authenticationContext.removeRemoveTokensListener(this._uuid);
    // @ts-expect-error - Comlink release proxy is not typed
    await this._removeTokensCallback[Comlink.releaseProxy]();
  }

  public onSessionKeepAlive(sessionKeepAlive: () => void) {
    this._sessionKeepAliveCallback = Comlink.proxy(sessionKeepAlive);
    this._authenticationContext.addSessionKeepAliveListener(
      this._uuid,
      this._sessionKeepAliveCallback,
    );
  }

  public async offSessionKeepAlive() {
    if (!this._sessionKeepAliveCallback) return;

    await this._authenticationContext.removeSessionKeepAliveListener(
      this._uuid,
    );
    // @ts-expect-error - Comlink release proxy is not typed
    await this._sessionKeepAliveCallback[Comlink.releaseProxy]();
  }

  public async dispose() {
    if (!this._authenticationContext) return;

    await this._authenticationContext[Comlink.releaseProxy]();
  }
}
