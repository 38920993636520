import { AxiosError } from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import api from '../services/api';

export type DisputeReason = { id: string; reason: string };

export const getDisputeReasons = async (): Promise<DisputeReason[]> => {
  const response = await api.get('/DisputeReason');
  return response.data.disputeReasons;
};

export const useDisputeReasons = (): UseQueryResult<
  DisputeReason[],
  AxiosError<ErrorData>
> => {
  return useQuery('disputeReasons', getDisputeReasons, {
    staleTime: 0,
    cacheTime: 0,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
};
