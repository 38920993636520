import React from 'react';
import { ActionTypes } from '../types';
import { UseAppraisalActions, AppraisalLoan } from './types';

interface Props {
  dispatch: (action: ActionTypes) => void;
}

export default function useApraisalActions({
  dispatch,
}: Props): UseAppraisalActions {
  const onChangeLoanId = React.useCallback(
    loanId => {
      dispatch({
        type: 'SET_LOAN_ID',
        payload: { loanId },
      });
    },
    [dispatch],
  );

  const onChangeLoanDetails = React.useCallback(
    (orderId: string, loan: Omit<AppraisalLoan, 'clientLoanId'>) => {
      dispatch({
        type: 'SET_LOAN_DETAILS',
        payload: { orderId, loan },
      });
    },
    [dispatch],
  );

  const onChangeLoanStaging = React.useCallback(
    (stagingId: string, loan: Omit<AppraisalLoan, 'clientLoanId'>) => {
      dispatch({
        type: 'SET_LOAN_STAGING_DETAILS',
        payload: { stagingId, loan },
      });
    },
    [dispatch],
  );

  const onClearDocuments = React.useCallback(() => {
    dispatch({
      type: 'CLEAR_DOCUMENTS',
    });
  }, [dispatch]);

  const onDeleteDocument = React.useCallback(
    (documentId: string) => {
      dispatch({
        type: 'REMOVE_DOCUMENT',
        payload: { documentId },
      });
    },
    [dispatch],
  );

  return {
    onChangeLoanId,
    onChangeLoanDetails,
    onChangeLoanStaging,
    onClearDocuments,
    onDeleteDocument,
  };
}
