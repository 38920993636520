import React from 'react';
import { useAuthenticationConfig } from '../contexts';
import Modal from './Modal';

type LogoutWarningModalProps = {
  children?: React.ReactNode;
  description: string;
  isVisible: boolean;
  showActions: boolean;
  handleLogout: React.MouseEventHandler<HTMLDivElement>;
  handleStayConnected: React.MouseEventHandler<HTMLDivElement>;
};

function LogoutWarningModal(props: LogoutWarningModalProps) {
  const {
    children,
    showActions,
    description,
    isVisible,
    handleLogout,
    handleStayConnected,
  } = props;

  const config = useAuthenticationConfig();

  return (
    <>
      {children}
      {config.showLogoutWarning && (
        <Modal
          title="Are you still there?"
          showButtons={showActions}
          description={description}
          firstOptionText="Logout"
          firstOptionHandler={handleLogout}
          isVisible={isVisible}
          secondOptionText="Stay Connected"
          secondOptionHandler={handleStayConnected}
        />
      )}
    </>
  );
}

export default LogoutWarningModal;
