import { DefaultTheme } from 'styled-components';

export const defaultTheme: DefaultTheme = {
  fontFamily: {
    heading: 'Source Sans Pro, sans-serif',
    main: 'Source Sans Pro, sans-serif',
  },
  fontSize: {
    xs: '10px',
    sm: '12px',
    base: '14px',
    lg: '16px',
    xl: '18px',
    xl2: '20px',
    xl3: '22px',
  },
  spacing: {
    xs: '5px',
    sm: '10px',
    base: '14px',
    lg: '20px',
    xl: '30px',
  },
  colors: {
    primary: '#133D91',
    darkBlue: '#163f75',
    lightBlue: '#163f75',
    red: '#BD0E08',
    dark: '#000000',
    background: '#f7f7f7',
  },
  animations: {
    button: 'background-color 0.3s ease',
    link: 'color 0.2 ease',
  },
  shadows: {
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
  },
};
