import * as React from 'react';
import { Button, Modal } from '..';
import { Variant } from '../Button/styles';
import { Row } from './styles';

export interface ConfirmModalProps {
  isModalOpen: boolean;
  title?: string;
  details: string;
  cancelText?: string;
  cancelVariant?: Variant;
  handleCancel: () => void;
  confirmText?: string;
  confirmVariant?: Variant;
  handleConfirm: () => void;
  width?: number;
  height?: number;
}

function ConfirmModal({
  isModalOpen,
  title,
  details,
  cancelText,
  cancelVariant,
  handleCancel,
  confirmText,
  confirmVariant,
  handleConfirm,
  width,
  height,
}: ConfirmModalProps) {
  return (
    <Modal
      isOpen={isModalOpen}
      onDismiss={handleCancel}
      width={width || 300}
      height={height || 'auto'}
    >
      {title && (
        <h3
          style={{
            fontSize: 18,
            fontWeight: 700,
          }}
        >
          {title}
        </h3>
      )}
      <Row>
        <p>{details}</p>
      </Row>

      <Row>
        <Button
          width="48%"
          height="30px"
          variant={cancelVariant || 'secondary'}
          onClick={handleCancel}
        >
          {cancelText || 'Cancel'}
        </Button>
        <Button
          width="48%"
          height="30px"
          variant={confirmVariant || 'primary'}
          onClick={handleConfirm}
        >
          {confirmText || 'Confirm'}
        </Button>
      </Row>
    </Modal>
  );
}

export default ConfirmModal;
