import type { LoginRedirectStrategy } from '../models/AuthenticationConfig';

const lastAccessedPathKey = 'auth-authentication-lastPath';

interface LoginRedirectOptions {
  loginRedirectStrategy: LoginRedirectStrategy;
}

export function getLoginRedirectPath(
  defaultLoginPath?: string,
  options?: LoginRedirectOptions,
) {
  const { loginRedirectStrategy } = options ?? {};

  if (loginRedirectStrategy !== 'ToLoginRedirectOnly') {
    const path = sessionStorage.getItem(lastAccessedPathKey);
    if (path) return path;
  }

  return defaultLoginPath ?? '/';
}

export function saveLastAccessedPath(path: string) {
  sessionStorage.setItem(lastAccessedPathKey, path);
}

export function clearLastAccessedPath() {
  sessionStorage.removeItem(lastAccessedPathKey);
}
