import React from 'react';
import Product, { ProductLoader } from './Product';
import { IProduct } from '../../../../context/order/types';
import { Container, Title, List } from './styles';

interface ProductsProps {
  title?: string;
  products: IProduct[];
  loading?: boolean;
}

function Products({ title, products, loading }: ProductsProps) {
  return (
    <Container>
      {title && <Title>{title}</Title>}
      <List>
        {loading ? (
          <>
            <ProductLoader />
            <ProductLoader />
            <ProductLoader />
          </>
        ) : (
          products.map(product => (
            <Product key={product.productId} product={product} />
          ))
        )}
      </List>
    </Container>
  );
}

export default Products;
