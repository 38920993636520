import React from 'react';
import { IMultiTabExcelFileItemClient, ISourceFile } from './interfaces';

function getEmptyMultiTabSourceFile(): IMultiTabExcelFileItemClient {
  return {
    headerRow: 1,
    dataRow: 2,
    tabIndex: -1,
    tabName: '',
    mergeIndex: -1,
    mergeName: '',
    headerRowMinValue: 1,
    headerRowMaxValue: 1,
  };
}

function getEmptySourceFile(): ISourceFile {
  return {
    tabIndex: 0,
    selectedTabName: '',
    headerRow: 1,
    dataRow: 2,
    fileFormat: 'Delimited',
    headerRowDelimiter: 'CarriageReturnLineFeed',
    rowDelimiter: 'CarriageReturnLineFeed',
    columnDelimiter: 'Comma',
    textQualifier: '',
    columnLength: 1,
    name: '',
    createdBy: '',
    rawDataSource: '',
    headerRowMinValue: 1,
    headerRowMaxValue: 1,
    items: [getEmptyMultiTabSourceFile()],
  };
}

export interface SourceFileStepProps {
  title?: string;
  compress?: boolean;
  excludeIncompleteImports?: boolean;
  excludeCompletedImports?: boolean;
  doNotPreload?: boolean;
  hideExcel?: boolean;
  hideMultiTabExcel?: boolean;
  hideFlat?: boolean;
  hideDbf?: boolean;
  importProcessId?: () => null | number | null;
}

export default function SourceFileStep({
  title = 'Source File',
  compress = false,
  excludeIncompleteImports = false,
  excludeCompletedImports = false,
  doNotPreload = false,
  hideExcel = false,
  hideMultiTabExcel = false,
  hideFlat = false,
  hideDbf = false,
  importProcessId = () => null,
}: SourceFileStepProps) {
  // TODO
  return <>Test</>;
}
