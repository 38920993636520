import { ToastMessage } from '@samc/react-ui-core';
import * as React from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import { BaseGrid as Grid } from '@samc/react-ui-grid';
import { AxiosError } from 'axios';
import { formatErrorMessage } from '../../../utils/helpers';
import { Heading, Loading } from '../../Common';
import { useToast } from '../../../context/toaster';
import api from '../../../services/api';
import { Content, Container } from './styles';
import { OrderTrackingLoan } from '../../../context/order/appraisal/types';
import ExtendedGridFieldConfiguration from '../../../types/ExtendedGridFieldConfiguration';
import { OrderTrackingData } from '../../../queries/useOrders';

export interface IOrderDetailsData extends OrderTrackingData {
  clarityUrl?: string;
}

interface ClarityBPOOrderProps {
  selectedOrder: OrderTrackingData;
  onBack: () => void;
  loanSelected: OrderTrackingLoan;
}

export function ClarityBPOOrder({
  selectedOrder,
  onBack,
  loanSelected,
}: ClarityBPOOrderProps) {
  const { toastError } = useToast();
  const [isLoading, setIsLoading] = React.useState(false);
  const [orders, setOrders] = React.useState<OrderTrackingLoan[]>([]);
  const [selectedUrl, setSelectedUrl] = React.useState<string | undefined>();
  const fields: ExtendedGridFieldConfiguration[] = React.useMemo(() => {
    return [
      {
        field: 'loanStatus',
        headerName: 'Status',
        cellRenderer: 'StatusCellRederer',
        width: 380,
      },
      {
        field: 'clientLoanId',
        headerName: 'Loan ID',
        type: 'string',
        width: 130,
      },
      {
        field: 'address',
        headerName: 'Street',
        width: 260,
      },
      {
        field: 'unitNumber',
        headerName: 'Suite/Unit #',
        width: 140,
      },
      {
        field: 'city',
        headerName: 'City',
        width: 200,
      },
      {
        field: 'state',
        headerName: 'State',
        width: 100,
      },
      {
        field: 'zip',
        headerName: 'Zip',
        width: 120,
      },
      {
        field: 'productName',
        headerName: 'BPO Type',
        valueFormatter: ({ value }) => value.replace('BPO -', ''),
        tooltipValueGetter: ({ valueFormatted }) => valueFormatted || '',
        width: 130,
      },
    ];
  }, []);

  const getClarityUrl = React.useCallback(
    async (
      OrderId: string,
      ClarityLoanId: number,
      loanNumber: number,
    ): Promise<string> => {
      const params = {
        OrderId,
        ClarityLoanId,
      };
      const config = {
        params,
      };
      try {
        const response = await api.get('/OrderTracking/urltoken', config);
        return response.data;
      } catch (err) {
        const message = await formatErrorMessage(err as AxiosError<ErrorData>);

        toastError(
          <ToastMessage
            title={`Error - Loan Number: ${loanNumber}`}
            message={message}
          />,
        );
        return '';
      } finally {
        setIsLoading(false);
      }
    },
    [toastError],
  );

  const fetchOrders = React.useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await api.get(`/Order/${selectedOrder.orderId}`);
      const { orderId } = response.data;
      const clarityUrl = await getClarityUrl(
        orderId,
        loanSelected.clarityLoanNumber,
        loanSelected.loanNumber,
      );
      const bpo: OrderTrackingLoan[] = [
        {
          orderItemId: loanSelected.orderItemId,
          accessDetails: loanSelected.accessDetails,
          address: loanSelected.address,
          unitNumber: loanSelected.unitNumber,
          borrowerLastName: loanSelected.borrowerLastName,
          city: loanSelected.city,
          state: loanSelected.state,
          zip: loanSelected.zip,
          loanStatus: loanSelected.loanStatus,
          clientLoanId: loanSelected.clientLoanId,
          clarityLoanNumber: loanSelected.clarityLoanNumber,
          loanId: loanSelected.loanId,
          loanNumber: loanSelected.loanNumber,
          productId: loanSelected.productId,
          productName: loanSelected.productName,
          providerRequestType: loanSelected.providerRequestType,
          disputeActionType: loanSelected.disputeActionType,
          customerReferenceId1: loanSelected.customerReferenceId1,
          customerReferenceId2: loanSelected.customerReferenceId2,
          customerReferenceId3: loanSelected.customerReferenceId3,
        },
      ];

      setOrders(bpo);
      setSelectedUrl(clarityUrl);
    } catch (err) {
      const message = await formatErrorMessage(err as AxiosError<ErrorData>);

      toastError(<ToastMessage title="Error" message={message} />);
    } finally {
      setIsLoading(false);
    }
  }, [
    selectedOrder.orderId,
    getClarityUrl,
    loanSelected.loanId,
    loanSelected.loanNumber,
    loanSelected.orderItemId,
    loanSelected.accessDetails,
    loanSelected.address,
    loanSelected.unitNumber,
    loanSelected.borrowerLastName,
    loanSelected.city,
    loanSelected.state,
    loanSelected.zip,
    loanSelected.loanStatus,
    loanSelected.clientLoanId,
    loanSelected.clarityLoanNumber,
    loanSelected.productId,
    loanSelected.productName,
    loanSelected.providerRequestType,
    loanSelected.disputeActionType,
    loanSelected.customerReferenceId1,
    loanSelected.customerReferenceId2,
    loanSelected.customerReferenceId3,
    toastError,
  ]);

  const subAppBarData = React.useMemo(() => {
    if (selectedOrder) {
      return {
        topLeft: (
          <>
            <FiChevronLeft size={12} />
            Back to Complete Orders
          </>
        ),
        bottomLeft: `Order: ${selectedOrder.orderNumber || ''} `,
        actionTopLeft: onBack,
      };
    }
    return {
      bottomLeft: 'Order Tracking',
    };
  }, [onBack, selectedOrder]);

  React.useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);

  return (
    <Container>
      {isLoading && <Loading />}
      <Heading
        bottomLeft={subAppBarData.bottomLeft}
        topLeft={subAppBarData.topLeft}
        actionTopLeft={subAppBarData.actionTopLeft}
      />
      <Content>
        <Grid
          data={orders}
          fields={fields}
          autoHeight
          sizeColumnsToFit
          checkboxSelection={false}
          suppressPaginationPanel
          suppressColumnFilters
        />
        {selectedUrl ? (
          <iframe title="clarity-portal" src={selectedUrl} frameBorder={0} />
        ) : null}
      </Content>
    </Container>
  );
}

export default ClarityBPOOrder;
