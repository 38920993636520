/* eslint-disable react/function-component-definition */
import React, { useEffect, useState } from 'react';
import { DefaultPublisher } from '@samc/common';
import { useClient } from '../../../contexts/ClientContext';
import { User, Role, CurrentUser } from '../../../models';
import { useCurrentUser } from '../../../contexts/CurrentUserContext';
import Entitlements from '../../../Entitlements';
import Title from '../Title';
import RoleFields from './RoleFields';
import useComplexState from '../../../hooks/useComplexState';
import {
  BuildErrorsForRole,
  ValidateRole,
  ValidationErrors,
} from '../../../validators';
import RoleEntitlementsAndUsers from './RoleEntitlementsAndUsers';
import { getAddedModels, getRemovedModels } from '../../../helpers/arrayDiffs';
import Alert, { AlertType } from '../../Alert';
import {
  EntitlementsChangedOnRoleEvent,
  OpenModalEvent,
  RoleSavedEvent,
  UserAddedToRoleEvent,
  UserRemovedFromRoleEvent,
} from '../../../events';
import { RoleEditingFlags } from './RoleEditingFlags';

type RoleFormProps = {
  role: Role;
  isClone?: boolean;
};

function getTitle(isNew: boolean, isClone: boolean) {
  if (!isNew) {
    return 'Edit Role';
  }
  if (isClone) {
    return 'Clone Role';
  }
  return 'Create Role';
}

const RoleForm: React.FC<RoleFormProps> = ({ role, isClone = false }) => {
  const isNewRole = role.id.isEmpty();
  const [currentState, updateCurrentState, replaceState] = useComplexState(
    role.clone(),
  );
  const [addedUsers, setAddedUsers] = useState(new Array<User>());
  const [removedUsers, setRemovedUsers] = useState(new Array<User>());
  const [wasSaveAttempted, setWasSaveAttempted] = useState(false);
  const [validationErrors, updateValidationErrors, setValidationErrors] =
    useComplexState(BuildErrorsForRole());

  const client = useClient();

  const currentUser = useCurrentUser();
  const relevantEntitlement =
    (isNewRole ?? isClone)
      ? Entitlements.Roles.Create
      : Entitlements.Roles.Update;

  const update = (propertyName: string, doUpdate: (role: Role) => void) => {
    updateCurrentState(doUpdate);
    updateValidationErrors(errors => {
      errors.clearServerErrorForProperty(propertyName);
      ValidateRole(errors, currentState);
    });
  };

  useEffect(() => {
    const clone = role.clone();
    const errors = BuildErrorsForRole();
    ValidateRole(errors, clone);
    replaceState(clone);
    setWasSaveAttempted(false);
    setValidationErrors(errors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);

  const editingFlags = getEditingFlags(
    currentUser,
    currentState,
    role,
    isNewRole,
    relevantEntitlement,
    addedUsers.length + removedUsers.length,
  );
  // Only highlight changed fields for an existing model (and where there are in fact changes).
  const dirtyFields =
    !isNewRole && editingFlags.isDirty ? currentState.getDirtyFields(role) : [];

  const [canSave, disabledSaveReason] = determineSaveability(
    editingFlags,
    validationErrors,
  );

  const save = async () => {
    if (canSave) {
      let success = false;
      if (!editingFlags.isDirty) {
        success = true; // Nothing to do
      } else if (isNewRole ?? isClone) {
        const [result, id] = await client.roles.createRole(
          currentState,
          updateValidationErrors,
        );
        success = result;
        if (success) {
          currentState.id = id;
        }
      } else {
        success = await client.roles.editRole(
          currentState,
          updateValidationErrors,
        );
      }
      if (success && editingFlags.areEntitlementsDirty) {
        if (isNewRole && currentState.entitlements.length > 0) {
          success = await client.roles.addRoleEntitlements(
            currentState.id,
            currentState.entitlements.map(e => e.id),
          );
        } else {
          const newEntitlementIds = getAddedModels(
            currentState.entitlements,
            role.entitlements,
          ).map(e => e.id);
          const removedEntitlementIds = getRemovedModels(
            currentState.entitlements,
            role.entitlements,
          ).map(e => e.id);
          success = await client.roles.updateRoleEntitlements(
            currentState.id,
            newEntitlementIds,
            removedEntitlementIds,
          );
          if (success) {
            DefaultPublisher.publish(
              new EntitlementsChangedOnRoleEvent(currentState.id),
            );
          }
        }
      }

      if (success && editingFlags.areUsersDirty) {
        const adds = addedUsers.map(async u => {
          const addSuccess = await client.users.addRoles(u.id, [
            currentState.id,
          ]);
          if (addSuccess) {
            DefaultPublisher.publish(
              new UserAddedToRoleEvent(u.id, currentState.id),
            );
          }
          return addSuccess;
        });
        const removes = removedUsers.map(async u => {
          const removeSuccess = await client.users.removeRoles(u.id, [
            currentState.id,
          ]);
          if (removeSuccess) {
            DefaultPublisher.publish(
              new UserRemovedFromRoleEvent(u.id, currentState.id),
            );
          }
          return removeSuccess;
        });
        success = (await Promise.all(adds.concat(removes))).every(r => r);
      }
      if (success) {
        DefaultPublisher.publish(new RoleSavedEvent(currentState));
      } else {
        setWasSaveAttempted(true);
      }
    }
  };
  const buttons =
    isNewRole || role.isSystemControlled
      ? []
      : [
          {
            label: 'DELETE',
            onClick: async () => {
              DefaultPublisher.publish(
                new OpenModalEvent(
                  'Are you sure you want to delete this role?',
                  [
                    {
                      label: 'Yes',
                      onClick: async () => {
                        if (await client.roles.deleteRole(role.id)) {
                          DefaultPublisher.publish(new RoleSavedEvent(role));
                        }
                      },
                      className: 'border-red-3 bg-red-2 text-red-1',
                    },
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    { label: 'No', onClick: () => {}, isFocused: true },
                  ],
                ),
              );
            },
            entitlement: Entitlements.Roles.Delete,
            className: 'border-red-3 bg-red-2 text-red-1',
          },
        ];

  return (
    <div className="form font-arial">
      <Title
        saveAction={save}
        canSave={canSave}
        disabledSaveReason={disabledSaveReason}
        additionalButtonProps={buttons}
      >
        {getTitle(isNewRole, isClone)}
      </Title>
      <div className="p-5 pt-0">
        {currentState.isSystemControlled && (
          <Alert type={AlertType.Boring}>
            This role is managed by the system and cannot be edited. Users can
            be assigned to or removed from this role.
          </Alert>
        )}
        <RoleFields
          role={currentState}
          dirtyFields={dirtyFields}
          validationErrors={validationErrors}
          isReadOnly={!editingFlags.canEdit}
          update={update}
          wasSaveAttempted={wasSaveAttempted}
        />
        <RoleEntitlementsAndUsers
          currentState={currentState}
          originalState={role}
          roleEditingFlags={editingFlags}
          setAddedUsers={setAddedUsers}
          setRemovedUsers={setRemovedUsers}
          updateRole={updateCurrentState}
          startingTab={currentState.isSystemControlled ? 'Users' : undefined}
        />
      </div>
    </div>
  );
};

function getEditingFlags(
  currentUser: CurrentUser,
  currentState: Role,
  original: Role,
  isNew: boolean,
  relevantEntitlement: string,
  changedUserCount: number,
): RoleEditingFlags {
  return {
    canEdit:
      currentUser.hasEntitlement(relevantEntitlement) &&
      !currentState.isSystemControlled,
    canViewEntitlements: currentUser.hasEntitlement(
      Entitlements.Entitlements.List,
    ),
    canAssignEntitlements:
      currentUser.hasEntitlement(Entitlements.Roles.AssignEntitlements) &&
      !currentState.isSystemControlled,
    canViewUsers: currentUser.hasEntitlement(Entitlements.Users.List),
    canAssignUsers: currentUser.hasEntitlement(Entitlements.Users.AssignRoles),
    isDirty: currentState.isDirty(original),
    areEntitlementsDirty:
      (isNew && currentState.entitlements.length > 0) ||
      currentState.areEntitlementsDirty(original),
    areUsersDirty: changedUserCount > 0,
  };
}

function determineSaveability(
  flags: RoleEditingFlags,
  errors: ValidationErrors,
): [boolean, string] {
  if (errors.hasAnyErrors) {
    const errorString = `The following must be fixed in order to save:\r\n${errors
      .getAllErrors()
      .map(e => ` - ${e}`)
      .join('\r\n')}`;
    return [false, errorString];
  }

  if (!flags.isDirty && !flags.areEntitlementsDirty && !flags.areUsersDirty) {
    return [false, 'There are no changes to save.'];
  }

  let canSave = true;
  let cantSaveReason = '';

  if (!flags.canEdit && flags.isDirty) {
    canSave = false;
    cantSaveReason +=
      "You don't have the entitlement required to update the role.\r\n";
  }

  if (!flags.canAssignEntitlements && flags.areEntitlementsDirty) {
    canSave = false;
    cantSaveReason +=
      "You don't have the entitlement required to update the entitlements assigned to a role.\r\n";
  }

  if (!flags.canAssignUsers && flags.areUsersDirty) {
    canSave = false;
    cantSaveReason +=
      "You don't have the entitlement required to update the users assigned to a role.";
  }

  return [canSave, cantSaveReason];
}

export default RoleForm;
