import * as React from 'react';
import { Container } from './styles';
import notFoundImage from '../../assets/404.png';

function NotFound() {
  return (
    <Container>
      <img src={notFoundImage} alt="Page Not Found" />
    </Container>
  );
}

export default NotFound;
