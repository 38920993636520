import { useQuery, UseQueryResult } from 'react-query';
import api from '../services/api';

interface IVendor {
  vendorId: number;
  vendorName: string;
}

export const getVendors = async (): Promise<IVendor[]> => {
  const response = await api.get('Vendors');
  return response.data;
};

export const useVendors = (): UseQueryResult<IVendor[], unknown> =>
  useQuery('vendors', getVendors);
