import { Guid } from '@samc/common';
import Base from './Base';

class Entitlement extends Base<Entitlement> {
  // id: Guid;

  referenceId?: Guid;

  // name: string;

  displayName: string;

  group: string;

  description: string;

  order: number; // The order in which it was originally received. Helps with sorting them later as drags/drops occur.

  constructor(id?: Guid, name?: string) {
    super(id, name);
    this.displayName = '';
    this.group = '';
    this.description = '';
    this.order = -1;
  }

  clone() {
    const clone = new Entitlement(this.id, this.name);
    clone.description = this.description;
    clone.displayName = this.displayName;
    clone.group = this.group;
    clone.isSystemControlled = this.isSystemControlled;
    clone.order = this.order;
    return clone;
  }

  // eslint-disable-next-line class-methods-use-this
  isDirty() {
    return false;
  }

  // eslint-disable-next-line class-methods-use-this
  getDirtyFields() {
    return [];
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromJson(json: any, order = 0) {
    const entitlement = new Entitlement(Guid.parse(json.id), json.name);
    entitlement.displayName = json.displayName;
    entitlement.group = json.group;
    entitlement.description = json.description;
    entitlement.isSystemControlled = json.isSystemControlled;
    entitlement.order = order;
    if (json.referenceId) {
      entitlement.referenceId = Guid.parse(json.referenceId);
    }
    return entitlement;
  }
}

export default Entitlement;
