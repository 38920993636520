import styled from 'styled-components';
import { ComboBox as ComboBoxUI } from '@fluentui/react';

export const Container = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const Label = styled.div`
  margin: 0 10px 4px 0;
  font-weight: 600;
  font-family: ${props => props.theme.fontFamily.main};
  font-size: ${props => props.theme.fontSize.lg};
  color: ${props => props.theme.colors.dark};
`;

export const ComboBox = styled(ComboBoxUI)`
  margin-top: 16px;
  max-width: fit-content;
`;
