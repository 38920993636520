import styled from 'styled-components';
import { mergeStyleSets, FontWeights } from '@fluentui/react';

interface Prop {
  isHidden: boolean;
}

export const Container = styled.div<Prop>`
  display: flex;
  flex-direction: column;
  width: fit-content;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
  stroke-width: 1;
  inset: 0px;
  position: absolute;
  visibility: ${props => (props.isHidden ? 'hidden' : 'visible')};
`;

export const styles = mergeStyleSets({
  button: {
    width: 130,
  },
  callout: {
    width: 'fit-content',
    maxWidth: '100%',
  },
  title: {
    marginBottom: 12,
    fontWeight: FontWeights.semilight,
  },
  link: {
    display: 'block',
    marginTop: 20,
  },
});
