/* eslint-disable react/function-component-definition */
import { AdditionalUserField, AdditionalUserSettings, User } from '../models';
import { ValidateCompany } from './ValidateCompany';
import { ValidationErrors } from './ValidationErrors';

export function BuildErrorsForUser(
  userDataFieldSettings: AdditionalUserSettings,
) {
  const fields = ['username', 'firstName', 'lastName', 'company', 'email'];
  if (userDataFieldSettings.showSsoIdField) {
    fields.push('ssoId');
  }
  userDataFieldSettings.fields.forEach(f => {
    fields.push(f.fieldName);
  });

  return new ValidationErrors(fields);
}

export function ValidateUser(
  validationErrors: ValidationErrors,
  usesClarityCompanies: boolean,
  userDataFieldSettings: AdditionalUserSettings,
  user?: User,
) {
  validationErrors.resetLocal();

  if (!user) {
    // Still undefined, so nothing to validate
    return validationErrors;
  }

  if (!user.firstName) {
    validationErrors.addLocalError(
      'firstName',
      'First Name must have a value.',
    );
  }

  if (!user.lastName) {
    validationErrors.addLocalError('lastName', 'Last Name must have a value.');
  }

  if (!user.email) {
    validationErrors.addLocalError('email', 'Email must have a value.');
  }

  if (userDataFieldSettings.fields.length > 0) {
    userDataFieldSettings.fields
      .filter(f => f.dataValidation != null)
      .forEach((customField: AdditionalUserField) => {
        const validations = customField.dataValidation?.isValid(
          user,
          userDataFieldSettings,
        );
        if (validations?.userData !== undefined) {
          validationErrors.addLocalError(
            customField.fieldName,
            validations?.userData,
          );
        }
      });
  }

  ValidateCompany(validationErrors, user.company, usesClarityCompanies);

  return validationErrors;
}
