import { AxiosError } from 'axios';
import React from 'react';
import { useMutation } from '../hooks/useMutation';
import { BPOLoanData } from '../context/order/bpo/types';
import {
  calculateBPOOrderResponse,
  GetBPOOrderResponse,
} from '../queries/useBPOOrder';
import api, { BPO_ENDPOINT_PATH } from '../services/api';

export type SetBPOOrderParams = {
  companyDivision?: number;
  // isCompletedSuccessfully: boolean;
  orderId: string;
  vendorId: string;
  productId: string;
  // identifier?: string;
  // productInfo: {
  //   vendorId: string;
  //   productId: string;
  //   productName: string;
  //   price?: number;
  // };
  data: BPOLoanData[];
  // products?: [
  //   {
  //     productId: string;
  //     productName: string;
  //     price: number;
  //     currentPrice: number;
  //     didPriceChange: boolean;
  //     isAvailable: boolean;
  //   },
  // ];
};

async function setBPOOrder(formData: SetBPOOrderParams) {
  const myFormData = {
    ...formData,
    data: formData.data.map(loan => {
      return {
        ...loan,
        // bpoType: loan.bpoProduct.name ?? undefined, // TODO: Remove when server no longer needs this
        errors: loan.errors?.map(error => {
          return {
            ...error,
            isDeleted: error.type !== 'Warning',
          };
        }),
      };
    }),
  };

  const response = await api.patch<GetBPOOrderResponse>(
    `/${BPO_ENDPOINT_PATH}/${myFormData.orderId}`,
    myFormData,
  );
  return calculateBPOOrderResponse(response.data);
}

export function useSetBPOOrder() {
  return useMutation<
    GetBPOOrderResponse,
    AxiosError<ErrorData>,
    SetBPOOrderParams
  >(React.useCallback(params => setBPOOrder(params), []));
}
