import React from 'react';
import { Dropdown } from '@fluentui/react';
import { useCurrentUser } from '../../../features/authorization';
import Modal from '../Modal';

import { useCompanies } from '../../../queries/useCompanies';
import Button from '../Button';
import { Message, Row } from './styles';
import { useSwitchClient } from '../../../mutations/useSwitchClient';
import { useUserInfo } from '../../../queries/useUserInfo';

export type SwitchClientModalProps = {
  isOpen: boolean;
  onDismiss: () => void;
};

export default function SwitchClientModal({
  isOpen,
  onDismiss,
}: SwitchClientModalProps) {
  const companies = useCompanies();
  const currentUser = useCurrentUser();
  const userInfo = useUserInfo();

  const [selectedClient, setSelectedClient] = React.useState<string | null>(
    null,
  );

  const [isLoaded, setIsLoaded] = React.useState(false);
  const [isSwitching, setIsSwitching] = React.useState(false);
  const [isSwitched, setIsSwitched] = React.useState(false);

  React.useEffect(() => {
    if (!isLoaded && !userInfo.isLoading && userInfo.data && companies.data) {
      setIsLoaded(true);

      const currentCompany = companies.data.find(company => {
        return company.clarityId === currentUser.companyClarityId;
      });

      setSelectedClient(
        (userInfo.data.emulatedCompany && userInfo.data.emulatedCompany.id) ||
          (currentCompany && currentCompany.id) ||
          null,
      );
    }
  }, [
    companies.data,
    currentUser.company,
    currentUser.companyClarityId,
    isLoaded,
    userInfo.data,
    userInfo.isLoading,
  ]);

  const useSwitchClientMutation = useSwitchClient();

  const handleConfirm = React.useCallback(() => {
    setIsSwitching(true);
    useSwitchClientMutation.mutate(
      { id: selectedClient },
      {
        onSuccess: () => {
          setIsSwitching(false);
          setIsSwitched(true);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        },
        onError: () => {
          setIsSwitching(false);
        },
      },
    );
  }, [selectedClient, useSwitchClientMutation]);

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={onDismiss}
      suppressCloseButton={isSwitching || isSwitched}
    >
      <h3>
        {isSwitching && 'Switching Client'}
        {isSwitched && 'Client Switched!'}
        {!isSwitching && !isSwitched && 'Switch Client'}
      </h3>
      {isSwitching && <Message>Please wait...</Message>}
      {isSwitched && <Message>Reloading...</Message>}
      {!isSwitching && !isSwitched && (
        <>
          <Row style={{ marginBottom: '-20px' }}>
            <b>Customer</b>
          </Row>
          <Row>
            {!companies.isLoading && companies.data && (
              <Dropdown
                options={companies.data.map(company => {
                  return {
                    key: company.id,
                    text: company.name,
                  };
                })}
                selectedKey={selectedClient}
                onChange={(_, option?) => {
                  if (option) {
                    setSelectedClient(option.key as string);
                  }
                }}
                styles={{ root: { width: '100%' } }}
              />
            )}
          </Row>
          <Row>
            <Button
              width="48%"
              height="30px"
              variant="secondary"
              onClick={onDismiss}
            >
              Cancel
            </Button>
            <Button
              width="48%"
              height="30px"
              variant="primary"
              onClick={handleConfirm}
            >
              Switch
            </Button>
          </Row>
        </>
      )}
    </Modal>
  );
}
