import * as React from 'react';
import { BaseGrid as Grid, GridFieldConfiguration } from '@samc/react-ui-grid';
import { FiChevronLeft } from 'react-icons/fi';
import { ToastMessage } from '@samc/react-ui-core';
import { AxiosError } from 'axios';
import api from '../../../services/api';
import { Loading, Heading } from '../../Common';
import { useToast } from '../../../context/toaster';
import { formatDate, formatErrorMessage } from '../../../utils/helpers';
import { Content } from './styles';
import { Params } from '../OrdersGrid/config';
import { ILoan } from '../../../context/order/appraisal/types';
import { OrderTrackingData } from '../../../queries/useOrders';

export interface IOrderDetailsData extends OrderTrackingData {
  clarityUrl?: string;
}

interface AppraisalDownload {
  orderId: string;
  loanId: string;
}

export interface DownloadResultsParams {
  loan?: AppraisalDownload;
}

interface ClarityOrderProps {
  selectedOrder: OrderTrackingData;
  onBack: () => void;
}

function ClarityOrder({ selectedOrder, onBack }: ClarityOrderProps) {
  const { toastError } = useToast();
  const [orders, setOrders] = React.useState<IOrderDetailsData[]>([]);
  const [selectedUrl, setSelectedUrl] = React.useState<string | undefined>();
  const [isLoading, setIsLoading] = React.useState(false);

  const getClarityUrl = React.useCallback(
    async (
      OrderId: string,
      ClarityLoanId: number,
      loanNumber: number,
    ): Promise<string> => {
      const params = {
        OrderId,
        ClarityLoanId,
      };

      const config = {
        params,
      };

      try {
        const response = await api.get('/OrderTracking/urltoken', config);

        return response.data;
      } catch (err) {
        const message = await formatErrorMessage(err as AxiosError<ErrorData>);

        toastError(
          <ToastMessage
            title={`Error - Loan Number: ${loanNumber}`}
            message={message}
          />,
        );
        return '';
      }
    },
    [toastError],
  );

  const fetchOrders = React.useCallback(async () => {
    try {
      setIsLoading(true);

      const response = await api.get(`/Order/${selectedOrder.orderId}`);
      const {
        orderId,
        orderNumber,
        orderStatus,
        loans,
        submittedBy,
        submittedOn,
      } = response.data;
      const newOrders: IOrderDetailsData[] = await Promise.all(
        loans.map(async (loan: ILoan) => {
          const clarityUrl = await getClarityUrl(
            orderId,
            loan.clarityLoanNumber,
            loan.loanNumber,
          );
          return {
            orderId,
            borrowerLastName: loan.borrowerLastName,
            clientLoanId: loan.clientLoanId,
            loanId: loan.loanId,
            loanNumber: loan.loanNumber,
            orderNumber,
            orderStatus,
            productName: loan.productInfo.productName,
            propertyStreet: loan.address,
            propertyCity: loan.city,
            propertyState: loan.state,
            propertyZip: loan.zip,
            submittedBy,
            submittedOn: formatDate(submittedOn),
            clarityUrl,
          };
        }),
      );

      setOrders(newOrders);
      setSelectedUrl(newOrders[0].clarityUrl);
    } catch (err) {
      const message = await formatErrorMessage(err as AxiosError<ErrorData>);

      toastError(<ToastMessage title="Error" message={message} />);
    } finally {
      setIsLoading(false);
    }
  }, [selectedOrder, toastError, getClarityUrl]);

  React.useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);

  const fields: GridFieldConfiguration[] = [
    {
      field: 'borrowerLastName',
      headerName: 'Borrower Name',
      type: 'string',
      width: 200,
    },
    {
      field: 'clientLoanId',
      headerName: 'Client Loan ID',
      type: 'string',
    },
    {
      field: 'orderNumber',
      headerName: 'Order #',
      type: 'string',
    },
    {
      field: 'productName',
      headerName: 'Product',
      type: 'string',
      width: 250,
    },
    {
      field: 'propertyStreet',
      headerName: 'Property Address',
      type: 'string',
      width: 250,
    },
    {
      field: 'propertyCity',
      headerName: 'City',
      type: 'string',
    },
    {
      field: 'propertyState',
      headerName: 'State',
      type: 'string',
    },
    {
      field: 'propertyZip',
      headerName: 'Zip',
      type: 'string',
    },
    {
      field: 'submittedBy',
      headerName: 'Submitted By',
      type: 'string',
    },
    {
      field: 'submittedOn',
      headerName: 'Submitted Date',
      type: 'date',
      filter: false,
      width: 250,
    },
  ];

  const subAppBarData = React.useMemo(() => {
    if (orders.length > 0) {
      return {
        topLeft: (
          <>
            <FiChevronLeft size={12} />
            Back to Complete Orders
          </>
        ),
        bottomLeft: `Order ${orders[0].orderNumber}`,
        actionTopLeft: onBack,
      };
    }
    return {
      bottomLeft: 'Order Tracking',
    };
  }, [onBack, orders]);

  const onChangeSelected = (params: unknown) => {
    const { data } = params as Params;
    setSelectedUrl(data.clarityUrl);
  };

  return (
    <>
      {isLoading && <Loading />}
      <Heading
        bottomLeft={subAppBarData.bottomLeft}
        topLeft={subAppBarData.topLeft}
        actionTopLeft={subAppBarData.actionTopLeft}
      />
      <Content>
        <Grid
          data={orders}
          fields={fields}
          autoHeight
          sizeColumnsToFit
          checkboxSelection={false}
          suppressPaginationPanel
          suppressColumnFilters
          gridOptions={{
            onRowClicked: onChangeSelected,
          }}
        />
        {selectedUrl ? (
          <iframe title="clarity-portal" src={selectedUrl} frameBorder={0} />
        ) : null}
      </Content>
    </>
  );
}

export default ClarityOrder;
