import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 20px;
`;

export const GridWrapper = styled.div`
  flex: 1;
`;

export const Button = styled.button`
  height: 100%;
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.primary};
  font-size: 12px;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
`;

export const Editable = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 105%;

  input {
    font-size: 14px;
    background: transparent;
    width: 100%;
  }

  svg {
    color: ${props => props.theme.colors.primary};
    display: none;
    margin-bottom: 4px;
  }

  &:hover {
    svg {
      display: unset;
    }
  }
`;

export const ProductCell = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  img {
    height: 15px;
    padding-right: 5px;
  }
`;

interface LinkButtonProps {
  color?: string;
  size?: string;
}

export const LinkButton = styled.span<LinkButtonProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
  font-weight: 600;
  cursor: pointer;
  font-size: inherit;
  color: ${props => props.color || props.theme.colors.primary};

  ${props =>
    props.size &&
    css`
      font-size: ${props.size};
    `}

  &:hover {
    text-decoration: underline;
  }
  span {
    margin-right: 3px;
  }
`;

export const ContainerIcons = styled.div`
  display: flex;
  flex-direction: row;
`;

export const WrapperButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  padding: 5px 10px;

  button {
    border-radius: 2px;
    background-size: cover;
    font-family: Source Sans Pro;
    font-size: 14px;
    color: #000000;
    text-decoration: none solid rgb(0, 0, 0);

    &:hover {
      text-decoration: underline;
    }
  }
`;
