/* eslint-disable react/function-component-definition */
import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import Base from '../../../models/Base';

const ModelNameRenderer: React.FC<ICellRendererParams> = ({ value }) => {
  if (value instanceof Base) {
    return <span>{value.name}</span>;
  }

  return <span />;
};

export default ModelNameRenderer;
