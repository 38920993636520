import * as React from 'react';
import { FaCheck } from 'react-icons/fa';
import { Container } from './styles';

interface RadioProps {
  text: string | number;
  label?: string;
  onChange: (value: string | number) => void;
  selected: string | number;
  value: string;
  name: string;
  suppressCheckIcon?: boolean;
}

export default function RadioItem({
  text,
  name,
  label,
  onChange,
  selected,
  value,
  suppressCheckIcon = false,
}: RadioProps) {
  return (
    <Container>
      {label}
      <input
        name={name}
        type="radio"
        value={value}
        checked={value === selected}
        onChange={e => onChange(e.target.value)}
      />
      <span />
      <p>
        {value === selected && !suppressCheckIcon && (
          <FaCheck size={20} color="#36b300" />
        )}
        {text}
      </p>
    </Container>
  );
}
