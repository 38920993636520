import * as React from 'react';
import { StepperProvider } from './context';
import Steps from './Steps';
import Step from './Step';
import { Content } from './Content';
import Nav from './Nav';
import { Container } from './styles';

interface StepperProps {
  children: React.ReactNode;
}

function Stepper({ children }: StepperProps) {
  return (
    <StepperProvider>
      <Container>{children}</Container>
    </StepperProvider>
  );
}

Stepper.Steps = Steps;
Stepper.Step = Step;
Stepper.Content = Content;
Stepper.Nav = Nav;

export default Stepper;
