import * as React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { RiDeleteBin7Line } from 'react-icons/ri';
import { FileGridData } from '.';

export interface DeleteBtRendererProps extends ICellRendererParams {
  onClick: (value: FileGridData) => void;
}

export function DeleteBtCellRenderer({ onClick, data }: DeleteBtRendererProps) {
  return (
    <button type="button" onClick={() => onClick(data)}>
      <RiDeleteBin7Line color="#bd0e08" size={16} />
    </button>
  );
}
