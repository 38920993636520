import * as React from 'react';
import { AnimatedLoader } from '@samc/react-ui-core/lib/atoms/AnimatedLoader/AnimatedLoader';
import { Container, ContainerProps, Message } from './styles';

interface LoadingProps {
  height?: string;
  width?: string;
  message?: string;
  topLevel?: boolean;
}

function Loading({
  height,
  width,
  message = 'Loading...',
  topLevel,
}: LoadingProps) {
  const containerProps: ContainerProps = { height, width };

  const loadingRef = React.useRef<HTMLDivElement>();
  const [clonedLoadingElement, setClonedLoadingElement] =
    React.useState<HTMLDivElement | null>(null);

  React.useEffect(() => {
    if (clonedLoadingElement === null && loadingRef.current && topLevel) {
      const clone = loadingRef.current.cloneNode(true) as HTMLDivElement;
      document.body.appendChild(clone);
      setClonedLoadingElement(clone);
      loadingRef.current.style.display = 'none';
    } else if (clonedLoadingElement && (!topLevel || !loadingRef.current)) {
      clonedLoadingElement.remove();
      setClonedLoadingElement(null);
    }

    return () => {
      if (clonedLoadingElement) {
        clonedLoadingElement.remove();
      }
    };
  }, [clonedLoadingElement, topLevel]);

  return (
    <Container
      {...containerProps}
      ref={ref => {
        if (ref) {
          loadingRef.current = ref;
        }
      }}
    >
      <AnimatedLoader size={100} />
      <Message>{message}</Message>
    </Container>
  );
}

export default Loading;
