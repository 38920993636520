/* eslint-disable react/function-component-definition */
import React from 'react';
import { DefaultPublisher } from '@samc/common';
import Button, { TextButton, TextButtonProps } from '../Button';
import { EditCanceledEvent } from '../../events/EditCanceledEvent';

type TitleProps = {
  cancelLabel?: string;
  additionalButtonProps?: TextButtonProps[];
  saveAction: () => void;
  canSave: boolean;
  disabledSaveReason?: string;
  children?: React.ReactNode;
};

const buttonClasses = 'text-1 inline-block p-2 mr-sm';
const addlButtonClasses = `${buttonClasses} border-mono-4 border-solid border rounded-sm mr-sm`;
const saveButtonClasses = `${buttonClasses} bg-blue-2 text-blue-7 rounded-sm`;
const disabledButtonClasses = `${buttonClasses} bg-mono-4 text-mono-12 rounded-sm`;

const Title: React.FC<TitleProps> = ({
  children,
  cancelLabel = 'CANCEL',
  additionalButtonProps = null,
  disabledSaveReason = '',
  saveAction,
  canSave,
}) => {
  const additionalButtons = React.useMemo(() => {
    if (additionalButtonProps) {
      return additionalButtonProps.map(b => (
        <TextButton
          key={b.label}
          {...b}
          className={`${addlButtonClasses} ${b.className}`}
          disabledClassName={`${addlButtonClasses} ${disabledButtonClasses} ${b.disabledClassName}`}
        />
      ));
    }
    return [];
  }, [additionalButtonProps]);
  return (
    <div className="title p-2 pl-4 bg-mono-16 text-mono-4 font-proxima flex flex-row">
      <span className="text-4 flex-none">{children}</span>
      <div className="flex-grow flex justify-end leading-none">
        <Button
          className={buttonClasses}
          onClick={() => DefaultPublisher.publish(new EditCanceledEvent())}
        >
          {cancelLabel.toUpperCase()}
        </Button>
        {additionalButtons}
        <Button
          className={saveButtonClasses}
          isDisabled={!canSave}
          onClick={saveAction}
          disabledClassName={disabledButtonClasses}
          disabledReason={disabledSaveReason}
        >
          SAVE
        </Button>
      </div>
    </div>
  );
};

export default Title;
