import * as React from 'react';
import { FallbackProps } from 'react-error-boundary';
import { FiAlertCircle } from 'react-icons/fi';
import Button from '../Button';
import { Container, Title, Text } from './styles';

export default function ErrorFallback({
  error,
  resetErrorBoundary,
}: FallbackProps) {
  console.log(error);
  return (
    <Container>
      <FiAlertCircle size={32} />
      <Title>Something went wrong:</Title>
      <Text>{error.message}</Text>
      <Button onClick={resetErrorBoundary} width="211px">
        Try again
      </Button>
    </Container>
  );
}
