import * as React from 'react';
import { Container, OrderImageStates } from './styles';

interface OrderCardProps {
  images: OrderImageStates;
  onClick: () => void;
}

function OrderCard({ images, onClick }: OrderCardProps) {
  return <Container onClick={onClick} images={images} />;
}

export default OrderCard;
