import { IDropdownStyles } from '@fluentui/react';
import styled from 'styled-components';

import { BPOErrorType } from '../../../context/order/types';
import { errorType } from './config';

import SearchIcon from '../../../assets/icon-search.svg';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px;
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

export const Title = styled.p``;

export const GridTools = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

export const Counts = styled.div`
  margin-top: 20px;
`;

export const SearchField = styled.div`
  border: solid 1px #dbdbdb;
  border-radius: 2px;
  padding: 3px;
  padding-left: 26px;
  background-image: url(${SearchIcon});
  background-position: 3px 5px;
  background-repeat: no-repeat;
  margin-bottom: 10px;
`;

export const GridWrapper = styled.div`
  flex: 1;
`;

export const Overview = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 10px 14px;
  border: 1px solid #d2d2d2;
  background-color: #ffffff;
  background-size: cover;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
`;

export const Description = styled.div`
  font-size: ${props => props.theme.fontSize.lg};
`;

export const Total = styled.div`
  span {
    margin-left: 10px;
    font-size: ${props => props.theme.fontSize.xl2};
  }
`;

export const OrderIdentifier = styled.div`
  margin: 20px 0;
`;

export const MainContainer = styled.div`
  position: relative;
  top: 10px;
  align-items: center;
  svg {
    color: #c73732;
    position: relative;
    top: 42px;
  }
`;
export const HeaderContainer = styled.div`
  position: relative;
  left: 50px;
`;

export const ProductTitle = styled.p`
  color: #c73732;
`;
export const UnavailableQuantity = styled.strong`
  color: #c73732;
`;

export const OrderSubmitted = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;

  svg {
    color: #36b300;
  }

  div {
    margin-left: 10px;

    p {
      font-family: ${props => props.theme.fontFamily.main};
      font-size: 16px;
      color: #000000;
    }
  }
`;

export const Info = styled.p`
  font-size: 18px;
`;
interface EditableProps {
  variant?: BPOErrorType;
}

export const Editable = styled.div<EditableProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  select::-ms-expand {
    display: none;
  }

  svg {
    color: ${props =>
      props.variant ? errorType[props.variant].color : props.theme.colors.red};
    margin-bottom: 4px;
  }
`;

export const PopupContainer = styled.button`
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  flex: 1;
  height: 100%;
  gap: 5px;
  min-height: 24px;

  svg {
    color: ${props => props.theme.colors.darkBlue};
    display: none;
    margin-left: auto;
    /* margin-bottom: 4px; */
  }

  &:hover {
    svg {
      display: unset;
    }
  }
`;

export const ErrorMessage = styled.p<EditableProps>`
  color: ${props =>
    props.variant ? errorType[props.variant].color : props.theme.colors.red};
  padding: 10px;
  font-weight: 600;
`;

export const NotesContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const gridDropDownStyles: Partial<IDropdownStyles> = {
  root: {
    width: '150%',
    height: '28px',
  },
  title: {
    borderColor: '#dbdbdb',
    borderWidth: 0,
    backgroundColor: 'transparent',
    padding: 0,
    fontFamily: 'Source Sans Pro, sans-serif',
  },
  caretDownWrapper: {
    display: 'none',
  },
  callout: {
    minWidth: '75px',
    maxHeight: '450px',
  },
};
