import * as React from 'react';
import { UseStepperData } from '../../../../hooks/useStepperData';
import { ActionTypes, StepperData, IStep } from './types';

interface IUseActions {
  onInitSteps: (data: StepperData[], initialStep?: number | string) => void;
  onNextStep: () => void;
  onPreviousStep: () => void;
  onClearStep: () => void;
  onNavigateToStep: (index: number) => void;
  onReviewStep: (stepNumber: number) => void;
  onFinalizeStep: () => void;
  onResetSteps: () => void;
  onRefreshData: () => void;
}

interface Props {
  dispatch: React.Dispatch<ActionTypes>;
}
export default function useActions({ dispatch }: Props): IUseActions {
  const getInitialData = React.useCallback((data: StepperData[]) => {
    const initialData = data.map((props, index: number) => ({
      id: index + 1,
      ...props,
    }));
    return initialData as IStep[];
  }, []);

  const onInitSteps = React.useCallback(
    (data, initialStep) => {
      dispatch({
        type: 'INIT_STEPPER',
        payload: { steps: getInitialData(data), initialStep },
      });
    },
    [dispatch, getInitialData],
  );

  const onNextStep = React.useCallback(() => {
    dispatch({ type: 'NEXT_STEP' });
  }, [dispatch]);

  const onPreviousStep = React.useCallback(() => {
    dispatch({ type: 'PREVIOUS_STEP' });
  }, [dispatch]);

  const onClearStep = React.useCallback(() => {
    dispatch({ type: 'CLEAR_STEP' });
  }, [dispatch]);

  const onNavigateToStep = React.useCallback(
    (index: number) => {
      dispatch({ type: 'NAVIGATE_TO', payload: { index } });
    },
    [dispatch],
  );

  const onReviewStep = React.useCallback(
    (stepNumber: number) => {
      dispatch({ type: 'JUMP_TO', payload: { stepNumber } });
    },
    [dispatch],
  );

  const onFinalizeStep = React.useCallback(() => {
    dispatch({ type: 'SET_SUBMITTED' });
  }, [dispatch]);

  const onResetSteps = React.useCallback(() => {
    dispatch({ type: 'RESET_STEPPER' });
  }, [dispatch]);

  const onRefreshData = React.useCallback(
    (stepperData?: UseStepperData) => {
      dispatch({ type: 'SET_REFRESH_DATA', payload: { stepperData } });
    },
    [dispatch],
  );

  return React.useMemo(
    () => ({
      onInitSteps,
      onNextStep,
      onPreviousStep,
      onClearStep,
      onNavigateToStep,
      onReviewStep,
      onFinalizeStep,
      onResetSteps,
      onRefreshData,
    }),
    [
      onClearStep,
      onFinalizeStep,
      onInitSteps,
      onNavigateToStep,
      onNextStep,
      onPreviousStep,
      onRefreshData,
      onResetSteps,
      onReviewStep,
    ],
  );
}
