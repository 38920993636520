import styled from 'styled-components';

export const Container = styled.a`
  font-size: 10px;
  color: #ffffff;
  text-decoration: none solid rgb(255, 255, 255);
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
