/* eslint-disable react/function-component-definition */
import React, { useEffect, useState } from 'react';
import { DefaultPublisher, setData } from '@samc/common';
import { Entitlement } from '../models';
import { useClient } from './ClientContext';
import { useCurrentUser } from './CurrentUserContext';
import Entitlements from '../Entitlements';
import { UserSavedEvent } from '../events/UserSavedEvent';
import { WithChildren } from '../types';

type Entitlements = {
  idMap: Map<string, Entitlement>;
  array: Array<Entitlement>;
  groupMap: Map<string, Entitlement[]>;
};

const EntitlementsContext = React.createContext<
  Entitlements | null | undefined
>(undefined);

const EntitlementsProvider: React.FC<Partial<WithChildren>> = ({
  children,
}) => {
  const client = useClient();
  const currentUser = useCurrentUser();
  const [entitlements, setEntitlements] = useState<Entitlements | null>(null);
  const reloadEntitlements = () => {
    setEntitlements(null);
    if (currentUser.hasEntitlement(Entitlements.Entitlements.List)) {
      client.entitlements.listEntitlements().then(e => {
        const groupMap = new Map<string, Entitlement[]>();
        const idMap = new Map<string, Entitlement>();
        // eslint-disable-next-line no-restricted-syntax
        for (const entitlement of e) {
          if (!groupMap.has(entitlement.group)) {
            groupMap.set(entitlement.group, new Array<Entitlement>());
          }
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          groupMap.get(entitlement.group)!.push(entitlement);
          idMap.set(entitlement.id.toString(), entitlement);
        }
        setData('EntitlementsIdMap', idMap);
        setEntitlements({ groupMap, array: e, idMap });
      });
    }
  };
  useEffect(() => {
    if (client.isReady && currentUser.isLoaded && !currentUser.isDisabled) {
      reloadEntitlements();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, currentUser]);

  useEffect(() => {
    const subscriptionId = DefaultPublisher.subscribe<UserSavedEvent>(
      UserSavedEvent.eventName,
      reloadEntitlements,
    );
    return () =>
      DefaultPublisher.unsubscribe(UserSavedEvent.eventName, subscriptionId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, currentUser]);

  return (
    <EntitlementsContext.Provider value={entitlements}>
      {children}
    </EntitlementsContext.Provider>
  );
};

function useEntitlementIdMap() {
  const context = React.useContext(EntitlementsContext);
  if (context === undefined) {
    throw new Error(
      'useEntitlementIdMap must be used within an EntitlementsProvider!',
    );
  }
  return context?.idMap;
}

function useEntitlementGroupMap() {
  const context = React.useContext(EntitlementsContext);
  if (context === undefined) {
    throw new Error(
      'useEntitlementGroupMap must be used within an EntitlementsProvider!',
    );
  }
  return context?.groupMap;
}

function useEntitlementArray() {
  const context = React.useContext(EntitlementsContext);
  if (context === undefined) {
    throw new Error(
      'useEntitlementArray must be used within an EntitlementsProvider!',
    );
  }
  return context?.array;
}

export {
  EntitlementsProvider,
  useEntitlementArray,
  useEntitlementGroupMap,
  useEntitlementIdMap,
};
