import { useState } from 'react';

export default function useComplexState<T>(
  model: T,
): [T, (action: (m: T) => void) => void, (mod: T) => void] {
  const [state, setState] = useState(model);
  const [update, setUpdate] = useState(0);
  const replaceState = (mod: T) => {
    setState(mod);
  };

  const updateState = (action: (m: T) => void) => {
    action(state);
    setUpdate(update + 1);
  };

  return [state, updateState, replaceState];
}
