import React from 'react';
import { UseAUSActions, AUSActionTypes } from './types';

interface Props {
  dispatch: (action: AUSActionTypes) => void;
}

export default function useAUSActions({ dispatch }: Props): UseAUSActions {
  const onChangeAUSLoan = React.useCallback(
    loan => {
      dispatch({
        type: 'SET_AUS_LOAN',
        payload: { loan },
      });
    },
    [dispatch],
  );

  const onChangeAUSCredits = React.useCallback(
    creditInfo => {
      dispatch({
        type: 'SET_AUS_LOAN',
        payload: { creditInfo },
      });
    },
    [dispatch],
  );

  const onDeleteAUSLoanDocument = React.useCallback(() => {
    dispatch({
      type: 'REMOVE_AUS_LOAN_DOCUMENT',
    });
  }, [dispatch]);

  const onDeleteAUSCreditDocument = React.useCallback(
    stagingId => {
      dispatch({
        type: 'REMOVE_AUS_CREDIT_DOCUMENT',
        payload: { stagingId },
      });
    },
    [dispatch],
  );

  return {
    onChangeAUSLoan,
    onChangeAUSCredits,
    onDeleteAUSLoanDocument,
    onDeleteAUSCreditDocument,
  };
}
