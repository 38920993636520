import * as React from 'react';
import { Loading } from '../..';
import useStepperData from '../../../../hooks/useStepperData';
import Button from '../../Button';
import { useStepper } from '../context';
import { ButtonWrapper, Container } from './styles';

type NavProps = {
  isLoading?: boolean;
};

function Nav({ isLoading }: NavProps) {
  const stepper = useStepper();
  const { state, onNextStep, onPreviousStep, onRefreshData, onFinalizeStep } =
    stepper;

  const stepperData = useStepperData();

  /*
   * This is supposed to detect changes to steps and transfer them to the stepper from stepperData.
   * Without this check, a perpetual loop of rendering occurs.
   */
  React.useEffect(() => {
    if (state.isRevalidating) {
      onRefreshData(stepperData);
    } else {
      let hasChange = false;
      if (stepperData.data.stepperData.length !== state.steps.length) {
        hasChange = true;
      } else {
        hasChange = stepperData.data.stepperData.some((step, index) => {
          if (
            state.steps[index].isValid !== step.isValid ||
            state.steps[index].nextButtonLabel !== step.nextButtonLabel ||
            state.steps[index].onSubmit !== step.onSubmit
          ) {
            return true;
          }
          return false;
        });
      }

      if (hasChange) {
        onRefreshData(stepperData);
      }
    }
  }, [onRefreshData, state.isRevalidating, state.steps, stepperData]);

  const isDisabled = React.useMemo(() => {
    return (
      state.steps[state.currentStep] && !state.steps[state.currentStep].isValid
    );
  }, [state.currentStep, state.steps]);

  const isLastStep = state.currentStep === state.steps.length - 1;

  const handleNext = React.useCallback(() => {
    const { onSubmit } = state.steps[state.currentStep];
    if (onSubmit) {
      onSubmit(
        isLastStep ? onFinalizeStep : onNextStep,
        stepper.onNavigateToStep,
      );
    } else {
      onNextStep();
    }

    if (state.steps[state.currentStep].refreshData) {
      onRefreshData();
    }
  }, [
    isLastStep,
    onFinalizeStep,
    onNextStep,
    onRefreshData,
    state.currentStep,
    state.steps,
    stepper.onNavigateToStep,
  ]);

  if (!state.steps[state.currentStep]) {
    return null;
  }

  if (state.isSubmitted) {
    return null;
  }

  const loadingMessage =
    state.steps[state.currentStep].loadingMessage || 'Loading...';

  return (
    <Container>
      {isLoading && <Loading message={loadingMessage} />}
      {stepperData.isSaving && <Loading message={stepperData.savingMessage} />}
      <ButtonWrapper>
        {state.currentStep > 0 && (
          <Button
            navButton="previous"
            onClick={onPreviousStep}
            variant="secondary"
            alternate
          >
            Previous
          </Button>
        )}

        <Button
          navButton={
            state.currentStep < state.steps.length - 1 ? 'next' : undefined
          }
          onClick={handleNext}
          disabled={isDisabled}
          isLoading={isLoading}
          variant="primary"
        >
          {state.steps[state.currentStep].nextButtonLabel || 'Next'}
        </Button>
      </ButtonWrapper>
    </Container>
  );
}

export default Nav;
