/* eslint-disable react/function-component-definition */
import React, { useEffect, useContext, useState } from 'react';
import { DefaultPublisher } from '@samc/common';
import { useClient } from './ClientContext';
import { UserSavedEvent } from '../events';
import { Company } from '../models';
import { WithChildren } from '../types';

const CompaniesContext = React.createContext<Company[] | undefined>(undefined);

const CompaniesProvider: React.FC<Partial<WithChildren>> = ({ children }) => {
  const client = useClient();
  const [companies, setCompanies] = useState(new Array<Company>());

  const loadCompanies = () => {
    let isCanceled = false;
    if (client.isReady) {
      // User is authenticated and has a token, so we can get the current user's data.
      client.company.listCompanies().then(companyResponse => {
        if (!isCanceled) {
          const companyList = [];
          const situsCompanyList = [];
          // eslint-disable-next-line no-restricted-syntax
          for (const company of companyResponse) {
            if (company.name.startsWith('SitusAMC')) {
              situsCompanyList.push(company);
            } else {
              companyList.push(company);
            }
          }
          setCompanies(situsCompanyList.concat(companyList));
        }
      });
    }
    return () => {
      isCanceled = true;
    };
  };
  useEffect(loadCompanies, [client]);

  useEffect(() => {
    const subscriptionId = DefaultPublisher.subscribe<UserSavedEvent>(
      UserSavedEvent.eventName,
      () => {
        loadCompanies();
      },
    );
    return () => {
      DefaultPublisher.unsubscribe(UserSavedEvent.eventName, subscriptionId);
    };
  });

  return (
    <CompaniesContext.Provider value={companies}>
      {children}
    </CompaniesContext.Provider>
  );
};

function useCompanies() {
  const context = useContext(CompaniesContext);
  if (context === undefined) {
    throw new Error('useCompanies must be used within a CurrentUserProvider!');
  }
  return context;
}

export { CompaniesProvider, useCompanies };
