import { AxiosError } from 'axios';
import React from 'react';
import { useMutation } from '../hooks/useMutation';
import api, { BPO_ENDPOINT_PATH } from '../services/api';

interface UpdateCustomerOrderIdsParams {
  orderId: string;
  orderItemId: string;
  loanId?: string;
  clarityLoanId: number;
  customerReferenceId1: string;
  customerReferenceId2: string;
  customerReferenceId3: string;
}

async function updateCustomerOrderIds(formData: UpdateCustomerOrderIdsParams) {
  const response = await api.patch(
    `${BPO_ENDPOINT_PATH}/UpdateCustomerOrderIds`,
    formData,
  );

  return response.data;
}

export function useUpdateCustomerOrderIds() {
  return useMutation<void, AxiosError<ErrorData>, UpdateCustomerOrderIdsParams>(
    React.useCallback(params => updateCustomerOrderIds(params), []),
  );
}
