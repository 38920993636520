/* eslint-disable react/function-component-definition */
import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { DefaultPublisher } from '@samc/common';
import Role from '../../../models/Role';
import User from '../../../models/User';
import { EditRoleEvent } from '../../../events/EditRoleEvent';
import { EditUserEvent } from '../../../events/EditUserEvent';
import { AdditionalUserSettings } from '../../../models';

interface IEditorActionRendererParams extends ICellRendererParams {
  userDataFieldSettings: AdditionalUserSettings;
}

const EditorActionRenderer: React.FC<IEditorActionRendererParams> = ({
  data,
  value,
  userDataFieldSettings,
}) => {
  const classes =
    'agGridAction text-blue-7 w-full outline-none focus:outline-none text-left';
  if (!(data instanceof Role) && !(data instanceof User)) {
    // eslint-disable-next-line no-console
    console.error(
      "EditorActionRenderer used for a type of object that isn't handled by editor.",
    );
    return null;
  }
  const editAction =
    data instanceof Role
      ? () => DefaultPublisher.publish(new EditRoleEvent(data))
      : () =>
          DefaultPublisher.publish(
            new EditUserEvent(data as User, false, userDataFieldSettings),
          );

  return (
    <button type="button" className={classes} onClick={editAction}>
      {value}
    </button>
  );
};

export default EditorActionRenderer;
