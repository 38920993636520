import * as React from 'react';
import Package from '../../../../package.json';
import { Container } from './styles';

export default function AppVersion() {
  return (
    <Container>
      <strong>Version:</strong> {Package.version}
    </Container>
  );
}
