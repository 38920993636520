import { IEvent, Guid } from '@samc/common';

export class UserRemovedFromRoleEvent implements IEvent {
  public name: string = UserRemovedFromRoleEvent.eventName;

  static eventName = 'UserRemovedFromRoleEvent' as const;

  roleId: Guid;

  userId: Guid;

  constructor(userId: Guid, roleId: Guid) {
    this.userId = userId;
    this.roleId = roleId;
  }
}
