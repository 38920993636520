import React from 'react';
import { useOrder } from '../context/order';
import { useValidateBpoOrder } from './useValidateBpoOrder';

export const useValidateBpoCorrections = () => {
  const { order } = useOrder();
  const { isLoading, onValidateBpoOrder } = useValidateBpoOrder();

  const onValidateBpoCorrections = React.useCallback(
    (onNextStep?: () => void, onNavigateToStep?) => {
      const hasChanges = order.bpoLoanValidation.data.some(
        loan => loan.hasChange,
      );

      if (hasChanges) {
        onValidateBpoOrder({
          onNextStep: () => {
            // Do nothing
          },
        });
      } else if (
        !order.bpoLoanValidation.hasUnselected &&
        !order.bpoLoanValidation.hasError &&
        !order.bpoLoanValidation.hasUnacknowledgedWarning &&
        onNextStep
      ) {
        onNextStep();
      } else if (
        order.bpoLoanValidation.hasError ||
        order.bpoLoanValidation.hasUnacknowledgedWarning
      ) {
        onNavigateToStep(2);
      } else {
        // Not exptected to get here
      }
    },
    [
      onValidateBpoOrder,
      order.bpoLoanValidation.data,
      order.bpoLoanValidation.hasError,
      order.bpoLoanValidation.hasUnacknowledgedWarning,
      order.bpoLoanValidation.hasUnselected,
    ],
  );

  return React.useMemo(
    () => ({
      isLoading,
      onValidateBpoCorrections,
    }),
    [isLoading, onValidateBpoCorrections],
  );
};
