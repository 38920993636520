/* eslint-disable react/function-component-definition */
import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import Checkbox from '../../Checkbox';

const CheckboxRenderer: React.FC<ICellRendererParams> = ({
  value,
  setValue,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  return <Checkbox setValue={setValue ?? (() => {})} value={value} />;
};

export default CheckboxRenderer;
