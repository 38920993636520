import { AxiosError } from 'axios';
import React from 'react';
import { useMutation } from '../hooks/useMutation';
import api from '../services/api';

export async function removeSecurentUpload({
  fileStagingId,
}: {
  fileStagingId: string;
}) {
  await api.delete('RWOrder/fileStagingId', {
    params: { fileStagingId },
  });
}

export function useRemoveSecurentUpload() {
  return useMutation<void, AxiosError<ErrorData>, { fileStagingId: string }>(
    React.useCallback(
      ({ fileStagingId }) => removeSecurentUpload({ fileStagingId }),
      [],
    ),
  );
}
