import React from 'react';
import {
  IFileSource,
  IRawMetaData,
  ISourcePickerInput,
  ISourceStepFileChange,
  ISourceStepUpdatePreview,
} from './interfaces';
import DataImport from './DataImport';
import SourceFileStep from './SourceFileStep';

export interface SourcePickerProps {
  verificationToken: string;
  client: string;
  user: string;
  dataImportUrl?: string;
  documentUrl?: string;
  configurationUrl?: string;
  finalize?: (evt: ISourcePickerInput) => void;
  updatePreview?: (evt: ISourceStepUpdatePreview) => void;
  fileChange?: (evt: ISourceStepFileChange) => void;
  afterPager?: () => void;
  title?: string;
  compress?: boolean;
  excludeIncompleteImports?: boolean;
  excludeCompletedImports?: boolean;
  doNotPreload?: boolean;
  hideExcel?: boolean;
  hideFlat?: boolean;
  hideDbf?: boolean;
  initialMetaData?: IRawMetaData | null;
  initialFileSource?: IFileSource | null;
  finalizeText?: string;
}

export default function SourcePicker({
  finalize,
  compress,
  ...props
}: SourcePickerProps) {
  const [metaData, setMetaData] = React.useState<IRawMetaData | null>(null);
  const [fileSource, setFileSource] = React.useState<IFileSource | null>(null);

  const onFinalize = React.useCallback(() => {
    if (metaData !== null && fileSource !== null && finalize) {
      finalize(JSON.parse(JSON.stringify({ metaData, fileSource })));
    }
  }, [fileSource, finalize, metaData]);

  return (
    <DataImport wrapUp {...props}>
      <SourceFileStep compress={compress} />
    </DataImport>
  );
}
