import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { ToastMessage } from '@samc/react-ui-core';
import { ITooltipParams } from 'ag-grid-community';
import axios, { AxiosError, CancelTokenSource } from 'axios';
import { useCurrentUser } from '../../features/authorization';
import api from '../../services/api';
import { useToast } from '../../context/toaster';
import { useEvent } from '../../context/event';
import {
  Heading,
  Loading,
  Tab,
  Tabs,
  Downloading,
} from '../../components/Common';
import ClarityOrder from '../../components/OrderTracking/ClarityOrder';
import { formatErrorMessage } from '../../utils/helpers';
import { Container, Content } from './styles';
import OrdersGrid from '../../components/OrderTracking/OrdersGrid';
import ExtendedGridFieldConfiguration from '../../types/ExtendedGridFieldConfiguration';
import BPOPanel from '../../components/OrderTracking/BPOPanel';
import { OrderTrackingData, useOrders } from '../../queries/useOrders';
import { queryClient } from '../../services/queryClient';
import { OrderTrackingLoan } from '../../context/order/appraisal/types';
import ClarityBPOOrder from '../../components/OrderTracking/ClarityBPOOrder';
import { initialLoanBPOState } from '../../context/order/initialState';
import { useOrderTrackingLoanSearch } from '../../queries/useOrderTrackingLoanSearch';

interface Location {
  orderId: string;
}
let source: CancelTokenSource;
function OrderTracking() {
  const downloadingRef = React.useRef<HTMLDivElement | null>(null);
  const { connection } = useEvent();
  const useQuery = () => {
    return new URLSearchParams(useLocation<Location>().search);
  };
  const currentUser = useCurrentUser();
  const query = useQuery();
  const highlightedRow = query.get('orderId') || undefined;
  const { toastError } = useToast();
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [isDownloading, setIsDownloading] = React.useState(false);
  const [selectedOrder, setSelectedOrder] = React.useState<
    OrderTrackingData | undefined
  >(undefined);
  const [activeRow, setActiveRow] = React.useState<string | undefined>(
    undefined,
  );
  const [selectedTab, setSelectedTab] = React.useState(selectedIndex || 0);

  const { data: orders, isLoading, error } = useOrders('TrackingOrders');

  const onActiveRow = React.useCallback(orderId => {
    setActiveRow(prevState => {
      return prevState === orderId ? undefined : orderId;
    });
  }, []);

  const [isOpenClarityBPOPanel, setisOpenClarityBPOPanel] =
    React.useState(false);
  const [isOpenBPOEditAddress, setIsOpenBPOEditAddress] = React.useState(false);
  const [
    isOpenBPOEditAddressCancelConfirm,
    setIsOpenBPOEditAddressCancelConfirm,
  ] = React.useState(false);

  const [bpoLoanSelected, setbpoLoanSelected] =
    React.useState<OrderTrackingLoan>(initialLoanBPOState);

  const handleDownloadResults = React.useCallback(
    async (
      orderId: string,
      orderNumber: string,
      reportType: string,
    ): Promise<void> => {
      setIsDownloading(true);

      let response;
      let fileName;

      source = axios.CancelToken.source();
      try {
        if (reportType === 'Initial') {
          fileName = `Initial_Report_Order_${orderNumber}`;
          response = await api.get(`Reports/${orderId}/initial`, {
            responseType: 'blob',
            cancelToken: source.token,
          });
        } else {
          const formData: never[] = [];
          fileName = `Updated_Report_Order_${orderNumber}`;
          response = await api.post(
            `Reports/completed?orderId=${orderId}&reportType=Appraisal_Pdf`,
            formData,
            {
              responseType: 'blob',
              cancelToken: source.token,
            },
          );
        }
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${fileName || ''}${'.pdf'}`);
        downloadingRef.current?.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      } catch (err) {
        const message = await formatErrorMessage(err as AxiosError<ErrorData>);

        if (!message) {
          return;
        }

        toastError(<ToastMessage title="Error" message={message} />);
      } finally {
        setIsDownloading(false);
      }
    },
    [toastError],
  );
  const handleClosingDownloading = React.useCallback(() => {
    setIsDownloading(false);
    source.cancel();
  }, []);

  const [loanSearchText, _setLoanSearchText] = React.useState('');
  const [resultOrders, setResultOrders] = React.useState<string[] | null>(null);
  const orderTrackingLoanSearchMutation = useOrderTrackingLoanSearch();

  const setLoanSearchText = React.useCallback(
    (text: string) => {
      const searchText = text.trim();
      _setLoanSearchText(searchText);
      if (searchText !== '') {
        orderTrackingLoanSearchMutation.mutate(
          { clientLoanId: searchText },
          {
            onSuccess: response => {
              setResultOrders(response.orderIds);
            },
          },
        );
      } else {
        setResultOrders(null);
      }
    },
    [orderTrackingLoanSearchMutation],
  );

  const allOrders: OrderTrackingData[] = React.useMemo(() => {
    return orders
      ? orders.filter(
          order =>
            order.orderStatus !== 'Accepted' &&
            (resultOrders === null || resultOrders.includes(order.orderId)),
        )
      : [];
  }, [orders, resultOrders]);

  const completedOrders: OrderTrackingData[] = React.useMemo(() => {
    return orders
      ? orders.filter(
          order =>
            order.orderStatus === 'Completed' &&
            (resultOrders === null || resultOrders?.includes(order.orderId)),
        )
      : [];
  }, [orders, resultOrders]);

  const pendingOrders: OrderTrackingData[] = React.useMemo(() => {
    return orders
      ? orders.filter(
          order =>
            order.orderStatus.match(/^Action Required/i) &&
            (resultOrders === null || resultOrders.includes(order.orderId)),
        )
      : [];
  }, [orders, resultOrders]);

  const erroredOrders: OrderTrackingData[] = React.useMemo(() => {
    return orders
      ? orders.filter(
          order =>
            order.orderStatus === 'Error' &&
            (resultOrders === null || resultOrders.includes(order.orderId)),
        )
      : [];
  }, [orders, resultOrders]);

  const inProgressOrders: OrderTrackingData[] = React.useMemo(() => {
    return orders
      ? orders.filter(
          order =>
            order.orderStatus === 'In Progress' &&
            (resultOrders === null || resultOrders.includes(order.orderId)),
        )
      : [];
  }, [orders, resultOrders]);

  const openClarityBpoPanel = React.useCallback(
    (loanDispute: OrderTrackingLoan) => {
      setbpoLoanSelected(loanDispute);
      setisOpenClarityBPOPanel(true);
    },
    [],
  );

  const openEditAddressModal = React.useCallback((isOpen: boolean) => {
    setIsOpenBPOEditAddress(isOpen);
  }, []);

  const openEditAddressCancelConfirmModal = React.useCallback(
    (isOpen: boolean) => {
      setIsOpenBPOEditAddressCancelConfirm(isOpen);
    },
    [],
  );

  const hasModifyOrderEntitlement = React.useMemo(
    () => currentUser.hasEntitlement('Modify Order Identifier'),
    [currentUser],
  );

  const fields: ExtendedGridFieldConfiguration[] = React.useMemo(() => {
    return [
      {
        field: 'orderStatus',
        headerName: 'Status',
        cellRenderer: 'BtnCellRenderer',
        filter: false,
        valueFormatter: () => 'View',
        tooltipValueGetter: (params: ITooltipParams) =>
          params.valueFormatted || '',
        minWidth: 230,
        isPinned: 'left',
      },
      {
        field: 'orderStatus',
        headerName: 'Actions',
        cellRenderer: 'BtnCellRendererActions',
        cellRendererParams: {
          clicked(order: OrderTrackingData) {
            setSelectedOrder(order);
          },
          handleDownloadResults,
        },
        filter: false,
        valueFormatter: () => 'View',
        tooltipValueGetter: (params: ITooltipParams) =>
          params.valueFormatted || '',
        minWidth: 220,
        isPinned: 'left',
      },
      {
        field: 'orderNumber',
        headerName: 'Order #',
        type: 'string',
        minWidth: 140,
      },
      {
        field: 'productName',
        headerName: 'Product',
        cellRenderer: 'ProductCellRenderer',
        minWidth: 190,
      },
      {
        field: 'divisionName',
        headerName: 'Division',
        type: 'string',
        minWidth: 140,
      },
      {
        field: 'orderIdentifier',
        headerName: 'Order Identifier',
        type: 'string',
        cellRenderer: hasModifyOrderEntitlement
          ? 'EditableRenderer'
          : undefined,
        tooltipValueGetter: ({ value }) => value || '',
        minWidth: 180,
      },
      {
        field: 'details',
        headerName: 'Details',
        type: 'string',
        singleClickEdit: true,
        minWidth: 150,
      },
      {
        field: 'createdBy',
        headerName: 'Submitted By',
        type: 'string',
        minWidth: 230,
      },
      {
        field: 'submittedDate',
        headerName: 'Submitted On',
        type: 'date',
        minWidth: 170,
      },
    ];
  }, [handleDownloadResults, hasModifyOrderEntitlement]);

  const onBack = React.useCallback(() => {
    if (!isOpenBPOEditAddress && !isOpenBPOEditAddressCancelConfirm) {
      setSelectedOrder(undefined);
      setisOpenClarityBPOPanel(false);
      queryClient.invalidateQueries(['orders', 'TrackingOrders']);
    }
  }, [isOpenBPOEditAddress, isOpenBPOEditAddressCancelConfirm]);

  React.useEffect(() => {
    if (connection) {
      connection.on('SendCompletedOrderAsync', () => {
        queryClient.invalidateQueries(['orders', 'TrackingOrders']);
        setSelectedIndex(0);
      });
    }

    return () => {
      if (connection) {
        connection.off('SendCompletedOrderAsync');
      }
    };
  }, [connection]);

  if (error) {
    const handleError = async () => {
      const err = error as AxiosError<ErrorData>;

      const message = await formatErrorMessage(err);

      toastError(<ToastMessage title="Error" message={message} />);
    };

    handleError();
  }

  if (isOpenClarityBPOPanel) {
    return (
      <Container>
        {isLoading && <Loading />}
        {selectedOrder &&
          selectedOrder.productName === 'Broker Price Opinion' &&
          isOpenClarityBPOPanel && (
            <ClarityBPOOrder
              selectedOrder={selectedOrder}
              onBack={onBack}
              loanSelected={bpoLoanSelected}
            />
          )}
      </Container>
    );
  }

  return (
    <Container>
      {isLoading && <Loading />}
      {selectedOrder &&
        selectedOrder.productName === 'Broker Price Opinion' && (
          <BPOPanel
            selectedBPOOrder={selectedOrder}
            onBack={onBack}
            openClarityBpoPanel={openClarityBpoPanel}
            isOpenBPOEditAddress={isOpenBPOEditAddress}
            isOpenPBOEditAddressCancelConfirm={
              isOpenBPOEditAddressCancelConfirm
            }
            openEditAddressModal={openEditAddressModal}
            openEditAddressCancelConfirm={openEditAddressCancelConfirmModal}
          />
        )}
      {selectedOrder && selectedOrder.productName === 'Appraisal Review' ? (
        <ClarityOrder onBack={onBack} selectedOrder={selectedOrder} />
      ) : (
        <>
          <Heading bottomLeft="Order Tracking" />
          <Content>
            <Tabs selectedTab={selectedTab} onSelectTab={setSelectedTab}>
              <Tab
                label="All Orders"
                count={allOrders.length}
                index={0}
                variant="All"
              >
                <OrdersGrid
                  fields={fields}
                  orders={allOrders}
                  setSelectedOrder={setSelectedOrder}
                  activeRow={activeRow}
                  onActiveRow={onActiveRow}
                  highlightedRow={highlightedRow}
                  loanSearchText={loanSearchText}
                  setLoanSearchText={setLoanSearchText}
                />
              </Tab>
              <Tab
                label="Complete"
                count={completedOrders.length}
                index={1}
                variant="Complete"
              >
                <OrdersGrid
                  fields={fields}
                  orders={completedOrders}
                  setSelectedOrder={setSelectedOrder}
                  activeRow={activeRow}
                  onActiveRow={onActiveRow}
                  highlightedRow={highlightedRow}
                  loanSearchText={loanSearchText}
                  setLoanSearchText={setLoanSearchText}
                />
              </Tab>
              <Tab
                label="Errors"
                count={erroredOrders.length}
                index={2}
                variant="Error"
              >
                <OrdersGrid
                  fields={fields}
                  orders={erroredOrders}
                  setSelectedOrder={setSelectedOrder}
                  activeRow={activeRow}
                  onActiveRow={onActiveRow}
                  highlightedRow={highlightedRow}
                  loanSearchText={loanSearchText}
                  setLoanSearchText={setLoanSearchText}
                />
              </Tab>
              <Tab
                label="Action Required"
                count={pendingOrders.length}
                index={3}
                variant="Action Required"
              >
                <OrdersGrid
                  fields={fields}
                  orders={pendingOrders}
                  setSelectedOrder={setSelectedOrder}
                  activeRow={activeRow}
                  onActiveRow={onActiveRow}
                  highlightedRow={highlightedRow}
                  loanSearchText={loanSearchText}
                  setLoanSearchText={setLoanSearchText}
                />
              </Tab>
              <Tab
                label="In Progress"
                count={inProgressOrders.length}
                index={4}
                variant="In Progress"
              >
                <OrdersGrid
                  fields={fields}
                  orders={inProgressOrders}
                  setSelectedOrder={setSelectedOrder}
                  activeRow={activeRow}
                  onActiveRow={onActiveRow}
                  highlightedRow={highlightedRow}
                  loanSearchText={loanSearchText}
                  setLoanSearchText={setLoanSearchText}
                />
              </Tab>
            </Tabs>
          </Content>
          <Downloading
            ref={downloadingRef}
            onDismiss={handleClosingDownloading}
            isOpen={isDownloading}
            width={340}
            height={258}
          />
        </>
      )}
    </Container>
  );
}

export default OrderTracking;
