/* eslint-disable react/no-unescaped-entities */
import * as React from 'react';
import { DefaultButton, Dialog, PrimaryButton } from '@fluentui/react';
import { RiErrorWarningLine } from 'react-icons/ri';
import {
  Content,
  DialogFooter,
  Countdown,
  ContentMessage,
  dialogButtonStyles,
} from './styles';
import useMonitorOkta from '../../../hooks/useMonitorOkta';

export interface ExpirationModalProps {
  warningTimeout: number;
  // remainingSecondsToLogout: number;
  handleLogout?: () => void;
  disabled?: boolean;
}

function ExpirationModal({
  handleLogout,
  warningTimeout,
  // remainingSecondsToLogout,
  disabled = false,
}: ExpirationModalProps) {
  const {
    timerMinutes,
    timerSecconds,
    isIdle: isOpen,
    isIdleError,
    onLogOut: handleCancel,
    onContinue: handleConfirm,
  } = useMonitorOkta({
    warningTimeout, // amount of seconds before logout to show the logout warning
    // remainingSecondsToLogout, // total seconds of inactive time to wait before logging user out
    handleLogout,
    disabled,
  });

  if (disabled) {
    return null;
  }

  return (
    <Dialog
      hidden={!isOpen}
      styles={{
        main: {
          borderBottom: '12px solid #bd0e08',
        },
      }}
      minWidth={350}
      modalProps={{
        layerProps: {
          styles: {
            root: { zIndex: 99999999 },
          },
        },
      }}
    >
      <Content>
        <RiErrorWarningLine size={40} color="#bd0e08" />
        <ContentMessage>
          <h3>{isIdleError ? 'Error' : 'Session About to Expire'}</h3>
          {isIdleError ? 'We were unable to renew your session. ' : ''}
          You will be logged out in:
          <Countdown>
            {timerMinutes}:{timerSecconds}
          </Countdown>
        </ContentMessage>
      </Content>
      <DialogFooter>
        <DefaultButton
          styles={dialogButtonStyles}
          onClick={handleCancel}
          text="Log Out"
        />
        <PrimaryButton
          styles={dialogButtonStyles}
          onClick={handleConfirm}
          text={isIdleError ? 'Try Again' : 'Stay Connected'}
        />
      </DialogFooter>
    </Dialog>
  );
}

export default ExpirationModal;
