import React, { CSSProperties } from 'react';
import './Modal.css';
import { useAuthenticationConfig } from '../contexts/AuthenticationConfigContext';

type ModalProps = {
  description?: string;
  showButtons: boolean;
  firstOptionText: string;
  firstOptionHandler: React.MouseEventHandler<HTMLDivElement>;
  isVisible: boolean;
  secondOptionText: string;
  secondOptionHandler: React.MouseEventHandler<HTMLDivElement>;
  title: string;
};

function Modal({
  description,
  showButtons,
  firstOptionText,
  firstOptionHandler,
  isVisible,
  secondOptionText,
  secondOptionHandler,
  title,
}: ModalProps) {
  const config = useAuthenticationConfig();
  const wrapperStyle =
    config.modalsZIndex === undefined || config.modalsZIndex === null
      ? {}
      : ({ zIndex: config.modalsZIndex } as CSSProperties);

  return (
    <div
      data-auth-okta
      className={`wrapper ${isVisible ? '' : 'disabled'}`}
      style={wrapperStyle}
    >
      <div className="overlay" />
      <div className="alert">
        <div className="header">
          <div className="content">
            <div className="title">{title}</div>
            <div className="description">{description}</div>
          </div>
        </div>
        {showButtons ? (
          <div className="actions space-x-4">
            <div className="grow" />
            <div className="button" onClick={firstOptionHandler}>
              {firstOptionText}
            </div>
            <div className="button" onClick={secondOptionHandler}>
              {secondOptionText}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Modal;
