/* eslint-disable react/function-component-definition */
import React, { useEffect, useState, CSSProperties } from 'react';
import { DefaultPublisher } from '@samc/common';
import { CSSTransition } from 'react-transition-group';
import { User, Role } from '../models';
import UserForm from '../components/form/User/UserForm';
import RoleForm from '../components/form/Role/RoleForm';
import { useClient } from '../contexts/ClientContext';
import './Editor.css';
import Patience from '../components/Patience';
import {
  EditUserEvent,
  EditRoleEvent,
  RoleSavedEvent,
  UserSavedEvent,
  EditCanceledEvent,
  CloseModalEvent,
  OpenModalEvent,
} from '../events';
import { tabLocker } from '../helpers/tabLocker';

type EditorProps = {
  styling?: CSSProperties;
};

// This isn't a true view, exactly; it provides the placement and activation/deactivation for the active form.
// It also provides a CSS transition animation for opening and closing the form.
const Editor: React.FC<EditorProps> = ({ styling }) => {
  const client = useClient();
  const [visible, setVisible] = useState(false);
  const [form, setForm] = useState<React.ReactNode>(undefined);
  const nodeRef = React.useRef<HTMLDivElement>(null);

  const stopEditing = () => {
    setVisible(false);
    setForm(undefined);
  };

  const editUser = (evt: EditUserEvent) => {
    setVisible(true);
    const user = evt.user;
    if (!user.id.isEmpty()) {
      client.users.getUser(user.id).then(u => {
        const formModel = evt.asClone ? User.copy(u) : u;
        setForm(
          <UserForm
            user={formModel}
            isClone={evt.asClone}
            userDataFieldSettings={evt.additionalUserSettings}
          />,
        );
      });
    } else {
      // Starting with a fresh one, because Id is empty
      setForm(
        <UserForm
          user={user}
          userDataFieldSettings={evt.additionalUserSettings}
        />,
      );
    }
  };

  const editRole = (evt: EditRoleEvent) => {
    setVisible(true);
    const editEvent = evt as EditRoleEvent;
    const role = editEvent.role;
    if (!role.id.isEmpty()) {
      client.roles.getRole(role.id).then(r => {
        const [success, possibleRole] = r;
        if (success) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const myRole = possibleRole!;
          const formModel = editEvent.asClone ? Role.copy(myRole) : myRole;
          setForm(<RoleForm role={formModel} isClone={editEvent.asClone} />);
        }
      });
    } else {
      // Starting with a fresh one, because Id is empty
      setForm(<RoleForm role={role} />);
    }
  };

  const keyHandler = (evt: KeyboardEvent) => {
    if (form !== undefined) {
      if (evt.key === 'Escape') {
        DefaultPublisher.publish(new EditCanceledEvent());
        return;
      }
      tabLocker(evt, nodeRef);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', keyHandler);
    const editCanceledSubscriptionId =
      DefaultPublisher.subscribe<EditCanceledEvent>(
        EditCanceledEvent.eventName,
        stopEditing,
      );
    const userSavedSubscriptionId = DefaultPublisher.subscribe<UserSavedEvent>(
      UserSavedEvent.eventName,
      stopEditing,
    );
    const roleSavedSubscriptionId = DefaultPublisher.subscribe<RoleSavedEvent>(
      RoleSavedEvent.eventName,
      stopEditing,
    );
    const userEditSubscriptionId = DefaultPublisher.subscribe<EditUserEvent>(
      EditUserEvent.eventName,
      editUser,
    );
    const roleEditSubscriptionId = DefaultPublisher.subscribe<EditRoleEvent>(
      EditRoleEvent.eventName,
      editRole,
    );
    const openModalSubscriptionId = DefaultPublisher.subscribe<OpenModalEvent>(
      OpenModalEvent.eventName,
      () => {
        if (form !== undefined) {
          document.removeEventListener('keydown', keyHandler);
        }
      },
    );
    const closeModalSubscriptionId =
      DefaultPublisher.subscribe<CloseModalEvent>(
        CloseModalEvent.eventName,
        () => {
          if (form !== undefined) {
            document.addEventListener('keydown', keyHandler);
          }
        },
      );
    return () => {
      document.removeEventListener('keydown', keyHandler);
      DefaultPublisher.unsubscribe(
        EditUserEvent.eventName,
        userEditSubscriptionId,
      );
      DefaultPublisher.unsubscribe(
        EditRoleEvent.eventName,
        roleEditSubscriptionId,
      );
      DefaultPublisher.unsubscribe(
        EditCanceledEvent.eventName,
        editCanceledSubscriptionId,
      );
      DefaultPublisher.unsubscribe(
        UserSavedEvent.eventName,
        userSavedSubscriptionId,
      );
      DefaultPublisher.unsubscribe(
        RoleSavedEvent.eventName,
        roleSavedSubscriptionId,
      );
      DefaultPublisher.unsubscribe(
        OpenModalEvent.eventName,
        openModalSubscriptionId,
      );
      DefaultPublisher.unsubscribe(
        CloseModalEvent.eventName,
        closeModalSubscriptionId,
      );
    };
  }, [client, form]);

  return (
    <>
      <div
        className={
          visible
            ? 'fixed w-full h-full bg-mono-13 opacity-25 z-10 top-0'
            : 'hidden'
        }
      />
      <CSSTransition
        in={visible}
        nodeRef={nodeRef}
        timeout={300}
        classNames="flyout"
      >
        <Patience showPatience={form !== undefined && !visible}>
          <div ref={nodeRef} className="editor bg-mono-1 z-20" style={styling}>
            {form}
          </div>
        </Patience>
      </CSSTransition>
    </>
  );
};

export default Editor;
