import { IDropdownStyles } from '@fluentui/react';
import styled from 'styled-components';
import { BPOErrorType } from '../../../context/order/bpo/types';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px;
  gap: 20px;
`;

export const BPOErrorHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
  overflow: hidden;
`;

export const GridWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const BPOEValidationFooter = styled.div`
  margin: -10px -15px -20px;
  font-size: 14px;
`;

export const Button = styled.button`
  height: 100%;
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.primary};
  font-size: 12px;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
`;

export const DeleteContainer = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  outline: none;
  p {
    margin-left: 5px;
    font-size: ${props => props.theme.fontSize.base};
    color: ${props => props.theme.colors.red};
    font-weight: bold;
  }
`;

interface ErrorType {
  [key: string]: {
    color: string;
  };
}

const errorType: ErrorType = {
  Error: {
    color: '#BD0E08',
  },
  Address: {
    color: '#BD0E08',
  },
  Warning: {
    color: '#e6930a',
  },
  Duplicated: {
    color: '#646464',
  },
};

interface EditableProps {
  variant?: BPOErrorType;
}

export const Editable = styled.div<EditableProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  select,
  input {
    font-size: 14px;
    background: transparent;
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
  }

  select::-ms-expand {
    display: none;
  }

  svg {
    color: ${props =>
      props.variant
        ? errorType[props.variant].color
        : props.theme.colors.primary};
    margin-bottom: 4px;
  }
`;

export const ErrorMessage = styled.p<EditableProps>`
  color: ${props =>
    props.variant
      ? errorType[props.variant].color
      : props.theme.colors.primary};
  font-weight: 600;
`;

export const gridDropDownStyles: Partial<IDropdownStyles> = {
  root: {
    width: '150%',
    height: '28px',
  },
  title: {
    borderColor: '#dbdbdb',
    borderWidth: 0,
    backgroundColor: 'transparent',
    padding: 0,
    fontFamily: 'Source Sans Pro, sans-serif',
  },
  caretDownWrapper: {
    display: 'none',
  },
  callout: {
    minWidth: '75px',
    maxHeight: '450px',
  },
};
interface LinkButtonProps {
  fontSize?: number;
}

export const LinkButton = styled.button<LinkButtonProps>`
  border: 0;
  background: transparent;
  color: ${props => props.theme.colors.primary};
  font-weight: 600;
  padding-left: 5px;
  font-size: ${props => (props.fontSize ? `${props.fontSize}px` : '14px')};
  &:hover {
    text-decoration: underline;
  }
`;

export const ExclamationIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  background-color: #ffffff;
  border: 1px solid #b8b8b8;
  border-radius: 50%;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  font-weight: 700;
  font-size: 13px;
  color: #bd0e08;
  margin-bottom: 4px;
`;

export const LoadingContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
`;
