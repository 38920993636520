import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1;
`;

export const ModalBody = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-width: 320px;
  min-height: 200px;
  border-radius: 2px;
  background-color: #ffffff;
  background-size: cover;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.7);
  padding: 20px;
`;

export const ModalCloseButton = styled.button`
  position: absolute !important;
  top: 20px;
  right: 20px;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  height: 100%;
  padding: 20px;
  gap: 10px;
  position: relative;
`;

export const CloseModalButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
`;
