/* eslint-disable react/function-component-definition */
import React, { useEffect, useState, useRef } from 'react';
import './FillRemainingContainer.css';
import { WithChildren } from '../types';

type FillRemainingContainerProps = Partial<WithChildren> & {
  className?: string;
  /** By default, FillRemainingContainer will set its height based on the remaining height of its immediate parent. If this has a value, it will find the nearest matching ancestor and use its height instead. */
  querySelectorForParent?: string;
  /** Point above the bottom at which to stop */
  leaveSpace?: number;
};

const FillRemainingContainer: React.FC<FillRemainingContainerProps> = ({
  className,
  querySelectorForParent,
  children,
  leaveSpace = 0,
}) => {
  const [top, setTop] = useState<number | undefined>(undefined);
  const [height, setHeight] = useState<number | undefined>(undefined);
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const timer = setInterval(checkForResize, 250);
    return () => clearInterval(timer);
  }, [ref]);

  useEffect(() => {
    if (ref.current && top) {
      const div = ref.current;
      const parent = querySelectorForParent
        ? div.closest(querySelectorForParent)
        : div.parentElement;
      if (!parent) {
        // eslint-disable-next-line no-console
        console.warn(
          'Cannot set the height for a FillRemainingContainer object without a parent.',
        );
        return;
      }
      const parentRect = parent.getBoundingClientRect();
      setHeight(parentRect.height - top - leaveSpace);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [top]);

  const checkForResize = () => {
    if (ref.current) {
      const newTop = ref.current.getBoundingClientRect().top;
      setTop(newTop);
    }
  };

  return (
    <div
      ref={ref}
      className={`fillRemainingContainer ${className}`}
      style={{ height: `${height}px` }}
    >
      {children}
    </div>
  );
};

export default FillRemainingContainer;
