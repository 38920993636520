import { Guid, getData } from '@samc/common';
import Entitlement from './Entitlement';
import { parseDate } from '../helpers/parseDate';
import Base from './Base';

class Role extends Base<Role> {
  name: string;

  description: string;

  displayName: string;

  entitlements: Entitlement[];

  updated: Date;

  updatedBy: Guid;

  updatedByUser: string;

  // The user experience for clone - it starts by cloning, but then wipes the fields not appropriate
  // for creating a new user based on an existing one.
  static copy = (role: Role) => {
    const clone = role.clone();
    clone.id = Guid.createEmpty();
    clone.updated = new Date(0);
    clone.updatedBy = Guid.createEmpty();
    clone.name = '';
    clone.displayName = '';
    // If user clones something that's system-controlled, they should be able to edit it.
    clone.isSystemControlled = false;
    return clone;
  };

  constructor(id?: Guid, name?: string) {
    super(id, name);
    this.name = '';
    this.description = '';
    this.displayName = '';
    this.entitlements = new Array<Entitlement>();
    this.updated = new Date(0);
    this.updatedBy = Guid.createEmpty();
    this.updatedByUser = '';
  }

  isDirty(original: Role) {
    if (!(original instanceof Role)) {
      return false;
    }
    return (
      this.displayName !== original.displayName ||
      this.description !== original.description
    );
  }

  getDirtyFields(original: Role) {
    if (!(original instanceof Role)) {
      return [];
    }

    const result = [];

    if (this.displayName !== original.displayName) {
      result.push('displayName');
    }

    if (this.description !== original.description) {
      result.push('description');
    }

    return result;
  }

  areEntitlementsDirty(original: Role) {
    if (!original?.entitlements && !this?.entitlements) {
      return false; // one or both are undefined, so can't really compare.
    }

    if (this.entitlements.length !== original.entitlements.length) {
      return true; // different size, guaranteed to not match
    }
    const ids: Record<string, string> = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const entitlement of this.entitlements) {
      ids[entitlement.id.toString()] = 'a';
    }

    return original.entitlements.some(e => !ids[e.id.toString()]);
  }

  clone() {
    const clone = new Role(this.id, this.name);
    clone.description = this.description;
    clone.displayName = this.displayName;
    clone.entitlements = this.entitlements.map(e => e.clone());
    clone.isSystemControlled = this.isSystemControlled;
    clone.updatedByUser = this.updatedByUser;
    return clone;
  }

  toCreateRequest() {
    return {
      name: this.displayName,
      displayName: this.displayName,
      description: this.description,
    };
  }

  toEditRequest() {
    return {
      name: this.name,
      displayName: this.displayName,
      description: this.description,
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromJson(json: any) {
    const entitlements = getData<Map<Guid, Entitlement>>('EntitlementsIdMap');
    const role = new Role(Guid.parse(json.id), json.name);
    role.description = json.description;
    role.displayName = json.displayName;
    role.updated = parseDate(json.lastUpdated, role.updated);
    role.updatedBy = Guid.parse(json.lastUpdatedBy);
    role.isSystemControlled = json.isSystemControlled;
    role.updatedByUser = json.updatedByUser;
    if (json.entitlements) {
      // eslint-disable-next-line no-restricted-syntax
      for (const entitlement of json.entitlements) {
        // Use the universal list if available - it's primarily to support the Role form,
        // and the only likely case for entitlements not being available is the load of current user
        // Thus, it's okay to fall back to generating the entitlement here instead of using the main collection
        const e =
          entitlements?.get(entitlement.id) ??
          Entitlement.fromJson(entitlement);
        role.entitlements.push(e);
      }
    }
    return role;
  }
}

export default Role;
