import * as React from 'react';
import { Container } from './styles';

interface ProgressBarProps {
  progress: number;
  height?: string;
  width?: string;
}

function ProgressBar({ progress, ...props }: ProgressBarProps) {
  return (
    <Container progress={progress} {...props}>
      <div />
    </Container>
  );
}

export default ProgressBar;
