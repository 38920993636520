import { useQuery, UseQueryResult } from 'react-query';
import api from '../services/api';

export type GetAppraisalOrderResponse = {
  orderId: string;
  stagingId: string;
  orderStatus: string;
  orderNumber: number;
  companyDivision: number;
  productInfo: {
    vendorId: string;
    productId: string;
    productName: string;
    price: number;
  };
  identifier: string;
  createdBy: string;
  createdOn: string;
  submittedBy: string;
  submittedOn: string;
  loans: {
    orderItemId: string;
    loanId: string;
    loanNumber: number;
    clientLoanId: string;
    clarityLoanNumber: number;
    loanStatus: string;
    borrowerLastName: string;
    accessDetails: string;
    address: string;
    unitNumber: string;
    city: string;
    state: string;
    zip: string;
    valuation: number;
    productInfo: {
      vendorId: string;
      productId: string;
      productName: string;
      price: number;
    };
    providerRequestType: string;
    documents: {
      documentId: string;
      documentName: string;
      typeId: string;
    }[];
    disputeActionType: string;
  }[];
};

export async function getAppraisalOrder(
  orderId: string,
): Promise<GetAppraisalOrderResponse> {
  const response = await api.get<GetAppraisalOrderResponse>(`Order/${orderId}`);
  return response.data;
}

export function useAppraisalOrder({
  orderId,
  productName,
}: {
  orderId: string;
  productName: string;
}): UseQueryResult<GetAppraisalOrderResponse, unknown> {
  return useQuery(
    ['appraisalOrder', orderId],
    () => getAppraisalOrder(orderId),
    {
      staleTime: 0,
      enabled: !!orderId && productName === 'Appraisal Review',
    },
  );
}
