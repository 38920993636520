/* eslint-disable react/function-component-definition */
import React, { useCallback } from 'react';
import { Guid } from '@samc/common';
import {
  User,
  Company,
  AdditionalUserSettings,
  AdditionalUserField,
} from '../../../models';
import { ValidationErrors } from '../../../validators';
import Field from '../Field';
import Checkbox from '../../Checkbox';
import { useCurrentUser, useUserMap } from '../../../contexts';
import Status from '../../Status';
import { ComboBox } from '../../ComboBox';
import { useServiceConfig } from '../../../contexts/ServiceConfigContext';
import { ISelectOption } from '../../../interfaces';

type UserFieldsProps = {
  user?: User;
  validationErrors: ValidationErrors;
  isReadOnly?: boolean;
  dirtyFields: string[];
  emailBlurred: () => void;
  wasSaveAttempted: boolean;
  companyOptions: Array<ISelectOption<Company>>;
  originalSelectedCompanyOption: ISelectOption<Company> | null;
  update: (propertyNames: string[], action: (user: User) => void) => void;
  userDataFieldSettings: AdditionalUserSettings;
};

const UserFields: React.FC<UserFieldsProps> = ({
  user,
  validationErrors,
  isReadOnly,
  dirtyFields,
  emailBlurred,
  wasSaveAttempted,
  companyOptions,
  originalSelectedCompanyOption,
  update,
  userDataFieldSettings,
}) => {
  const ref = useCallback((node: HTMLInputElement) => {
    if (node) {
      node.focus();
      node.select();
    }
  }, []);
  const serviceConfig = useServiceConfig();
  const currentUser = useCurrentUser();
  const users = useUserMap();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const additionalValues: Record<string, any> = {};

  if (userDataFieldSettings === undefined) {
    userDataFieldSettings = new AdditionalUserSettings();
  }
  if (!user) {
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const previousData: Record<string, any> =
    user.userData !== '' ? (JSON.parse(user.userData) as object) : {};

  userDataFieldSettings.fields.forEach(f => {
    const keyName = f.fieldName;

    if (previousData[keyName] !== undefined) {
      additionalValues[keyName] = previousData[keyName];
    } else {
      additionalValues[keyName] = '';
      previousData[keyName] = '';
    }
  });

  const updateCustom = (propertyName: string, propertyValue: string) => {
    additionalValues[propertyName] = propertyValue;
    update(['userData'], u => {
      u.userData = JSON.stringify(additionalValues);
    });
  };

  const updater = users?.get(user.updatedBy.toString());
  return (
    <div className="grid grid-cols-2 gap-8">
      <div>
        <Field
          label="Username"
          name="username"
          dirtyFields={dirtyFields}
          validationErrors={validationErrors}
          isReadOnly
          model={user}
          wasSaveAttempted={wasSaveAttempted}
        >
          <input type="text" value={user.username} readOnly />
        </Field>
        <Field
          label="Email"
          name="email"
          dirtyFields={dirtyFields}
          validationErrors={validationErrors}
          isReadOnly={isReadOnly}
          model={user}
          wasSaveAttempted={wasSaveAttempted}
          isRequired
        >
          <input
            ref={ref}
            type="text"
            value={user.email}
            onBlur={emailBlurred}
            onChange={e =>
              update(['email'], u => {
                u.email = e.target.value;
              })
            }
          />
        </Field>
        <Field
          label="First Name"
          name="firstName"
          dirtyFields={dirtyFields}
          validationErrors={validationErrors}
          isReadOnly={isReadOnly}
          model={user}
          wasSaveAttempted={wasSaveAttempted}
          isRequired
        >
          <input
            type="text"
            value={user.firstName}
            onChange={e =>
              update(['firstName'], u => {
                u.firstName = e.target.value;
              })
            }
          />
        </Field>
        <Field
          label="Last Name"
          name="lastName"
          dirtyFields={dirtyFields}
          validationErrors={validationErrors}
          isReadOnly={isReadOnly}
          model={user}
          wasSaveAttempted={wasSaveAttempted}
          isRequired
        >
          <input
            type="text"
            value={user.lastName}
            onChange={e =>
              update(['lastName'], u => {
                u.lastName = e.target.value;
              })
            }
          />
        </Field>

        <Field
          label="Company"
          name="company"
          dirtyFields={dirtyFields}
          validationErrors={validationErrors}
          isReadOnly={isReadOnly}
          model={user}
          wasSaveAttempted={wasSaveAttempted}
        >
          <ComboBox
            initialSelectedValue={originalSelectedCompanyOption}
            values={companyOptions}
            isDisabled={isReadOnly}
            hasClearButton
            selectionChanged={v =>
              update(['company'], u => {
                u.company = v?.value ?? null;
              })
            }
            createOptionValue={t => new Company(Guid.createEmpty(), t)}
            /* If we have a serviceConfig value AND it says we're using Clarity's company data, then you can't create new companies. Otherwise, you can. */
            isCreateAllowed={
              serviceConfig ? !serviceConfig.usesClarityCompanies : true
            }
          />
        </Field>

        <div className="grid grid-cols-2 gap-x-3">
          {userDataFieldSettings.showSsoIdField && (
            <Field
              label="External SSO ID"
              name="ssoId"
              dirtyFields={dirtyFields}
              validationErrors={validationErrors}
              isReadOnly={isReadOnly}
              model={user}
              wasSaveAttempted={wasSaveAttempted}
            >
              <input
                type="text"
                value={user.ssoId}
                onChange={e =>
                  update(['ssoId'], u => {
                    u.ssoId = e.target.value;
                  })
                }
              />
            </Field>
          )}
          {userDataFieldSettings.fields
            .filter(uf => {
              return uf.visible != null
                ? uf.visible.isVisible(user, userDataFieldSettings)
                : true;
            })
            .map((f: AdditionalUserField) => (
              <Field
                key={f.fieldName}
                label={f.label}
                name={f.fieldName}
                dirtyFields={dirtyFields}
                validationErrors={validationErrors}
                isReadOnly={isReadOnly}
                model={additionalValues}
                wasSaveAttempted={wasSaveAttempted}
              >
                <input
                  type="text"
                  value={additionalValues[f.fieldName]}
                  onChange={e => updateCustom(f.fieldName, e.target.value)}
                />
              </Field>
            ))}
        </div>
      </div>
      <div className="pt-2 font-proxima text-1">
        <div className="bg-mono-2 h-full p-4">
          <Checkbox
            value={user.isDisabled}
            setValue={e =>
              update(['isDisabled'], u => {
                u.isDisabled = e;
              })
            }
            label="Disable Account"
            readonly={user.id.equals(currentUser.id)}
          />
          <hr className="w-3/5 border-mono-6 mt-sm" />
          <div className="text-mono-11 mt-md">
            <Status
              label="Disabled"
              value={user.disabledDate}
              className="mt-md"
              hasDateClassName="text-red-2"
              showTime
            />
            <Status
              label="Last Login"
              value={user.lastLogin}
              className="mt-md"
              showTime
            />
            <Status
              label="Last Updated"
              value={user.updated}
              className="mt-md"
              showTime
            />
            <Status
              label="Last Updated By"
              value={updater?.username}
              className="mt-md"
            />
            <Status
              label="Created"
              value={user.created}
              className="mt-md"
              showTime
            />
          </div>
        </div>
      </div>
    </div>
  );
};
// <input type="text" value={user.company} onChange={(e) => update(["company"], (u) => u.company = e.target.value)} />
export default UserFields;
