import React, { useContext, useEffect } from 'react';
import { MessageBus } from '@samc/message-bus';
import { WithChildren } from '../types';

const MessageBusContext = React.createContext<MessageBus | null>(null);
type MessageBusProviderProps = WithChildren & { bus?: MessageBus };

function MessageBusProvider({ bus, children }: MessageBusProviderProps) {
  const log = (window as any).$log || console; // use centricity client logging if available.

  useEffect(() => {
    if (bus !== undefined) {
      log.info('Starting the message bus with provider');
      bus.start();
    }
    return () => {
      if (bus !== undefined) {
        log.info('Closing the message bus with the provider');
        bus.close();
      }
    };
  }, []);

  return (
    <MessageBusContext.Provider value={bus ?? null}>
      {children}
    </MessageBusContext.Provider>
  );
}

function useMessageBus() {
  return useContext(MessageBusContext);
}

export { MessageBusProvider, useMessageBus };
