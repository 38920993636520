import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  /* margin: 30px 0 0; */
`;

export const Title = styled.div`
  font-weight: 700;
  font-family: ${props => props.theme.fontFamily.main};
  font-size: ${props => props.theme.fontSize.lg};
  color: #000;
`;

export const List = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 20px;
`;
