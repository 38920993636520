import React from 'react';
import { FiAlertCircle } from 'react-icons/fi';
import { useOrder } from '../../../context/order';
import { Button, useStepper } from '../../Common';
import { Container, Title, Text } from './styles';

type BPONoLoanDataProps = {
  message: string;
};

function BPONoLoanData({ message }: BPONoLoanDataProps) {
  const { onNavigateToStep, onRefreshData } = useStepper();
  const {
    bpoActions: {
      onChangeBPOLoanError,
      onChangeBPOData,
      onChangeBPOValidationData,
    },
  } = useOrder();

  const onClick = () => {
    const fileSource = null;
    const metaData = {
      columnMeta: [],
      id: '',
      totalRowCount: 0,
    };

    onChangeBPOData(fileSource, metaData);
    onChangeBPOLoanError(true);

    onChangeBPOValidationData({
      data: [],
      errorLoans: [],
    });

    onRefreshData();

    onNavigateToStep(1);
  };

  return (
    <Container>
      <FiAlertCircle size={32} />
      <Title>No Loans</Title>
      <Text>{message}</Text>
      <Button onClick={onClick} width="211px">
        Return to Step 2
      </Button>
    </Container>
  );
}

export default BPONoLoanData;
