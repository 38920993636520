import { createRoot } from 'react-dom/client';
import { initializeIcons } from '@fluentui/react';
import { Authentication } from './features/authentication-okta';
import App from './App';
import { oktaConfig } from './utils/authConfig';

initializeIcons();

const root = document.getElementById('root');
if (root !== null) {
  createRoot(root).render(
    <Authentication config={oktaConfig}>
      <App />
    </Authentication>,
  );
}
