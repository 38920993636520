import { Role } from '../models';
import { ValidationErrors } from './ValidationErrors';

export function BuildErrorsForRole() {
  return new ValidationErrors(['displayName', 'description']);
}

export function ValidateRole(validationErrors: ValidationErrors, role?: Role) {
  validationErrors.resetLocal();

  if (!role) {
    // Still undefined, so nothing to validate
    return validationErrors;
  }

  if (!role.displayName) {
    validationErrors.addLocalError('displayName', 'Name must have a value.');
  }

  if (!role.description) {
    validationErrors.addLocalError(
      'description',
      'Description must have a value.',
    );
  }

  return validationErrors;
}
