import { ValidationErrors } from '../validators';

export type Header = {
  name: string;
  value: string;
};

export default class Methods {
  public url: string;

  public jwt: string;

  public defaultHeaders: Record<string, string> | Array<Header> | undefined;

  constructor(
    url: string,
    jwt: string,
    defaultHeaders?: Array<Header> | Record<string, string>,
  ) {
    this.url = url;
    this.jwt = jwt;
    this.defaultHeaders = defaultHeaders;
  }

  public get(route: string, addlHeaders: Array<Header> | null = null) {
    return this.emptyRequest(route, 'GET', addlHeaders);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public post(route: string, body: any = undefined) {
    return body
      ? this.bodyRequest(route, 'POST', body)
      : this.emptyRequest(route, 'POST');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public put(route: string, body: any) {
    return body
      ? this.bodyRequest(route, 'PUT', body)
      : this.emptyRequest(route, 'PUT');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public patch(route: string, body: any = undefined) {
    return body
      ? this.bodyRequest(route, 'PATCH', body)
      : this.emptyRequest(route, 'PATCH');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public delete(route: string, body: any = undefined) {
    return body
      ? this.bodyRequest(route, 'DELETE', body)
      : this.emptyRequest(route, 'DELETE');
  }

  // Little bit of https://gist.github.com/devloco/5f779216c988438777b76e7db113d05c,
  // Little bit of https://stackoverflow.com/a/59940621
  // eslint-disable-next-line class-methods-use-this
  public async download(response: Response, type?: string, filename?: string) {
    const blob = await response.blob();
    const downloadableBlob = new Blob([blob], { type });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window.navigator as any).msSaveOrOpenBlob(downloadableBlob, filename);
    } else {
      const file = window.URL.createObjectURL(downloadableBlob);
      const link = document.createElement('a');
      link.href = file;
      if (filename) {
        link.download = filename;
      }
      link.click();
    }
  }

  public emptyRequest(
    route: string,
    method: string,
    addlHeaders: Array<Header> | null = null,
  ) {
    return fetch(`${this.url}/${route}`, {
      method,
      headers: this.headers(addlHeaders),
    });
  }

  public bodyRequest(
    route: string,
    method: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    body: any = null,
    addlHeaders: Array<Header> | null = null,
  ) {
    return fetch(`${this.url}/${route}`, {
      method,
      headers: this.headers(addlHeaders),
      body: body !== null ? JSON.stringify(body) : null,
    });
  }

  public headers(addlHeaders: Array<Header> | null = null) {
    const result = new Headers({
      Authorization: `Bearer ${this.jwt}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });

    if (this.defaultHeaders && Array.isArray(this.defaultHeaders)) {
      // Handle the case where this.defaultHeaders is a Array<Header>
      // eslint-disable-next-line no-restricted-syntax
      for (const header of this.defaultHeaders) {
        result.set(header.name, header.value);
      }
    } else {
      // Handle the case where this.defaultHeaders is a Record<string, string>
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const key in this.defaultHeaders) {
        result.set(key, this.defaultHeaders[key]);
      }
    }

    addlHeaders?.forEach(h => {
      result.set(h.name, h.value);
    });

    return result;
  }

  // eslint-disable-next-line class-methods-use-this
  public async handleErrors(
    action: string,
    response: Response,
    updateValidationErrors?: (
      update: (errors: ValidationErrors) => void,
    ) => void,
  ) {
    if (response.ok || response.redirected) {
      return true;
    }
    const message = await response.text();
    if (response.status === 400) {
      // Validation failure
      // eslint-disable-next-line no-console
      console.warn(`Validation error for action "${action}"`);
      const errorJson = JSON.parse(message);
      // eslint-disable-next-line no-console
      console.warn(errorJson);
      if (updateValidationErrors) {
        updateValidationErrors(validationErrors => {
          // eslint-disable-next-line no-restricted-syntax, guard-for-in
          for (const name in errorJson) {
            const actualName = name.replace('Model.', '');
            const errors = errorJson[name] as string[];
            if (errors && errors.length > 0) {
              validationErrors.addServerErrors(actualName, errors);
            }
          }
        });
      }
      return false;
    }
    const error = `Failure for action "${action}"`;
    // eslint-disable-next-line no-console
    console.error(error);
    // eslint-disable-next-line no-console
    console.error(
      `Response Status: ${response.status} - ${response.statusText}`,
    );
    // eslint-disable-next-line no-console
    console.error(`Response Message: ${message}`);
    throw new Error(error);
  }
}
