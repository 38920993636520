import styled, { css } from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px;
  gap: 20px;
  overflow: auto;
`;

export const LoanIdContainer = styled.button`
  display: flex;
  flex: 1;
  height: 100%;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSize.base};
`;

export const HeaderAddressSuggestion = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  div {
    display: flex;
    flex-direction: column;
    gap: 5px;
    p {
      font-size: ${props => props.theme.fontSize.lg};
    }
  }
`;

export const Editable = styled.div`
  display: flex;
  flex-direction: column;

  select,
  input {
    font-size: 14px;
    background: transparent;
    /* width: 100%; */
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
  }

  select::-ms-expand {
    display: none;
  }
`;

export const UndoButton = styled.button`
  font-size: ${props => props.theme.fontSize.base};
  color: ${props => props.theme.colors.darkBlue};
`;

interface PopupContainerProps {
  isSelected?: boolean;
}

export const PopupContainer = styled.button<PopupContainerProps>`
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  flex: 1;
  height: 100%;
  gap: 5px;

  span {
    font-size: ${props => props.theme.fontSize.base};
    ${props =>
      props.isSelected &&
      css`
        font-weight: bold;
      `}
  }

  svg {
    color: ${props => props.theme.colors.darkBlue};
  }
`;

export const DeleteContainer = styled.button`
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  outline: none;

  p {
    margin-left: 5px;
    font-size: ${props => props.theme.fontSize.base};
    color: ${props => props.theme.colors.red};
    font-weight: bold;
  }
`;

export const SelectableContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  button {
    font-family: ${props => props.theme.fontFamily.main};
    font-size: ${props => props.theme.fontSize.base};
    color: #133d91;
    text-decoration: none solid rgb(19, 61, 145);
    background: transparent;
    border: none;
    cursor: pointer;
  }
`;

export const AddressNotFound = styled.div`
  strong {
    padding-left: 22px;
    font-size: ${props => props.theme.fontSize.base};
  }

  label p {
    font-weight: bold;
    color: #000;
  }
`;

export const Grid = styled.div`
  width: 100%;
`;

export const GridRow = styled.div`
  display: flex;
  flex-direction: row;

  &:nth-child(even) {
    background-color: #ffffff;
  }

  &:nth-child(odd) {
    background-color: #f0f0f0;
  }
`;

export const GridHeader = styled(GridRow)`
  &:nth-child(1) {
    background-color: white;
  }

  font-size: 13px;
  font-weight: bold;
  height: 28px;
`;

export const GridCell = styled.div`
  flex: 1 1 auto;
  border: solid 1px rgb(189, 195, 199);
  padding: 0px 11px;
  display: flex;
  align-items: center;
  margin-left: -1px;
  margin-top: -1px;

  &:first-child {
    margin-left: 0;
  }
`;

export const ExcelRowCell = styled(GridCell)`
  width: 85px;
`;

export const LoanIdCell = styled(GridCell)`
  width: 85px;
`;

export const OriginalAddressCell = styled(GridCell)`
  width: 350px;
`;

export const TypeCell = styled(GridCell)`
  width: 80px;
`;

export const AccessDetailsCell = styled(GridCell)`
  width: 150px;
`;

export const SuggestedAddressesCell = styled(GridCell)`
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 350px;

  label {
    font-size: 14px;
    min-height: 28px;

    > span {
      align-self: flex-start;
      margin-top: 6px;
    }
  }
`;

export const SuggestedAddressesCellNotFound = styled(SuggestedAddressesCell)`
  background-color: #ffe3e3;
  color: #bd0e08;
`;

export const SuggestedAddress = styled.div``;

export const DeleteCell = styled(GridCell)`
  flex: 0 0 auto;
  width: 120px;
`;

interface ErrorType {
  [key: string]: {
    color: string;
  };
}

const errorType: ErrorType = {
  Error: {
    color: '#BD0E08',
  },
  Address: {
    color: '#BD0E08',
  },
  Warning: {
    color: '#e6930a',
  },
  Duplicated: {
    color: '#646464',
  },
};

interface ErrorMessageProps {
  type?: string;
}

export const ErrorMessage = styled.div<ErrorMessageProps>`
  color: ${props =>
    props.type && errorType[props.type]
      ? errorType[props.type].color
      : props.theme.colors.primary};
  font-size: ${props => props.theme.fontSize.base};
  font-weight: bold;
  padding-left: 22px;
`;

export const CellContent = styled.div`
  flex: 1;
  display: flex;
  height: 100%;

  svg {
    display: none;
  }

  &:hover svg {
    display: inline-block;
  }
`;
