/* eslint-disable react/function-component-definition */
import React, { useState } from 'react';
import Droppable from './Droppable';
import './VerticalLists.css';
import Arrow from './Arrow.svg';
import DisabledArrow from './DisabledArrow.svg';
import Base from '../../models/Base';
import Button from '../Button';

type VerticalListsProps<T extends Base<T>> = {
  assignedModels: T[];
  availableModels: T[];
  typeName: string;
  groupProperty?: string;
  display?: (model: T) => string;
  addModels: (list: T[]) => void;
  removeModels: (list: T[]) => void;
  querySelectorForParent?: string;
};

const VerticalLists = <T extends Base<T>>(props: VerticalListsProps<T>) => {
  const [selectedAssignedModels, setSelectedAssignedModels] = useState(
    new Array<T>(),
  );
  const [selectedAvailableModels, setSelectedAvailableModels] = useState(
    new Array<T>(),
  );
  const {
    assignedModels,
    availableModels,
    typeName,
    groupProperty,
    display,
    addModels,
    removeModels,
    querySelectorForParent,
  } = props;

  const handleAssignedDrop = (models: T[]) => {
    // Get the items from the drag that aren't already selected;
    const newItems = models.filter(
      m => selectedAvailableModels.indexOf(m) === -1,
    );
    newItems.push(...selectedAvailableModels);
    addModels(newItems);
  };

  const handleAvailableDrop = (models: T[]) => {
    // Get the items from the drag that aren't already selected;
    const newItems = models.filter(
      m => selectedAssignedModels.indexOf(m) === -1,
    );
    newItems.push(...selectedAssignedModels);
    removeModels(newItems);
  };

  const assignClick = () => {
    if (selectedAvailableModels.length) {
      addModels(selectedAvailableModels);
    }
  };

  const availableClick = () => {
    if (selectedAssignedModels.length) {
      removeModels(selectedAssignedModels);
    }
  };

  return (
    <div className="grid grid-cols-21 gap-1 p-2">
      <Droppable
        models={assignedModels}
        containerClasses="col-span-10 bg-mono-1"
        title="Assigned"
        canFilter={false}
        groupProperty={groupProperty}
        display={display}
        typeName={typeName}
        dropped={handleAssignedDrop}
        querySelectorForParent={querySelectorForParent}
        selectedModels={selectedAssignedModels}
        setSelectedModels={setSelectedAssignedModels}
      />
      <div className="col-span-1 dragIconsContainer">
        <div className="dragIcons">
          <Button
            isDisabled={selectedAvailableModels.length === 0}
            onClick={assignClick}
          >
            <img
              className="arrow fill-current"
              src={selectedAvailableModels.length === 0 ? DisabledArrow : Arrow}
              alt="Assign"
            />
          </Button>
          <Button
            isDisabled={selectedAssignedModels.length === 0}
            onClick={availableClick}
          >
            <img
              className="arrow rightArrow fill-current"
              src={selectedAssignedModels.length === 0 ? DisabledArrow : Arrow}
              alt="Available"
            />
          </Button>
        </div>
      </div>
      <Droppable
        models={availableModels}
        containerClasses="col-span-10 bg-mono-1"
        title="Available"
        canFilter
        groupProperty={groupProperty}
        display={display}
        typeName={typeName}
        dropped={handleAvailableDrop}
        querySelectorForParent={querySelectorForParent}
        selectedModels={selectedAvailableModels}
        setSelectedModels={setSelectedAvailableModels}
      />
    </div>
  );
};

export default VerticalLists;
