/* eslint-disable react/function-component-definition */
import React, { useEffect, useRef } from 'react';
import './Button.css';
import { useCurrentUser } from '../contexts/CurrentUserContext';
import { WithChildren } from '../types';

export type ButtonProps = Partial<WithChildren> & {
  onClick: (() => void) | null;
  className?: string;
  entitlement?: string;
  isDisabled?: boolean;
  disabledClassName?: string;
  disabledReason?: string;
  isFocused?: boolean;
};

export type TextButtonProps = ButtonProps & {
  label: string;
};

/**
 * This button has three features:
 * 1. It automatically vertically centers children
 * 1. It checks an optional entitlements and shows as disabled if the user doesn't have the necessary rights.
 * 1. If isDisabled is set OR the entitlements fails, it applies the classes provided in disabledClassName.
 */

export const TextButton: React.FC<TextButtonProps> = (
  props: TextButtonProps,
) => {
  const { label } = props;
  return <Button {...props}>{label}</Button>;
};

const Button: React.FC<ButtonProps> = ({
  onClick,
  className = '',
  children,
  entitlement = null,
  disabledClassName = '',
  isDisabled = false,
  disabledReason,
  isFocused,
}) => {
  const currentUser = useCurrentUser();
  const ref = useRef<HTMLButtonElement>(null);
  let disabled = isDisabled;
  let disabledHover = null;
  let disabledText = disabledReason;
  let buttonClass = className;

  useEffect(() => {
    if (ref?.current && isFocused) {
      ref.current.focus();
    }
  });

  if (entitlement && !currentUser.hasEntitlement(entitlement)) {
    disabled = true;
    disabledText = "You don't have the required entitlement to complete this.";
  }

  if (disabled) {
    buttonClass = `disabled ${disabledClassName}`;
    if (disabledText) {
      disabledHover = (
        <span className="disabledText">
          {disabledText.split('\r\n').map((t, i) => (
            <span key={i} className="block">
              {t}
            </span>
          ))}
        </span>
      );
    }
    disabled = true;
  }

  const clickAction = disabled || onClick === null ? undefined : onClick;

  return (
    <button
      type="button"
      ref={ref}
      className={buttonClass}
      onClick={clickAction}
      disabled={disabled}
    >
      <span className="buttonContent">{children}</span>
      {disabledHover}
    </button>
  );
};

export default Button;
