import styled from 'styled-components';

interface ContainerProps {
  progress: number;
  height?: string;
  width?: string;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: ${props => (props.width ? props.width : '133px')};
  height: ${props => (props.height ? props.height : '10px')};
  border: 1px solid #c0c5cd;
  border-radius: 5px;
  background-color: #e9eaeb;
  background-size: cover;
  div {
    background-color: #36b300;
    width: ${props => props.progress}%;
    height: ${props => (props.height ? props.height : '10px')};
  }
`;
