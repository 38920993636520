import { Guid } from '@samc/common';
import Entitlement from './Entitlement';
import Role from './Role';
import Company from './Company';
import { parseDate } from '../helpers/parseDate';
import Base from './Base';
import { AuthenticationSchemes } from './AuthenticationSchemes';

class User extends Base<User> {
  username: string;

  systemId: string;

  email: string;

  firstName: string;

  lastName: string;

  name: string;

  company: Company | null;

  created: Date;

  updated: Date;

  updatedBy: Guid;

  isSystemAccount: boolean;

  termsOfUseAcceptance: boolean;

  termsOfUseAcceptanceDate?: Date;

  authenticationScheme?: AuthenticationSchemes; // Optional as it will only be set on create for now

  isDisabled: boolean;

  disabledDate: Date;

  lastLogin: Date;

  ssoId: string;

  userData: string;

  roles: Role[];

  entitlements: Entitlement[];

  updatedByUser: string;

  static SystemAccount = 'InternalSystemAccount';

  static RemoveSystemAccount(users: User[]) {
    return users.filter(u => u.username !== this.SystemAccount);
  }

  // The user experience for clone - it starts by cloning, but then wipes the fields not appropriate
  // for creating a new user based on an existing one.
  static copy = (user: User) => {
    const clone = user.clone();
    clone.id = Guid.createEmpty();
    clone.systemId = '';
    clone.isDisabled = false;
    clone.updated = new Date(0);
    clone.disabledDate = new Date(0);
    clone.created = new Date(0);
    clone.updatedBy = Guid.createEmpty();
    clone.email = ''; // When we make email a dropdown, may want to make sure the domain is preserved
    clone.firstName = '';
    clone.lastName = '';
    clone.username = '';
    clone.lastLogin = new Date(0);
    // If user clones something that's system-controlled, they should be able to edit it.
    clone.isSystemControlled = false;
    clone.ssoId = '';
    clone.userData = '{}';
    return clone;
  };

  constructor(id?: Guid, name?: string) {
    super(id, name);
    this.username = '';
    this.systemId = '';
    this.email = '';
    this.firstName = '';
    this.lastName = '';
    this.name = '';
    this.company = null;
    this.roles = new Array<Role>();
    this.entitlements = new Array<Entitlement>();
    this.created = new Date(0);
    this.updated = new Date(0);
    this.updatedBy = Guid.createEmpty();
    this.isSystemAccount = false;
    this.termsOfUseAcceptance = false;
    this.isDisabled = false;
    this.disabledDate = new Date(0);
    this.lastLogin = new Date(0);
    this.ssoId = '';
    this.userData = '{}';
    this.updatedByUser = '';
  }

  isDirty(original: User) {
    if (!(original instanceof User)) {
      return false;
    }

    return (
      this.firstName !== original.firstName ||
      this.lastName !== original.lastName ||
      (this.company == null && original.company !== null) ||
      this.company?.isDirty(original.company) ||
      this.email !== original.email ||
      this.isSystemAccount !== original.isSystemAccount ||
      this.userData !== original.userData ||
      this.ssoId !== original.ssoId
    );
  }

  getDirtyFields(original: User) {
    if (!(original instanceof User)) {
      return [];
    }

    const result = [];

    if (this.firstName !== original.firstName) {
      result.push('firstName');
    }

    if (this.lastName !== original.lastName) {
      result.push('lastName');
    }

    if (this.email !== original.email) {
      result.push('email');
    }

    if (this.company?.isDirty(original.company)) {
      result.push('company');
    }

    if (this.userData !== original.userData) {
      result.push('userData');
    }

    if (this.ssoId !== original.ssoId) {
      result.push('ssoId');
    }

    return result;
  }

  clone() {
    const clone = new User(this.id, this.name);
    clone.roles = this.roles.map(e => e.clone());
    clone.username = this.username;
    clone.firstName = this.firstName;
    clone.lastName = this.lastName;
    clone.email = this.email;
    clone.company = this.company;
    clone.isSystemAccount = this.isSystemAccount;
    clone.isDisabled = this.isDisabled;
    clone.disabledDate = this.disabledDate;
    clone.created = this.created;
    clone.updated = this.updated;
    clone.updatedBy = this.updatedBy;
    clone.lastLogin = this.lastLogin;
    clone.isSystemControlled = this.isSystemControlled;
    clone.ssoId = this.ssoId;
    clone.userData = this.userData;
    clone.updatedByUser = this.updatedByUser;
    return clone;
  }

  areRolesDirty(original: User) {
    if (!original?.roles && !this?.roles) {
      return false; // one or both are undefined, so can't really compare.
    }

    if (this.roles.length !== original.roles.length) {
      return true; // different size, guaranteed to not match
    }
    const ids: Record<string, string> = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const role of this.roles) {
      ids[role.id.toString()] = 'a';
    }

    return original.roles.some(e => !ids[e.id.toString()]);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromJson(json: any) {
    const user = new User(Guid.parse(json.id), json.name);
    user.username = json.username;
    user.firstName = json.firstName;
    user.lastName = json.lastName;
    user.email = json.email;
    user.name = json.name;
    user.isSystemAccount = json.isSystemAccount;
    user.isSystemControlled = json.isSystemControlled;
    user.isDisabled = json.isDisabled;
    user.disabledDate = parseDate(json.disabledDate, user.disabledDate);
    user.termsOfUseAcceptance = json.termsOfUseAcceptance;
    user.termsOfUseAcceptanceDate = parseDate(
      json.termsOfUseAcceptanceDate,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      user.termsOfUseAcceptanceDate!,
    );
    user.created = parseDate(json.created, user.created);
    user.updated = parseDate(json.lastUpdated, user.updated);
    user.updatedBy = Guid.parse(json.lastUpdatedBy);
    user.lastLogin = parseDate(json.lastLogin, user.lastLogin);
    user.systemId = json.systemId;
    user.ssoId = json.ssoId;
    user.userData = json.userData;
    user.updatedByUser = json.updatedByUser;
    user.company = json.company
      ? Company.fromJson(json.company)
      : new Company();

    if (json.roles) {
      // eslint-disable-next-line no-restricted-syntax
      for (const role of json.roles) {
        user.roles.push(Role.fromJson(role));
      }
    }

    if (json.entitlements) {
      // eslint-disable-next-line no-restricted-syntax
      for (const entitlement of json.entitlements) {
        user.entitlements.push(Entitlement.fromJson(entitlement));
      }
    }

    return user;
  }

  toCreateRequest() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const request: any = this.toEditRequest();
    request.authenticationScheme = this.authenticationScheme;
    return request;
  }

  toEditRequest() {
    const company = this.company
      ? {
          id: this.company.id.toString(),
          name: this.company.name,
        }
      : null;
    return {
      email: this.email,
      firstName: this.firstName,
      lastName: this.lastName,
      company,
      ssoId: this.ssoId,
      userData: this.userData,
    };
  }
}

export default User;
