/* eslint-disable react/function-component-definition */
import React, { useState, useEffect } from 'react';
import { ValidationErrors } from '../../validators';
import './Field.css';
import { WithChildren } from '../../types';

type FieldProps = Partial<WithChildren> & {
  label: string;
  name: string;
  isRequired?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  model: any; // Exists primarily for triggering useEffect to reset "hasHadFocus"
  dirtyFields: string[];
  validationErrors: ValidationErrors;
  isReadOnly?: boolean;
  wasSaveAttempted: boolean;
};

const Field: React.FC<FieldProps> = ({
  label,
  name,
  isRequired,
  model,
  dirtyFields,
  validationErrors,
  isReadOnly,
  wasSaveAttempted,
  children,
}) => {
  const [hasHadFocus, setHasHadFocus] = useState(false);
  const hasErrors = validationErrors.hasErrors(name);
  const fieldClasses = buildClasses(
    isRequired,
    isReadOnly,
    hasErrors && (hasHadFocus || wasSaveAttempted),
    dirtyFields.some(f => f === name),
  );

  useEffect(() => {
    setHasHadFocus(false);
  }, [model]);
  const errorMessages = hasErrors
    ? buildValidationErrors(validationErrors.getErrors(name))
    : null;

  return (
    <div className={fieldClasses} onBlurCapture={() => setHasHadFocus(true)}>
      <label>{label}</label>
      {children}
      <div className="validation">
        <span className="mdi mdi-alert-outline" />
        {errorMessages}
      </div>
    </div>
  );
};

function buildClasses(
  isRequired?: boolean,
  isReadOnly?: boolean,
  showErrors?: boolean,
  isDirty?: boolean,
) {
  let classes = 'field';

  if (isReadOnly) {
    classes += ' readOnly';
    return classes;
  }

  if (isRequired) {
    classes += ' required';
  }

  if (showErrors) {
    classes += ' invalid';
  }

  if (isDirty) {
    classes += ' dirty';
  }

  return classes;
}

function buildValidationErrors(errors?: string[]) {
  if (!errors) {
    return null;
  }
  // If only one error, return it as a single item
  if (errors.length === 1) {
    const error = errors[0];
    return <span className="validationError"> {error}</span>;
  }

  // Otherwise, return a list that we can put bullet points on
  return (
    <>
      <span> You must fix the following:</span>
      <ul>
        {errors.map(e => (
          <li key={e} className="validationError">
            {e}
          </li>
        ))}
      </ul>
    </>
  );
}

export default Field;
