/* eslint-disable react/function-component-definition */
import React from 'react';
import { useCurrentUser } from '../../contexts/CurrentUserContext';
import Button from '../Button';
import { WithChildren } from '../../types';

export type GridButtonProps = WithChildren & {
  action: () => void;
  entitlement?: string;
};

const GridButton: React.FC<GridButtonProps> = ({
  action,
  entitlement = null,
  children,
}) => {
  const user = useCurrentUser();
  const isDisabled = Boolean(entitlement && user.hasEntitlement(entitlement));
  return (
    <Button
      onClick={action}
      className="text-blue-7 float-right text-2"
      disabledClassName="text-mono-12 float-right text-2"
      isDisabled={isDisabled}
    >
      {children}
    </Button>
  );
};

export default GridButton;
