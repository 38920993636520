import Base from '../models/Base';

export function getAddedModels<T extends Base<T>>(
  currentStateList: Array<T>,
  originalList: Array<T>,
) {
  return currentStateList.filter(
    m => !originalList.some(o => o.id.equals(m.id)),
  );
}

export function getRemovedModels<T extends Base<T>>(
  currentStateList: Array<T>,
  originalList: Array<T>,
) {
  return originalList.filter(
    o => !currentStateList.some(m => m.id.equals(o.id)),
  );
}
