import React from 'react';
import {
  IFileSource,
  IRawMetaData,
} from '../../../components/Common/ImportComponents/interfaces';
import { ActionTypes } from '../types';

import {
  BPOLoanMode,
  IManualLoan,
  BPOLoanValidationData,
  UseBPOActions,
  BPOLoanData,
  Address,
} from './types';

interface Props {
  dispatch: (action: ActionTypes) => void;
}

export default function useBPOActions({ dispatch }: Props): UseBPOActions {
  const onChangeBPOLoanMode = React.useCallback(
    (bpoLoanMode: BPOLoanMode) => {
      dispatch({
        type: 'SET_BPO_LOAN_MODE',
        payload: { bpoLoanMode },
      });
    },
    [dispatch],
  );

  const onChangeBPODocument = React.useCallback(() => {
    dispatch({
      type: 'CLEAR_BPO_DOCUMENT',
    });
  }, [dispatch]);

  const onChangeBPOPreviewData = React.useCallback(() => {
    dispatch({
      type: 'CLEAR_BPO_DOC_PREVIEW_DATA',
    });
  }, [dispatch]);

  const onChangeBPOData = React.useCallback(
    (fileSource: IFileSource | null, metaData?: IRawMetaData) => {
      dispatch({
        type: 'SET_BPO_DOC_DATA',
        payload: { fileSource, metaData },
      });
    },
    [dispatch],
  );

  const onChangeBPOLoanError = React.useCallback(
    (isCompletedSuccessfully: boolean) => {
      dispatch({
        type: 'SET_BPO_LOAN_ERROR',
        payload: { isCompletedSuccessfully },
      });
    },
    [dispatch],
  );

  const onAddManualBPOLoanRow = React.useCallback(() => {
    dispatch({
      type: 'ADD_MANUAL_BPO_LOAN_ROW',
    });
  }, [dispatch]);

  const onRemoveManualBPOLoanRow = React.useCallback(
    (index: number) => {
      dispatch({
        type: 'REMOVE_MANUAL_BPO_LOAN_ROW',
        payload: { index },
      });
    },
    [dispatch],
  );

  const onChangeManualBPO = React.useCallback(
    (manualLoans: IManualLoan[]) => {
      dispatch({
        type: 'SET_MANUAL_BPO_LOAN_DATA',
        payload: { manualLoans },
      });
    },
    [dispatch],
  );

  const onChangeBPOValidationData = React.useCallback(
    (
      bpoLoanValidationData: BPOLoanValidationData,
      resetErrorsCorrections?: boolean,
    ) => {
      dispatch({
        type: 'SET_BPO_LOAN_VALIDATION_DATA',
        payload: { bpoLoanValidationData, resetErrorsCorrections },
      });
    },
    [dispatch],
  );

  const onChangeSingleBPOValidationData = React.useCallback(
    (loan: BPOLoanData) => {
      dispatch({
        type: 'SET_SINGLE_BPO_LOAN_VALIDATION_DATA',
        payload: { loan },
      });
    },
    [dispatch],
  );

  const onEditBPOLoanAddress = React.useCallback(
    (row: number, editedAddress: Address) => {
      dispatch({
        type: 'EDIT_BPO_LOAN_ADDRESS',
        payload: { row, editedAddress },
      });
    },
    [dispatch],
  );

  const onUpdateBPOValidationData = React.useCallback(
    (record: BPOLoanData) => {
      dispatch({
        type: 'PATCH_BPO_LOAN_VALIDATION_DATA',
        payload: { record },
      });
    },
    [dispatch],
  );

  const onRemoveRowBPOValidationData = React.useCallback(
    (rowIndex: number) => {
      dispatch({
        type: 'REMOVE_ROW_LOAN_VALIDATION_DATA',
        payload: { rowIndex },
      });
    },
    [dispatch],
  );

  const onRemoveDuplicatesBPOValidationData = React.useCallback(() => {
    dispatch({
      type: 'REMOVE_DUPLICATES_LOAN_VALIDATION_DATA',
    });
  }, [dispatch]);

  const onRemoveSelectedBPOValidationData = React.useCallback(
    (rows: number[]) => {
      dispatch({
        type: 'REMOVE_SELECTED_LOAN_VALIDATION_DATA',
        payload: { rows },
      });
    },
    [dispatch],
  );

  const onSelectBPOLoanAddress = React.useCallback(
    (row: number, bpoOrderStagingAddressId: string) => {
      dispatch({
        type: 'SET_SELECTED_ADDRESS',
        payload: { row, bpoOrderStagingAddressId },
      });
    },
    [dispatch],
  );

  const onUpdateBPOLoanData = React.useCallback(
    (updatedLoan: BPOLoanData) => {
      dispatch({
        type: 'UPDATE_BPO_LOAN_DATA',
        payload: { updatedLoan },
      });
    },
    [dispatch],
  );

  const onSetShowSavePrompt = React.useCallback(
    (value: boolean) => {
      dispatch({
        type: 'SET_SHOW_SAVE_PROMPT',
        payload: { value },
      });
    },
    [dispatch],
  );

  return React.useMemo(
    () => ({
      onChangeBPOLoanMode,
      onChangeBPOData,
      onChangeBPODocument,
      onChangeBPOPreviewData,
      onChangeBPOLoanError,
      onAddManualBPOLoanRow,
      onRemoveManualBPOLoanRow,
      onChangeManualBPO,
      onChangeBPOValidationData,
      onChangeSingleBPOValidationData,
      onEditBPOLoanAddress,
      onUpdateBPOValidationData,
      onRemoveRowBPOValidationData,
      onRemoveDuplicatesBPOValidationData,
      onRemoveSelectedBPOValidationData,
      onSelectBPOLoanAddress,
      onUpdateBPOLoanData,
      onSetShowSavePrompt,
    }),
    [
      onChangeBPOLoanMode,
      onChangeBPOData,
      onChangeBPODocument,
      onChangeBPOPreviewData,
      onChangeBPOLoanError,
      onAddManualBPOLoanRow,
      onRemoveManualBPOLoanRow,
      onChangeManualBPO,
      onChangeBPOValidationData,
      onChangeSingleBPOValidationData,
      onEditBPOLoanAddress,
      onUpdateBPOValidationData,
      onRemoveRowBPOValidationData,
      onRemoveDuplicatesBPOValidationData,
      onRemoveSelectedBPOValidationData,
      onSelectBPOLoanAddress,
      onUpdateBPOLoanData,
      onSetShowSavePrompt,
    ],
  );
}
