/* eslint-disable react/function-component-definition */
import React from 'react';
import { AuthorizationConfigProvider } from './contexts/AuthorizationConfigContext';
import { ClientProvider } from './contexts/ClientContext';
import { CurrentUserProvider } from './contexts/CurrentUserContext';
import AuthorizationConfig from './models/AuthorizationConfig';
import ValidUserCheck from './ValidUserCheck';
import TermsOfUse from './views/TermsOfUse';
import { WithChildren } from './types';

type AuthorizationProps = Partial<WithChildren> & {
  config: AuthorizationConfig;
};

const Authorization: React.FC<AuthorizationProps> = ({ config, children }) => {
  const components = (
    <AuthorizationConfigProvider config={config}>
      <ClientProvider>
        <CurrentUserProvider>
          <ValidUserCheck>
            <TermsOfUse>{children}</TermsOfUse>
          </ValidUserCheck>
        </CurrentUserProvider>
      </ClientProvider>
    </AuthorizationConfigProvider>
  );
  return config.setContainerId ? <div id="auth">{components}</div> : components;
};

export default Authorization;
