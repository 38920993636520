import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 52px;
  border-top-width: 1px;
  border-style: solid;
  border-color: #c8c8c8;
  background-size: cover;
  font-size: 14px;

  &:first-child {
    border-top-width: 0;
  }
`;
