/* eslint-disable react/function-component-definition */
import React from 'react';
import { User } from '../../../models';
import { UserSystemData } from '../../../models/UserSystemData';
import { doStringsMatch } from '../../../helpers/stringCompare';
import Button from '../../Button';
import { useAuthConfig } from '../../../contexts';
import Alert, { AlertType } from '../../Alert';

type UserSystemDataRefreshProps = {
  canBeShown: boolean;
  systemData?: UserSystemData;
  user: User;
  currentState: User;
  update: (propertyNames: string[], action: (user: User) => void) => void;
  keepShowing: (show: boolean) => void;
};

const UserSystemDataRefresh: React.FC<UserSystemDataRefreshProps> = ({
  systemData,
  canBeShown,
  user,
  currentState,
  update,
  keepShowing,
}) => {
  const config = useAuthConfig();

  const updateData = () => {
    keepShowing(false);
    if (systemData) {
      update(['email', 'firstName', 'lastName'], u => {
        u.email = systemData.email;
        u.firstName = systemData.firstName;
        u.lastName = systemData.lastName;
      });
    }
  };

  if (canBeShown && showUpdate(user, systemData)) {
    const text = currentState.id.isEmpty()
      ? 'This user is already registered as a SitusAMC user. Do you want to use their existing information?'
      : `Some user information was updated recently outside of ${config.appName}. Do you want to use the new information?`;
    const okButtonText = currentState.id.isEmpty()
      ? 'Use Existing Info'
      : 'Use Updated Info';

    return (
      <Alert type={AlertType.NotBoring}>
        {text}
        <div>
          <Button
            onClick={updateData}
            className="p-1 rounded mt-xs bg-blue-6 text-mono-1 min-w-1/4"
          >
            {okButtonText}
          </Button>
          <Button
            onClick={() => keepShowing(false)}
            className="p-1 ml-sm rounded bg-mono-2 min-w-1/4"
          >
            No Thanks
          </Button>
        </div>
      </Alert>
    );
  }

  return null;
};

function showUpdate(user: User, systemData?: UserSystemData) {
  if (!systemData || !systemData.found) {
    return false;
  }

  if (user.updated > systemData.lastUpdated) {
    return false;
  }

  return (
    !doStringsMatch(systemData.firstName, user.firstName, true, true) ||
    !doStringsMatch(systemData.lastName, user.lastName, true, true) ||
    !doStringsMatch(systemData.email, user.email, true, true)
  );
}

export default UserSystemDataRefresh;
