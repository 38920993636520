import { IEvent } from '@samc/common';
import { User } from '../models';

export class UserSavedEvent implements IEvent {
  public name: string = UserSavedEvent.eventName;

  static eventName = 'UserSavedEvent' as const;

  User: User;

  constructor(myUser: User) {
    this.User = myUser;
  }
}
