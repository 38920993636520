import { AxiosError } from 'axios';
import React from 'react';
import { useMutation } from '../hooks/useMutation';
import api from '../services/api';

export async function removeAUSCreditFile({
  stagingId,
}: {
  stagingId: string;
}) {
  await api.delete('AUSOrder/creditFileStaging', {
    params: { creditStagingId: stagingId },
  });
}

export function useRemoveAUSCreditFile() {
  return useMutation<void, AxiosError<ErrorData>, { stagingId: string }>(
    React.useCallback(
      ({ stagingId }) => removeAUSCreditFile({ stagingId }),
      [],
    ),
  );
}
