import { IOrder } from './types';
import { IManualLoan } from './bpo/types';
import { OrderTrackingLoan } from './appraisal/types';

export const initialBPOManualLoan: IManualLoan = {
  clientLoanId: '',
  street: '',
  unitNumber: '',
  city: '',
  state: '',
  zip: '',
  bpoProduct: {
    id: null,
    name: null,
  },
  accessDetails: '',
  customerReferenceId1: '',
  customerReferenceId2: '',
  customerReferenceId3: '',
};

export const initialLoanBPOState: OrderTrackingLoan = {
  orderItemId: '',
  accessDetails: '',
  address: '',
  unitNumber: '',
  borrowerLastName: '',
  city: '',
  state: '',
  zip: '',
  loanStatus: '',
  clientLoanId: '',
  clarityLoanNumber: 0,
  loanId: '',
  loanNumber: 0,
  productId: '',
  productName: '',
  providerRequestType: '',
  disputeActionType: '',
  customerReferenceId1: '',
  customerReferenceId2: '',
  customerReferenceId3: '',
};

const initialState: IOrder = {
  stagingId: '',
  orderId: '',
  orderStatus: '',
  orderIdentifier: '',
  product: {
    productId: '',
    productName: '',
    vendorId: '',
    vendorName: '',
    available: true,
    cvpPrice: 0,
    description: '',
    clarityCompanyId: 0,
    clarityProductId: '',
    clarityVendorId: 0,
    companyId: '',
    companyName: '',
    pricePer: '',
    vpPrice: 0,
    subProducts: [],
  },
  appraisalLoan: [
    {
      clientLoanId: '',
      borrowerLastName: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      unitNumber: '',
      documents: [],
    },
  ],
  bpoLoan: {
    bpoLoanMode: 'upload',
    isCompletedSuccessfully: true,
    manualLoans: [initialBPOManualLoan],
    fileSource: null,
    metaData: {
      columnMeta: [],
      id: '',
      totalRowCount: 0,
    },
    validation: true,
  },
  bpoLoanValidation: {
    data: [],
    changeData: false,
  },
  indexingLoan: {
    columnName: '',
    fileSource: null,
    metaData: {
      columnMeta: [],
      id: '',
      totalRowCount: 0,
    },
    validation: true,
  },
  securentFiles: [],
  ausLoan: {},
};

export default initialState;
