import React from 'react';
import { GetBPOOrderResponse } from '../../queries/useBPOOrder';
import { ActionTypes, IUnsubmittedOrder, UseOrderActions } from './types';

interface Props {
  dispatch: (action: ActionTypes) => void;
}
export default function useActions({ dispatch }: Props): UseOrderActions {
  const onCheckProduct = React.useCallback(
    (product, checked) => {
      if (!checked) {
        dispatch({
          type: 'ADD_PRODUCT',
          payload: { product },
        });
      } else {
        dispatch({
          type: 'REMOVE_PRODUCT',
          payload: { product },
        });
      }
    },
    [dispatch],
  );

  const onSelectDivision = React.useCallback(
    (division: number) => {
      dispatch({ type: 'SET_DIVISION', payload: { division } });
    },
    [dispatch],
  );

  const onSaveOrderId = React.useCallback(
    (orderId: string) => {
      dispatch({
        type: 'SET_ORDER_ID',
        payload: { orderId },
      });
    },
    [dispatch],
  );

  const onChangeOrderNumber = React.useCallback(
    (orderNumber: number) => {
      dispatch({
        type: 'SET_ORDER_NUMBER',
        payload: { orderNumber },
      });
    },
    [dispatch],
  );

  const onChangeOrderPrice = React.useCallback(
    (price: number) => {
      dispatch({
        type: 'SET_ORDER_PRICE',
        payload: { price },
      });
    },
    [dispatch],
  );

  const onUpdateOrderIdentifier = React.useCallback(
    (orderIdentifier: string) => {
      dispatch({
        type: 'UPDATE_ORDER_IDENTIFIER',
        payload: { orderIdentifier },
      });
    },
    [dispatch],
  );

  const onInitOrder = React.useCallback(() => {
    dispatch({
      type: 'INIT_ORDER',
    });
  }, [dispatch]);

  const onLoadOrder = React.useCallback(
    (unsubmittedOrder: IUnsubmittedOrder) => {
      dispatch({
        type: 'LOAD_ORDER',
        payload: { unsubmittedOrder },
      });
    },
    [dispatch],
  );

  const onLoadBPOOrder = React.useCallback(
    (order: GetBPOOrderResponse) => {
      dispatch({
        type: 'LOAD_BPO_ORDER',
        payload: { order },
      });
    },
    [dispatch],
  );

  return {
    onCheckProduct,
    onSelectDivision,
    onInitOrder,
    onSaveOrderId,
    onChangeOrderNumber,
    onChangeOrderPrice,
    onUpdateOrderIdentifier,
    onLoadOrder,
    onLoadBPOOrder,
  };
}
