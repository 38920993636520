import React from 'react';
import { useOrder } from '../context/order';
import { SetBPOOrderParams, useSetBPOOrder } from '../mutations/useSetBPOOrder';
import { queryClient } from '../services/queryClient';

export const useValidateBpoOrder = () => {
  const {
    order,
    bpoActions: { onChangeBPOValidationData },
  } = useOrder();
  const {
    orderId,
    product: { vendorId, productId },
    bpoLoanValidation: { data: allLoans },
  } = order;
  const setBPOOrderMutation = useSetBPOOrder();

  const changedLoans = React.useMemo(() => {
    return allLoans.filter(
      loan =>
        loan.hasChange ||
        loan.errors?.some(
          err => err.type === 'Warning' && !err.errorAcknowledged,
        ),
    );
  }, [allLoans]);

  const onValidateBpoOrder = React.useCallback(
    ({ acknowledgeWarnings, onNextStep }) => {
      const formData = {
        orderId,
        vendorId,
        productId,
        data: changedLoans
          .filter(loan => {
            return loan.hasChange || acknowledgeWarnings;
          })
          .map(loan => {
            return {
              ...loan,
              hasChange: true,
              errors: acknowledgeWarnings
                ? loan.errors?.map(err => {
                    if (err.type === 'Warning') {
                      return {
                        ...err,
                        errorAcknowledged: true,
                      };
                    }
                    return err;
                  })
                : loan.errors,
              addresses: loan.addresses.map(address => {
                return {
                  ...address,
                  bpoOrderStagingAddressId:
                    address.bpoOrderStagingAddressId === 'edited'
                      ? undefined
                      : address.bpoOrderStagingAddressId,
                };
              }),
            };
          }),
      };

      if (formData.data.length === 0) {
        if (onNextStep) {
          onNextStep({
            ...order.bpoLoanValidation,
            orderId: order.orderId,
            isCompletedSuccessfully: false,
            identifier: order.orderIdentifier || '',
            companyDivision: order.division || 0,
            summary: order.bpoLoanValidation.summary || {
              loansCant: 0,
              exteriorCant: 0,
              exteriorPrice: 0,
              interiorCant: 0,
              interiorPrice: 0,
              orderTotal: 0,
            },
            productInfo: {
              productId: order.product.productId,
              productName: order.product.productName,
              vendorId: order.product.vendorId,
              price: order.product.price || 0,
              subProducts: [],
            },
          });
        }
      } else {
        setBPOOrderMutation.mutate(formData as SetBPOOrderParams, {
          onSuccess: response => {
            onChangeBPOValidationData(response);

            if (onNextStep) {
              onNextStep(response);
            }

            queryClient.invalidateQueries(['orders', 'UnsubmittedOrders']);
          },
        });
      }
    },
    [
      changedLoans,
      onChangeBPOValidationData,
      order.bpoLoanValidation,
      order.division,
      order.orderId,
      order.orderIdentifier,
      order.product.price,
      order.product.productId,
      order.product.productName,
      order.product.vendorId,
      orderId,
      productId,
      setBPOOrderMutation,
      vendorId,
    ],
  );

  return React.useMemo(
    () => ({
      onValidateBpoOrder,
      isLoading: setBPOOrderMutation.isLoading,
    }),
    [onValidateBpoOrder, setBPOOrderMutation.isLoading],
  );
};
