/* eslint-disable react/function-component-definition */
import React from 'react';
import { useAuthorizationConfig } from '../contexts/AuthorizationConfigContext';
import loginImage from './PrettyCenteredBox.png';
import './PrettyCenteredBox.css';
import logo from '../images/situsamc.png';
import { WithChildren } from '../types';

type PrettyCenteredBoxProps = Partial<WithChildren> & {
  /** Points to the box actually holding content (div.prettyCenteredBox). */
  className?: string;
  /** If not provided, will use "defaultWidth", which has a width of 440px. */
  widthClassName?: string;
};

const PrettyCenteredBox: React.FC<PrettyCenteredBoxProps> = ({
  className = '',
  widthClassName = 'defaultWidth',
  children,
}) => {
  const { platformName, appName } = useAuthorizationConfig();
  const contentClassNames = `prettyCenteredBox flex flex-col justify-center ${widthClassName}${
    className ? ` ${className}` : ''
  }`;
  // Authentication.tsx uses this later to decide whether to trigger a user data refresh
  // (tell Auth Service to call Okta and get their latest name, email, login, etc.)
  localStorage.setItem('refreshData', 'true');

  return (
    <div className="fixed w-screen h-screen bg-mono-15 text-center left-0 top-0">
      <div
        id="BackgroundContaioner"
        className="top-0 right-0 z-0 absolute h-screen overflow-hidden"
      >
        <img src={loginImage} alt="Login" />
      </div>
      <div className={contentClassNames}>
        <div className="flex-grow relative">
          <div className="ContentBox bg-mono-1 rounded-md">
            <h1 className="font-proxima text-8 mt-sm">{platformName}</h1>
            <h2 className="text-blue-3 font-proxima text-6 font-bold subTitle">
              {appName}
            </h2>
            {children}
          </div>
        </div>
        <div className="Footer text-1 flex-none text-mono-9">
          <img src={logo} alt="Logo" />
          <p className="mt-md">
            Attention: Use of this system is limited to authorized individuals
            only.
          </p>
          <p>
            By using this system you consent to having your actions monitored
            and logged.
          </p>
          <p className="mt-md">
            Copyright © 1996-2020 SitusAMC. All rights reserved.
          </p>
          <div className="mt-md underline">
            <a
              href="https://www.situsamc.com/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            <a
              className="ml-xl"
              href="https://www.situsamc.com/terms-conditions"
              target="_blank"
              rel="noreferrer"
            >
              Terms of Use
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrettyCenteredBox;
