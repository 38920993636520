/* eslint-disable no-restricted-globals */
import React from 'react';
import { useAuthenticationConfig } from './contexts';
import { saveLastAccessedPath } from './helpers/loginRedirectPathHelpers';
import { WithChildren } from './types';

const IGNORE_PATHS = ['/login', '/logout', '/loggedOut'];

interface LastAccessedPathTrackingProps extends Partial<WithChildren> {
  /**
   * Keeps tracking from happening
   */
  suspendTracking?: boolean;
}

function LastAccessedPathTracking({
  children,
  suspendTracking,
}: LastAccessedPathTrackingProps) {
  const { ignoredLastRedirectUrlMatchers, loginRedirectStrategy } =
    useAuthenticationConfig();

  React.useEffect(() => {
    if (suspendTracking) return undefined;

    if (loginRedirectStrategy === 'ToLastVisitedUrl') {
      const handler = () => {
        if (IGNORE_PATHS.includes(location.pathname)) return;
        if (
          ignoredLastRedirectUrlMatchers &&
          ignoredLastRedirectUrlMatchers.some(m => m.test(location.href))
        )
          return;

        saveLastAccessedPath(location.href);
      };

      handler(); // initial run
      const tick = setInterval(handler, 1000);
      return () => clearInterval(tick);
    }
    return undefined;
  }, [suspendTracking]);

  return (
    <>
      {undefined}
      {children}
    </>
  );
}

export default LastAccessedPathTracking;
