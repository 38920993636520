import { AxiosError } from 'axios';
import React from 'react';
import { useMutation } from '../hooks/useMutation';
import api from '../services/api';

interface UpdateOrderIdentifierParams {
  orderId: string;
  orderIdentifier?: string;
}

async function updateOrderIdentifier({
  orderId,
  orderIdentifier,
}: UpdateOrderIdentifierParams) {
  const encodedId = encodeURIComponent(orderId);
  const encodedIdentifier = encodeURIComponent(orderIdentifier || '');
  const response = await api.patch(
    `/Order/${encodedId}/identifier?Identifier=${encodedIdentifier}`,
  );

  return response.data;
}

export function useUpdateOrderIdentifier() {
  return useMutation<void, AxiosError<ErrorData>, UpdateOrderIdentifierParams>(
    React.useCallback(params => updateOrderIdentifier(params), []),
  );
}
