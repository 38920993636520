import * as React from 'react';
import { useOrder } from '../../../context/order';
import { Container } from './styles';
import AddressSuggestionGrid from './AddressSuggestionGrid';
import { Address, BPOLoanData } from '../../../context/order/bpo/types';
import BPONoLoanData from '../BPONoLoanData';

export type BPOValidationAddressGridData = {
  row: number;
  loanId: string;
  orderItemId: string;
  originalAddress?: Address;
  suggestedAddresses?: Address[];
  selectedAddress?: Address;
};

function BPOAddressSuggestion() {
  const { order } = useOrder();

  const gridData: BPOLoanData[] = order.bpoLoanValidation.data;

  const errorMessage = React.useMemo<string | undefined>(() => {
    return order.bpoLoanValidation.data.length === 0
      ? 'There are no loans in this order. Please return to step 2 and re-enter your loan details.'
      : undefined;
  }, [order.bpoLoanValidation.data.length]);

  if (errorMessage) {
    return <BPONoLoanData message={errorMessage} />;
  }

  return (
    <Container>
      {gridData.length > 0 && (
        <AddressSuggestionGrid
          data={gridData}
          suggestedAddressLabel="Corrected Address"
        />
      )}
    </Container>
  );
}

export default BPOAddressSuggestion;
