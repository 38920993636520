/* eslint-disable react/function-component-definition */
import { DefaultPublisher } from '@samc/common';
import React, { useEffect, useRef, useState } from 'react';
import { CloseModalEvent, OpenModalEvent } from '../events';
import { tabLocker } from '../helpers/tabLocker';
import { TextButton, TextButtonProps } from './Button';
import './Modal.css';

export const Modal: React.FC = () => {
  const [message, setMessage] = useState('');
  const [buttons, setButtons] = useState<Array<TextButtonProps>>();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const subscriptionId = DefaultPublisher.subscribe<OpenModalEvent>(
      OpenModalEvent.eventName,
      e => {
        if (e.actions.filter(a => a.isFocused).length > 1) {
          throw new Error(
            'You cannot have multiple focused buttons in a modal',
          );
        }
        setMessage(e.message);
        setButtons(e.actions);
        ref.current?.focus();
      },
    );
    return () =>
      DefaultPublisher.unsubscribe(OpenModalEvent.eventName, subscriptionId);
  });

  const keypress = (e: KeyboardEvent) => tabLocker(e, ref);

  useEffect(() => {
    if (message) {
      document.addEventListener('keydown', keypress);
      return () => document.removeEventListener('keydown', keypress);
    }
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  }, [message]);

  const click = (action: (() => void) | null) => {
    if (action) {
      setMessage('');
      setButtons(undefined);
      action();
      DefaultPublisher.publish(new CloseModalEvent());
    }
  };

  return (
    <div id="authModalHolder" className={message ? 'active' : ''}>
      <div id="authModalBackground" />
      <div id="authModal" ref={ref}>
        <h5>{message}</h5>
        <div className="authModalActions">
          {buttons?.map(b => {
            return (
              <TextButton
                key={b.label}
                {...b}
                className={`text-3 p-2 inline-block border-mono-8 border-solid border rounded-sm mr-sm ${b.className}`}
                onClick={() => click(b.onClick)}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
