import { Guid } from '@samc/common';
import { parseDate } from '../helpers/parseDate';
import Base from './Base';

class Company extends Base<Company> {
  isActive: boolean;

  clarityId: number | null;

  updated: Date;

  updatedBy: Guid;

  constructor(id?: Guid, name?: string) {
    super(id, name);
    this.isActive = true;
    this.updated = new Date(0);
    this.updatedBy = Guid.createEmpty();
    this.clarityId = null;
  }

  isDirty(original: Company | null) {
    return !original || this.name !== original.name;
  }

  /** Probably shouldn't be used for now; Company has been updated to look like
   * Role and User should we ever allow more direct management of the Companies
   * under the hood, but at present you can only edit a Company for one user at
   * a time and edits won't reflect on others. See */
  getDirtyFields(original: Company) {
    if (!(original instanceof Company)) {
      return [];
    }

    const result = [];

    if (this.name !== original.name) {
      result.push('name');
    }

    return result;
  }

  clone() {
    const clone = new Company(this.id, this.name);
    clone.isSystemControlled = this.isSystemControlled;
    return clone;
  }

  toEditRequest() {
    return {
      name: this.name,
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromJson(json: any) {
    const company = new Company(Guid.parse(json.id), json.name);
    company.updated = parseDate(json.lastUpdated, company.updated);
    company.updatedBy = Guid.parse(json.lastUpdatedBy);
    company.isSystemControlled = json.isSystemControlled;
    company.clarityId = json.clarityId;
    return company;
  }
}

export default Company;
