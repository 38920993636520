/* eslint-disable react/function-component-definition */
import React, { useState } from 'react';
import { ActionMeta } from 'react-select';
import Creatable from 'react-select/creatable';
import WindowedSelect, { WindowedMenuList } from 'react-windowed-select';
import './ComboBox.css';
import { ISelectOption } from '../interfaces';

// If we need multiselect in the future, rather than adding to this, I'd suggest creating a new type that extends it,
// and a new component below Combobox that handles that case instead.
type ComboBoxProps<T> = {
  values: Array<ISelectOption<T>>;
  /** The name of the item to be selected by default */
  initialSelectedValue: ISelectOption<T> | null;
  isDisabled?: boolean;
  selectionChanged: (value?: ISelectOption<T>) => void;
  isCreateAllowed: boolean;
  hasClearButton: boolean;
  /** Describes how to translate user text into a value for T.
   * A default TextCreateOption is available if your type is a string,
   * and the underlying value should match the label. */
  createOptionValue: (text: string) => T;
};

export const ComboBox = <T,>(props: ComboBoxProps<T>) => {
  const {
    values,
    initialSelectedValue,
    isDisabled,
    selectionChanged,
    createOptionValue,
    isCreateAllowed,
    hasClearButton,
  } = props;
  const [original] = useState(initialSelectedValue);
  const [selectedValue, setSelectedValue] = useState<ISelectOption<T> | null>(
    initialSelectedValue,
  );

  // https://stackoverflow.com/a/54370057 - the examples and definitions from the source were not very descriptive
  const handleChange = (
    selected: ISelectOption<T> | null,
    action: ActionMeta<ISelectOption<T>>,
  ) => {
    if (action.action === 'clear') {
      selectionChanged(undefined);
      setSelectedValue(null);
    } else {
      setSelectedValue(selected ?? null);
      selectionChanged(selected || undefined);
    }
  };

  const formatOptionLabel = React.useCallback(
    (v: ISelectOption<T>) => {
      if (!isCreateAllowed) {
        return original !== null && original.label === v.label ? (
          <label className="original">{v.label}</label>
        ) : (
          v.label
        );
      }
      const existingValue = values.find(val => val.label === v.label);
      if (!existingValue) {
        return <label className="new">{v.label}</label>;
      }

      return original !== null && original.label === v.label ? (
        <label className="original">{v.label}</label>
      ) : (
        v.label
      );
    },
    [isCreateAllowed, original, values],
  );

  if (!isCreateAllowed) {
    return (
      <WindowedSelect
        options={values}
        isDisabled={isDisabled}
        className="combobox"
        classNamePrefix="combobox"
        isClearable={hasClearButton}
        value={selectedValue}
        // @ts-expect-error Unable to override type
        onChange={handleChange}
        // @ts-expect-error Unable to override type
        formatOptionLabel={formatOptionLabel}
      />
    );
  }
  return (
    <Creatable
      options={values}
      isDisabled={isDisabled}
      className="combobox"
      classNamePrefix="combobox"
      components={{ MenuList: WindowedMenuList }}
      value={selectedValue}
      onChange={handleChange}
      isClearable={hasClearButton}
      formatOptionLabel={formatOptionLabel}
      getNewOptionData={(label): ISelectOption<T> => {
        return { label, value: createOptionValue(label) };
      }}
    />
  );
};
