import * as React from 'react';
import '@samc/icons/css/materialdesignicons.css';
import { QueryClientProvider } from 'react-query';
import { AgGridLicenseProvider } from '@samc/react-ui-grid';
import { Authorization } from './features/authorization';
import './features/authorization/index.css';
import './features/authentication-okta/index.css';
import { authConfig } from './utils/authConfig';
import Main from './Main';
// import { EventProvider } from './context/event';
import { queryClient } from './services/queryClient';

function App() {
  return (
    <AgGridLicenseProvider license={authConfig.agGridLicense}>
      <Authorization config={authConfig}>
        {/* <EventProvider> */}
        <QueryClientProvider client={queryClient}>
          <Main />
          {/* <ReactQueryDevtools /> */}
        </QueryClientProvider>
        {/* </EventProvider> */}
      </Authorization>
    </AgGridLicenseProvider>
  );
}

export default App;
