/* eslint-disable react/function-component-definition */
import React, { useState, useRef, CSSProperties } from 'react';
import { AgGridReact } from 'ag-grid-react';
import {
  ColDef,
  ColGroupDef,
  GridReadyEvent,
  FirstDataRenderedEvent,
  ModuleRegistry,
  CsvExportModule,
  CellValueChangedEvent,
  MenuItemDef,
  GetContextMenuItemsParams,
} from 'ag-grid-community';
import './Grid.css';
import { GridButtonProps } from './GridButton';
import { addRenderers } from './GridRenderers';
import ButtonBar from './ButtonBar';

ModuleRegistry.registerModules([CsvExportModule]);

export type GridProps = {
  columnDefs: (ColDef | ColGroupDef)[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rowData?: any[];
  wrapperClassName?: string;
  buttonProps?: Array<GridButtonProps & { key?: string | number }>;
  paging?: boolean;
  excelExportAction?: () => void;
  canUseFullScreen?: boolean;
  containerStyling?: CSSProperties;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getContextMenuItems?: (data?: any) => MenuItemDef[];
  onGridReady?: (event: GridReadyEvent) => void;
  onFirstDataRendered?: (event: FirstDataRenderedEvent) => void;
  onCellValueChanged?: (event: CellValueChangedEvent) => void;
  excelTooltip?: string;
  fullScreenTooltip?: string;
  clearFiltersTooltip?: string;
};

const Grid: React.FC<GridProps> = ({
  columnDefs,
  rowData,
  wrapperClassName = '',
  buttonProps = new Array<GridButtonProps>(),
  onGridReady,
  onFirstDataRendered,
  onCellValueChanged,
  paging = false,
  excelExportAction,
  canUseFullScreen = false,
  containerStyling,
  getContextMenuItems,
  clearFiltersTooltip = 'Clear Filters',
  excelTooltip = 'Export to Excel',
  fullScreenTooltip = 'Toggle Full Screen',
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const agGrid = useRef<AgGridReact<any>>(null);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const clearFilterAction = () => {
    agGrid.current?.api?.setFilterModel(null);
    agGrid.current?.api?.onFilterChanged();
  };

  const fullScreenFunc = React.useMemo(() => {
    return canUseFullScreen
      ? () => {
          setIsFullScreen(!isFullScreen);
        }
      : undefined;
  }, [canUseFullScreen, isFullScreen]);

  const className = React.useMemo(
    () =>
      `ag-theme-balham bg-mono-15 h-full flex flex-col ${wrapperClassName} ${
        isFullScreen ? 'fullscreen' : ''
      }`,
    [isFullScreen, wrapperClassName],
  );

  const frameworkComponents = React.useMemo(() => {
    const ret = {};
    addRenderers(ret);
    return ret;
  }, []);

  const contextMenuAction = React.useMemo(() => {
    return getContextMenuItems
      ? (params: GetContextMenuItemsParams) =>
          getContextMenuItems(params.node?.data)
      : undefined;
  }, [getContextMenuItems]);

  return (
    <div className={className} style={containerStyling}>
      <div className="flex-grow-0">
        <ButtonBar
          buttonProps={buttonProps}
          clearFiltersTooltip={clearFiltersTooltip}
          clearFilterFunc={clearFilterAction}
          isFullScreen={isFullScreen}
          fullScreenFunc={fullScreenFunc}
          excelFunc={excelExportAction}
          excelTooltip={excelTooltip}
          fullScreenTooltip={fullScreenTooltip}
        />
      </div>
      <div className="flex-grow">
        <AgGridReact
          disableStaticMarkup // https://www.ag-grid.com/react-fine-tuning/#react-cell-rendering - disabled this because it causes the very first cell to frequently render twice
          ref={agGrid}
          defaultColDef={{
            filter: true,
            resizable: true,
            sortable: true,
          }}
          columnDefs={columnDefs}
          columnTypes={{
            leftAligned: { cellStyle: { textAlign: 'left' } },
          }}
          rowData={rowData}
          components={frameworkComponents}
          onGridReady={onGridReady}
          onFirstDataRendered={onFirstDataRendered}
          onCellValueChanged={onCellValueChanged}
          pagination={paging}
          paginationAutoPageSize
          accentedSort
          getContextMenuItems={contextMenuAction}
        />
      </div>
    </div>
  );
};
export default Grid;
