import { Guid, IEvent } from '@samc/common';

export enum SelectionType {
  /** No key modifier */
  Normal,
  /** Ctrl key modifier */
  Single,
  /** Shift key modifier */
  Range,
}

export class SelectionEvent implements IEvent {
  public name: string = SelectionEvent.eventName;

  static eventName: 'SelectionEvent' = 'SelectionEvent' as const;

  id: Guid;

  /** Essentially, if the selection was made with shift pressed, which would mean the change is isolated to the item in question. */
  selectionType: SelectionType;

  constructor(id: Guid, selectionType: SelectionType = SelectionType.Normal) {
    this.id = id;
    this.selectionType = selectionType;
  }
}
