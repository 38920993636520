/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { useOktaAuth } from '@okta/okta-react';
import { AccessToken } from '@okta/okta-auth-js';
import { useAuthenticationConfig } from './contexts';
import LogoutWarningModal from './components/LogoutWarningModal';
import { WithChildren } from './types';
import { AuthenticationWatcher } from './AuthenticationWatcher';

function InteractionTokenRefresh({ children }: Partial<WithChildren>) {
  const config = useAuthenticationConfig();
  const [authenticationWatcher] = React.useState(new AuthenticationWatcher());

  const log = (window as any).$log || console; // use client logging if available.

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { oktaAuth } = useOktaAuth();

  const [isLogoutWarningVisible, setIsLogoutWarningVisible] = useState(false);

  const handleLogout: React.MouseEventHandler<HTMLDivElement> = () => {
    log.info(
      'InteractionTokenRefresh#handleLogout: User selected Logout on logout warning modal.',
      'Component: Auth',
    );

    applicationLogout();
  };

  const handleStayConnected: React.MouseEventHandler<HTMLDivElement> = () => {
    log.info(
      'InteractionTokenRefresh#handleStayConnected: User selected Stay Connected on logout warning modal.',
      'Component: Auth',
    );

    sessionKeepAlive();

    markInteracted();

    authenticationWatcher.setShowLogoutWarning(false);
  };

  log.info(
    'InteractionTokenRefresh:',
    'Component: Auth',
    'Component re-created',
  );

  // This runs only on page load
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    log.debug(
      'InteractionTokenRefresh#pageLoad: init state management',
      'Component: Auth',
    );

    const init = async () => {
      authenticationWatcher.init(config);

      await sessionKeepAlive();

      await markInteracted();
    };

    init();

    authenticationWatcher.onSessionKeepAlive(sessionKeepAlive);
    authenticationWatcher.onRenewTokens(renewTokens);
    authenticationWatcher.onRemoveTokens(removeTokens);
    authenticationWatcher.onLogoutWarning(setLogoutWarningVisibility);

    const authState = oktaAuth.authStateManager.getAuthState();
    const accessToken = authState?.accessToken;
    authenticationWatcher.setToken(accessToken);

    const pageHideHandler = async () => {
      authenticationWatcher.offLogoutWarning();
      authenticationWatcher.offRemoveTokens();
      authenticationWatcher.offRenewTokens();
      authenticationWatcher.offSessionKeepAlive();
    };

    window.addEventListener('pagehide', pageHideHandler);

    return () => {
      log.debug(
        'InteractionTokenRefresh#pageLoad: removing state management',
        'Component: Auth',
      );

      window.removeEventListener('pagehide', pageHideHandler);

      authenticationWatcher.dispose();
    };
  }, []);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const debounceHandler = debounce(markInteracted, 1000);

    if (isLogoutWarningVisible) {
      document.removeEventListener('keydown', debounceHandler);
      document.removeEventListener('mousedown', debounceHandler);
    } else {
      document.addEventListener('keydown', debounceHandler);
      document.addEventListener('mousedown', debounceHandler);
    }

    return () => {
      document.removeEventListener('keydown', debounceHandler);
      document.removeEventListener('mousedown', debounceHandler);
    };
  }, [isLogoutWarningVisible]);

  async function renewTokens() {
    const idToken = await oktaAuth.tokenManager.renew('idToken');
    log.info(
      `InteractionTokenRefresh#renewTokens: token [idToken] renewed. Expire=[${idToken?.expiresAt}]`,
      'Component: Auth',
    );

    const accessToken = await oktaAuth.tokenManager.renew('accessToken');
    log.info(
      `InteractionTokenRefresh#renewTokens: token [accessToken] renewed. Expire=[${accessToken?.expiresAt}]`,
      'Component: Auth',
    );

    authenticationWatcher.setToken(accessToken as AccessToken);
  }

  async function removeTokens() {
    try {
      const cookieUrl = config.cookieUrl;

      if (cookieUrl) {
        const response = await fetch(cookieUrl, {
          headers: new Headers(config.defaultHeaders),
          method: 'DELETE',
        });

        if (response.ok)
          log.error(
            'InteractionTokenRefresh#removeTokens: Cookie deleted',
            'Component: Auth',
          );
        else
          log.error(
            'InteractionTokenRefresh#removeTokens: failed deleting cookie',
            'Component: Auth',
          );
      }

      oktaAuth.tokenManager.clear();

      log.info(
        'InteractionTokenRefresh#removeTokens: cleared tokens',
        'Component: Auth',
      );
    } catch (err) {
      log.error('InteractionTokeRefresh#removeTokens:', 'Component: Auth', err);
      throw err;
    }
  }

  async function sessionKeepAlive() {
    log.debug(
      `InteractionTokenRefresh#sessionKeepAlive: ${new Date()
        .getTime()
        .toString()}`,
      'Component: Auth',
    );

    await oktaAuth.session.refresh();
  }

  async function applicationLogout() {
    log.info(
      'InteractionTokenRefresh#applicationLogout: logging out',
      'Component: Auth',
    );

    window.location.href = '/logout';
  }

  async function markInteracted() {
    const interactionTime = new Date().getTime().toString();

    log.info(
      `InteractionTokenRefresh: Interaction time set: ${interactionTime}`,
      'Component: Auth',
    );

    authenticationWatcher.setLastInteractionTime(new Date().getTime());
  }

  async function setLogoutWarningVisibility(show: boolean) {
    log.info(
      `InteractionTokenRefresh#setLogoutWarningVisibility: ${show}`,
      'Component: Auth',
    );

    setIsLogoutWarningVisible(show);
  }

  return (
    <>
      {children}
      {config.showLogoutWarning &&
        config.tokenRefreshMethod === 'Interaction' && (
          <LogoutWarningModal
            description="Your session is about to expire. Please save any work in progress."
            isVisible={isLogoutWarningVisible}
            handleLogout={handleLogout}
            handleStayConnected={handleStayConnected}
            showActions
          />
        )}
    </>
  );
}

export default InteractionTokenRefresh;
