/* eslint-disable react/function-component-definition */
import React, { useState, useCallback } from 'react';
import './ToolTip.css';
import { WithChildren } from '../types';

type ToolTipProps = Partial<WithChildren> & {
  title: string;
  orientation: string;
  style?: React.CSSProperties;
};
let debounceTimeout: ReturnType<typeof setTimeout>;

const ToolTip: React.FC<ToolTipProps> = ({
  title,
  orientation,
  style,
  children,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const className = ['tooltip', orientation].join(' ');

  /**
   * Helper method is meant to clear debounces and set the open flag
   */
  const open = useCallback(
    (newIsOpen: boolean) => {
      clearTimeout(debounceTimeout);
      debounceTimeout = setTimeout(() => setIsOpen(newIsOpen));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOpen],
  );

  return (
    <div
      className="tooltip-wrapper"
      onMouseEnter={() => open(true)}
      onMouseLeave={() => open(false)}
      style={style}
    >
      <span>{children}</span>
      {isOpen && <div className={className}> {title} </div>}
    </div>
  );
};

export default ToolTip;
