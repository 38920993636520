import React from 'react';
import { RiArrowLeftSFill, RiArrowRightSFill } from 'react-icons/ri';
import Spinner from '../Spinner';
import { Container, Label, Children, Variant } from './styles';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean;
  width?: string;
  height?: string;
  variant?: Variant;
  alternate?: boolean;
  navButton?: 'previous' | 'next';
  children?: React.ReactNode;
}

function Button({
  children,
  variant = 'primary',
  alternate = false,
  isLoading,
  disabled,
  navButton,
  ...props
}: ButtonProps) {
  return (
    <Container
      disabled={disabled}
      navButton={navButton}
      variant={variant}
      alternate={alternate}
      isLoading={isLoading}
      {...props}
    >
      {navButton === 'previous' && <RiArrowLeftSFill size={20} />}
      <Label>{isLoading ? <Spinner /> : <Children>{children}</Children>}</Label>
      {navButton === 'next' && <RiArrowRightSFill size={20} />}
    </Container>
  );
}

export default Button;
