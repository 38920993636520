import React from 'react';
import Item from './Item';
import { Container } from './styles';

interface PanelHeaderProps {
  children: React.ReactNode;
}

function PanelHeader({ children }: PanelHeaderProps) {
  return <Container>{children}</Container>;
}

PanelHeader.Item = Item;

export default PanelHeader;
