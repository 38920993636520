import * as React from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Provider as StyletronProvider } from 'styletron-react';
import { Client as Styletron } from 'styletron-engine-atomic';
import {
  ThemeProvider as SitusThemeProvider,
  defaultTheme as situsDefaultTheme,
} from '@samc/react-ui-theme';
import { ErrorBoundary } from 'react-error-boundary';
import { Admin as AdminProvider } from './features/authorization';
import ErrorFallback from './components/Common/ErrorFallback';
import { Layout, Loading } from './components/Common';
import { OrderProvider } from './context/order';
import { ToastProvider } from './context/toaster';
import OrderTracking from './pages/OrderTracking';
import AccountSettings from './pages/AccountSettings';
import GlobalStyle from './styles/global';
import { defaultTheme } from './styles/theme';
import NotFound from './components/NotFound';
import ExpirationTimer from './components/Common/ExpirationTimer';
import {
  useCurrentUserHasEntitlement,
  useCurrentUserIsLoaded,
} from './features/authorization/contexts/CurrentUserContext';

const UnsubmittedOrders = React.lazy(() => import('./pages/UnsubmittedOrders'));
const NewOrder = React.lazy(() => import('./pages/NewOrder'));
const Admin = React.lazy(() => import('./pages/Admin'));

const engine = new Styletron();

function Main() {
  const history = useHistory();
  const handleLogout = () => {
    history.push('/logout');
  };

  const isLoaded = useCurrentUserIsLoaded();
  const hasNewOrderEntitlement = useCurrentUserHasEntitlement('New Order');
  const hasOrderTrackingEntitlement =
    useCurrentUserHasEntitlement('Order Tracking');
  const hasListUsersEntitlement = useCurrentUserHasEntitlement('List Users');
  const hasUnsubmittedOrdersEntitlement =
    useCurrentUserHasEntitlement('Unsubmitted Orders');

  const firstLoadedRender = React.useRef(true);
  React.useEffect(() => {
    if (isLoaded && firstLoadedRender.current) {
      firstLoadedRender.current = false;
    }
  }, [isLoaded]);

  if (!isLoaded) {
    return null;
  }

  return (
    <StyletronProvider value={engine} debugAfterHydration>
      <SitusThemeProvider value={situsDefaultTheme}>
        <ThemeProvider theme={defaultTheme}>
          <ExpirationTimer
            warningTimeout={300}
            disabled={false}
            handleLogout={handleLogout}
          />
          <OrderProvider>
            <Layout>
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <React.Suspense fallback={<Loading />}>
                  <ToastProvider>
                    <AdminProvider>
                      <Switch>
                        <Route
                          path="/account-settings"
                          component={AccountSettings}
                        />
                        {hasNewOrderEntitlement && (
                          <Route path="/new-order">
                            <NewOrder refreshOrder />
                          </Route>
                        )}
                        {hasOrderTrackingEntitlement && (
                          <Route
                            path="/order-tracking"
                            component={OrderTracking}
                          />
                        )}
                        {hasListUsersEntitlement && (
                          <Route exact path="/admin" component={Admin} />
                        )}
                        <Route exact path="/">
                          {hasNewOrderEntitlement ? (
                            <Redirect to="/new-order" />
                          ) : (
                            <Redirect to="/order-tracking" />
                          )}
                        </Route>
                        {hasUnsubmittedOrdersEntitlement && (
                          <Switch>
                            <Route
                              exact
                              path="/unsubmitted-orders"
                              component={UnsubmittedOrders}
                            />
                            <Route
                              exact
                              path="/unsubmitted-orders/:id/:productOrder"
                            >
                              <NewOrder />
                            </Route>
                            <Route component={NotFound} />
                          </Switch>
                        )}
                        <Route component={NotFound} />
                      </Switch>
                    </AdminProvider>
                  </ToastProvider>
                  <GlobalStyle />
                </React.Suspense>
              </ErrorBoundary>
            </Layout>
          </OrderProvider>
        </ThemeProvider>
      </SitusThemeProvider>
    </StyletronProvider>
  );
}

export default Main;
