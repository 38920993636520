import React from 'react';
import { Autosave } from '@samc/common';
import {
  IDataImportService,
  IDocumentService,
  IFieldService,
  IFileSource,
  IMapping,
  IMappingDetail,
  IMappingVersion,
  IMessage,
  IRawMetaData,
  IStagingService,
} from './interfaces';
import { IStep } from './IStep';

interface IDataImportAutosaveBlob {
  extraParams: Record<string, string> | null;
  fileSource: IFileSource;
  basicMetaData: IRawMetaData | null;
  metadata: IRawMetaData;
  step: string;
  mapping: IMapping<IMappingDetail>;
  mappingVersion: IMappingVersion | null;
}

export interface DataImportProps {
  url?: string;
  verificationToken: string;
  client: string;
  dataImportService?: IDataImportService | null;
  dataImportUrl?: string;
  documentService?: IDocumentService | null;
  documentUrl?: string;
  fieldService?: IFieldService | null;
  fieldUrl?: string;
  stagingService?: IStagingService | null;
  stagingUrl?: string;
  user: string;
  jwt?: string | null;
  extraParams?: Record<string, string> | null;
  wrapUp?: boolean;
  finalizeText?: string;
  isAutosaveEnabled?: boolean;
  autosaveId?: number | null;
  initialMetaData?: IRawMetaData | null;
  initialBasicMetaData?: IRawMetaData | null;
  initialFileSource?: IFileSource | null;
  initialMapping?: IMapping<IMappingDetail> | null;
  initialMappingVersion?: IMappingVersion | null;
  initialStep?: string | null;
  children?: any;
}

export default function DataImport({
  url = '',
  verificationToken,
  client,
  dataImportService = null,
  dataImportUrl = '',
  documentService = null,
  documentUrl = '',
  fieldService = null,
  fieldUrl = '',
  stagingService = null,
  stagingUrl = '',
  user,
  jwt = null,
  extraParams = null,
  wrapUp = false,
  finalizeText = 'Finalize',
  isAutosaveEnabled = false,
  autosaveId = null,
  initialMetaData = null,
  initialBasicMetaData = null,
  initialFileSource = null,
  initialMapping = null,
  initialMappingVersion = null,
  initialStep = null,
  children,
}: DataImportProps) {
  const [originalMappingJson, setOriginalMappingJson] = React.useState<
    string | null
  >(null);
  const [autosave, setAutosave] =
    React.useState<Autosave<IDataImportAutosaveBlob> | null>(null);
  const [steps, setSteps] = React.useState<IStep[]>([]);
  const [stepNumber, setStepNumber] = React.useState<number[]>([]);
  const [messages, setMessages] = React.useState<IMessage[]>([]);
  const [current, setCurrent] = React.useState(-1);
  const [isAutosaveInitialized, setIsAutosaveInitialized] =
    React.useState(false);
  const [isStepInitializing, setIsStepInitializing] = React.useState(false);

  return <div>{children}</div>;
}
