import React from 'react';
import { toastError, ToastMessage } from '@samc/react-ui-core';
import { useOrder } from '../context/order';
import { useProducts } from '../queries/useProducts';
import { IProduct, ISubProduct } from '../context/order/types';
import BPOLoanDetails from '../components/NewOrder/BPOLoanDetails';
import BPOError from '../components/NewOrder/BPOError';
import BPOReviewSubmit from '../components/NewOrder/BPOReviewSubmit';
import { useToast } from '../context/toaster';
import BPOAddressSuggestion from '../components/NewOrder/BPOAddressSuggestion';
import { useValidateBpoOrder } from './useValidateBpoOrder';
import { useSubmitBPOOrder } from '../mutations/useSubmitBPOOrder';
import {
  SetBPOLoansUploadParams,
  useSetBPOLoans,
} from '../mutations/useSetBPOLoans';
import { queryClient } from '../services/queryClient';
import { useValidateBpoCorrections } from './useValidateBpoCorrections';
import { BPOLoanValidationData } from '../context/order/bpo/types';

const useLoanDetailsStep = ({
  isBPOLoanValid,
  isManualBPOValid,
  onSetBPOLoans,
}: any) => {
  const { order } = useOrder();
  return React.useMemo(
    () => ({
      title: 'Loan Details',
      content: <BPOLoanDetails />,
      isValid:
        order.bpoLoan.bpoLoanMode === 'upload'
          ? isBPOLoanValid
          : isManualBPOValid,
      onSubmit: onSetBPOLoans,
      loadingMessage: 'Checking for Errors...',
    }),
    [
      isBPOLoanValid,
      isManualBPOValid,
      onSetBPOLoans,
      order.bpoLoan.bpoLoanMode,
    ],
  );
};

const useErrorsStep = ({ error, onValidateBPOErrors }: any) => {
  const { order } = useOrder();
  return React.useMemo(
    () => ({
      title: 'Errors',
      content: <BPOError error={error} />,
      isValid:
        (!order.bpoLoanValidation.hasError &&
          order.bpoLoanValidation.data.length > 0) ||
        order.bpoLoanValidation.data.some(loan => loan.hasChange),
      onSubmit: onValidateBPOErrors,
      loadingMessage: 'Checking for Errors...',
      isVisible:
        order.bpoLoanValidation.errorLoans !== undefined &&
        order.bpoLoanValidation.errorLoans.length > 0,
      nextButtonLabel: order.bpoLoanValidation.data.some(loan => loan.hasChange)
        ? 'Save'
        : 'Next',
    }),
    [
      error,
      onValidateBPOErrors,
      order.bpoLoanValidation.data,
      order.bpoLoanValidation.errorLoans,
      order.bpoLoanValidation.hasError,
    ],
  );
};

const useAddressCorrectionStep = ({ onValidateBPOCorrections }: any) => {
  const { order } = useOrder();

  return React.useMemo(
    () => ({
      title: 'Address Corrections',
      content: <BPOAddressSuggestion />,
      isValid:
        (!order.bpoLoanValidation.hasUnselectedNonSingleSuggestion &&
          order.bpoLoanValidation.data.length > 0) ||
        order.bpoLoanValidation.data.some(loan => loan.hasChange),
      onSubmit: onValidateBPOCorrections,
      loadingMessage: 'Checking for Errors...',
      refreshData: true,
      isVisible:
        order.bpoLoanValidation.correctionLoans !== undefined &&
        order.bpoLoanValidation.correctionLoans.length > 0,
      nextButtonLabel: order.bpoLoanValidation.data.some(loan => loan.hasChange)
        ? 'Save'
        : 'Next',
    }),
    [
      onValidateBPOCorrections,
      order.bpoLoanValidation.correctionLoans,
      order.bpoLoanValidation.data,
      order.bpoLoanValidation.hasUnselectedNonSingleSuggestion,
    ],
  );
};

const useReviewSubmitStep = ({
  onSubmitBPOLoans,
  submitBPOLoansLabel,
}: any) => {
  const { order } = useOrder();
  return React.useMemo(
    () => ({
      title: 'Review & Submit',
      content: <BPOReviewSubmit />,
      isValid: true,
      onSubmit: onSubmitBPOLoans,
      nextButtonLabel: submitBPOLoansLabel,
      refreshData:
        order.bpoLoanValidation.hasError ||
        order.bpoLoanValidation.hasUnacknowledgedWarning ||
        order.bpoLoanValidation.hasUnselected,
      // || order.bpoLoanValidation.hasUnvalidated,
    }),
    [
      onSubmitBPOLoans,
      order.bpoLoanValidation.hasError,
      order.bpoLoanValidation.hasUnacknowledgedWarning,
      order.bpoLoanValidation.hasUnselected,
      submitBPOLoansLabel,
    ],
  );
};

export const useBpoSteps = ({
  selectProductStep,
  setBPOLoansMutation,
  submitBPOOrderMutation,
}: {
  selectProductStep: any;
  setBPOLoansMutation: ReturnType<typeof useSetBPOLoans>;
  submitBPOOrderMutation: ReturnType<typeof useSubmitBPOOrder>;
}) => {
  const { order, bpoActions, orderActions } = useOrder();
  const { onSaveOrderId } = orderActions;
  const { onChangeBPOLoanError, onChangeBPOValidationData } = bpoActions;
  const { toastWarning } = useToast();
  const { isLoading: isValidateBpoOrderLoading, onValidateBpoOrder } =
    useValidateBpoOrder();

  const [error, setError] = React.useState<string | undefined>(undefined);

  const productsQuery = useProducts();
  const productLookup = React.useMemo(() => {
    const lookup: {
      [key: string]: {
        product: IProduct;
        subProductLookup: { [key: string]: ISubProduct };
      };
    } = {};
    if (productsQuery.data) {
      productsQuery.data.forEach(product => {
        lookup[product.productId] = {
          product,
          subProductLookup: product.subProducts.reduce<{
            [key: string]: ISubProduct;
          }>((ret, subProduct) => {
            ret[subProduct.productName] = subProduct;
            return ret;
          }, {}),
        };
      });
    }
    return lookup;
  }, [productsQuery.data]);

  const isBPOLoanValid = React.useMemo(
    () => !!order.bpoLoan.metaData && !!order.bpoLoan.metaData.id,
    [order.bpoLoan.metaData],
  );

  const isManualBPOValid = React.useMemo(() => {
    const nonEmptyRows = order.bpoLoan.manualLoans.filter(
      loan =>
        loan.clientLoanId !== '' ||
        loan.street !== '' ||
        loan.city !== '' ||
        loan.state !== '' ||
        loan.zip !== '' ||
        loan.bpoProduct.id !== null,
    );

    const hasNonEmptyRows = nonEmptyRows.every(
      loan =>
        loan.clientLoanId !== '' &&
        loan.street !== '' &&
        loan.bpoProduct.id !== null &&
        loan.city !== '' &&
        loan.state !== '' &&
        loan.zip !== '',
    );

    const interiorLoans = nonEmptyRows.filter(
      loan =>
        loan.bpoProduct.name !== null &&
        productLookup[order.product.productId] &&
        productLookup[order.product.productId].subProductLookup[
          loan.bpoProduct.name
        ] &&
        productLookup[order.product.productId].subProductLookup[
          loan.bpoProduct.name
        ].subCategory === 3,
    );

    const interiorBpoHasAccessDetails =
      interiorLoans.length > 0
        ? interiorLoans.every(loan => loan.accessDetails !== '')
        : true;

    return (
      nonEmptyRows.length > 0 && hasNonEmptyRows && interiorBpoHasAccessDetails
    );
  }, [order.bpoLoan.manualLoans, order.product.productId, productLookup]);

  const onSubmitBPOLoans = React.useCallback(
    (onFinalizeStep, onNavigateToStep) => {
      if (order.bpoLoanValidation.data.some(loan => loan.hasChange)) {
        onValidateBpoOrder({
          acknowledgeWarnings: true,
          onNextStep: () => {
            // Stay on screen
          },
        });
      } else if (
        order.bpoLoanValidation.hasError ||
        order.bpoLoanValidation.hasUnacknowledgedWarning ||
        order.bpoLoanValidation.hasUnselected
      ) {
        if (
          order.bpoLoanValidation.hasError ||
          order.bpoLoanValidation.hasUnacknowledgedWarning
        ) {
          onNavigateToStep(2);
        } else if (
          order.bpoLoanValidation.errorLoans &&
          order.bpoLoanValidation.errorLoans.length
        ) {
          onNavigateToStep(3);
        } else {
          onNavigateToStep(2);
        }
      } else {
        submitBPOOrderMutation.mutate(
          {
            orderId: order.orderId,
            orderIdentifier: myOrderIdentifier.current,
          },
          {
            onSuccess: response => {
              orderActions.onChangeOrderNumber(response.orderNumber);

              onFinalizeStep();

              queryClient.invalidateQueries('orders');
            },
          },
        );
      }
    },
    [
      order.bpoLoanValidation.data,
      order.bpoLoanValidation.hasError,
      order.bpoLoanValidation.hasUnacknowledgedWarning,
      order.bpoLoanValidation.hasUnselected,
      order.bpoLoanValidation.errorLoans,
      order.orderId,
      onValidateBpoOrder,
      submitBPOOrderMutation,
      orderActions,
    ],
  );

  const submitBPOLoansLabel = React.useMemo(() => {
    if (order.bpoLoanValidation.data.some(loan => loan.hasChange)) {
      return 'Save';
    }

    if (
      order.bpoLoanValidation.hasError ||
      order.bpoLoanValidation.hasUnacknowledgedWarning ||
      order.bpoLoanValidation.hasUnselected
    ) {
      return 'Re-check for Errors';
    }

    return 'Submit Order';
  }, [
    order.bpoLoanValidation.data,
    order.bpoLoanValidation.hasError,
    order.bpoLoanValidation.hasUnacknowledgedWarning,
    order.bpoLoanValidation.hasUnselected,
  ]);

  const onValidateBPOErrors = React.useCallback(
    (onNextStep?: () => void) => {
      const hadChanges = order.bpoLoanValidation.data.some(
        loan => loan.hasChange,
      );
      onValidateBpoOrder({
        acknowledgeWarnings: true,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onNextStep: (response: any) => {
          if (!hadChanges && onNextStep && !response.hasError) {
            onNextStep();
          } else {
            // Not expected to get here
          }
        },
      });
    },
    [onValidateBpoOrder, order.bpoLoanValidation.data],
  );

  const {
    isLoading: isValidateBpoCorrectionsLoading,
    onValidateBpoCorrections,
  } = useValidateBpoCorrections();

  const onSetBPOLoans = React.useCallback(
    (onNextStep?: () => void) => {
      if (order.bpoLoan.metaData) {
        const productsPrice = order.product.subProducts.map(product => ({
          productId: product.productId,
          productName: product.productName,
          price: product.price,
        }));

        const manualData = order.bpoLoan.manualLoans.filter(
          loan =>
            loan.clientLoanId !== '' ||
            loan.street !== '' ||
            loan.city !== '' ||
            loan.state !== '' ||
            loan.zip !== '' ||
            loan.bpoProduct.id !== null ||
            loan.customerReferenceId1 !== '' ||
            loan.customerReferenceId2 !== '' ||
            loan.customerReferenceId3 !== '',
        );

        const params: SetBPOLoansUploadParams = {
          formData: {
            data: manualData,
            orderId: order.orderId,
            productId: order.product.productId,
            vendorId: order.product.vendorId,
            documentId: order.bpoLoan.metaData.id,
            companyDivision: order.division,
            productsPrice,
          },
        };

        setBPOLoansMutation.mutate(params, {
          onSuccess: (
            response: BPOLoanValidationData & {
              orderId: string;
              isCompletedSuccessfully: boolean;
            },
          ) => {
            onChangeBPOValidationData(response, true);

            const { orderId, productInfo, isCompletedSuccessfully, data } =
              response;

            if (data === undefined || data.length === 0) {
              setError('No Loans');
            } else if (productInfo) {
              setError(undefined);
              if (productInfo.subProducts) {
                const didPriceChange: boolean = productInfo.subProducts.some(
                  (x: ISubProduct) => x.didPriceChange,
                );

                if (didPriceChange && productInfo.subProducts.length > 0) {
                  const message = productInfo.subProducts
                    .filter((x: ISubProduct) => x.didPriceChange)
                    .map(
                      (product: ISubProduct) =>
                        `The price of ${product.productName} has changed from $${product.price} to $${product.currentPrice} per loan      `,
                    );

                  toastWarning(
                    <ToastMessage
                      title="Price Change"
                      message={JSON.stringify(message)}
                      primaryText=""
                      secondaryText=""
                    />,
                  );
                }
              }
            }

            onChangeBPOLoanError(isCompletedSuccessfully);

            if (order.orderId === '') {
              onSaveOrderId(orderId);
              // history.push(
              //   `/unsubmitted-orders/${orderId}/${order.product.productName}`,
              // );
            }

            queryClient.invalidateQueries(['orders', 'UnsubmittedOrders']);

            if (onNextStep) {
              // Use a timeout to let the state update
              setTimeout(() => {
                onNextStep();
              }, 300);
            }
          },
          onError: e => {
            if (e.response?.data.errors) {
              e.response.data.errors.forEach(err => {
                toastError(
                  <ToastMessage title="Save Error" message={err.message} />,
                );
              });
            } else {
              toastError(
                <ToastMessage
                  title="Save Error"
                  message="An error occurred while processing the request."
                />,
              );
            }
          },
        });
      }
    },
    [
      onChangeBPOLoanError,
      onChangeBPOValidationData,
      onSaveOrderId,
      order.bpoLoan.manualLoans,
      order.bpoLoan.metaData,
      order.division,
      order.orderId,
      order.product.productId,
      order.product.subProducts,
      order.product.vendorId,
      setBPOLoansMutation,
      toastWarning,
    ],
  );

  const myOrderIdentifier = React.useRef(order.orderIdentifier);

  React.useEffect(() => {
    myOrderIdentifier.current = order.orderIdentifier;
  }, [order.orderIdentifier]);

  const loanDetailsStep = useLoanDetailsStep({
    isBPOLoanValid,
    isManualBPOValid,
    onSetBPOLoans,
  });

  const errorsStep = useErrorsStep({
    error,
    onValidateBPOErrors,
  });

  const addressCorrectionStep = useAddressCorrectionStep({
    onValidateBPOCorrections: onValidateBpoCorrections,
  });

  const reviewSubmitStep = useReviewSubmitStep({
    onSubmitBPOLoans,
    submitBPOLoansLabel,
  });

  return React.useMemo(
    () => ({
      isLoading: isValidateBpoOrderLoading || isValidateBpoCorrectionsLoading,
      steps: [
        selectProductStep,
        loanDetailsStep,
        errorsStep,
        addressCorrectionStep,
        reviewSubmitStep,
      ],
    }),
    [
      addressCorrectionStep,
      errorsStep,
      isValidateBpoCorrectionsLoading,
      isValidateBpoOrderLoading,
      loanDetailsStep,
      reviewSubmitStep,
      selectProductStep,
    ],
  );
};
