import styled from 'styled-components';
import uploadedIcon from '../../../assets/checkbox-marked-circle-outline.svg';
import errorIcon from '../../../assets/icon-error-large.svg';
import deleteIcon from '../../../assets/icon-delete-small.svg';

type UploadFileProps = {
  hasMatch?: boolean;
};

export const UploadedFile = styled.div<UploadFileProps>`
  background-color: white;
  background-image: ${props =>
    props.hasMatch ? `url(${uploadedIcon})` : `url(${errorIcon})`};
  background-repeat: no-repeat;
  background-size: 28px 28px;
  background-position: 8px center;
  border: solid 1px rgb(177, 179, 180);
  height: 70px;
  width: 360px;
  padding: 8px 16px 5px 44px;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const DeleteButton = styled.button`
  width: 16px;
  height: 18px;
  background-image: url(${deleteIcon});
  background-repeat: no-repeat;
  background-size: 16px 18px;
  margin-left: auto;

  &:focus {
    outline: none;
  }
`;

export const Mismatch = styled.span`
  color: #bd0e08;
`;

export const Match = styled.span`
  color: #36b300;
`;

export const Grid = styled.table`
  width: 857px;
  border-collapse: collapse;
  background-color: white;

  th {
    text-align: left;
    font-size: 14px;
  }

  td {
    font-size: 14px;
    color: #bd0e08;
    font-weight: 600;
  }

  td,
  th {
    border: solid 1px #d1d1d1;
    padding: 5px 10px;
  }

  tr:odd {
    background-color: #fafafa;
  }

  tr:even {
    background-color: #f0f0f0;
  }

  tr.hasMatch td {
    color: #36b300;
  }
`;
