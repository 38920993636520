/* eslint-disable react/function-component-definition */
import React, { CSSProperties } from 'react';
import { LicenseManager } from 'ag-grid-enterprise';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Editor from './views/Editor';
import { UsersProvider } from './contexts/UsersContext';
import { useAuthConfig } from './contexts';
import { EntitlementsProvider } from './contexts/EntitlementsContext';
import { Modal } from './components/Modal';
import { CompaniesProvider } from './contexts/CompaniesContext';
import { ServiceConfigProvider } from './contexts/ServiceConfigContext';
import { WithChildren } from './types';

type AdminProps = Partial<WithChildren> & {
  editorStyling?: CSSProperties;
};

const Admin: React.FC<AdminProps> = ({ children, editorStyling }) => {
  const authConfig = useAuthConfig();
  LicenseManager.setLicenseKey(authConfig.agGridLicense);
  return (
    <DndProvider debugMode backend={HTML5Backend}>
      <CompaniesProvider>
        <EntitlementsProvider>
          <UsersProvider>
            <ServiceConfigProvider>
              {children}
              <Editor styling={editorStyling} />
              <Modal />
            </ServiceConfigProvider>
          </UsersProvider>
        </EntitlementsProvider>
      </CompaniesProvider>
    </DndProvider>
  );
};

export default Admin;
