import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Animation = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.5);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */

  & div {
    box-sizing: content-box;
  }

  div {
    box-sizing: border-box !important;
  }

  & > div {
    position: absolute;
    width: 34px;
    height: 34px;
    top: 8px;
    left: 8px;
    border-radius: 50%;
    border: 4px solid #000;
    border-color: #fff transparent #fff transparent;
    animation: ldio-lvijpe7xwqp 1s linear infinite;
  }

  & > div:nth-child(2) {
    border-color: transparent;
  }

  & > div:nth-child(2) div {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: rotate(45deg);
  }

  & > div:nth-child(2) div:before,
  & > div:nth-child(2) div:after {
    content: '';
    display: block;
    position: absolute;
    width: 4px;
    height: 4px;
    top: -4px;
    left: 11px;
    background: #a6a6a6; /* color here should be only #000000 (light background) or #FFFFFF (dark background) depending on usage */
    border-radius: 50%;
    box-shadow: 0 30px 0 0 #a6a6a6;
  }

  & > div:nth-child(2) div:after {
    left: -4px;
    top: 11px;
    box-shadow: 30px 0 0 0 #a6a6a6;
  }
`;

export const Container = styled.div`
  margin-left: 5px;
  width: 25px;
  height: 25px;
  display: inline-block;
  overflow: hidden;
  background: none;

  animation: ${rotate} 2s linear infinite;
`;
