import { parseDate } from '../helpers/parseDate';

/** The data for the user from the authentication system used (their profile in Okta, their whatever
 * in whatever else we might support in the future) */
export class UserSystemData {
  found: boolean;

  firstName: string;

  lastName: string;

  email: string;

  lastUpdated: Date;

  constructor(found: boolean) {
    this.found = found;
    this.firstName = '';
    this.lastName = '';
    this.email = '';
    this.lastUpdated = new Date(0);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromJson(json: any) {
    const data = new UserSystemData(json !== null && json !== undefined);
    data.firstName = json.firstName;
    data.lastName = json.lastName;
    data.email = json.email;
    data.lastUpdated = parseDate(json.lastUpdated, data.lastUpdated);
    return data;
  }
}
