import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  width: 100%;
  height: 50px;
  border: 1px solid #d1d1d1;
  background-color: #ffffff;
  background-size: cover;

  border-top-width: 1px;
  border-bottom-width: 1px;
  border-style: solid;
  border-color: #d2d2d2;
  background-image: linear-gradient(0deg, #e6e6e6 0%, #ececec 100%);
  background-position: center center;
  background-size: cover;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const ContainerSave = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const showLabel = keyframes`
0%{opacity: 1}
100%{opacity:0}
`;

export const LabelSaveChanges = styled.div`
  color: #36b300;
  animation-name: ${showLabel};
  animation-duration: 5s;
  opacity: 0;
`;
