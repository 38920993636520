import { IEvent } from '@samc/common';
import { TextButtonProps } from '../components/Button';

export class OpenModalEvent implements IEvent {
  public name: string = OpenModalEvent.eventName;

  static eventName: 'OpenModalEvent' = 'OpenModalEvent' as const;

  message: string;

  actions: TextButtonProps[];

  constructor(message: string, actions: TextButtonProps[]) {
    this.message = message;
    this.actions = actions;
  }
}
