import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: 80px;
  border-bottom-width: 1px;
  border-style: solid;
  border-color: #d2d2d2;
  background-image: linear-gradient(0deg, #e6e6e6 0%, #ececec 100%);
  background-position: center center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;

  /* width: 100%;

  background-image: linear-gradient(0deg, #e6e6e6 0%, #ececec 100%);
  background-position: center center;
  background-size: cover; */

  font-family: ${props => props.theme.fontFamily.heading};
  font-size: ${props => props.theme.fontSize.xl3};
  color: ${props => props.theme.colors.dark};
`;

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  width: 14%;
`;

export const Center = styled.div`
  flex: 1;
  align-self: center;
  margin: auto;
  display: flex;
  align-items: center;
`;
export const Right = styled.div`
  align-self: flex-end;
  margin: auto 0 auto auto;
  display: flex;
  align-items: center;
  width: 14%;
`;

export const BottomLeft = styled.div`
  font-family: ${props => props.theme.fontFamily.heading};
  font-size: ${props => props.theme.fontSize.xl2};
  color: #000000;
  line-height: 1.2;
  font-weight: 600;
`;

export const TopLeft = styled.div`
  color: #8a929c;
  button {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
  }
`;

export const BottomRight = styled.div``;

export const Button = styled.button`
  border: 0 transparent;
  background: transparent;
  outline: 0;
  cursor: pointer;
`;
