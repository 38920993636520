import styled, { css } from 'styled-components';
import { Variant } from '../context/types';

interface StepProps {
  isSubmitted?: boolean;
  variant?: Variant;
}

export const Container = styled.div<StepProps>`
  width: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  pointer-events: none;

  &:not(last-child):not(:first-child)::before {
    position: absolute;
    content: '';
    margin-top: -30px;
    margin-left: -160px;
    height: 1px;
    width: 150px;
    background: #c0c3c5;

    ${props =>
      props.variant &&
      css`
        background: ${props.theme.colors.primary};
        pointer-events: auto;
      `}
  }

  ${props =>
    props.variant &&
    !props.isSubmitted &&
    css`
      pointer-events: auto;
    `}
`;

export const Number = styled.div<StepProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-size: cover;
  font-family: ${props => props.theme.fontFamily.main};
  font-size: ${props => props.theme.fontSize.base};
  text-align: center;
  z-index: 1;

  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);

  ${({ variant }) => {
    switch (variant) {
      case 'current':
        return css`
          border: 1px solid #207cc7;
          background: linear-gradient(0deg, #2ca9ff 0%, #2eafff 100%);
          background-position: center center;
          color: #ffffff;
          text-decoration: none solid rgb(255, 255, 255);
          font-weight: 700;
        `;
      case 'validated':
        return css`
          border: 1px solid #b8b8b8;
          background-color: #ffffff;
          color: #133d91;
          font-weight: 700;

          cursor: pointer;
        `;
      case 'error':
        return css`
          border: 1px solid ${props => props.theme.colors.red};
          background-color: #fff0f0;
          color: ${props => props.theme.colors.red};
        `;
      case 'complete':
        return css`
          border: 1px solid green;
          background-color: green;
          color: #ffffff;
          text-decoration: none solid rgb(255, 255, 255);
        `;
      default:
        return css`
          border: 1px solid #a6a6a6;
          background-color: #d9d9d9;
          color: rgba(0, 0, 0, 0.4);
        `;
    }
  }}
`;

export const StepTitle = styled.div<StepProps>`
  margin-top: 10px;
  font-family: ${props => props.theme.fontFamily.main};
  font-size: 15px;
  text-align: center;
  line-height: 1.5;

  ${({ variant }) => {
    switch (variant) {
      case 'current':
        return css`
          color: ${props => props.theme.colors.dark};
          font-weight: 700;
        `;
      case 'validated':
        return css`
          color: ${props => props.theme.colors.primary};

          cursor: pointer;
        `;
      case 'error':
        return css`
          color: ${props => props.theme.colors.red};
        `;
      case 'complete':
        return css`
          color: green;
          text-decoration: none solid green;
          font-weight: 600;
        `;
      default:
        return css`
          color: #5c5c5c;
          text-decoration: none solid rgb(92, 92, 92);
        `;
    }
  }}
`;
