import * as React from 'react';
import { useHistory } from 'react-router-dom';
import OrderCard from './OrderCard';
import { Container, Heading, Cards } from './styles';
import { useOrder } from '../../../context/order';
import { useStepper } from '../../Common';
import imgDefaultTrack from '../../../assets/button_track.png';
import imgHoverTrack from '../../../assets/button_track_hover.png';
import imgActiveTrack from '../../../assets/button_track_pressed.png';
import imgDefaultNewOrder from '../../../assets/button_new_order.png';
import imgHoverNewOrder from '../../../assets/button_new_order_hover.png';
import imgActiveNewOrder from '../../../assets/button_new_order_pressed.png';

import { OrderImageStates } from './OrderCard/styles';

const orderTrackingImgs: OrderImageStates = {
  default: imgDefaultTrack,
  hover: imgHoverTrack,
  active: imgActiveTrack,
};

const newOrderImgs: OrderImageStates = {
  default: imgDefaultNewOrder,
  hover: imgHoverNewOrder,
  active: imgActiveNewOrder,
};

interface OrderCardListProps {
  orderId?: string;
}
function OrderCardList({ orderId }: OrderCardListProps) {
  const history = useHistory();
  const { onResetSteps } = useStepper();
  const {
    orderActions: { onInitOrder },
  } = useOrder();

  const handleStartNewOrder = () => {
    history.push(`/new-order`);
    onResetSteps();
    onInitOrder();
  };

  return (
    <Container>
      <Heading>Next, you can</Heading>
      <Cards>
        <OrderCard
          images={orderTrackingImgs}
          onClick={() => {
            handleStartNewOrder();
            history.push(`/order-tracking?orderId=${orderId}`);
          }}
        />
        <OrderCard images={newOrderImgs} onClick={handleStartNewOrder} />
      </Cards>
    </Container>
  );
}

export default OrderCardList;
