import { AxiosError } from 'axios';
import { IComboBoxOption } from '@fluentui/react';

export const formatDate = (date: string): string => {
  const gmtDate = new Date(date);

  return new Date(
    gmtDate.getTime() - gmtDate.getTimezoneOffset() * 60 * 1000,
  ).toLocaleString([], {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  });
};

export const formatDateUTC = (date: string): string => {
  const gmtDate = new Date(date);

  return gmtDate.toLocaleString([], {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  });
};

export const formatCurrency = (price: number): string => {
  return price.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
};

export const formatElementId = (value: string, prefix: string): string => {
  return `${prefix}-${value.replace(' ', '-').toLowerCase()}`;
};

export const equalArrays = (
  a: (number | string)[],
  b: (number | string)[],
): boolean => {
  return a.length === b.length && a.every((v, i) => v === b[i]);
};

export type Option = { [key: string]: string };
export interface FormatCombobox {
  options: Option[];
  key: string;
  text: string;
}
export const formatComboBox = (params: FormatCombobox): IComboBoxOption[] => {
  const options = params.options.map(option => ({
    key: option[params.key],
    text: option[params.text],
  }));

  return options;
};

export async function formatErrorMessage(
  err: AxiosError<any, any>,
): Promise<string> {
  if (err.response && err.response.status >= 500) {
    return 'Internal server error';
  }

  if (err.response && err.response.data) {
    if (
      err.response.data.errors &&
      err.response.data.errors.length &&
      err.response.data.errors[0].message
    ) {
      return err.response.data.errors[0].message;
    }
    if (
      err.response &&
      err.response.data &&
      err.response.data.type === 'text/plain'
    ) {
      return (err.response.data as Blob).text();
    }
  }

  return err.message;
}

export function setTruncatedValue(value: string, length: number) {
  return value.length > length
    ? value.substring(0, length - 2).concat('...')
    : value.substring(0, length + 1);
}

export function replaceWhiteSpaceWithSpace(string: string): string {
  if (typeof string !== 'string') {
    return string;
  }
  return string.replace(/\s/g, ' ') as string;
}
