import AnimatedLoader from '@samc/react-ui-core/lib/atoms/AnimatedLoader/AnimatedLoader';
import * as React from 'react';
import { Modal, Button } from '..';

interface DownloadingProps {
  isOpen: boolean;
  onDismiss?: () => void;
  width?: number | string;
  height?: number | string;
}

const Downloading = React.forwardRef(
  (
    { isOpen, onDismiss, width, height, ...props }: DownloadingProps,
    ref: React.LegacyRef<HTMLDivElement>,
  ) => {
    return (
      <Modal
        onDismiss={onDismiss}
        width={width}
        height={height}
        isOpen={isOpen}
        suppressCloseButton
        {...props}
      >
        <div ref={ref} />
        <AnimatedLoader size={100} dark />
        <h3>Download Processing</h3>
        <p>Please wait...</p>
        <Button variant="tertiary" alternate width="100%" onClick={onDismiss}>
          Cancel
        </Button>
      </Modal>
    );
  },
);

Downloading.displayName = 'Downloading';

export default Downloading;
