import axios from 'axios';

let gatewayApiUrl = window.config.gatewayApiUrl;
if (process.env.REACT_APP_GATEWAY_API_URL) {
  gatewayApiUrl = process.env.REACT_APP_GATEWAY_API_URL;
}

const api = axios.create({
  baseURL: gatewayApiUrl,
  timeout: 1000 * 10 * 6 * 15, // 15 minutes,
});

export default api;

export const BPO_ENDPOINT_PATH = 'V2BPOOrderForFakeData';
