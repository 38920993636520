import { IEvent, Guid } from '@samc/common';

export class UserAddedToRoleEvent implements IEvent {
  public name: string = UserAddedToRoleEvent.eventName;

  static eventName = 'UserAddedToRoleEvent' as const;

  roleId: Guid;

  userId: Guid;

  constructor(userId: Guid, roleId: Guid) {
    this.userId = userId;
    this.roleId = roleId;
  }
}
