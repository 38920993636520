import styled from 'styled-components';

type StepContainerProps = {
  align?: 'center' | 'flex-start' | 'flex-end';
};

export const StepContainer = styled.div<StepContainerProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: ${props => (props.align ? props.align : 'flex-start')};
  width: 100%;
  padding: 30px;
  gap: 30px;
  /* overflow: auto; */
`;

export const Instructions = styled.div`
  font-size: ${props => props.theme.fontSize.xl2};
`;

export const RoundButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  border: 1px solid #cecece;
  background-color: #ffffff;
  background-size: cover;
  font-size: 14px;
  font-weight: bold;
  color: #133d91;
  text-decoration: none solid rgb(19, 61, 145);
  text-align: center;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
`;
